import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { Alert } from '../../../common/alerts/Alert';
import { Button } from '../../../common/buttons/Button';
import { OnboardingAppItem } from './OnboardingAppItem';
import { StyledBorderBox } from '../../../common/LayoutComponents';
import {
    StyledOnboardingActions,
    StyledOnboardingBox,
    StyledOnboardingBoxContent,
    StyledOnboardingBoxHeader,
} from '../../OnboardingComponents';

interface OnboardingAppSelectorProps {
    apps: {
        uid: string;
        name: string;
    }[];
    errors?: string;
    saving?: boolean;
    selectedApps?: string[];
    onGoBack: () => void;
    onSave: (apps: string[]) => void;
}

const StyledAppContainer = styled(StyledBorderBox)(({ theme }) => ({
    maxHeight: 496,
    overflow: 'auto',
    padding: theme.spacing(3),
}));

export const OnboardingAppSelector: React.FC<OnboardingAppSelectorProps> = ({
    apps,
    errors,
    saving = false,
    selectedApps,
    onGoBack,
    onSave,
}) => {
    const [currentSelectedApps, setCurrentSelectedApps] = useState<string[]>(selectedApps ?? []);

    const handleSelectApp = (uid: string): void => {
        if (currentSelectedApps.includes(uid)) {
            setCurrentSelectedApps(currentSelectedApps.filter((sa) => sa !== uid));
        } else {
            setCurrentSelectedApps([...currentSelectedApps, uid]);
        }
    };

    const appItems = apps
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((app) => (
            <Grid xs={2}>
                <OnboardingAppItem
                    key={app.uid}
                    name={app.name}
                    selected={currentSelectedApps.includes(app.uid)}
                    uid={app.uid}
                    onSelect={handleSelectApp}
                />
            </Grid>
        ));

    const appsNextButtonText = `Next ${currentSelectedApps.length > 0 ? '(' + currentSelectedApps.length + ')' : ''}`;

    return (
        <StyledOnboardingBox sx={{ maxWidth: 900 }}>
            <StyledOnboardingBoxHeader>
                <Typography variant="h5" component="h2">
                    What apps do you use?
                </Typography>
                <Typography variant="subtitle1" fontWeight="normal" color="text.secondary">
                    Personalise your experience by providing details about yourself
                </Typography>
            </StyledOnboardingBoxHeader>
            <Divider />
            <StyledOnboardingBoxContent>
                {errors && <Alert severity="error" title={errors} />}
                <Typography variant="subtitle1">Select all that apply</Typography>
                <StyledAppContainer>
                    <Grid container spacing={2}>
                        {appItems}
                    </Grid>
                </StyledAppContainer>
            </StyledOnboardingBoxContent>
            <Divider />
            <StyledOnboardingActions>
                <Button variant="text" onClick={() => onGoBack()}>
                    Back
                </Button>
                <Button data-pendo="appsSubmitted" disabled={saving} onClick={() => onSave(currentSelectedApps)}>
                    {appsNextButtonText}
                </Button>
            </StyledOnboardingActions>
        </StyledOnboardingBox>
    );
};
