import { useState } from 'react';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { Alert } from '../../../common/alerts/Alert';
import { Button } from '../../../common/buttons/Button';
import { CopyTextField } from '../../../common/inputs/custom/CopyTextField';
import { Dialog } from '../../../common/Dialog';
import { IconCircle } from '../../../common/IconCircle';
import { OrderedList, makeNonListItem } from '../../../common/lists/OrderedList';
import { ProductIcon } from '../../../icons/ProductIcon';
import { SecretTextField } from '../../../common/inputs/custom/SecretTextField';
import { StyledBorderBox, StyledBorderBoxContent, StyledBorderBoxSubHeader } from '../../../common/LayoutComponents';
import { eventListenerDocumentationUrl } from '../../../../utils/documentation';
import { handleKeyDown } from '../../../../utils/input';
import { APP } from '@avst-stitch/repository-lib/constants';

interface MicrosoftEventListenerSetupDialogProps {
    errors?: string;
    loading?: boolean;
    saving?: boolean;
    open?: boolean;
    webhookBaseUrl: string;
    webhookUrlId: string;
    workspaceLocked?: boolean;
    onClose: () => void;
    onSave: (token: string) => void;
}

export const MicrosoftEventListenerSetupDialog: React.FC<MicrosoftEventListenerSetupDialogProps> = ({
    errors,
    loading = false,
    open = false,
    saving = false,
    webhookBaseUrl,
    webhookUrlId,
    workspaceLocked = false,
    onClose,
    onSave,
}) => {
    const appName = APP.MICROSOFT.NAME;

    const [token, setToken] = useState('');

    const handleSaveToken = (): void => {
        onSave(token);
    };

    const canSave = !loading && !saving && !!token;

    const listContent = [
        <Typography>
            Open <strong>Microsoft Teams</strong> and navigate to the team you want to setup the event listener for.
        </Typography>,
        <Typography>
            Click on the <strong>three dots</strong> next to the team.
        </Typography>,
        <Typography>
            Click on <strong>Manage team</strong>.
        </Typography>,
        <Typography>
            Click on the <strong>Apps</strong> tab.
        </Typography>,
        <Typography>
            On the bottom right, click on <strong>Create an outgoing webhook</strong>.
        </Typography>,
        'Give the webhook a name. This is the name you will use to invoke the webhook on the channel.',
        <Typography>
            Paste the copied URL into the <strong>Callback URL</strong> field.
        </Typography>,
        makeNonListItem(
            <CopyTextField fullWidth label="Webhook URL" value={`${webhookBaseUrl}/${webhookUrlId}`} />,
            'microsoft-webhook-url'
        ),
        'Give the webhook a description to make it easier for your team to know the purpose of this webhook.',
        <Typography>
            Click on <strong>Create</strong>. A security token will appear on the screen. Copy this token and paste it
            in the <strong>Security Token</strong> field below.
        </Typography>,
        makeNonListItem(
            <SecretTextField
                disabled={workspaceLocked}
                fullWidth
                label="Security Token"
                placeholder="Enter token"
                value={token}
                onChange={(e) => setToken(e.target.value.trim())}
            />,
            'microsoft-security-token'
        ),
    ];

    return (
        <>
            <Dialog
                leftButton={
                    <Button onClick={() => window.open(eventListenerDocumentationUrl)} variant="text">
                        Learn more
                    </Button>
                }
                buttons={[
                    <Button disabled={loading} onClick={onClose} variant="outlined">
                        Close
                    </Button>,
                    <Button busy={saving} disabled={!canSave} onClick={handleSaveToken}>
                        Save changes
                    </Button>,
                ]}
                icon={<IconCircle icon={<ProductIcon name={appName} />} size="medium" />}
                open={open}
                title="Event listener setup"
                subtitle={appName}
                onClose={onClose}
                onKeyDown={(event) =>
                    handleKeyDown({ event, enterCondition: canSave, enterFn: () => onSave(token), escFn: onClose })
                }
                loading={loading}
            >
                {workspaceLocked && (
                    <Alert
                        severity="warning"
                        title="The workspace is currently locked and the token field cannot be edited"
                    />
                )}
                {errors && <Alert severity="error" title={errors} />}
                <StyledBorderBox>
                    <StyledBorderBoxSubHeader>
                        <Typography variant="subtitle1" component="h6">
                            How to set up an event listener
                        </Typography>
                        <Typography color="text.secondary">
                            Create a webhook in {appName} channel to listen to events.
                        </Typography>
                    </StyledBorderBoxSubHeader>
                    <Divider />
                    <StyledBorderBoxContent gap={1.5}>
                        <OrderedList content={listContent} id="microsoft" />
                    </StyledBorderBoxContent>
                </StyledBorderBox>
            </Dialog>
        </>
    );
};
