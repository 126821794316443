import { APP } from '@avst-stitch/repository-lib/constants';
import { BehaviorSubject, map, Subject } from 'rxjs';
import {
    connectionCreatedAction$,
    ConnectionDetails,
    connectionCreatedFirstTimeAction$,
    connectionCreatedFirstTime$,
} from '..';
import { createConnection, saveConnection } from '../../../data/connection';
import { InformativeError } from '../../../utils/error';
import { configTopic$ } from '../../config';
import { publishLocalFeedbackEventAction$ } from '../../feedback';
import { monitor } from '../../monitor';
import {
    initiate,
    loadLoggedInUserConnectionsWhenEventIsEmitted,
    saveConnectionAllowed,
    openConsentWindow,
    handleManageConnectionError,
    TimeoutError,
} from '../utils';
import { promptQuestion } from '../../confirm';
import { AYSYWTSAETCWHAI } from '../../../i18n';

interface SalesforceConnectionDetails extends ConnectionDetails {
    url?: string;
    instanceUrl?: string;
    clientId?: string;
    clientSecret?: string;
}

interface SalesforceAppSetupSaveProps {
    instanceUrl: string;
    clientId: string;
    clientSecret: string;
}

export const salesforceManageConnectionOpenDialogAction$ = monitor(
    'salesforceManageConnectionOpenDialogAction$',
    new Subject<SalesforceConnectionDetails>()
);
export const salesforceManageConnectionCloseDialogAction$ = monitor(
    'salesforceManageConnectionCloseDialogAction$',
    new Subject<void>()
);

export const salesforceAppSetupOpenDialogAction$ = monitor(
    'salesforceAppSetupOpenDialogAction$',
    new Subject<SalesforceConnectionDetails>()
);
export const salesforceAppSetupCloseDialogAction$ = monitor(
    'salesforceAppSetupCloseDialogAction$',
    new Subject<void>()
);
export const salesforceAppSetupDialogOpen$ = monitor('salesforceAppSetupDialogOpen$', new BehaviorSubject(false));
export const salesforceAppSetupAuthorizeAction$ = monitor(
    'salesforceAppSetupAuthorizeAction$',
    new Subject<SalesforceAppSetupSaveProps>()
);
export const salesforceAppSetupDialogErrors$ = monitor(
    'salesforceAppSetupDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const salesforceSaveConnectionAction$ = monitor('salesforceSaveConnectionAction$', new Subject<string>());
export const salesforceConnectionCreatedAction$ = monitor('salesforceConnectionCreatedAction$', new Subject<string>());
export const salesforceConnectionSavedAction$ = monitor(
    'salesforceConnectionSavedAction$',
    new Subject<{ uid: string; connectionTypeUid: string }>()
);
export const salesforceManageConnectionInitiateSetupAction$ = monitor(
    'salesforceManageConnectionInitiateSetupAction$',
    new Subject<string>()
);

export const salesforceManageConnectionDialogOpen$ = monitor(
    'salesforceManageConnectionDialogOpen$',
    new BehaviorSubject(false)
);
export const salesforceConnectionSaving$ = monitor('salesforceConnectionSaving$', new BehaviorSubject(false));
export const salesforceManageConnectionAuthorizeLoading$ = monitor(
    'salesforceManageConnectionAuthorizeLoading$',
    new BehaviorSubject(false)
);
export const salesforceManageConnectionDetails$ = monitor(
    'salesforceManageConnectionDetails$',
    new BehaviorSubject<SalesforceConnectionDetails | undefined>(undefined)
);
export const salesforceManageConnectionDialogErrors$ = monitor(
    'salesforceManageConnectionDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const salesforceAppSetupDialogStage$ = monitor('salesforceAppSetupDialogStage$', new BehaviorSubject(0));

salesforceAppSetupOpenDialogAction$.subscribe((details) => {
    salesforceAppSetupDialogErrors$.next(undefined);
    salesforceManageConnectionDialogErrors$.next(undefined);
    salesforceAppSetupDialogOpen$.next(true);
    salesforceAppSetupDialogStage$.next(0);
    salesforceManageConnectionDetails$.next(details);
});

salesforceAppSetupCloseDialogAction$.subscribe(() => {
    salesforceAppSetupDialogOpen$.next(false);
    salesforceManageConnectionAuthorizeLoading$.next(false);
});

salesforceManageConnectionOpenDialogAction$.subscribe((details) => {
    salesforceAppSetupDialogErrors$.next(undefined);
    salesforceManageConnectionDialogErrors$.next(undefined);
    salesforceManageConnectionDialogOpen$.next(true);
    salesforceManageConnectionDetails$.next(details);
});

salesforceManageConnectionCloseDialogAction$.subscribe(() => {
    salesforceManageConnectionDialogOpen$.next(false);
    salesforceAppSetupDialogOpen$.next(false);
    salesforceManageConnectionDetails$.next(undefined);
});

salesforceConnectionCreatedAction$.subscribe((uid) => connectionCreatedAction$.next(uid));

salesforceAppSetupAuthorizeAction$
    .pipe(
        map(async (appInfoProps) => {
            salesforceConnectionSaving$.next(true);
            salesforceAppSetupDialogErrors$.next(undefined);

            const { uid, connectionType } = salesforceManageConnectionDetails$.value ?? {};
            try {
                if (!connectionType) {
                    // eslint-disable-next-line sonarjs/no-duplicate-string
                    throw Error('Salesforce Connector type not defined');
                }
                if (!uid) {
                    throw Error('Salesforce Connector has not been created so cannot configure it');
                }

                const baseUrl = configTopic$.value.connection?.salesforce?.baseUrl;
                if (!baseUrl) {
                    throw new Error('No Salesforce Connector url configured in meta');
                }

                const response = await initiate(
                    {
                        ...appInfoProps,
                        url: appInfoProps.instanceUrl,
                        connectionId: uid,
                        connectionType: 'salesforce',
                    },
                    `${baseUrl}/initiate`
                );
                openConsentWindow(response.location, salesforceManageConnectionAuthorizeLoading$);

                salesforceConnectionCreatedAction$.next(uid);
            } catch (e) {
                if (e instanceof InformativeError || e instanceof TimeoutError) {
                    salesforceAppSetupDialogErrors$.next(e.message);
                } else {
                    salesforceAppSetupDialogErrors$.next(
                        'Failed to save Salesforce application details, please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while saving Salesforce application info', e);
                }
            }

            salesforceConnectionSaving$.next(false);
            salesforceManageConnectionAuthorizeLoading$.next(false);
        })
    )
    .subscribe();

salesforceSaveConnectionAction$
    .pipe(
        map(async (name) => {
            salesforceConnectionSaving$.next(true);
            salesforceManageConnectionDialogErrors$.next(undefined);

            const { uid, connectionType } = salesforceManageConnectionDetails$.value ?? {};

            try {
                if (!connectionType) {
                    throw Error('Salesforce Connector type not defined');
                }
                const connectionTypeUid = connectionType.uid;

                if (uid) {
                    if (saveConnectionAllowed(uid)) {
                        await saveSalesforceConnection(uid, name, connectionTypeUid);
                    } else {
                        promptQuestion({
                            title: AYSYWTSAETCWHAI,
                            onProceed: async () => {
                                await saveSalesforceConnection(uid, name, connectionTypeUid);
                            },
                        });
                    }
                } else {
                    promptQuestion({
                        title: AYSYWTSAETCWHAI,
                        onProceed: async () => {
                            try {
                                const { uid } = await createConnection(
                                    connectionType.uid,
                                    name,
                                    connectionType.apiHandlerTypes[0]?.uid ?? '',
                                    connectionType.eventListenerTypes[0]?.uid ?? ''
                                );

                                salesforceConnectionCreatedAction$.next(uid);
                                salesforceConnectionSavedAction$.next({ uid, connectionTypeUid });
                                salesforceManageConnectionCloseDialogAction$.next();
                                publishLocalFeedbackEventAction$.next({
                                    level: 'SUCCESS',
                                    message: 'Connector created.',
                                });
                                connectionCreatedFirstTimeAction$.next();
                            } catch (e) {
                                handleManageConnectionError(
                                    e,
                                    salesforceManageConnectionDialogErrors$,
                                    APP.SALESFORCE.NAME
                                );
                            }
                        },
                    });
                }
            } catch (e) {
                handleManageConnectionError(e, salesforceManageConnectionDialogErrors$, APP.SALESFORCE.NAME);
            }

            salesforceConnectionSaving$.next(false);
        })
    )
    .subscribe();

salesforceManageConnectionInitiateSetupAction$
    .pipe(
        map(async (name) => {
            salesforceConnectionSaving$.next(true);
            salesforceManageConnectionAuthorizeLoading$.next(true);
            salesforceManageConnectionDialogErrors$.next(undefined);

            const {
                uid,
                connectionType,
                name: currentName,
                ...restOfDetails
            } = salesforceManageConnectionDetails$.value ?? {};
            try {
                if (!connectionType) {
                    throw Error('Salesforce Connector type not defined');
                }
                const connectionTypeUid = connectionType.uid;

                if (uid) {
                    if (currentName !== name) {
                        await saveConnection(uid, name);
                    }

                    salesforceConnectionSavedAction$.next({ uid, connectionTypeUid });
                    salesforceAppSetupOpenDialogAction$.next({
                        ...restOfDetails,
                        uid,
                        connectionType,
                        name,
                    });
                } else {
                    const { uid } = await createConnection(
                        connectionType.uid,
                        name,
                        connectionType.apiHandlerTypes[0]?.uid ?? '',
                        connectionType.eventListenerTypes[0]?.uid ?? ''
                    );

                    salesforceConnectionCreatedAction$.next(uid);
                    salesforceConnectionSavedAction$.next({ uid, connectionTypeUid });
                    salesforceManageConnectionDetails$.next({
                        connectionType,
                        name,
                        uid,
                    });

                    salesforceConnectionSaving$.next(false);

                    salesforceAppSetupOpenDialogAction$.next({
                        connectionType,
                        name,
                        uid,
                    });
                    connectionCreatedFirstTime$.next(true);
                }
            } catch (e) {
                handleManageConnectionError(e, salesforceManageConnectionDialogErrors$, APP.SALESFORCE.NAME);
                salesforceManageConnectionAuthorizeLoading$.next(false);
            }

            salesforceConnectionSaving$.next(false);
        })
    )
    .subscribe();

loadLoggedInUserConnectionsWhenEventIsEmitted(salesforceConnectionSavedAction$);

const saveSalesforceConnection = async (uid: string, name: string, connectionTypeUid: string): Promise<void> => {
    try {
        await saveConnection(uid, name);

        salesforceConnectionSavedAction$.next({ uid, connectionTypeUid });
        salesforceManageConnectionCloseDialogAction$.next();
        publishLocalFeedbackEventAction$.next({
            level: 'SUCCESS',
            message: 'Connector saved.',
        });
        if (connectionCreatedFirstTime$.value) {
            connectionCreatedFirstTimeAction$.next();
            connectionCreatedFirstTime$.next(false);
        }
    } catch (e) {
        handleManageConnectionError(e, salesforceManageConnectionDialogErrors$, APP.SALESFORCE.NAME);
    }
};
