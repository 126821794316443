/* eslint-disable sonarjs/cognitive-complexity */
import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import { Button } from '../../common/buttons/Button';
import { BillingDetailsList } from './BillingDetailsList';
import { LoadingSpinner } from '../../common/LoadingSpinner';
import { DialogAlert, DialogTitleMain } from '../../for-deprecation/dialog/DialogComponents';
import { IconCircle } from '../../for-deprecation/IconCircle';
import { handleKeyDown } from '../../../utils/input';

interface SelectBillingDetailsDialogProps {
    billingEntities?: {
        uid: string;
        contactName: string;
        contactEmail: string;
    }[];
    errors?: string;
    loading?: boolean;
    onCancel: () => void;
    onCreate: (event: SelectBillingDetailsEvent) => void;
    open?: boolean;
    saving?: boolean;
}

export interface SelectBillingDetailsEvent {
    newBillingEntity?: {
        contactName: string;
        contactEmail: string;
    };
    existingBillingEntityUid?: string;
}
type BillingForm = 'saved' | 'create';

export const SelectBillingDetailsDialog: React.FC<SelectBillingDetailsDialogProps> = ({
    billingEntities = [],
    errors,
    loading = false,
    open = false,
    saving = false,
    onCancel,
    onCreate,
}) => {
    const [billingFormType, setBillingFormType] = useState<BillingForm>(billingEntities.length ? 'saved' : 'create');
    const [billingContactName, setBillingContactName] = useState('');
    const [billingContactEmail, setBillingContactEmail] = useState('');
    const [selectedBillingEntityUid, setSelectedBillingEntityUid] = useState(billingEntities[0]?.uid);

    useEffect(() => {
        if (!billingEntities.length) {
            setBillingFormType('create');
        } else {
            setBillingFormType('saved');
        }
    }, [billingEntities.length]);

    useEffect(() => {
        setBillingContactName('');
        setBillingContactEmail('');
        setSelectedBillingEntityUid(billingEntities[0]?.uid);
        setBillingFormType(billingEntities.length ? 'saved' : 'create');
    }, [open]);

    const hasBillingDetails =
        billingFormType === 'saved' ? !!selectedBillingEntityUid : !!billingContactEmail && !!billingContactName;

    const handleSelectBillingDetails = (): void => {
        const event: SelectBillingDetailsEvent = {};
        if (billingFormType === 'create') {
            event.newBillingEntity = {
                contactEmail: billingContactEmail,
                contactName: billingContactName,
            };
        } else {
            event.existingBillingEntityUid = selectedBillingEntityUid;
        }

        onCreate(event);
    };

    const canProceed = !saving && !loading && hasBillingDetails;

    return (
        <Dialog
            open={open}
            onKeyDown={(event) =>
                handleKeyDown({
                    event,
                    enterCondition: canProceed,
                    enterFn: handleSelectBillingDetails,
                    escFn: onCancel,
                })
            }
        >
            {loading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <DialogTitleMain title="Billing details" icon={<IconCircle icon={<ReceiptLongOutlinedIcon />} />} />
                    <DialogAlert
                        severity="info"
                        title="Please create or select billing details in order to change your team plan."
                    />
                    {errors && <DialogAlert severity="error" title={errors} />}
                    {!!billingEntities.length && (
                        <RadioGroup
                            name="controlled-radio-buttons-group-billing"
                            value={billingFormType}
                            onChange={(e) => setBillingFormType(e.target.value as BillingForm)}
                        >
                            <FormControlLabel
                                value="saved"
                                control={<Radio />}
                                label="Select from saved billing details"
                                sx={{
                                    '&>.MuiTypography-root':
                                        billingFormType === 'saved' ? { fontWeight: 500 } : { fontWeight: 400 },
                                }}
                            />

                            <FormControlLabel
                                value="create"
                                control={<Radio />}
                                label="Create new billing details"
                                sx={{
                                    '&>.MuiTypography-root':
                                        billingFormType === 'create' ? { fontWeight: 500 } : { fontWeight: 400 },
                                }}
                            />
                        </RadioGroup>
                    )}
                    <DialogContent>
                        {billingFormType === 'create' ? (
                            <>
                                <TextField
                                    variant="outlined"
                                    label="Billing contact name"
                                    placeholder="Enter a name"
                                    value={billingContactName}
                                    required
                                    onChange={(event) => {
                                        setBillingContactName(event.target.value);
                                    }}
                                    sx={{ marginTop: 2 }}
                                />
                                <TextField
                                    variant="outlined"
                                    label="Email"
                                    placeholder="Enter an email"
                                    required
                                    value={billingContactEmail}
                                    onChange={(event) => {
                                        setBillingContactEmail(event.target.value);
                                    }}
                                />
                            </>
                        ) : (
                            <BillingDetailsList
                                billingEntities={billingEntities}
                                onSelect={(uid) => {
                                    setSelectedBillingEntityUid(uid);
                                }}
                                selectedBillingEntity={selectedBillingEntityUid}
                            />
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" busy={saving} disabled={saving} onClick={onCancel}>
                            Cancel
                        </Button>
                        <Button busy={saving} disabled={!canProceed} onClick={handleSelectBillingDetails}>
                            Save
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};
