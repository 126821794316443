interface ModalKeyDownOptions {
    enterCondition?: boolean;
    escCondition?: boolean;
    event: React.KeyboardEvent<HTMLElement>;
    enterFn?: () => void;
    escFn?: () => void;
}

export const handleKeyDown = ({
    event,
    enterFn,
    escFn,
    enterCondition = true,
    escCondition = true,
}: ModalKeyDownOptions): void => {
    if (event.key === 'Enter' && enterCondition) {
        enterFn?.();
    } else if (event.key === 'Escape' && escCondition) {
        escFn?.();
    }
};

export const autoFocus = (ref: React.RefObject<HTMLElement>): void => {
    setTimeout(() => {
        ref?.current?.focus();
    }, 100);
};

export const isFocused = (ref: React.RefObject<HTMLElement>): boolean => {
    return ref?.current === document.activeElement;
};

export const isScriptOrEnvVariableNameValid = (name?: string): boolean => {
    return name ? /^[A-Za-z0-9_]*$/.test(name) : true;
};

export const testUrl = async (instanceUrl: string): Promise<boolean> => {
    try {
        const url = new URL(instanceUrl);
        return url.protocol === 'http:' || url.protocol === 'https:';
    } catch {
        return false;
    }
};
