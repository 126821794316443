import { BehaviorSubject, Subject, map, filter } from 'rxjs';
import {
    getLoggedInUserAuditLogs,
    UserAuditLogsRequest,
    UserAuditLogsResponse,
    getLoggedInUserAuditLogsUrl,
    UserAuditLogsUrlRequest,
} from '../data/reporting';
import { InformativeError } from '../utils/error';
import { monitor } from './monitor';

export const auditLogs$ = monitor('auditLogs$', new BehaviorSubject<UserAuditLogsResponse>({ auditLogs: [] }));

export const auditLogsPageLoading$ = monitor('auditLogsPageLoading$', new BehaviorSubject(false));

export const auditLogsPageErrors$ = monitor('auditLogsPageErrors$', new BehaviorSubject<string | undefined>(undefined));

export const getMyAuditLogsAction$ = monitor('getMyAuditLogsAction$', new Subject<UserAuditLogsRequest>());

export const getMyAuditLogsUrlAction$ = monitor('getMyAuditLogsUrlAction$', new Subject<UserAuditLogsUrlRequest>());

export const auditLogsPageGeneratingCsv$ = monitor('auditLogsPageGeneratingCsv$', new BehaviorSubject(false));

export const addAuditLogsAction$ = monitor('addAuditLogsAction$', new Subject<UserAuditLogsResponse>());

getMyAuditLogsAction$
    .pipe(
        filter(({ cursor }) => !!cursor),
        map(async (request) => {
            auditLogsPageErrors$.next(undefined);

            try {
                const auditLogs = await getLoggedInUserAuditLogs(request);
                addAuditLogsAction$.next(auditLogs);
            } catch (e) {
                if (e instanceof InformativeError) {
                    auditLogsPageErrors$.next(e.message);
                } else {
                    auditLogsPageErrors$.next(
                        'Failed to query for Audit logs, please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while querying audit logs', e);
                }
            }
            auditLogsPageLoading$.next(false);
        })
    )
    .subscribe();

getMyAuditLogsUrlAction$
    .pipe(
        map(async (request) => {
            auditLogsPageGeneratingCsv$.next(true);
            try {
                const auditLogsUrl = await getLoggedInUserAuditLogsUrl(request);
                window.open(auditLogsUrl.url, '_self');
            } catch (e) {
                if (e instanceof InformativeError) {
                    auditLogsPageErrors$.next(e.message);
                } else {
                    auditLogsPageErrors$.next(
                        'Failed to generating CSV for Audit logs, please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while generating CSV for audit logs', e);
                }
            }
            auditLogsPageGeneratingCsv$.next(false);
        })
    )
    .subscribe();

addAuditLogsAction$
    .pipe(
        filter(({ auditLogs }) => auditLogs.length > 0),
        map(({ auditLogs, cursor }) => {
            const current = auditLogs$.value;
            return {
                auditLogs: [...current.auditLogs, ...auditLogs],
                cursor,
            };
        })
    )
    .subscribe((auditLogs) => auditLogs$.next(auditLogs));
