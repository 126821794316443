import { useObservableState, useSubscription } from 'observable-hooks';
import { useNavigate } from 'react-location';
import { apps$ } from '../../../../store/apps';
import { connectionCreatedAction$, openConnectionDetailsDialogAction$ } from '../../../../store/connection';
import { loggedInUserConnections$ } from '../../../../store/connections';
import {
    selectedWorkspace$,
    selectedWorkspaceEnvironment$,
    selectedWorkspaceResources$,
    selectedWorkspaceSelectedResource$,
} from '../../../../store/workspace';
import {
    saveApiHandlerAction$,
    savingSelectedApiHandler$,
    selectedApiHandlerDetails$,
    selectedApiHandlerErrors$,
} from '../../../../store/workspace/api-handler';
import { getUserDisplayName } from '../../../../utils/account';
import { useState } from 'react';
import { ApiConnectionDetails } from '../../../../components/workspace-resources/advanced-view/api-connections/ApiConnectionDetails';

export const ApiHandlerDetailsContainer: React.FC = () => {
    const navigate = useNavigate();

    const [createdConnectionUid, setCreatedConnectionUid] = useState<string | undefined>(undefined);

    const errors = useObservableState(selectedApiHandlerErrors$);
    const details = useObservableState(selectedApiHandlerDetails$);
    const saving = useObservableState(savingSelectedApiHandler$);
    const connections = useObservableState(loggedInUserConnections$);
    const apps = useObservableState(apps$);
    const selectedWorkspace = useObservableState(selectedWorkspace$);
    const selectedWorkspaceEnvironment = useObservableState(selectedWorkspaceEnvironment$);
    const workspaceResources = useObservableState(selectedWorkspaceResources$);

    useSubscription(connectionCreatedAction$, (connectionUid) => {
        setCreatedConnectionUid(connectionUid);
    });

    const vendorApiVersions = workspaceResources.apiHandlers
        .flatMap((handler) =>
            handler.uid === details?.uid
                ? handler.libraries.map((library) => ({
                      value: library.uid,
                      name: library.deprecated
                          ? `${library.label} (deprecated)`
                          : library.recommended
                          ? `${library.label} (recommended)`
                          : library.label || '',
                      deprecated: library.deprecated,
                      recommended: library.recommended,
                  }))
                : []
        )
        .sort((l1, l2) => (l2.recommended ? 1 : 0) - (l1.recommended ? 1 : 0));

    const appName = apps.find((a) => a.connectionType.uid === details?.connectionTypeUid)?.connectionType.name;

    const filteredConnections = connections
        .filter((c) => c.connectionType.uid === details?.connectionTypeUid)
        .map((c) => ({
            name: c.name,
            uid: c.uid,
            authorized: c.authorized,
        }));

    if (details?.selectedSharedConnection) {
        filteredConnections.unshift({
            name: `${details.selectedSharedConnection.name} (owned by ${getUserDisplayName(
                details.selectedSharedConnection.ownedBy
            )})`,
            uid: details.uid,
            authorized: details.selectedSharedConnection.authorized,
        });
    }

    const handleCancel = (): void => {
        navigate({ to: '../../' });
        selectedWorkspaceSelectedResource$.next(undefined);
    };

    const handleNewConnection = (): void => {
        const connectionType = (apps ?? [])
            .flatMap((app) => app.connectionType)
            .find((ct) => ct.uid === details?.connectionTypeUid);

        if (connectionType) {
            openConnectionDetailsDialogAction$.next({
                connectionType,
            });
        } else {
            console.error('Connection type not found');
            alert('Connection type not found');
        }
    };

    return (
        <ApiConnectionDetails
            appName={appName ?? ''}
            uid={details?.uid ?? ''}
            saving={saving}
            errors={errors}
            connections={filteredConnections}
            path={details?.path ?? details?.defaultImportPath}
            selectedConnectionUid={details?.connectionUid}
            environmentDeployed={selectedWorkspaceEnvironment?.deployment !== undefined}
            onCancel={handleCancel}
            onSave={(event) => saveApiHandlerAction$.next(event)}
            createdConnectionUid={createdConnectionUid}
            onNewConnection={handleNewConnection}
            workspaceLocked={!!selectedWorkspace?.locked}
            hasImplicitlySharedConnectionAttached={!!details?.selectedSharedConnection}
            selectedApiVersionUid={details?.apiHandlerLibraryUid}
            vendorApiVersions={vendorApiVersions}
        />
    );
};
