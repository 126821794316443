import { createScheduledTrigger as createScheduledTriggerRpc } from '@avst-stitch/repository-lib/lib';
import { getScheduledTrigger as getScheduledTriggerRpc } from '@avst-stitch/repository-lib/lib';
import { saveScheduledTrigger as saveScheduledTriggerRpc } from '@avst-stitch/repository-lib/lib';
import { deleteScheduledTrigger as deleteScheduledTriggerRpc } from '@avst-stitch/repository-lib/lib/rpcs/deleteScheduledTrigger';
import { Response as CreatedScheduledTrigger } from '@avst-stitch/repository-lib/lib/rpcs/createScheduledTrigger';
import { Response as ScheduledTrigger } from '@avst-stitch/repository-lib/lib/rpcs/getScheduledTrigger';
import { Response as ScheduledTriggerDeletion } from '@avst-stitch/repository-lib/lib/rpcs/deleteScheduledTrigger';
import { Request as SaveScheduledTriggerRequest } from '@avst-stitch/repository-lib/lib/rpcs/saveScheduledTrigger';
import { repositoryInvoker } from '../utils/repository';
export type { ScheduledTrigger };

export const createScheduledTrigger = async (workspaceUid: string): Promise<CreatedScheduledTrigger> => {
    return createScheduledTriggerRpc(repositoryInvoker, {
        workspaceUid,
    });
};

export const getScheduledTrigger = async (uid: string, environmentUid?: string): Promise<ScheduledTrigger> => {
    return getScheduledTriggerRpc(repositoryInvoker, {
        uid,
        environmentUid,
    });
};

export const saveScheduledTrigger = async (request: SaveScheduledTriggerRequest): Promise<void> => {
    return saveScheduledTriggerRpc(repositoryInvoker, request);
};

export const deleteScheduledTrigger = async (
    uid: string,
    ignoreWarnings?: boolean
): Promise<ScheduledTriggerDeletion> => {
    return deleteScheduledTriggerRpc(repositoryInvoker, {
        uid,
        ignoreWarnings,
    });
};
