import Typography from '@mui/material/Typography';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PlayCircleOutlineRoundedIcon from '@mui/icons-material/PlayCircleOutlineRounded';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import {
    ResourceActionButton,
    StyledResourceListItemButton,
    StyledResourceActionButtons,
    StyledResourceIcon,
    StyledResourceInfo,
    StyledResourceTitle,
    StyledResourceActionButton,
} from './ResourceTreeComponents';
import { WorkspaceLanguage } from './types';

interface ResourceTreeScriptProps {
    deleting?: boolean;
    deployedEnvironmentMode?: boolean;
    executing?: boolean;
    language?: WorkspaceLanguage;
    name: string;
    readOnlyMode?: boolean;
    selectedNode?: string;
    uid: string;
    unsaved?: boolean;
    onDelete(uid: string): void;
    onOpen(uid: string): void;
    onTrigger?(uid: string): void;
}

export const ResourceTreeScript: React.FC<ResourceTreeScriptProps> = ({
    deleting,
    deployedEnvironmentMode = false,
    executing,
    language = 'ts',
    name,
    readOnlyMode = false,
    selectedNode,
    uid,
    unsaved,
    onDelete,
    onOpen,
    onTrigger,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const selected = selectedNode === uid;
    const showSaveIcon = !deployedEnvironmentMode && !readOnlyMode && unsaved;
    const canTrigger = !deleting && !readOnlyMode;
    const canDelete = !deployedEnvironmentMode && !readOnlyMode;

    return (
        <StyledResourceListItemButton key={uid} onClick={() => onOpen(uid)} selected={selected}>
            <StyledResourceInfo>
                <StyledResourceTitle>
                    <StyledResourceIcon>
                        <img
                            src={process.env.PUBLIC_URL + `/${language !== 'js' ? 'ts' : 'js'}.png`}
                            alt={(language ? language : 'Typescript') + ' icon'}
                        />
                    </StyledResourceIcon>
                    <Typography>{name}</Typography>
                </StyledResourceTitle>
            </StyledResourceInfo>
            <StyledResourceActionButtons>
                {showSaveIcon && (
                    <StyledResourceActionButton disabled>
                        <SaveOutlinedIcon fontSize="small" color="primary" />
                    </StyledResourceActionButton>
                )}
                {canTrigger && (
                    <ResourceActionButton
                        busy={executing}
                        icon={<PlayCircleOutlineRoundedIcon fontSize="small" color="primary" />}
                        tooltipTitle="Trigger script manually"
                        uid={uid}
                        onClick={onTrigger}
                    />
                )}
                {canDelete && (
                    <ResourceActionButton
                        busy={deleting}
                        icon={<DeleteOutlineIcon fontSize="small" color="primary" />}
                        tooltipTitle="Delete"
                        uid={uid}
                        onClick={onDelete}
                    />
                )}
            </StyledResourceActionButtons>
        </StyledResourceListItemButton>
    );
};
