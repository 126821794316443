import { getApps as getAppsRpc } from '@avst-stitch/repository-lib/lib';
import { Response as Apps } from '@avst-stitch/repository-lib/lib/rpcs/getApps';
import { repositoryInvoker } from '../utils/repository';

export type { Apps };

export const getApps = async (): Promise<Apps> => {
    return await getAppsRpc(repositoryInvoker);
};

export type App = Apps[number];
export type ConnectionType = App['connectionType'];
