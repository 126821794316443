import { LoaderFn, UnloaderFn } from 'react-location';
import { segmentAnalyticsTrack } from '../data/segment-analytics';
import { getTemplate } from '../data/template';
import { getWorkspace } from '../data/workspace';
import { LocationGenerics } from '../router';
import { loadApps } from '../store/apps';
import { navigateToWorkspaceFromTemplateEditAction$, selectedReadOnlyTemplate$ } from '../store/templates';
import {
    exitWorkspaceAction$,
    selectedWorkspace$,
    selectedWorkspaceReadOnlyMode$,
    selectedWorkspaceUid$,
    selectEnvironmentAction$,
    selectWorkspaceAction$,
} from '../store/workspace';
import { loadEnvironmentVariables, loadReadmeFile, loadWorkspaces } from '../store/workspace/utils';
import { initEditor, resetEditor } from './workspace/editor';
import { InformativeError } from '../utils/error';
import { loadErrorPage } from '../store/error';
import { loggedInUserDetails$ } from '../store/user';
import { selectedOrganizationUid$ } from '../store/organization';
import { Language } from '../components/workspace-dialogs/language-selector';
import { selectedReadmeFileUid$ } from '../store/workspace/readme';
import { createWorkspaceFromTemplateAction$ } from '../store/workspaces';

export const readOnlyTemplateLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    try {
        //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const templateUid = routeMatch.params.templateUid!;

        const createWorkspaceDialogOpen = routeMatch.search.create;

        if (!selectedReadOnlyTemplate$.value) {
            const template = await getTemplate(templateUid);
            selectedReadOnlyTemplate$.next(template);
        }

        const selectedTemplate = selectedReadOnlyTemplate$.value;
        if (!selectedTemplate) {
            throw new InformativeError('Could not fetch the selected template');
        }
        const [workspace] = await Promise.all([
            getWorkspace(selectedTemplate.workspaceUid, false, true),
            loadWorkspaces(selectedOrganizationUid$.value ?? '', false),
            loadApps(),
            loadEnvironmentVariables(
                selectedReadOnlyTemplate$.value?.workspaceUid ?? '',
                selectedReadOnlyTemplate$.value?.environmentUid ?? ''
            ),
        ]);

        await loadReadmeFile(
            selectedTemplate?.readmeFileUid ?? '',
            selectedTemplate?.workspaceUid ?? '',
            selectedTemplate?.environmentUid ?? ''
        );

        selectedReadmeFileUid$.next(selectedTemplate?.readmeFileUid ?? null);

        selectedWorkspaceReadOnlyMode$.next(true);
        selectEnvironmentAction$.next(selectedTemplate.environmentUid);
        selectWorkspaceAction$.next(selectedTemplate.workspaceUid);
        selectedWorkspace$.next(workspace);

        initEditor(workspace.language as Language);

        if (loggedInUserDetails$.value?.onboardingCompleted && createWorkspaceDialogOpen) {
            createWorkspaceFromTemplateAction$.next();
        }

        segmentAnalyticsTrack('Template Viewed', {
            template: {
                uid: selectedTemplate.templateUid,
                name: selectedTemplate.name,
            },
            userOrigin: loggedInUserDetails$.value?.userOrigin,
        });
    } catch (e) {
        loadErrorPage({
            error: e,
            background: 'paper',
            genericMessage: 'Failed to load template.',
        });

        throw e;
    }

    return {};
};

export const readOnlyTemplateUnloader: UnloaderFn<LocationGenerics> = () => {
    exitWorkspaceAction$.next(selectedWorkspaceUid$.value ?? '');
    selectedReadOnlyTemplate$.next(undefined);
    resetEditor();
};

export const editTemplatePendingLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const templateUid = routeMatch.params.templateUid!;

        const template = await getTemplate(templateUid);
        navigateToWorkspaceFromTemplateEditAction$.next(template);
        selectedReadOnlyTemplate$.next(undefined);
    } catch (e) {
        loadErrorPage({
            error: e,
            pageTitle: 'Templates',
            genericMessage: 'Failed to load edit template view.',
        });

        throw e;
    }

    return {};
};
