import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import React from 'react';
import { AWSConnection } from './AWSAppConfigureDialog';

export enum AWSWizardStage {
    CREATE_USER,
    GENERATE_CREDENTIALS,
    INPUT_CREDENTIALS,
}

export interface AWSWizardStepsProps {
    readonly stage: AWSWizardStage;
}

export const AWSWizardSteps: React.FC<AWSWizardStepsProps> = ({ stage }) => {
    return (
        <Stepper alternativeLabel activeStep={stage}>
            <Step key={AWSWizardStage.CREATE_USER}>
                <StepLabel>Create user</StepLabel>
            </Step>
            <Step key={AWSWizardStage.GENERATE_CREDENTIALS}>
                <StepLabel>Generate access key and secret</StepLabel>
            </Step>
            <Step key={AWSWizardStage.INPUT_CREDENTIALS}>
                <StepLabel>Enter access key and secret</StepLabel>
            </Step>
        </Stepper>
    );
};

export type StageType = React.FC<
    AWSConnection & {
        stage: AWSWizardStage;
        setStage: (stage: AWSWizardStage) => void;
    }
>;
