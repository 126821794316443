import { styled, Theme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AllInboxOutlinedIcon from '@mui/icons-material/AllInboxOutlined';
import CloudOffOutlinedIcon from '@mui/icons-material/CloudOffOutlined';
import CloudOutlinedIcon from '@mui/icons-material/CloudOutlined';
import CodeRoundedIcon from '@mui/icons-material/CodeRounded';
import DeveloperBoardOutlinedIcon from '@mui/icons-material/DeveloperBoardOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined';
import Box from '@mui/material/Box';
import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import SupportOutlinedIcon from '@mui/icons-material/SupportOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { Button } from '../../common/buttons/Button';
import { Dropdown } from '../../common/inputs/dropdown/Dropdown';
import { DropdownMenu } from '../../common/inputs/dropdown/DropdownMenu';

export interface WorkspaceHeaderProps {
    deploymentButtonDisabled?: boolean;
    environments?: Environment[];
    isDraft?: boolean;
    isTemplate: boolean;
    loadingEnvironments?: boolean;
    locked?: boolean;
    manageEnvironmentDeploymentTargetsButtonDisabled?: boolean;
    organizationName?: string;
    selectedEnvironmentUid?: string;
    selectedWorkspaceUid?: string;
    showSetupGuideButton?: boolean; // TODO Remove this when button is usable
    templateActionsInProgress?: boolean;
    workspaces?: Workspace[];
    onChangeEnvironment(uid: string): void;
    onCreateNewEnvironment(): void;
    onDeploy(): void;
    onEditWorkspace(): void;
    onOpenEnvironmentManager(): void;
    onOpenHelpAndSupportDialog?: () => void;
    onOpenLanguageSelector(): void;
    onOpenLayoutTutorial?: () => void;
    onOpenManageEnvironmentDeploymentTargets(): void;
    onOpenPackageManager(): void;
    onPublishTemplate(): void;
    onSetupGuide?(): void;
    onShareWorkspace(workspaceUid: string): void;
    onSwitchWorkspace(workspaceUid: string, environmentUid: string): void;
    onUnpublishTemplate(): void;
}

export interface SelectableItem {
    uid: string;
    name: string;
}

export interface Workspace extends SelectableItem {
    environmentUid: string;
}

interface Environment extends SelectableItem {
    deployed?: boolean;
    deploymentVersion?: string;
}

const buttonStyle = (theme: Theme) =>
    ({
        height: 40,
        width: 40,
        border: `1px solid ${theme.palette.divider}`,
    } as const);

const StyledToolbar = styled(Box)(({ theme }) => ({
    borderBottom: '1px solid ' + theme.palette.divider,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1.5),
    height: 64,
    gap: theme.spacing(2),
    flexWrap: 'nowrap',
    '& .MuiButtonBase-root': {
        whiteSpace: 'nowrap',
    },
    background: theme.palette.background.paper,
}));

export const StyledTeamChip = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    height: 40,
    borderRadius: theme.constants.borderRadius,
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[900], // TODO: add missing color to a theme
    color: theme.palette.text.primary,
    padding: theme.spacing(1, 1.5),
    border: `1px solid ${theme.palette.divider}`,
}));

export const StyledIconAndLabelChipContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    maxWidth: 300,
    '& .MuiSvgIcon-root': {
        height: 24,
    },
});

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    ...buttonStyle(theme),
}));

const StyledDropdownMenu = styled(DropdownMenu)(({ theme }) => ({
    ...buttonStyle(theme),
}));

const StyledButton = styled(Button)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    ':hover': {
        border: `1px solid ${theme.palette.divider}`,
    },
    boxShadow: 'none',
}));

const StyledContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(1.5),
    alignItems: 'center',
}));

export const WorkspaceHeader: React.FC<WorkspaceHeaderProps> = ({
    deploymentButtonDisabled = false,
    environments = [],
    isDraft = false,
    isTemplate = false,
    loadingEnvironments = false,
    locked = false,
    manageEnvironmentDeploymentTargetsButtonDisabled = false,
    organizationName,
    selectedWorkspaceUid,
    selectedEnvironmentUid,
    showSetupGuideButton = false,
    templateActionsInProgress = false,
    workspaces = [],
    onChangeEnvironment,
    onCreateNewEnvironment,
    onDeploy,
    onEditWorkspace,
    onOpenEnvironmentManager,
    onOpenHelpAndSupportDialog,
    onOpenLanguageSelector,
    onOpenLayoutTutorial,
    onOpenManageEnvironmentDeploymentTargets,
    onOpenPackageManager,
    onPublishTemplate,
    onSetupGuide,
    onShareWorkspace,
    onSwitchWorkspace,
    onUnpublishTemplate,
}) => {
    const settingsDropdownItems = [
        {
            disabled: locked,
            name: 'Edit',
            label: 'Edit Workspace',
            action: onEditWorkspace,
            startIcon: <WorkspacesOutlinedIcon />,
        },
        {
            disabled: locked,
            name: 'Language',
            label: 'Switch Scripting Language',
            startIcon: <CodeRoundedIcon />,
            action: onOpenLanguageSelector,
        },
        {
            name: 'Package',
            label: 'Package Manager',
            startIcon: <AllInboxOutlinedIcon />,
            action: onOpenPackageManager,
        },
        {
            name: 'Deployment',
            label: 'Release and Deploy',
            startIcon: <FileDownloadOutlinedIcon sx={{ transform: 'rotate(180deg)' }} />,
            disabled: locked || deploymentButtonDisabled,
            action: onDeploy,
        },
        {
            name: 'Environment',
            label: 'Deployment Manager',
            startIcon: <DeveloperBoardOutlinedIcon />,
            disabled: manageEnvironmentDeploymentTargetsButtonDisabled || locked,
            action: onOpenManageEnvironmentDeploymentTargets,
        },
        ...(isTemplate
            ? [
                  isDraft
                      ? {
                            disabled: locked || templateActionsInProgress,
                            name: 'Publish',
                            label: 'Publish template',
                            startIcon: <CloudOutlinedIcon />,
                            action: onPublishTemplate,
                        }
                      : {
                            disabled: locked || templateActionsInProgress,
                            name: 'Unpublish',
                            label: 'Unpublish template',
                            startIcon: <CloudOffOutlinedIcon />,
                            action: onUnpublishTemplate,
                        },
              ]
            : []),
    ];

    return (
        <StyledToolbar data-test-id="workspace-header">
            <StyledContainer>
                {organizationName && (
                    <Box>
                        <StyledTeamChip>
                            <StyledIconAndLabelChipContainer>
                                <Box display="inline-flex" pr={1}>
                                    <FolderSharedOutlinedIcon />
                                </Box>
                                <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
                                    {organizationName}
                                </Typography>
                            </StyledIconAndLabelChipContainer>
                        </StyledTeamChip>
                    </Box>
                )}
                <Dropdown
                    size="small"
                    items={workspaces.map(({ uid, name }) => {
                        return { name, value: uid };
                    })}
                    label="Workspace"
                    selectedItem={selectedWorkspaceUid}
                    onSelectItem={(value) =>
                        onSwitchWorkspace(value, workspaces.find((w) => w.uid === value)?.environmentUid ?? '')
                    }
                />
                {!loadingEnvironments && (
                    <Dropdown
                        actionsDisabled={locked}
                        items={environments.map(({ name, uid }) => ({ name: name, value: uid }))}
                        label="Environment"
                        selectedItem={selectedEnvironmentUid}
                        size="small"
                        onCreateNew={onCreateNewEnvironment}
                        onManageEnvironments={onOpenEnvironmentManager}
                        onSelectItem={(uid) => onChangeEnvironment(uid)}
                    />
                )}
                {loadingEnvironments && <CircularProgress />}
            </StyledContainer>
            <StyledContainer>
                {showSetupGuideButton && (
                    <StyledButton onClick={onSetupGuide} variant="outlined" startIcon={<RocketLaunchOutlinedIcon />}>
                        Setup guide
                    </StyledButton>
                )}
                {onOpenLayoutTutorial && (
                    <Tooltip title="Layout tutorial" placement="bottom" role="button">
                        <StyledIconButton onClick={onOpenLayoutTutorial}>
                            <SupportOutlinedIcon />
                        </StyledIconButton>
                    </Tooltip>
                )}
                <Tooltip title="Help & support" placement="bottom" role="button">
                    <StyledIconButton onClick={onOpenHelpAndSupportDialog}>
                        <HelpOutlineOutlinedIcon />
                    </StyledIconButton>
                </Tooltip>
                <Tooltip title="Share" placement="bottom" role="button">
                    <StyledIconButton onClick={() => onShareWorkspace(selectedWorkspaceUid ?? '')}>
                        <PersonAddAltOutlinedIcon />
                    </StyledIconButton>
                </Tooltip>
                <StyledDropdownMenu dropdownItems={settingsDropdownItems}>
                    <MoreVertIcon />
                </StyledDropdownMenu>
            </StyledContainer>
        </StyledToolbar>
    );
};
