import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import RadioGroup from '@mui/material/RadioGroup';
import { StyledBorderBox, StyledBorderBoxContent, StyledBorderBoxHeader } from '../common/LayoutComponents';

export const StyledOnboardingBox = styled(StyledBorderBox)(() => ({
    maxWidth: 600,
    width: '100%',
}));

export const StyledOnboardingBoxHeader = styled(StyledBorderBoxHeader)(({ theme }) => ({
    flexDirection: 'column',
    gap: theme.spacing(1),
    justifyContent: 'center',
    padding: theme.spacing(3),
    textAlign: 'center',
}));

export const StyledOnboardingBoxContent = styled(StyledBorderBoxContent)(({ theme }) => ({
    padding: theme.spacing(3),
}));

export const StyledOnboardingOptionGroup = styled(RadioGroup)(({ theme }) => ({
    gap: theme.spacing(2),
}));

export const StyledOnboardingOption = styled(ButtonBase)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    cursor: 'pointer',
    height: 56,
    // eslint-disable-next-line sonarjs/no-duplicate-string
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1.5),
}));

export const StyledOnboardingActions = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    justifyContent: 'space-between',
    padding: theme.spacing(3),
}));

export const StyledTemplateBox = styled(StyledBorderBox)(() => ({
    display: 'flex',
    flexBasis: 825,
    justifyContent: 'center',
    maxWidth: 1040,
    overflowY: 'hidden',
    width: '100%',
}));

export const StyledTemplateBoxLeftSide = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    borderRight: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    flex: '0 1 280px',
    flexDirection: 'column',
    maxWidth: 280,
    minWidth: 180,
    width: '100%',
}));

export const StyledTemplateBoxRightSide = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flex: '0 1 760px',
    flexDirection: 'column',
    minWidth: 600,
    width: '100%',
}));

export const StyledTemplateBoxHeaderRight = styled(Box)(({ theme }) => ({
    display: 'flex',
    flex: '0 0 120px',
    padding: theme.spacing(2),
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
}));

export const StyledTemplateBoxContentRight = styled(StyledBorderBoxContent)(() => ({
    flexGrow: 1,
    overflowY: 'auto',
}));
