import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import CodeIcon from '@mui/icons-material/Code';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import WarningOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import {
    ResourceActionButton,
    StyledResourceListItemButton,
    StyledResourceActionButtons,
    StyledResourceIcon,
    StyledResourceInfo,
    StyledResourceSubtitle,
    StyledResourceTitle,
} from './ResourceTreeComponents';

interface ResourceTreeScheduledTriggerProps {
    cronDescription?: string;
    cronExpression?: string;
    deleting?: boolean;
    deployedEnvironmentMode?: boolean;
    inactive?: boolean;
    needsSetup?: boolean;
    readOnlyMode?: boolean;
    scriptName?: string;
    selectedNode?: string;
    uid: string;
    disabled?: boolean;
    onDelete(uid: string, fullPath?: string): void;
    onOpen(uid: string): void;
}

export const ResourceTreeScheduledTrigger: React.FC<ResourceTreeScheduledTriggerProps> = ({
    cronDescription,
    cronExpression,
    deleting = false,
    deployedEnvironmentMode = false,
    inactive = false,
    needsSetup = false,
    readOnlyMode = false,
    scriptName,
    selectedNode,
    uid,
    disabled = false,
    onDelete,
    onOpen,
}) => {
    const selected = selectedNode === uid;
    const showSetupWarning = needsSetup && !readOnlyMode;
    const showInactiveWarning = inactive && !needsSetup && !readOnlyMode;
    const showDisabledWarning = disabled && !inactive && !needsSetup && !readOnlyMode;
    const canDelete = !deployedEnvironmentMode && !readOnlyMode;

    return (
        <StyledResourceListItemButton key={uid} onClick={() => onOpen(uid)} selected={selected}>
            <StyledResourceInfo>
                <StyledResourceTitle>
                    <Tooltip title={'Scheduled Trigger'} placement="top">
                        <StyledResourceIcon role="img">
                            <TimerOutlinedIcon />
                        </StyledResourceIcon>
                    </Tooltip>
                    <Tooltip title={cronDescription || 'Add a cron expression'} placement="top">
                        <Typography role="definition">{cronExpression || 'New Scheduled Trigger'}</Typography>
                    </Tooltip>
                </StyledResourceTitle>
                {scriptName && (
                    <Tooltip title={scriptName} placement="top-start">
                        <StyledResourceSubtitle selected={selected} className="main" role="note">
                            <CodeIcon />
                            <Typography pr={1}>Script:</Typography>
                            <Typography>{scriptName}</Typography>
                        </StyledResourceSubtitle>
                    </Tooltip>
                )}
                {showSetupWarning && (
                    <StyledResourceSubtitle className="warning">
                        <WarningOutlinedIcon />
                        <Typography>Complete scheduled trigger setup</Typography>
                    </StyledResourceSubtitle>
                )}
                {showInactiveWarning && (
                    <StyledResourceSubtitle className="warning">
                        <WarningOutlinedIcon />
                        <Typography>Inactive</Typography>
                    </StyledResourceSubtitle>
                )}
                {showDisabledWarning && (
                    <StyledResourceSubtitle className="warning">
                        <WarningOutlinedIcon />
                        <Typography>Disabled</Typography>
                    </StyledResourceSubtitle>
                )}
            </StyledResourceInfo>
            {canDelete && (
                <StyledResourceActionButtons>
                    <ResourceActionButton
                        busy={deleting}
                        icon={<DeleteOutlineIcon fontSize="small" color="primary" />}
                        tooltipTitle="Delete"
                        uid={uid}
                        onClick={onDelete}
                    />
                </StyledResourceActionButtons>
            )}
        </StyledResourceListItemButton>
    );
};
