import { useRef } from 'react';
import { styled, useTheme } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import CodeIcon from '@mui/icons-material/Code';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import SaveIcon from '@mui/icons-material/Save';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined';
import TokenOutlinedIcon from '@mui/icons-material/TokenOutlined';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import WarningOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { InvocationsLimitAlert } from './InvocationLimitAlert';
import { ResourceTreeSection } from './ResourceTreeSection';
import {
    ApiHandlerResource,
    EventListenerResource,
    FileResource,
    ScheduledTriggerResource,
    ScriptResource,
    TreeResource,
    WorkspaceLanguage,
} from './types';
import { StyledResourceIcon, StyledResourceTitle } from './ResourceTreeComponents';
import { SelectedResource } from '../../../store/workspace';
import { LoadingSpinner } from '../../common/LoadingSpinner';

export interface ResourceTreeProps {
    apiHandlers: ApiHandlerResource[];
    deployedEnvironmentMode?: boolean;
    environmentVariablesHaveMissingInformation?: boolean;
    environmentVariablesHaveUnsavedChanges?: boolean;
    eventListeners: EventListenerResource[];
    externalTriggerBaseUrl?: string;
    loading: boolean;
    open?: boolean;
    readmeFile: FileResource;
    readOnlyMode?: boolean;
    remainingInvocations?: number;
    scheduledTriggers: ScheduledTriggerResource[];
    scripts: Omit<ScriptResource, 'type'>[];
    selectedNode?: SelectedResource;
    showInvocationsLimitAlert?: boolean;
    workspaceLanguage?: WorkspaceLanguage;
    workspaceUid: string;
    onCloseInvocationsLimitAlert?(): void;
    onCopyApiHandlerPath(uid: string): void;
    onCopyEventListenerUrl(uid: string): void;
    onCreateNewApiHandler(): void;
    onCreateNewEventListener(): void;
    onCreateNewScheduledTrigger(): void;
    onCreateNewScript(): void;
    onDeleteApiHandler(uid: string): void;
    onDeleteEventListener(uid: string): void;
    onDeleteScheduledTrigger(uid: string): void;
    onDeleteScript(uid: string): void;
    onOpenApiHandler(uid: string): void;
    onOpenEnvironmentVariables(): void;
    onOpenEventListener(uid: string): void;
    onOpenReadmeFile(uid?: string): void;
    onOpenScheduledTrigger(uid: string): void;
    onOpenScript(uid: string): void;
    onTriggerEventListener(uid: string): void;
    onTriggerScript(uid: string): void;
    onUpgradePlan?(): void;
}

export const resourceTreeIconWrapperSize = 24;
export const resourceTreeActionButtonIconWrapperSize = 30;
export const resourceTreeIconSize = 20;
export const resourceTreeInfoIconSize = 16;

const StyledMainArea = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    minWidth: 280,
    position: 'relative',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
}));

const StyledTitleArea = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    padding: theme.spacing(2, 2, 2, 2),
    position: 'relative',
    '& .MuiSvgIcon-root': {
        color: theme.palette.info.main,
        height: resourceTreeIconSize,
        marginRight: theme.spacing(1.5),
        transform: 'scaleY(-1)',
        width: resourceTreeIconSize,
    },
}));

const StyledUpperSelection = styled('div')(() => ({
    minWidth: 0,

    position: 'relative',
    width: '100%',
}));

const StyledUpperSelectionListItemButton = styled(ListItemButton)<{
    selected?: boolean;
}>(({ selected, theme }) => ({
    backgroundColor: selected ? theme.palette.action.selected : undefined,
    border: selected ? theme.constants.borderSelected : undefined,
    borderRadius: theme.constants.borderRadius,
    gap: theme.spacing(1),
    justifyContent: 'flex-start',
    padding: theme.spacing(1.25),
    height: 32,
    margin: theme.spacing(0, 1),
    '& .MuiTypography-root': {
        marginRight: theme.spacing(1.5),
        color: selected ? theme.palette.primary.main : undefined,
        fontWeight: selected ? theme.typography.fontWeightBold : undefined,
    },
}));

export const ResourceTree: React.FC<ResourceTreeProps> = ({
    apiHandlers = [],
    deployedEnvironmentMode = false,
    environmentVariablesHaveMissingInformation = false,
    environmentVariablesHaveUnsavedChanges = false,
    eventListeners = [],
    externalTriggerBaseUrl,
    loading = true,
    open = true,
    readOnlyMode = false,
    readmeFile,
    remainingInvocations,
    scheduledTriggers = [],
    scripts = [],
    selectedNode,
    showInvocationsLimitAlert = false,
    workspaceLanguage,
    workspaceUid,
    onCloseInvocationsLimitAlert,
    onCopyApiHandlerPath,
    onCopyEventListenerUrl,
    onCreateNewApiHandler,
    onCreateNewEventListener,
    onCreateNewScheduledTrigger,
    onCreateNewScript,
    onDeleteApiHandler,
    onDeleteEventListener,
    onDeleteScheduledTrigger,
    onDeleteScript,
    onOpenApiHandler,
    onOpenEnvironmentVariables,
    onOpenEventListener,
    onOpenReadmeFile,
    onOpenScheduledTrigger,
    onOpenScript,
    onTriggerScript,
    onTriggerEventListener,
    onUpgradePlan,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const scriptsRef = useRef<HTMLDivElement>(null);
    const apiConnectionsRef = useRef<HTMLDivElement>(null);
    const eventListenersRef = useRef<HTMLDivElement>(null);

    const theme = useTheme();

    const createTreeNode = (
        scriptName: string,
        path: string,
        script: Omit<ScriptResource, 'type'>,
        tree: TreeResource[]
    ): void => {
        const splitPath = scriptName.split('/');
        const name = splitPath.shift() ?? '';
        const fullPath = path ? path + '/' + name : name;
        if (splitPath.length < 1) {
            tree.push({ ...script, type: 'SCRIPT' });
        } else {
            const folder = tree.find((f) => f.name === name && f.type === 'FOLDER');

            if (!folder) {
                tree.push({ fullPath, name, subItems: [], type: 'FOLDER', workspaceUid });
            }
            const newScript = { ...script, fullPath, name: splitPath[0] ?? '' };

            const child = tree[tree.length - 1];

            if (child) {
                createTreeNode(splitPath.join('/'), fullPath, newScript, child.type === 'FOLDER' ? child.subItems : []);
            }
        }
    };

    const handleFolders = (scripts: Omit<ScriptResource, 'type'>[]): TreeResource[] => {
        const tree: TreeResource[] = [];
        scripts.forEach((script) => {
            const scriptName = script.name ?? '';
            createTreeNode(scriptName, '', script, tree);
        });
        return tree;
    };

    const sortTree = (tree: TreeResource[]): TreeResource[] => {
        tree.sort((left, right) => {
            const leftIsFolder = left.type === 'FOLDER';
            const rightIsFolder = right.type === 'FOLDER';
            if (leftIsFolder && rightIsFolder) {
                const leftName = left.name ?? '';
                const rightName = right.name ?? '';
                return leftName.localeCompare(rightName);
            }
            return leftIsFolder ? -1 : rightIsFolder ? 1 : 0;
        });
        tree.forEach((el) => {
            if (el.type === 'FOLDER') {
                sortTree(el.subItems);
            }
        });
        return tree;
    };

    const tree = sortTree(handleFolders(scripts));

    const readmeIsSelected = selectedNode?.uid === readmeFile?.uid;
    const environmentVariablesIsSelected = selectedNode?.resourceType === 'ENVIRONMENT_VARIABLES';
    const showSaveIconForReadme = readmeFile.unsaved && !readOnlyMode;
    const showSaveIconForEnvironmentVariables = !readOnlyMode && environmentVariablesHaveUnsavedChanges;
    const showWarningIconForEnvironmentVariables = !readOnlyMode && environmentVariablesHaveMissingInformation;

    return open ? (
        <>
            {loading ? (
                <LoadingSpinner />
            ) : (
                <StyledMainArea data-test-id="resource-tree" ref={containerRef}>
                    <StyledTitleArea>
                        <Typography variant="subtitle1" component="h2" color="text.primary">
                            Resource manager
                        </Typography>
                    </StyledTitleArea>
                    <StyledUpperSelection>
                        {showInvocationsLimitAlert && (
                            <InvocationsLimitAlert
                                remainingInvocations={remainingInvocations}
                                onClose={() => onCloseInvocationsLimitAlert?.()}
                                onUpgradePlan={() => onUpgradePlan?.()}
                            />
                        )}

                        <StyledUpperSelectionListItemButton
                            selected={readmeIsSelected}
                            onClick={() => {
                                onOpenReadmeFile(readmeFile?.uid);
                            }}
                        >
                            <StyledResourceTitle>
                                <StyledResourceIcon>
                                    <IntegrationInstructionsOutlinedIcon color="primary" />
                                </StyledResourceIcon>
                                <Typography>README</Typography>
                            </StyledResourceTitle>

                            {showSaveIconForReadme && (
                                <SaveIcon sx={{ color: theme.palette.primary.dark, padding: 0.25 }} />
                            )}
                        </StyledUpperSelectionListItemButton>
                    </StyledUpperSelection>
                    <StyledUpperSelection>
                        <StyledUpperSelectionListItemButton
                            selected={environmentVariablesIsSelected}
                            onClick={() => {
                                onOpenEnvironmentVariables();
                            }}
                        >
                            <StyledResourceTitle>
                                <StyledResourceIcon>
                                    <TokenOutlinedIcon color="primary" />
                                </StyledResourceIcon>
                                <Typography>Parameters</Typography>
                            </StyledResourceTitle>
                            {showWarningIconForEnvironmentVariables && (
                                <Tooltip title="Some parameters are missing essential information">
                                    <WarningOutlinedIcon sx={{ color: theme.palette.warning.light, padding: 0.25 }} />
                                </Tooltip>
                            )}
                            {showSaveIconForEnvironmentVariables && (
                                <SaveIcon sx={{ color: theme.palette.primary.dark, padding: 0.25 }} />
                            )}
                        </StyledUpperSelectionListItemButton>
                    </StyledUpperSelection>
                    <Divider sx={{ marginTop: 1 }} />
                    <ResourceTreeSection
                        deployedEnvironmentMode={deployedEnvironmentMode}
                        innerRef={scriptsRef}
                        language={workspaceLanguage}
                        noResourcesCreated={!scripts.length}
                        readOnlyMode={readOnlyMode}
                        resourceIcon={<CodeIcon />}
                        resourceName="Scripts"
                        selectedNode={selectedNode?.uid}
                        tooltipText="Scripts allow you to describe your business logic."
                        treeNodes={tree}
                        workspaceUid={workspaceUid}
                        onCreateNew={onCreateNewScript}
                        onDelete={onDeleteScript}
                        onOpen={onOpenScript}
                        onTrigger={onTriggerScript}
                    />
                    <Divider />
                    <ResourceTreeSection
                        apiHandlers={apiHandlers}
                        deployedEnvironmentMode={deployedEnvironmentMode}
                        innerRef={apiConnectionsRef}
                        noResourcesCreated={!apiHandlers.length}
                        readOnlyMode={readOnlyMode}
                        resourceIcon={<UploadOutlinedIcon />}
                        resourceName="API connections"
                        selectedNode={selectedNode?.uid}
                        tooltipText="API connections allow you to call APIs of external services."
                        workspaceUid={workspaceUid}
                        onCreateNew={onCreateNewApiHandler}
                        onDelete={onDeleteApiHandler}
                        onOpen={onOpenApiHandler}
                        onPathCopy={onCopyApiHandlerPath}
                    />
                    <Divider />
                    <ResourceTreeSection
                        deployedEnvironmentMode={deployedEnvironmentMode}
                        eventListeners={eventListeners}
                        externalTriggerBaseUrl={externalTriggerBaseUrl}
                        innerRef={eventListenersRef}
                        noResourcesCreated={!eventListeners.length}
                        readOnlyMode={readOnlyMode}
                        resourceIcon={<DownloadOutlinedIcon />}
                        resourceName="Event listeners"
                        selectedNode={selectedNode?.uid}
                        tooltipText="Event listeners allow you to listen to events triggered by external services."
                        workspaceUid={workspaceUid}
                        onCreateNew={onCreateNewEventListener}
                        onDelete={onDeleteEventListener}
                        onOpen={onOpenEventListener}
                        onTrigger={onTriggerEventListener}
                        onUrlCopy={onCopyEventListenerUrl}
                    />
                    <Divider />
                    <ResourceTreeSection
                        deployedEnvironmentMode={deployedEnvironmentMode}
                        noResourcesCreated={!scheduledTriggers.length}
                        readOnlyMode={readOnlyMode}
                        resourceIcon={<ScheduleOutlinedIcon />}
                        resourceName="Scheduled triggers"
                        scheduledTriggers={scheduledTriggers}
                        selectedNode={selectedNode?.uid}
                        tooltipText="Scheduled triggers allow you to trigger scripts based on a schedule."
                        workspaceUid={workspaceUid}
                        onCreateNew={onCreateNewScheduledTrigger}
                        onDelete={onDeleteScheduledTrigger}
                        onOpen={onOpenScheduledTrigger}
                    />
                    <Divider />
                </StyledMainArea>
            )}
        </>
    ) : null;
};
