import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Button } from '../../../common/buttons/Button';
import { CopyTextField } from '../../../common/inputs/custom/CopyTextField';
import { Dialog } from '../../../common/Dialog';
import { IconCircle } from '../../../common/IconCircle';
import { OrderedList, makeNonListItem } from '../../../common/lists/OrderedList';
import { ProductIcon } from '../../../icons/ProductIcon';
import { StyledBorderBox, StyledBorderBoxContent, StyledBorderBoxSubHeader } from '../../../common/LayoutComponents';
import { eventListenerDocumentationUrl } from '../../../../utils/documentation';
import { handleKeyDown } from '../../../../utils/input';
import { SecretTextField } from '../../../common/inputs/custom/SecretTextField';
import { useState, useEffect } from 'react';
import { Alert } from '../../../common/alerts/Alert';

interface AtlassianCloudEventListenerSetupDialogProps {
    connectionType?: 'Jira' | 'Confluence' | 'Jira Service Management';
    eventTypeName: string;
    loading?: boolean;
    saving?: boolean;
    open?: boolean;
    workspaceLocked?: boolean;
    hasToken?: boolean;
    setupBaseUrl?: string;
    webhookBaseUrl: string;
    webhookUrlId: string;
    errors?: string;
    onSave: (token: string) => void;
    onClose: () => void;
}

export const AtlassianCloudEventListenerSetupDialog: React.FC<AtlassianCloudEventListenerSetupDialogProps> = ({
    errors,
    connectionType,
    eventTypeName,
    loading = false,
    saving = false,
    open = false,
    workspaceLocked = false,
    hasToken = false,
    setupBaseUrl,
    webhookBaseUrl,
    webhookUrlId,
    onClose,
    onSave,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const connectionTypeName = connectionType ?? 'Your';
    const connectionTypeNameUpperCase = connectionType ? `_${connectionType.toUpperCase().replaceAll(' ', '_')}` : '';
    const isIssueTransitionedEvent = eventTypeName === 'Issue Transitioned';
    const isJSM = connectionType === 'Jira Service Management';
    const isJiraCloud = connectionType === 'Jira';

    const [token, setToken] = useState('');

    useEffect(() => {
        if (isJiraCloud && hasToken) {
            setToken('<REDACTED>');
        } else {
            setToken('');
        }
    }, [hasToken]);

    const handleSaveToken = (): void => {
        onSave(token);
    };
    const canSave = !loading && !saving;
    const proceedButton =
        isJiraCloud && (hasToken || token || saving) ? (
            <Button busy={saving} disabled={!canSave} onClick={handleSaveToken}>
                Save changes
            </Button>
        ) : (
            <Button disabled={loading} onClick={onClose} color="success">
                Mark as complete
            </Button>
        );

    const listContent = [
        setupBaseUrl ? (
            <Typography>
                Visit your{' '}
                <Link target="_blank" href={`${setupBaseUrl}/plugins/servlet/webhooks`}>
                    WebHooks
                </Link>{' '}
                page.
            </Typography>
        ) : (
            <Typography>
                {isJSM ? (
                    <>
                        Open https://
                        <br />
                        [YOUR{connectionTypeNameUpperCase}_INSTANCE].atlassian.net/plugins/
                        <br />
                        servlet/webhooks URL in your browser
                    </>
                ) : (
                    <>
                        Open https://[YOUR{connectionTypeNameUpperCase}
                        _INSTANCE].atlassian.net/plugins/servlet/webhooks URL in your browser
                    </>
                )}
            </Typography>
        ),
        <Typography>
            Click <strong>Create a WebHook</strong>.
        </Typography>,
        <Typography>
            Enter a meaningful name into the <strong>Name</strong> field.
        </Typography>,
        <Typography>
            Paste the copied URL into the <strong>URL</strong> field.
        </Typography>,

        makeNonListItem(
            <CopyTextField fullWidth label="Webhook URL" value={`${webhookBaseUrl}/${webhookUrlId}`} />,
            'atlassian-cloud-webhook-url'
        ),
        ...(isJiraCloud
            ? [
                  <Typography>
                      If you wish to send a secret with the event, click on <strong>Generate Secret</strong> and paste
                      the secret below.
                  </Typography>,
                  makeNonListItem(
                      <SecretTextField
                          disabled={workspaceLocked}
                          fullWidth
                          label="Secret"
                          placeholder="Enter secret"
                          value={token}
                          onChange={(e) => setToken(e.target.value.trim())}
                      />,
                      'atlassian-cloud-secret'
                  ),
              ]
            : []),
        ...(!isIssueTransitionedEvent
            ? [
                  <Typography>
                      Select ONLY the following event to listen: <strong>{eventTypeName}</strong>.
                  </Typography>,
                  <Typography>
                      Then click <strong>Create</strong>.
                  </Typography>,
              ]
            : [
                  <Typography>
                      Then click <strong>Create</strong>.
                  </Typography>,
                  ...(setupBaseUrl
                      ? [
                            <Typography>
                                Visit your{' '}
                                <Link
                                    target="_blank"
                                    href={`${setupBaseUrl}/secure/admin/workflows/ListWorkflows.jspa`}
                                >
                                    Workflows
                                </Link>{' '}
                                page.
                            </Typography>,
                        ]
                      : [
                            <Typography>
                                {isJSM ? (
                                    <>
                                        Open https://
                                        <br />
                                        [YOUR{connectionTypeNameUpperCase}
                                        _INSTANCE]/secure/admin/
                                        <br />
                                        workflows/ListWorkflows.jspa URL in your browser
                                    </>
                                ) : (
                                    <>
                                        Open https://[YOUR
                                        {connectionTypeNameUpperCase}
                                        _INSTANCE]/secure/admin/workflows/ListWorkflows.jspa URL in your browser
                                    </>
                                )}
                            </Typography>,
                        ]),
                  <Typography>
                      Click <strong>Edit</strong> next to the relevant workflow.
                  </Typography>,
                  <Typography>
                      In the Workflow Designer, select the transition and click <strong>Post functions</strong> in the
                      properties panel.
                  </Typography>,
                  <Typography>
                      Click <strong>Add post function</strong>, select <strong>Trigger a Webhook</strong> and click{' '}
                      <strong>Add</strong>.
                  </Typography>,
                  <Typography>
                      Select the created webhook and click <strong>Add</strong>.
                  </Typography>,
                  <Typography>
                      Repeat steps <strong>8 through 10</strong> for each transition you want to get an event.
                  </Typography>,
                  <Typography>
                      Click <strong>Publish Draft</strong> to publish the workflow.
                  </Typography>,
              ]),
    ];

    return (
        <>
            <Dialog
                leftButton={
                    <Button onClick={() => window.open(eventListenerDocumentationUrl)} variant="text">
                        Learn more
                    </Button>
                }
                buttons={[
                    <Button disabled={loading} onClick={onClose} variant="outlined">
                        Close
                    </Button>,
                    proceedButton,
                ]}
                icon={<IconCircle icon={<ProductIcon name={`${connectionType} Cloud`} />} size="medium" />}
                open={open}
                title="Event listener setup"
                subtitle={loading ? '' : `${connectionTypeName} Cloud`}
                onClose={onClose}
                onKeyDown={(event) => {
                    if (token) {
                        handleKeyDown({ event, enterCondition: canSave, enterFn: () => onSave(token), escFn: onClose });
                    } else {
                        handleKeyDown({ event, enterCondition: !loading, enterFn: onClose, escFn: onClose });
                    }
                }}
                loading={loading}
            >
                {workspaceLocked && (
                    <Alert
                        severity="warning"
                        title="The workspace is currently locked and the token field cannot be edited"
                    />
                )}
                {errors && <Alert severity="error" title={errors} />}
                <StyledBorderBox>
                    <StyledBorderBoxSubHeader>
                        <Typography variant="subtitle1" component="h6">
                            How to set up an event listener
                        </Typography>
                        <Typography color="text.secondary">
                            Create a webhook in {connectionTypeName} Cloud instance to listen to events.
                        </Typography>
                    </StyledBorderBoxSubHeader>
                    <Divider />
                    <StyledBorderBoxContent gap={1.5}>
                        <OrderedList content={listContent} id="atlassian-cloud" />
                    </StyledBorderBoxContent>
                </StyledBorderBox>
            </Dialog>
        </>
    );
};
