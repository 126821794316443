import { getOrganisationsReport as getOrganizationsReportRpc } from '@avst-stitch/repository-lib/lib';
import {
    Response as OrganizationsReportResponse,
    Request as OrganizationsReportRequest,
} from '@avst-stitch/repository-lib/lib/rpcs/report/getOrganisations';
import { repositoryInvoker } from '../../utils/repository';
export type { OrganizationsReportResponse, OrganizationsReportRequest };

export const getOrganizationsReport = async (
    request: OrganizationsReportRequest
): Promise<OrganizationsReportResponse> => {
    return await getOrganizationsReportRpc(repositoryInvoker, request);
};
