import { styled } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import AssistantOutlinedIcon from '@mui/icons-material/AssistantOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import SupportOutlinedIcon from '@mui/icons-material/SupportOutlined';
import { SRConnectIcon } from '../icons/SRConnectIcon';

export interface EmptyWorkspaceViewProps {
    onOpenAiAssistance?: () => void;
    onOpenHelpAndSupportDialog?: () => void;
    onOpenLayoutTutorial?: () => void;
    onOpenSetupGuide?: () => void;
    onOpenYoutubeVideo?: () => void;
}

const StyledEmptyWorkspaceContainer = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    backgroundColor: theme.palette.background.default,
    height: '100%',
    justifyContent: 'center',
    width: '100%',
}));

const StyledContent = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: 600,
    padding: theme.spacing(2),
    '&>.MuiSvgIcon-root': {
        height: 42,
        marginBottom: theme.spacing(2),
        width: 42,
    },
    '&>.MuiTypography-root': {
        marginBottom: theme.spacing(3),
    },
}));

const StyledHelpItem = styled(ButtonBase)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    gap: theme.spacing(1.5),
    minHeight: 80,
    padding: theme.spacing(1, 1.5),
    width: '100%',
    '&:hover': {
        border: theme.constants.borderSelected,
    },
    '&:focus-visible': {
        backgroundColor: theme.palette.action.focus,
        border: theme.constants.borderSelected,
    },
}));

const StyledHelpItemIconContainer = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    justifyContent: 'center',
    '& .MuiSvgIcon-root': {
        height: 24,
        width: 24,
    },
}));

const StyledHelpItemTextContainer = styled('div')(({ theme }) => ({
    flexGrow: 1,
    textAlign: 'start',
    '& .MuiTypography-root:first-of-type': {
        fontWeight: theme.typography.fontWeightBold,
    },
    '& .MuiTypography-root:last-of-type': {
        color: theme.palette.text.secondary,
    },
}));

export const EmptyWorkspaceView: React.FC<EmptyWorkspaceViewProps> = ({
    onOpenAiAssistance,
    onOpenHelpAndSupportDialog,
    onOpenLayoutTutorial,
    onOpenSetupGuide,
    onOpenYoutubeVideo,
}) => {
    return (
        <StyledEmptyWorkspaceContainer>
            <StyledContent>
                <SRConnectIcon />
                <Typography variant="h5" component="h2">
                    How can I help you?
                </Typography>
                <Grid container spacing={2}>
                    {!!onOpenSetupGuide && (
                        <Grid xs={12}>
                            <StyledHelpItem disabled onClick={onOpenSetupGuide}>
                                <StyledHelpItemIconContainer>
                                    <RocketLaunchOutlinedIcon />
                                </StyledHelpItemIconContainer>
                                <StyledHelpItemTextContainer>
                                    <Typography>Setup guide</Typography>
                                    <Typography>
                                        Our guide will help you configure and optimise your integration
                                    </Typography>
                                </StyledHelpItemTextContainer>
                                <StyledHelpItemIconContainer>
                                    <KeyboardArrowRightIcon />
                                </StyledHelpItemIconContainer>
                            </StyledHelpItem>
                        </Grid>
                    )}
                    {!!onOpenLayoutTutorial && (
                        <Grid xs={6}>
                            <StyledHelpItem onClick={onOpenLayoutTutorial}>
                                <StyledHelpItemIconContainer>
                                    <SupportOutlinedIcon />
                                </StyledHelpItemIconContainer>
                                <StyledHelpItemTextContainer>
                                    <Typography>Layout tutorial</Typography>
                                    <Typography>Master the interface and navigate your workspace</Typography>
                                </StyledHelpItemTextContainer>
                                <StyledHelpItemIconContainer>
                                    <KeyboardArrowRightIcon />
                                </StyledHelpItemIconContainer>
                            </StyledHelpItem>
                        </Grid>
                    )}
                    {!!onOpenHelpAndSupportDialog && (
                        <Grid xs={6}>
                            <StyledHelpItem onClick={onOpenHelpAndSupportDialog}>
                                <StyledHelpItemIconContainer>
                                    <HelpOutlineOutlinedIcon />
                                </StyledHelpItemIconContainer>
                                <StyledHelpItemTextContainer>
                                    <Typography>Help & support</Typography>
                                    <Typography>Learn the best practices of ScriptRunner Connect</Typography>
                                </StyledHelpItemTextContainer>
                                <StyledHelpItemIconContainer>
                                    <KeyboardArrowRightIcon />
                                </StyledHelpItemIconContainer>
                            </StyledHelpItem>
                        </Grid>
                    )}
                    {!!onOpenAiAssistance && (
                        <Grid xs={6}>
                            <StyledHelpItem onClick={onOpenAiAssistance}>
                                <StyledHelpItemIconContainer>
                                    <AssistantOutlinedIcon />
                                </StyledHelpItemIconContainer>
                                <StyledHelpItemTextContainer>
                                    <Typography>AI assistant</Typography>
                                    <Typography>Get instant help and expert guidance</Typography>
                                </StyledHelpItemTextContainer>
                                <StyledHelpItemIconContainer>
                                    <KeyboardArrowRightIcon />
                                </StyledHelpItemIconContainer>
                            </StyledHelpItem>
                        </Grid>
                    )}
                    {!!onOpenYoutubeVideo && (
                        <Grid xs={6}>
                            <StyledHelpItem onClick={onOpenYoutubeVideo}>
                                <StyledHelpItemIconContainer>
                                    <PlayCircleOutlinedIcon />
                                </StyledHelpItemIconContainer>
                                <StyledHelpItemTextContainer>
                                    <Typography>Watch video</Typography>
                                    <Typography>Understand how to build your integration</Typography>
                                </StyledHelpItemTextContainer>
                                <StyledHelpItemIconContainer>
                                    <KeyboardArrowRightIcon />
                                </StyledHelpItemIconContainer>
                            </StyledHelpItem>
                        </Grid>
                    )}
                </Grid>
            </StyledContent>
        </StyledEmptyWorkspaceContainer>
    );
};
