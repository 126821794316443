import { BehaviorSubject, debounceTime, map, Subject, tap } from 'rxjs';
import {
    newReadmeFileCreatedAction$,
    selectedEnvironmentUid$,
    selectedWorkspaceEnvironment$,
    selectedWorkspaceEnvironments$,
    selectedWorkspaceSelectedResource$,
    selectedWorkspaceUid$,
} from '.';
import { InformativeError } from '../../utils/error';
import { publishLocalFeedbackEventAction$ } from '../feedback';
import { monitor } from '../monitor';
import { saveReadmeFile as saveReadmeFileRpcCall, ReadmeFile } from '../../data/readme';
import { getSanitizedParsedMarkdown } from '../../utils/readme';

export const selectReadmeFileAction$ = monitor('selectReadmeFileAction$', new Subject<string>());
export const navigateToReadmeFileAction$ = monitor('navigateToReadmeFileAction$', new Subject<string>());
export const saveReadmeFileAction$ = monitor('saveReadmeFileAction$', new Subject<void>());
export const revertReadmeFileChangesAction$ = monitor('revertReadmeFileChangesAction$', new Subject<void>());

export const selectedReadmeFileContentChangedAction$ = monitor(
    'selectedReadmeFileContentChangedAction$',
    new BehaviorSubject<string>('')
);

export const savedReadmeFileDetails$ = monitor(
    'savedReadmeFileDetails$',
    new BehaviorSubject<Record<string, ReadmeFile>>({})
);
export const unsavedReadmeFileDetails$ = monitor(
    'unsavedReadmeFileDetails$',
    new BehaviorSubject<Record<string, ReadmeFile>>({})
);
export const parsedReadmeFileContent$ = monitor(
    'parsedReadmeFileContent$',
    new BehaviorSubject<Record<string, string>>({})
);
export const selectedReadmeFileUid$ = monitor('selectedReadmeFileUid$', new BehaviorSubject<string | null>(null));
export const readmeFileBeingSaved$ = monitor('readmeFileBeingSaved$', new BehaviorSubject<boolean>(false));
export const readmeFileHasUnsavedChanges$ = monitor(
    'readmeFileHasUnsavedChanges$',
    new BehaviorSubject<Record<string, boolean>>({})
);

selectReadmeFileAction$.subscribe((fileUid) => {
    selectedReadmeFileUid$.next(fileUid);
    const environmentUid = selectedEnvironmentUid$.value;
    const readmeKey = `${fileUid}_${environmentUid ?? ''}`;
    const unsavedScriptDetails = unsavedReadmeFileDetails$.value[readmeKey];
    if (unsavedScriptDetails) {
        selectedReadmeFileContentChangedAction$.next(unsavedScriptDetails?.content);
        selectedWorkspaceSelectedResource$.next({
            resourceType: 'README',
            uid: fileUid,
        });
    }
});

selectedReadmeFileContentChangedAction$.subscribe((content) => {
    const readmeUid = selectedReadmeFileUid$.value;
    if (readmeUid) {
        const environmentUid = selectedEnvironmentUid$.value;
        const readmeKey = `${readmeUid}_${environmentUid ?? ''}`;
        const unsavedReadmeFileDetails = unsavedReadmeFileDetails$.value[readmeKey];

        const updatedDetails = unsavedReadmeFileDetails
            ? { ...unsavedReadmeFileDetails, content }
            : { name: 'README.md', content };

        let otherHeadEnvironmentReadmeFileDetails: Record<string, ReadmeFile> = {};
        const selectedEnvironment = selectedWorkspaceEnvironment$.value;

        if (selectedEnvironment && !selectedEnvironment.deployment) {
            const otherHeadEnvironments = selectedWorkspaceEnvironments$.value
                .filter((env) => !env.deployment && env.uid !== selectedEnvironment.uid)
                .map((env) => env.uid);

            otherHeadEnvironmentReadmeFileDetails = Object.fromEntries(
                otherHeadEnvironments.map((env) => [`${readmeUid}_${env}`, { ...updatedDetails }])
            );
        }

        unsavedReadmeFileDetails$.next({
            ...unsavedReadmeFileDetails$.value,
            ...otherHeadEnvironmentReadmeFileDetails,
            [readmeKey]: {
                ...updatedDetails,
            },
        });

        const savedReadmeFileDetails = savedReadmeFileDetails$.value[readmeKey];
        if (savedReadmeFileDetails) {
            const missingOtherHeadEnvironmentSavedReadmeFileDetails = Object.fromEntries(
                Object.keys(otherHeadEnvironmentReadmeFileDetails)
                    .filter((key) => !savedReadmeFileDetails$.value[key])
                    .map((key) => [key, { ...updatedDetails }])
            );

            savedReadmeFileDetails$.next({
                ...savedReadmeFileDetails$.value,
                ...missingOtherHeadEnvironmentSavedReadmeFileDetails,
            });
        }
    }
});

saveReadmeFileAction$
    .pipe(
        map(async () => {
            const fileUid = selectedReadmeFileUid$.value;
            if (fileUid) {
                await saveReadmeFile(fileUid);
            }
        })
    )
    .subscribe();

selectedReadmeFileContentChangedAction$
    .pipe(
        debounceTime(100),
        tap(() => updateReadmeFileSavedStatus(selectedReadmeFileUid$.value ?? ''))
    )
    .subscribe();

selectedReadmeFileContentChangedAction$
    .pipe(
        debounceTime(500),
        tap((content) => {
            if (selectedReadmeFileUid$.value) {
                const parsedContent = getSanitizedParsedMarkdown(content ?? '');
                parsedReadmeFileContent$.next({
                    ...parsedReadmeFileContent$.value,
                    [`${selectedReadmeFileUid$.value}_${selectedEnvironmentUid$.value ?? ''}`]: parsedContent,
                });
            }
        })
    )
    .subscribe();

revertReadmeFileChangesAction$.subscribe(() => {
    const readmeUid = selectedReadmeFileUid$.value;
    const environmentUid = selectedEnvironmentUid$.value;

    const readmeKey = `${readmeUid}_${environmentUid}`;
    const savedReadmeFileContent = savedReadmeFileDetails$.value?.[readmeKey]?.content;

    selectedReadmeFileContentChangedAction$.next(savedReadmeFileContent ?? '');
});

const updateReadmeFileSavedStatus = (fileUid: string): void => {
    readmeFileHasUnsavedChanges$.next({
        ...readmeFileHasUnsavedChanges$.value,
        [fileUid]: readmeFileHasUnsavedChanges(fileUid),
    });
};

const readmeFileHasUnsavedChanges = (fileUid: string): boolean => {
    const environmentUid = selectedEnvironmentUid$.value;
    const readmeKey = `${fileUid}_${environmentUid ?? ''}`;
    return savedReadmeFileDetails$.value[readmeKey]?.content !== unsavedReadmeFileDetails$.value[readmeKey]?.content;
};

//Legacy code for backwards compatibility
export const createReadmeFile = async (): Promise<void> => {
    const readmeFileHasUnsavedChanges = readmeFileHasUnsavedChanges$.value;

    const unsavedReadmeFileDetails = unsavedReadmeFileDetails$.value;

    readmeFileBeingSaved$.next(true);

    try {
        const {
            uid: readmeFileUid,
            name: readmeFileName,
            content: readmeFileContent,
        } = await saveReadmeFileRpcCall(selectedWorkspaceUid$.value ?? '');
        newReadmeFileCreatedAction$.next(readmeFileUid);

        const newReadme = {
            name: readmeFileName,
            content: readmeFileContent,
        };

        const environmentUid = selectedEnvironmentUid$.value;
        const readmeKey = `${readmeFileUid}_${environmentUid}`;
        unsavedReadmeFileDetails$.next({ ...unsavedReadmeFileDetails, [readmeKey]: newReadme });

        savedReadmeFileDetails$.next({
            ...savedReadmeFileDetails$.value,
            [readmeKey]: newReadme,
        });

        readmeFileHasUnsavedChanges$.next({
            ...readmeFileHasUnsavedChanges,
            [readmeFileUid]: false,
        });

        selectedReadmeFileUid$.next(readmeFileUid);
        selectedReadmeFileContentChangedAction$.next(readmeFileContent);

        navigateToReadmeFileAction$.next(readmeFileUid);
    } catch (e) {
        if (e instanceof InformativeError) {
            publishLocalFeedbackEventAction$.next({
                level: 'ERROR',
                message: `Error while saving README.md file: ${e.message}. Please try saving the file again, if the issue persists please contact support.`,
            });
        } else {
            console.error('Failed to save README.md file while saving', e);
            publishGenericError();
        }
    }

    readmeFileBeingSaved$.next(false);
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const saveReadmeFile = async (fileUid: string): Promise<void> => {
    const environmentUid = selectedEnvironmentUid$.value;
    const readmeKey = `${fileUid}_${environmentUid ?? ''}`;
    const readme = unsavedReadmeFileDetails$.value[readmeKey];
    if (readme) {
        readmeFileBeingSaved$.next(true);

        try {
            await saveReadmeFileRpcCall(selectedWorkspaceUid$.value ?? '', readme.content, fileUid);
        } catch (e) {
            if (e instanceof InformativeError) {
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: `Error while saving README.md file: ${e.message}. Please try saving the file again, if the issue persists please contact support.`,
                });
            } else {
                console.error('Failed to save README.md file while saving', e);
                publishGenericError();
            }
        }

        savedReadmeFileDetails$.next({
            ...Object.fromEntries(
                Object.entries(savedReadmeFileDetails$.value).filter(([key]) => !key.startsWith(fileUid))
            ),
            [readmeKey]: {
                name: readme.name,
                content: readme.content,
                // TODO: set last modified date and by whom
            },
        });
        unsavedReadmeFileDetails$.next({
            ...Object.fromEntries(
                Object.entries(unsavedReadmeFileDetails$.value).filter(([key]) => !key.startsWith(fileUid))
            ),
            [readmeKey]: {
                name: readme.name,
                content: readme.content,
                // TODO: set last modified date and by whom
            },
        });

        updateReadmeFileSavedStatus(fileUid);
        selectedReadmeFileContentChangedAction$.next(readme.content);

        readmeFileBeingSaved$.next(false);
    } else {
        publishGenericError();
    }
};

const publishGenericError = (): void => {
    publishLocalFeedbackEventAction$.next({
        level: 'ERROR',
        message:
            'Error while saving README.md file. Please try saving the file again, if the issue persists please contact support.',
    });
};
