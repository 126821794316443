import copy from 'copy-to-clipboard';
import { RefObject, useState } from 'react';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { IconButton } from './buttons/IconButton';

export interface IconCopyButtonProps {
    copiedTooltip?: string;
    label?: string | JSX.Element;
    tooltip?: string;
    value?: string | RefObject<Element>;
}

export const IconCopyButton: React.FC<IconCopyButtonProps> = ({
    copiedTooltip = 'Copied to clipboard',
    tooltip = 'Copy to clipboard',
    value,
}) => {
    const [copied, setCopied] = useState<boolean>(false);

    const doCopy = (): void => {
        const content = typeof value === 'string' ? value : value?.current?.textContent ?? undefined;

        if (content !== undefined) {
            copy(content);
            setCopied(true);
            setTimeout(() => {
                setCopied(false);
            }, 2000);
        }
    };

    return value !== undefined ? (
        <IconButton
            aria-label="Copy"
            icon={copied ? <CheckOutlinedIcon color="success" /> : <ContentCopyOutlinedIcon />}
            tooltip={copied ? copiedTooltip : tooltip}
            onClick={doCopy}
        />
    ) : null;
};
