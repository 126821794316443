import { getScriptingFamiliarityOptions as getScriptingFamiliarityOptionsRpc } from '@avst-stitch/repository-lib/lib';
import { getOnboardingTemplates as getOnboardingTemplatesRpc } from '@avst-stitch/repository-lib/lib';
import { saveScriptingFamiliarity as saveScriptingFamiliarityRpc } from '@avst-stitch/repository-lib/lib';
import { saveIndustryRole as saveIndustryRoleRpc } from '@avst-stitch/repository-lib/lib';
import { saveUserAppPreference as saveUserAppPreferenceRpc } from '@avst-stitch/repository-lib/lib';
import { toggleOnboardingCompletion as toggleOnboardingCompletionRpc } from '@avst-stitch/repository-lib/lib';
import { Response as scriptingFamiliarityOptions } from '@avst-stitch/repository-lib/lib/rpcs/getScriptingFamiliarityOptions';
import { Request as saveScriptingFamiliarityRequest } from '@avst-stitch/repository-lib/lib/rpcs/saveScriptingFamiliarity';
import { Request as saveIndustryRoleRequest } from '@avst-stitch/repository-lib/lib/rpcs/saveIndustryRole';
import { Request as saveUserAppPreferenceRequest } from '@avst-stitch/repository-lib/lib/rpcs/saveUserAppPreference';
import { Response as OnboardingTemplates } from '@avst-stitch/repository-lib/lib/rpcs/getOnboardingTemplates';
import { repositoryInvoker } from '../utils/repository';
export type { scriptingFamiliarityOptions, saveIndustryRoleRequest };

export const getScriptingFamiliarityOptions = async (): Promise<scriptingFamiliarityOptions> => {
    return await getScriptingFamiliarityOptionsRpc(repositoryInvoker);
};

export const getOnboardingTemplates = async (): Promise<OnboardingTemplates> => {
    return await getOnboardingTemplatesRpc(repositoryInvoker);
};

export const saveIndustryRole = async (request: saveIndustryRoleRequest): Promise<void> => {
    return await saveIndustryRoleRpc(repositoryInvoker, request);
};

export const saveScriptingFamiliarity = async (request: saveScriptingFamiliarityRequest): Promise<void> => {
    return await saveScriptingFamiliarityRpc(repositoryInvoker, request);
};

export const saveUserAppPreference = async (request: saveUserAppPreferenceRequest): Promise<void> => {
    return await saveUserAppPreferenceRpc(repositoryInvoker, request);
};

export const toggleOnboardingCompletion = async (request: { completed: boolean }): Promise<void> => {
    return await toggleOnboardingCompletionRpc(repositoryInvoker, request);
};
