import { FeedbackEvent } from '@avst-stitch/feedback-lib';
import { BehaviorSubject, Subject } from 'rxjs';
import { selectedEnvironmentUid$, selectedWorkspace$, selectedWorkspaceEnvironments$, selectedWorkspaceUid$ } from '.';
import { getFilterKey } from '../../utils/miscellaneous';
import { feedbackIncomingEvent$, publishLocalFeedbackEventAction$ } from '../feedback';
import { monitor } from '../monitor';
import { workspaceSetupGuideSteps$ } from '../setup-guide';

export const filteredFeedback$ = monitor('filteredFeedback$', new BehaviorSubject<Record<string, FeedbackEvent[]>>({}));
export const displayLiveFeedback$ = monitor('displayLiveFeedback$', new BehaviorSubject(true));
export const filterByEnvironment$ = monitor('filterByEnvironment$', new BehaviorSubject(true));

export const clearWorkspaceFeedbackAction$ = monitor('clearWorkspaceFeedbackAction$', new Subject<void>());
export const changeLiveFeedbackSwitchAction$ = monitor('switchLiveFeedbackSwitchAction$', new Subject<boolean>());
export const changeFilterByEnvironmentSwitchAction$ = monitor(
    'changeFilterByEnvironmentSwitchAction$',
    new Subject<boolean>()
);

changeLiveFeedbackSwitchAction$.subscribe((enabled) => displayLiveFeedback$.next(enabled));

changeFilterByEnvironmentSwitchAction$.subscribe((enabled) => filterByEnvironment$.next(enabled));

feedbackIncomingEvent$.subscribe((event) => {
    if (event && event.workspaceUid && event.environmentUid) {
        const setupGuide = !!selectedWorkspace$.value?.setupGuide;
        const runStep = workspaceSetupGuideSteps$.value?.find((s) => s.name === 'RUN');

        if (!setupGuide || (setupGuide && !runStep?.disabled)) {
            const workspaceFeedback =
                filteredFeedback$.value[getFilterKey(event.workspaceUid, event.environmentUid)] ?? [];
            workspaceFeedback.push(event);

            filteredFeedback$.next({
                ...filteredFeedback$.value,
                [getFilterKey(event.workspaceUid, event.environmentUid)]: workspaceFeedback.sort(
                    (a, b) => a.time - b.time
                ),
            });
        }
    }
});

publishLocalFeedbackEventAction$.subscribe(({ type = 'local', ...rest }) => {
    feedbackIncomingEvent$.next({
        ...rest,
        type,
        workspaceUid: selectedWorkspaceUid$.value,
        environmentUid: selectedEnvironmentUid$.value,
        time: Date.now(),
    });
});

clearWorkspaceFeedbackAction$.subscribe(() => {
    const workspaceUid = selectedWorkspaceUid$.value;
    const environmentUid = selectedEnvironmentUid$.value;
    const filterByEnvironments = filterByEnvironment$.value;
    const environments = selectedWorkspaceEnvironments$.value;

    if (filterByEnvironments) {
        if (workspaceUid && environmentUid) {
            filteredFeedback$.next({ ...filteredFeedback$.value, [getFilterKey(workspaceUid, environmentUid)]: [] });
        }
    } else {
        if (workspaceUid) {
            filteredFeedback$.next({
                ...filteredFeedback$.value,
                ...environments.reduce(
                    (prev, current) => ({ ...prev, [getFilterKey(workspaceUid, current.uid)]: [] }),
                    {}
                ),
            });
        }
    }
});
