import { alpha, styled, Theme } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';

type CircleSeverity = 'grey' | 'success' | 'info' | 'warning' | 'error';

interface IconCircleProps extends BoxProps {
    icon: JSX.Element;
    severity?: CircleSeverity;
    size?: 'small' | 'medium';
}

const getCircleColor = (theme: Theme, severity: CircleSeverity): { backgroundColor: string; color: string } => {
    switch (severity) {
        case 'info':
            return {
                backgroundColor: alpha(theme.palette.info.light, 0.1),
                color: theme.palette.info.main,
            };
        case 'success':
            return {
                backgroundColor: alpha(theme.palette.success.light, 0.1),
                color: theme.palette.success.main,
            };
        case 'warning':
            return {
                backgroundColor: alpha(theme.palette.warning.light, 0.1),
                color: theme.palette.warning.main,
            };
        case 'error':
            return {
                backgroundColor: alpha(theme.palette.error.light, 0.1),
                color: theme.palette.error.main,
            };
        default:
        case 'grey':
            return {
                backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800], // Change this
                color: theme.palette.text.primary, // Change this
            };
    }
};

const StyledCircle = styled(Box, { shouldForwardProp: (prop) => prop !== 'severity' })<{
    severity: CircleSeverity;
    size: 'small' | 'medium';
}>(({ severity, size, theme }) => {
    const isSmall = size === 'small';
    return {
        ...getCircleColor(theme, severity),
        ...theme.typography.flexAlignCenter,
        borderRadius: theme.constants.radiusCircle,
        height: isSmall ? 40 : 56,
        justifyContent: 'center',
        width: isSmall ? 40 : 56,
        '& .MuiSvgIcon-root': {
            height: isSmall ? 24 : 32,
            width: isSmall ? 24 : 32,
        },
        flexShrink: 0,
    };
});

export const IconCircle: React.FC<IconCircleProps> = ({ icon, severity = 'grey', size = 'small', ...props }) => {
    return (
        <StyledCircle severity={severity} size={size} {...props}>
            {icon}
        </StyledCircle>
    );
};
