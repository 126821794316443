import ClickAwayListener from '@mui/material/ClickAwayListener';
import {
    StyledMenu,
    StyledMenuItem,
    StyledMenuItemContent,
    StyledMenuItemText,
} from '../side-navigation/SidenavComponents';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
// import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import PolicyOutlinedIcon from '@mui/icons-material/PolicyOutlined';
import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined';
import Divider from '@mui/material/Divider';

interface MoreActionsContextMenuProps {
    anchor: HTMLButtonElement | null;
    setAnchor(anchor: null): void;
    setShowLearnMoreAboutCreditsScreenState(show: boolean): void;
    setShowBestPracticesScreenState(show: boolean): void;
}

export const MoreActionsContextMenu: React.FC<MoreActionsContextMenuProps> = ({
    anchor,
    setAnchor,
    setShowLearnMoreAboutCreditsScreenState,
    setShowBestPracticesScreenState,
}) => {
    return (
        <ClickAwayListener
            onClickAway={() => {
                setAnchor(null);
            }}
        >
            <StyledMenu
                open={!!anchor}
                anchorEl={anchor}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: -180,
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={() => setAnchor(null)}
                sx={{
                    width: 235,
                }}
            >
                <StyledMenuItem
                    onClick={() => {
                        setAnchor(null);
                        setShowLearnMoreAboutCreditsScreenState(true);
                    }}
                >
                    <StyledMenuItemContent>
                        <LocalActivityOutlinedIcon />
                        <StyledMenuItemText>Learn about credits</StyledMenuItemText>
                    </StyledMenuItemContent>
                </StyledMenuItem>
                <StyledMenuItem
                    onClick={() => {
                        setAnchor(null);
                        setShowBestPracticesScreenState(true);
                    }}
                >
                    <StyledMenuItemContent>
                        <AssignmentTurnedInOutlinedIcon />
                        <StyledMenuItemText>Best practices</StyledMenuItemText>
                    </StyledMenuItemContent>
                </StyledMenuItem>
                <Divider />
                {/* TODO: uncomment, when FAQ page is created
                                        <StyledMenuItem
                                            component="a"
                                            href="" // TODO: change to a real one
                                            target="_blank"
                                            onClick={() => setAnchor(null)}
                                        >
                                            <StyledMenuItemContent>
                                                <QuizOutlinedIcon />
                                                <StyledMenuItemText>FAQ's</StyledMenuItemText>
                                            </StyledMenuItemContent>
                                        </StyledMenuItem> */}
                <StyledMenuItem
                    component="a"
                    href="https://www.theadaptavistgroup.com/policy/privacy"
                    target="_blank"
                    onClick={() => setAnchor(null)}
                >
                    <StyledMenuItemContent>
                        <PolicyOutlinedIcon />
                        <StyledMenuItemText>Privacy policy</StyledMenuItemText>
                    </StyledMenuItemContent>
                </StyledMenuItem>
                <StyledMenuItem
                    component="a"
                    href="https://www.theadaptavistgroup.com/policy/terms"
                    target="_blank"
                    onClick={() => setAnchor(null)}
                >
                    <StyledMenuItemContent>
                        <AssignmentOutlinedIcon />
                        <StyledMenuItemText>Terms & conditions</StyledMenuItemText>
                    </StyledMenuItemContent>
                </StyledMenuItem>
            </StyledMenu>
        </ClickAwayListener>
    );
};
