import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import styled from '@mui/system/styled';
import React, { useState } from 'react';
import { Button } from '../../../common/buttons/Button';
import { ZoomIcon } from '../../../icons/product-icons/ZoomIcon';
import { OpenInNewLink } from '../../../common/OpenInNewLink';
import {
    ConnectionModalTextField,
    ConnectionModalSecretTextField,
} from '../../../for-deprecation/textfield/ConnectionModalTextField';
import { ReadOnlyTextField } from '../../../for-deprecation/textfield/ReadOnlyTextField';
import { ZoomWizardStage, ZoomWizardSteps, StageType } from './ZoomWizardSteps';
import { Alert } from '../../../common/alerts/Alert';
import { DialogTitleMain } from '../../../for-deprecation/dialog/DialogComponents';

export interface ZoomClientInfo {
    clientId: string;
    clientSecret: string;
}

export interface ZoomConnection {
    clientInfo: ZoomClientInfo;
    open: boolean;
    onClose: () => void;
    saving: boolean;
    currentStage: ZoomWizardStage;
    setStage: (stage: ZoomWizardStage) => void;
    onSave: (props: onSaveProps) => void;
    errors?: string;
    setError: (error: string) => void;
    clearErrors: () => void;
    callbackUrl: string;
}

export interface onSaveProps {
    clientId: string;
    clientSecret: string;
}

const StyledAlert = styled(Alert)(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));

const StageContent: StageType = (props) => {
    const [clientId, setClientId] = useState(props.clientInfo.clientId);
    const [clientSecret, setClientSecret] = useState(props.clientInfo.clientSecret);

    switch (props.stage) {
        case ZoomWizardStage.CREATE:
            return (
                <>
                    <StyledAlert
                        severity="info"
                        text="If you already have an application, skip the steps below and click next"
                    />
                    <StyledAlert severity="info" text="You must be logged into Zoom to create an application." />
                    <DialogContent>
                        <DialogContentText component="ol">
                            <li>
                                Visit the{' '}
                                <OpenInNewLink url={'https://marketplace.zoom.us/user/build'}>
                                    Zoom Apps Marketplace
                                </OpenInNewLink>{' '}
                                page. You will need to sign in if you're not already signed in.
                            </li>
                            <li>
                                Click on the <strong>Develop</strong> dropdown then select <strong>Build App</strong>.
                            </li>
                            <li>
                                Select the <strong>OAuth</strong> app type.
                            </li>
                            <li>
                                Under <strong>App Name</strong> enter <strong>ScriptRunnerConnect</strong>.
                            </li>
                            <li>
                                Select <strong>User-managed app</strong> option for <strong>Choose app type</strong>.
                            </li>
                            <li>Uncheck the option to publish to marketplace.</li>
                            <li>
                                Click <strong>Create</strong>.
                            </li>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => props.onClose?.()}>
                            Close
                        </Button>
                        <Button onClick={() => props.setStage(ZoomWizardStage.DETAILS)}>Next</Button>
                    </DialogActions>
                </>
            );
        case ZoomWizardStage.DETAILS:
            return (
                <>
                    <DialogContent>
                        <DialogContentText component="ol">
                            <li>
                                Zoom will route you to the newly created app. If it doesn't, click on the newly created
                                client (or an existing one if you skipped the previous step).
                            </li>
                            <li>
                                Copy the <strong>Client ID</strong> into the form below.
                            </li>
                            <li>
                                Copy the <strong>Client Secret</strong> into the form below.
                            </li>
                            <li>
                                Copy the <strong>Redirect URL</strong> into the <strong>Redirect URL for OAuth</strong>{' '}
                                field.
                            </li>
                            <li>
                                Scroll down to <strong>OAuth allow list</strong> and copy the{' '}
                                <strong>Redirect URL</strong> into the <strong>Add allow lists</strong> field.
                            </li>
                            <ReadOnlyTextField label="Redirect URL" value={props.callbackUrl} />
                        </DialogContentText>
                        <ConnectionModalTextField
                            label="Client ID"
                            value={clientId}
                            onUpdate={(e) => {
                                if (props.errors) props.clearErrors();
                                setClientId(e.target.value.trim());
                            }}
                        />
                        <ConnectionModalSecretTextField
                            label="Client Secret"
                            value={clientSecret}
                            onUpdate={(e) => {
                                if (props.errors) props.clearErrors();
                                setClientSecret(e.target.value.trim());
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(ZoomWizardStage.CREATE);
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            busy={props.saving}
                            disabled={!clientId || !clientSecret}
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(ZoomWizardStage.ADD_SCOPES);
                            }}
                        >
                            Next
                        </Button>
                    </DialogActions>
                </>
            );
        case ZoomWizardStage.ADD_SCOPES:
            return (
                <>
                    <StyledAlert
                        severity="info"
                        text={`You will need to select the necessary permissions to give ScriptRunner Connect.`}
                    />
                    <DialogContent>
                        <DialogContentText component="ol">
                            <li>
                                Click on <strong>Scopes</strong>.
                            </li>
                            <li>
                                Click on <strong>Add Scopes</strong>.
                            </li>
                            <li>
                                Select the necessary permissions you would like to give ScriptRunner Connect then click{' '}
                                <strong>Done</strong>.
                            </li>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(ZoomWizardStage.DETAILS);
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            busy={props.saving}
                            disabled={!clientId || !clientSecret}
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(ZoomWizardStage.AUTHORIZE);
                            }}
                        >
                            Next
                        </Button>
                    </DialogActions>
                </>
            );
        case ZoomWizardStage.AUTHORIZE:
            return (
                <>
                    <DialogContent>
                        <DialogContentText>
                            To access information in Zoom you need to authorize our app to be able to make requests on
                            your behalf.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(ZoomWizardStage.ADD_SCOPES);
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            data-pendo={'connectorAuthorised'}
                            busy={props.saving}
                            disabled={!clientId || !clientSecret}
                            onClick={() =>
                                props.onSave({
                                    clientId,
                                    clientSecret,
                                })
                            }
                        >
                            Authorize
                        </Button>
                    </DialogActions>
                </>
            );
        case ZoomWizardStage.SUCCESS:
            return (
                <>
                    <DialogContentText>Success</DialogContentText>
                </>
            );
    }
};

export const ZoomConnectionAppConfigureDialog: React.FC<ZoomConnection> = (props) => {
    const error = <StyledAlert severity="error" title={props.errors} />;
    return (
        <Dialog open={props.open} onClose={() => props.onClose()}>
            <DialogTitleMain title="Configure Connector" variant="h6" icon={<ZoomIcon />} />
            {props.errors && error}
            <ZoomWizardSteps stage={props.currentStage} />
            <StageContent {...props} stage={props.currentStage} setStage={props.setStage} />
        </Dialog>
    );
};
