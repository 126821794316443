import { useObservableState } from 'observable-hooks';
import { ErrorPage } from '../../components/app-main/ErrorPage';
import { errorPageDetails$ } from '../../store/error';
import { loadTokens$, sessionExpired$, showSessionExpiredWarning$ } from '../../store/config';
import { logoutFromAppAction$, userLoggedOutFromApp$ } from '../../store/user';
import { supportPortalUrl } from '../../utils/documentation';
import { useAuth } from '../../utils/auth';

export const ErrorPageContainer: React.FC = () => {
    const errorPageDetails = useObservableState(errorPageDetails$);
    const sessionExpired = useObservableState(sessionExpired$);
    const showSessionExpiredWarning = useObservableState(showSessionExpiredWarning$);
    const userLoggedOutFromApp = useObservableState(userLoggedOutFromApp$);

    const { operations } = useAuth();

    const showRefreshSessionWarning = sessionExpired && showSessionExpiredWarning;

    if (userLoggedOutFromApp === true) {
        void operations.logout({ redirectUrl: window.location.origin });
    }

    return (
        <ErrorPage
            error={errorPageDetails?.errorMessage}
            background={errorPageDetails?.background}
            title={errorPageDetails?.pageTitle}
            skipRefreshMessage={errorPageDetails?.skipRefreshMessage}
            showDashboardLink={errorPageDetails?.showDashboardLink}
            skipRefreshMessageOverride={errorPageDetails?.skipRefreshMessageOverride}
            showRefreshSessionWarning={showRefreshSessionWarning}
            supportPortalUrl={supportPortalUrl}
            onLogoutFromApp={() => logoutFromAppAction$.next()}
            onSetLoadTokens={(loadTokens) => loadTokens$.next(loadTokens)}
        />
    );
};
