import { useObservableState, useSubscription } from 'observable-hooks';
import {
    selectedEnvironmentUid$,
    selectedWorkspace$,
    selectedWorkspaceEnvironment$,
    selectedWorkspaceResources$,
    selectedWorkspaceSelectedResource$,
    selectedWorkspaceUid$,
} from '../../../../store/workspace';
import { useNavigate } from 'react-location';
import {
    saveScheduledTriggerAction$,
    savingSelectedScheduledTrigger$,
    selectedScheduledTriggerDetails$,
    selectedScheduledTriggerErrors$,
    scheduleBuilderDialogOpen$,
    unsavedCronExpression$,
    cronHasUnsavedChanges$,
    creatingScriptForScheduledTrigger$,
    createScriptForScheduledTriggerAction$,
} from '../../../../store/workspace/scheduled-trigger';
import { useState } from 'react';
import { scriptCreatedForScheduledTriggerAction$ } from '../../../../store/workspace/script';
import { newDeploymentCreatedAction$ } from '../../../../store/workspace/deployment';
import { getBasePath } from '../../../../utils/path';
import { ScheduledTriggerDetails } from '../../../../components/workspace-resources/advanced-view/scheduled-triggers/ScheduledTriggerDetails';

export const ScheduledTriggerDetailsContainer: React.FC = () => {
    const navigate = useNavigate();

    const workspaceResources = useObservableState(selectedWorkspaceResources$);
    const details = useObservableState(selectedScheduledTriggerDetails$);
    const selectedScheduleBuilderCron = useObservableState(unsavedCronExpression$);
    const cronHasUnsavedChanges = useObservableState(cronHasUnsavedChanges$);
    const errors = useObservableState(selectedScheduledTriggerErrors$);
    const saving = useObservableState(savingSelectedScheduledTrigger$);
    const selectedWorkspace = useObservableState(selectedWorkspace$);
    const selectedWorkspaceEnvironment = useObservableState(selectedWorkspaceEnvironment$);

    const [createdScriptUid, setCreatedScriptUid] = useState<string | undefined>(undefined);

    const handleCancel = (): void => {
        navigate({ to: '../../' });
        selectedWorkspaceSelectedResource$.next(undefined);
    };

    useSubscription(scriptCreatedForScheduledTriggerAction$, ({ uid, name }) => {
        creatingScriptForScheduledTrigger$.next(false);
        selectedWorkspaceResources$.next({
            ...workspaceResources,
            scripts: [...workspaceResources.scripts, { name, uid }],
        });
        setCreatedScriptUid(uid);
    });

    useSubscription(newDeploymentCreatedAction$, () => {
        // Ugly hack to refresh the page: https://github.com/TanStack/react-location/discussions/269
        // TODO: find a fix
        navigate({ to: '../../' });
        setTimeout(
            () =>
                navigate({
                    to: `${getBasePath()}/workspace/${selectedWorkspaceUid$.value}/environment/${
                        selectedEnvironmentUid$.value
                    }/scheduled/${details?.uid}`,
                }),
            100
        );
    });

    return (
        <ScheduledTriggerDetails
            cron={selectedScheduleBuilderCron ?? details?.cronExpression}
            cronHasUnsavedChanges={cronHasUnsavedChanges}
            disabled={details?.disabled}
            errors={errors}
            nextScheduledDates={details?.nextScheduledDates.map((t) => new Date(t)) ?? []}
            saving={saving}
            workspaceLocked={!!selectedWorkspace?.locked}
            scripts={workspaceResources.scripts}
            warnings={details?.warnings}
            selectedScriptUid={createdScriptUid ?? details?.scriptUid}
            environmentDeployed={selectedWorkspaceEnvironment?.deployment !== undefined}
            templateMode={false}
            uid={details?.uid ?? ''}
            onCancel={handleCancel}
            onChangeCron={(cron) => unsavedCronExpression$.next(cron)}
            onOpenScheduleBuilder={() => scheduleBuilderDialogOpen$.next(true)}
            onSave={(event) => saveScheduledTriggerAction$.next(event)}
            onCreateNewScript={() => createScriptForScheduledTriggerAction$.next()}
        />
    );
};
