import { LoaderFn } from 'react-location';
import { LocationGenerics } from '../../../router';
import { loadErrorPage } from '../../../store/error';
import { loadWorkspaceSetupGuideStep, selectedWorkspaceSetupGuideTriggerScreenType$ } from '../../../store/setup-guide';
import { selectedEnvironmentUid$, selectedWorkspace$, selectedWorkspaceResources$ } from '../../../store/workspace';
import { loadWorkspaceEnvironments, loadWorkspaceResources } from '../../../store/workspace/utils';
import { getEventListener } from '../../../data/event-listener';
import { selectedEventListenerDetails$ } from '../../../store/workspace/event-listener';
import { loadLoggedInUserConnections } from '../../../store/connection/utils';
import { getScheduledTrigger } from '../../../data/scheduled-trigger';
import { unsavedCronExpression$, selectedScheduledTriggerDetails$ } from '../../../store/workspace/scheduled-trigger';
import { loggedInUserConnections$ } from '../../../store/connections';
import { loadApps } from '../../../store/apps';

export const setupGuideTriggerStepLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const workspaceUid = routeMatch.params.workspaceUid!;

    try {
        await loadWorkspaceSetupGuideStep(workspaceUid, 'TRIGGERS');

        if (!loggedInUserConnections$.value.length) {
            await loadLoggedInUserConnections();
        }

        if (!selectedEnvironmentUid$.value) {
            await loadWorkspaceEnvironments(workspaceUid);
            await loadWorkspaceResources(workspaceUid, selectedEnvironmentUid$.value ?? '');
        }

        if (!selectedWorkspaceSetupGuideTriggerScreenType$.value) {
            const defaultScreen =
                selectedWorkspace$.value?.setupGuide === 'TEMPLATE'
                    ? selectedWorkspaceResources$.value.eventListeners.length > 0
                        ? 'event'
                        : selectedWorkspaceResources$.value.scheduledTriggers.length > 0
                        ? 'schedule'
                        : 'event'
                    : 'event';

            selectedWorkspaceSetupGuideTriggerScreenType$.next(defaultScreen);
        }
    } catch (e) {
        loadErrorPage({
            error: e,
            genericMessage: 'Failed to load triggers step.',
        });

        throw e;
    }

    return {};
};

export const setupGuideEditEventListenerScreenLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const workspaceUid = routeMatch.params.workspaceUid!;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const eventListenerUid = routeMatch.params.eventListenerUid!;

    try {
        await Promise.all([(loadWorkspaceSetupGuideStep(workspaceUid, 'TRIGGERS'), loadApps())]);

        if (!loggedInUserConnections$.value.length) {
            await loadLoggedInUserConnections();
        }

        if (!selectedEnvironmentUid$.value) {
            await loadWorkspaceEnvironments(workspaceUid);
            await loadWorkspaceResources(workspaceUid, selectedEnvironmentUid$.value ?? '');
        }

        const eventListener = await getEventListener(eventListenerUid, selectedEnvironmentUid$.value ?? '');
        selectedEventListenerDetails$.next(eventListener);
    } catch (e) {
        loadErrorPage({
            error: e,
            genericMessage: 'Failed to load event listener.',
        });

        throw e;
    }

    return {};
};

export const setupGuideEditScheduledTriggerScreenLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const workspaceUid = routeMatch.params.workspaceUid!;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const scheduledTriggerUid = routeMatch.params.scheduledTriggerUid!;

    try {
        await loadWorkspaceSetupGuideStep(workspaceUid, 'TRIGGERS');

        if (!loggedInUserConnections$.value.length) {
            await loadLoggedInUserConnections();
        }

        if (!selectedEnvironmentUid$.value) {
            await loadWorkspaceEnvironments(workspaceUid);
            await loadWorkspaceResources(workspaceUid, selectedEnvironmentUid$.value ?? '');
        }

        const scheduledTrigger = await getScheduledTrigger(scheduledTriggerUid, selectedEnvironmentUid$.value ?? '');
        selectedScheduledTriggerDetails$.next(scheduledTrigger);
        unsavedCronExpression$.next(scheduledTrigger.cronExpression);
    } catch (e) {
        loadErrorPage({
            error: e,
            genericMessage: 'Failed to load scheduled trigger.',
        });

        throw e;
    }

    return {};
};
