import { useState } from 'react';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import { Button } from '../common/buttons/Button';
import { Dialog } from '../common/Dialog';
import { handleKeyDown } from '../../utils/input';
import { dpaUrl, eulaUrl, privacyPolicyUrl, termsAndConditionsUrl } from '../../utils/documentation';

const StyledContent = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(1),
}));

const StyledLink = styled(Link)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightBold,
}));

interface ConsentDialogProperties {
    saving?: boolean;
    onAgree(): void;
    onDisagree(): void;
}

export const ConsentDialog: React.FC<ConsentDialogProperties> = ({ saving = false, onAgree, onDisagree }) => {
    const [checked, setChecked] = useState(false);

    const buttons = [
        <Button variant="outlined" onClick={onDisagree} data-pendo={'tocDenied'}>
            Deny
        </Button>,
        <Button busy={saving} disabled={!checked} onClick={onAgree} data-pendo={'tocAccepted'}>
            Accept
        </Button>,
    ];

    return (
        <Dialog
            buttons={buttons}
            open={true}
            title="Cookies and data privacy"
            onKeyDown={(event) =>
                handleKeyDown({
                    event,
                    enterCondition: checked && !saving,
                    enterFn: onAgree,
                    escFn: onDisagree,
                })
            }
        >
            <StyledContent>
                <Checkbox onChange={() => setChecked(!checked)} />
                <Box>
                    By checking this box I agree to the terms of the{' '}
                    <StyledLink href={eulaUrl} target="_blank">
                        Adaptavist EULA
                    </StyledLink>
                    {', '}
                    <StyledLink href={termsAndConditionsUrl} target="_blank">
                        Website Terms
                    </StyledLink>
                    {', '}
                    <StyledLink href={privacyPolicyUrl} target="_blank">
                        Privacy Policy
                    </StyledLink>
                    {', and '}
                    <StyledLink href={dpaUrl} target="_blank">
                        DPA
                    </StyledLink>
                    .
                </Box>
            </StyledContent>
        </Dialog>
    );
};
