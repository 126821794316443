import { BehaviorSubject, map, Subject } from 'rxjs';
import {
    connectionCreatedAction$,
    ConnectionDetails,
    connectionCreatedFirstTimeAction$,
    connectionCreatedFirstTime$,
} from '..';
import { createConnection, saveConnection, saveConnectionInstance } from '../../../data/connection';
import { configTopic$ } from '../../config';
import { publishLocalFeedbackEventAction$ } from '../../feedback';
import { monitor } from '../../monitor';
import {
    loadLoggedInUserConnectionsWhenEventIsEmitted,
    saveConnectionAllowed,
    initiate,
    openConsentWindow,
    handleManageConnectionError,
    TimeoutError,
} from '../utils';
import { promptQuestion } from '../../confirm';
import { AYSYWTSAETCWHAI } from '../../../i18n';
import { InformativeError } from '../../../utils/error';

export interface AtlassianCloudConnectionDetails extends ConnectionDetails {
    clientId?: string;
    clientSecret?: string;
    scopes?: string;
    instanceUrl?: string;
    cloudId?: string;
    proxyAccount?: string;
}

interface AtlassianCloudAppSetupSaveProps {
    clientId?: string;
    clientSecret?: string;
    scopes?: string;
    proxyAccount?: string;
}

export const atlassianCloudManageConnectionOpenDialogAction$ = monitor(
    'atlassianCloudManageConnectionOpenDialogAction$',
    new Subject<AtlassianCloudConnectionDetails>()
);
export const atlassianCloudManageConnectionCloseDialogAction$ = monitor(
    'atlassianCloudManageConnectionCloseDialogAction$',
    new Subject<void>()
);
export const atlassianCloudSaveConnectionAction$ = monitor(
    'atlassianCloudSaveConnectionAction$',
    new Subject<string>()
);
export const atlassianCloudConnectionCreatedAction$ = monitor(
    'atlassianCloudConnectionCreatedAction$',
    new Subject<string>()
);
export const atlassianCloudConnectionSavedAction$ = monitor(
    'atlassianCloudConnectionSavedAction$',
    new Subject<{ uid: string; connectionTypeUid: string }>()
);

export const atlassianCloudManageConnectionDialogOpen$ = monitor(
    'atlassianCloudManageConnectionDialogOpen$',
    new BehaviorSubject(false)
);
export const atlassianCloudConnectionSaving$ = monitor('atlassianCloudConnectionSaving$', new BehaviorSubject(false));
export const atlassianCloudManageConnectionAuthorizeLoading$ = monitor(
    'atlassianCloudManageConnectionAuthorizeLoading$',
    new BehaviorSubject(false)
);
export const atlassianCloudManageConnectionDetails$ = monitor(
    'atlassianCloudManageConnectionDetails$',
    new BehaviorSubject<AtlassianCloudConnectionDetails | undefined>(undefined)
);
export const atlassianCloudManageConnectionDialogErrors$ = monitor(
    'atlassianCloudManageConnectionDialog',
    new BehaviorSubject<string | undefined>(undefined)
);

export const atlassianCloudAppSetupOpenDialogAction$ = monitor(
    'atlassianCloudAppSetupOpenDialogAction$',
    new Subject<AtlassianCloudConnectionDetails>()
);
export const atlassianCloudAppSetupCloseDialogAction$ = monitor(
    'atlassianCloudAppSetupCloseDialogAction$',
    new Subject<void>()
);
export const atlassianCloudAppSetupDialogOpen$ = monitor(
    'atlassianCloudAppSetupDialogOpen$',
    new BehaviorSubject(false)
);
export const atlassianCloudAppSetupAuthorizeAction$ = monitor(
    'atlassianCloudAppSetupAuthorizeAction$',
    new Subject<AtlassianCloudAppSetupSaveProps>()
);
export const atlassianCloudAppSetupDialogErrors$ = monitor(
    'atlassianCloudAppSetupDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const atlassianCloudManageConnectionInitiateSetupAction$ = monitor(
    'atlassianCloudManageConnectionInitiateSetupAction$',
    new Subject<string>()
);

export const atlassianCloudInstanceSelectionOpen$ = monitor(
    'atlassianCloudInstanceSelectionOpen$',
    new BehaviorSubject(false)
);
export const atlassianCloudInstanceSelectionSaving$ = monitor(
    'atlassianCloudInstanceSelectionSaving$',
    new BehaviorSubject(false)
);
export const atlassianCloudInstanceSelectionInstances$ = monitor(
    'atlassianCloudInstanceSelectionInstances$',
    new BehaviorSubject<{ id: string; url: string }[] | undefined>(undefined)
);
export const atlassianCloudInstanceSelectionCloseDialogAction$ = monitor(
    'atlassianCloudInstanceSelectionCloseDialogAction$',
    new Subject<void>()
);
export const atlassianCloudSaveInstanceAction$ = monitor(
    'atlassianCloudSaveInstanceAction$',
    new Subject<{ id: string; url: string }>()
);

export const atlassianCloudAppSetupDialogStage$ = monitor('atlassianCloudAppSetupDialogStage$', new BehaviorSubject(0));
export const atlassianCloudAppSetupDialogInstanceType$ = monitor(
    'atlassianCloudAppSetupDialogInstanceType$',
    new BehaviorSubject<'SAAS' | 'SELF-MANAGED'>('SAAS')
);

atlassianCloudInstanceSelectionCloseDialogAction$.subscribe(() => {
    atlassianCloudInstanceSelectionOpen$.next(false);
    atlassianCloudInstanceSelectionInstances$.next(undefined);
});

atlassianCloudSaveInstanceAction$
    .pipe(
        map(async ({ id: cloudId, url: instanceUrl }) => {
            atlassianCloudInstanceSelectionSaving$.next(true);
            atlassianCloudManageConnectionDialogErrors$.next(undefined);

            const { uid, name, connectionType } = atlassianCloudManageConnectionDetails$.value ?? {};

            if (!uid) {
                throw Error('Atlassian Cloud Connector not created');
            }

            if (!connectionType) {
                throw Error(CONNECTOR_TYPE_UNDEFINED);
            }

            try {
                await saveConnectionInstance(uid, instanceUrl, cloudId);

                // Update details
                atlassianCloudManageConnectionDetails$.next({
                    connectionType,
                    name,
                    uid,
                    cloudId,
                    instanceUrl,
                });
                atlassianCloudConnectionSavedAction$.next({ uid, connectionTypeUid: connectionType.uid });
                atlassianCloudInstanceSelectionCloseDialogAction$.next();
                atlassianCloudAppSetupCloseDialogAction$.next();
                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: 'Connector authorized.',
                });
            } catch (e) {
                atlassianCloudInstanceSelectionCloseDialogAction$.next();
                if (e instanceof InformativeError) {
                    atlassianCloudManageConnectionDialogErrors$.next(e.message);
                } else {
                    atlassianCloudManageConnectionDialogErrors$.next(
                        'Failed to authorize Connector, please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while authorizing Atlassian Cloud Connector', e);
                }
            }

            atlassianCloudInstanceSelectionSaving$.next(false);
            atlassianCloudConnectionSaving$.next(false);
            atlassianCloudManageConnectionAuthorizeLoading$.next(false);
            atlassianCloudAppSetupCloseDialogAction$.next();
        })
    )
    .subscribe();

atlassianCloudConnectionCreatedAction$.subscribe((uid) => connectionCreatedAction$.next(uid));

atlassianCloudAppSetupOpenDialogAction$.subscribe((details) => {
    atlassianCloudAppSetupDialogErrors$.next(undefined);
    atlassianCloudManageConnectionDialogErrors$.next(undefined);
    atlassianCloudAppSetupDialogOpen$.next(true);
    atlassianCloudAppSetupDialogStage$.next(0);
    atlassianCloudAppSetupDialogInstanceType$.next('SAAS');
    atlassianCloudManageConnectionDetails$.next(details);
});

atlassianCloudAppSetupCloseDialogAction$.subscribe(() => {
    atlassianCloudAppSetupDialogOpen$.next(false);
    atlassianCloudManageConnectionAuthorizeLoading$.next(false);
});

atlassianCloudManageConnectionOpenDialogAction$.subscribe((details) => {
    atlassianCloudAppSetupDialogErrors$.next(undefined);
    atlassianCloudManageConnectionDialogErrors$.next(undefined);
    atlassianCloudManageConnectionDialogOpen$.next(true);
    atlassianCloudManageConnectionDetails$.next(details);
});

atlassianCloudManageConnectionCloseDialogAction$.subscribe(() => {
    atlassianCloudManageConnectionDialogOpen$.next(false);
    atlassianCloudAppSetupDialogOpen$.next(false);
    atlassianCloudManageConnectionDetails$.next(undefined);
});

atlassianCloudSaveConnectionAction$
    .pipe(
        // eslint-disable-next-line sonarjs/cognitive-complexity
        map(async (name) => {
            atlassianCloudConnectionSaving$.next(true);
            atlassianCloudManageConnectionDialogErrors$.next(undefined);

            const { uid, connectionType } = atlassianCloudManageConnectionDetails$.value ?? {};

            if (!connectionType) {
                throw Error(CONNECTOR_TYPE_UNDEFINED);
            }
            const connectionTypeUid = connectionType.uid;

            if (uid) {
                if (saveConnectionAllowed(uid)) {
                    await saveAtlassianCloudConnection(uid, name, connectionTypeUid);
                } else {
                    promptQuestion({
                        title: AYSYWTSAETCWHAI,
                        onProceed: async () => {
                            await saveAtlassianCloudConnection(uid, name, connectionTypeUid);
                        },
                    });
                }
            } else {
                promptQuestion({
                    title: AYSYWTSAETCWHAI,
                    onProceed: async () => {
                        try {
                            const { uid } = await createConnection(
                                connectionType.uid,
                                name,
                                connectionType.apiHandlerTypes[0]?.uid ?? '',
                                connectionType.eventListenerTypes[0]?.uid ?? ''
                            );

                            atlassianCloudConnectionCreatedAction$.next(uid);
                            atlassianCloudConnectionSavedAction$.next({ uid, connectionTypeUid });
                            atlassianCloudManageConnectionCloseDialogAction$.next();
                            publishLocalFeedbackEventAction$.next({
                                level: 'SUCCESS',
                                message: 'Connector created.',
                            });
                            connectionCreatedFirstTimeAction$.next();
                        } catch (e) {
                            handleManageConnectionError(
                                e,
                                atlassianCloudManageConnectionDialogErrors$,
                                ATLASSIAN_CLOUD_APP
                            );
                        }
                    },
                });
            }
            atlassianCloudConnectionSaving$.next(false);
        })
    )
    .subscribe();

atlassianCloudAppSetupAuthorizeAction$
    .pipe(
        map(async (appInfoProps) => {
            atlassianCloudConnectionSaving$.next(true);
            atlassianCloudAppSetupDialogErrors$.next(undefined);

            const { uid, connectionType } = atlassianCloudManageConnectionDetails$.value ?? {};
            try {
                if (!connectionType) {
                    throw Error(CONNECTOR_TYPE_UNDEFINED);
                }

                if (!uid) {
                    throw Error('Atlassian Cloud connection has not been created so cannot configure it');
                }

                const baseUrl = configTopic$.value.connection?.atlassianCloud?.baseUrl;
                if (!baseUrl) {
                    throw new Error('No Atlassian Cloud Connector URL configured in meta');
                }
                const connectionTypeName = connectionType.name.toLowerCase().replaceAll(' ', '-');
                const response = await initiate(
                    {
                        ...appInfoProps,
                        connectionId: uid,
                        connectionType: connectionTypeName,
                    },
                    `${baseUrl}/initiate`
                );
                openConsentWindow(response.location, atlassianCloudManageConnectionAuthorizeLoading$);

                atlassianCloudConnectionCreatedAction$.next(uid);
            } catch (e) {
                if (e instanceof InformativeError || e instanceof TimeoutError) {
                    atlassianCloudAppSetupDialogErrors$.next(e.message);
                } else {
                    atlassianCloudAppSetupDialogErrors$.next(
                        'Failed to save Atlassian Cloud application details, please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while saving Atlassian Cloud application info', e);
                }
            }

            atlassianCloudConnectionSaving$.next(false);
            atlassianCloudManageConnectionAuthorizeLoading$.next(false);
        })
    )
    .subscribe();

atlassianCloudManageConnectionInitiateSetupAction$
    .pipe(
        map(async (name) => {
            atlassianCloudConnectionSaving$.next(true);
            atlassianCloudManageConnectionAuthorizeLoading$.next(true);
            atlassianCloudManageConnectionDialogErrors$.next(undefined);

            const {
                uid,
                connectionType,
                name: currentName,
                ...restOfDetails
            } = atlassianCloudManageConnectionDetails$.value ?? {};
            try {
                if (!connectionType) {
                    throw Error('Atlassian Cloud connector type not defined');
                }
                const connectionTypeUid = connectionType.uid;

                if (uid) {
                    if (currentName !== name) {
                        await saveConnection(uid, name);
                    }

                    atlassianCloudConnectionSavedAction$.next({ uid, connectionTypeUid });
                    atlassianCloudAppSetupOpenDialogAction$.next({
                        ...restOfDetails,
                        uid,
                        connectionType,
                        name,
                    });
                } else {
                    const { uid } = await createConnection(
                        connectionType.uid,
                        name,
                        connectionType.apiHandlerTypes[0]?.uid ?? '',
                        connectionType.eventListenerTypes[0]?.uid ?? ''
                    );

                    atlassianCloudConnectionCreatedAction$.next(uid);
                    atlassianCloudConnectionSavedAction$.next({ uid, connectionTypeUid });
                    atlassianCloudManageConnectionDetails$.next({
                        connectionType,
                        name,
                        uid,
                    });

                    atlassianCloudConnectionSaving$.next(false);

                    atlassianCloudAppSetupOpenDialogAction$.next({
                        connectionType,
                        name,
                        uid,
                    });
                    connectionCreatedFirstTime$.next(true);
                }
            } catch (e) {
                handleManageConnectionError(e, atlassianCloudManageConnectionDialogErrors$, ATLASSIAN_CLOUD_APP);
                atlassianCloudManageConnectionAuthorizeLoading$.next(false);
            }

            atlassianCloudConnectionSaving$.next(false);
        })
    )
    .subscribe();

loadLoggedInUserConnectionsWhenEventIsEmitted(atlassianCloudConnectionSavedAction$);

export const saveAtlassianCloudConnection = async (
    uid: string,
    name: string,
    connectionTypeUid: string
): Promise<void> => {
    try {
        await saveConnection(uid, name);

        atlassianCloudConnectionSavedAction$.next({ uid, connectionTypeUid });
        atlassianCloudManageConnectionCloseDialogAction$.next();
        publishLocalFeedbackEventAction$.next({
            level: 'SUCCESS',
            message: 'Connector saved.',
        });
        if (connectionCreatedFirstTime$.value) {
            connectionCreatedFirstTimeAction$.next();
            connectionCreatedFirstTime$.next(false);
        }
    } catch (e) {
        handleManageConnectionError(e, atlassianCloudManageConnectionDialogErrors$, ATLASSIAN_CLOUD_APP);
    }
};

export const ATLASSIAN_CLOUD_APP = 'Atlassian Cloud';
const CONNECTOR_TYPE_UNDEFINED = 'Atlassian Cloud connector type not defined';
