import { useObservableState, useSubscription } from 'observable-hooks';
import { useMonaco } from '../../../../components/workspace-resources/scripts/editor/useMonaco';
import {
    ScriptDetails,
    ScriptPosition,
} from '../../../../components/workspace-resources/advanced-view/scripts/ScriptDetails';
import {
    currentTypeDeclarationsLoaded$,
    editorAlertMessage$,
    legacyPackageImports$,
    legacyPackagesDialogErrors$,
    legacyPackagesDialogLoading$,
    legacyPackagesDialogOpen$,
    prependEditorContentAction$,
    totalTypeDeclarationsLoaded$,
} from '../../../../store/editor/editor';
import { selectedReadOnlyTemplate$ } from '../../../../store/templates';
import {
    selectedEnvironmentUid$,
    selectedWorkspace$,
    selectedWorkspaceEnvironment$,
    selectedWorkspaceUid$,
} from '../../../../store/workspace';
import { openApiConnectionImportDialogAction$ } from '../../../../store/workspace/api-handler';
import {
    saveScriptAction$,
    scriptsBeingSaved$,
    scriptHasUnsavedChanges$,
    selectedScriptContentChangedAction$,
    selectedScriptUid$,
    unsavedScriptDetails$,
    triggerScriptAction$,
    editorDisableReadOnlyMode$,
    editorReadOnly$,
    userDisabledEditorReadOnly$,
    scriptExecutionInProgress$,
    scriptHelperPopupOpen$,
    scriptHelperPopupVisible$,
    storedScriptsPosition$,
    storeScriptPositionAction$,
    openEditScriptNameDialogAction$,
} from '../../../../store/workspace/script';
import { publishLocalFeedbackEventAction$ } from '../../../../store/feedback';
import { legacyImportsRegex } from '../../../../utils/miscellaneous';
import { loggedInUserDetails$ } from '../../../../store/user';
import {
    currentReplayInvocationDetails$,
    loadingReplayInvocationPayload$,
    replayInvocationDialogOpen$,
    replayInvocationPayload$,
    triggerReplayInvocationAction$,
} from '../../../../store/workspace/replay-invocation';
import {
    assumeWorkspaceEditControlAction$,
    workspaceEditControlAssumedForReplayInvocationAction$,
} from '../../../../store/workspaces';
import { useNavigate } from 'react-location';
import { getBasePath } from '../../../../utils/path';
import { upgradeWorkspaceLegacyPackagesAction$ } from '../../../../store/workspace/packages';
import { segmentAnalyticsTrack } from '../../../../data/segment-analytics';

// eslint-disable-next-line sonarjs/cognitive-complexity
export const ScriptDetailsContainer: React.FC = () => {
    const selectedScriptUid = useObservableState(selectedScriptUid$);
    const selectedEnvironmentUid = useObservableState(selectedEnvironmentUid$);
    const savingScript = useObservableState(scriptsBeingSaved$);
    const scriptHasUnsavedChanges = useObservableState(scriptHasUnsavedChanges$);
    const editorReadOnly = useObservableState(editorReadOnly$);
    const userDisabledEditorReadOnly = useObservableState(userDisabledEditorReadOnly$);
    const selectedWorkspaceEnvironment = useObservableState(selectedWorkspaceEnvironment$);
    const currentTypeDeclarationsLoaded = useObservableState(currentTypeDeclarationsLoaded$);
    const totalTypeDeclarationsToLoad = useObservableState(totalTypeDeclarationsLoaded$);
    const editorAlertMessage = useObservableState(editorAlertMessage$);
    const monaco = useMonaco();
    const triggeringScript = useObservableState(scriptExecutionInProgress$);
    const scriptHelperPopupOpen = useObservableState(scriptHelperPopupOpen$);
    const scriptHelperPopupVisible = useObservableState(scriptHelperPopupVisible$);
    const selectedReadOnlyTemplate = useObservableState(selectedReadOnlyTemplate$);
    const selectedWorkspace = useObservableState(selectedWorkspace$);
    const legacyImports = useObservableState(legacyPackageImports$);
    const legacyPackagesDialogOpen = useObservableState(legacyPackagesDialogOpen$);
    const legacyPackagesDialogLoading = useObservableState(legacyPackagesDialogLoading$);
    const legacyPackagesDialogErrors = useObservableState(legacyPackagesDialogErrors$);
    const loggedInUserDetails = useObservableState(loggedInUserDetails$);
    const replayInvocationDialogOpen = useObservableState(replayInvocationDialogOpen$);
    const replayInvocationPayload = useObservableState(replayInvocationPayload$);
    const loadingReplayInvocationPayload = useObservableState(loadingReplayInvocationPayload$);
    const currentReplayInvocationDetails = useObservableState(currentReplayInvocationDetails$);
    const storedScriptsPosition = useObservableState(storedScriptsPosition$);

    const readOnlyTemplateMode = !!selectedReadOnlyTemplate?.templateUid;
    const workspaceLocked = !!selectedWorkspace?.locked;
    const navigate = useNavigate();
    const handleContentChange = (content: string): void => {
        selectedScriptContentChangedAction$.next(content);
    };

    const handlePackageNameReplacement = (content: string): void => {
        if (legacyImports) {
            const legacyPackages: string[] = [];
            const updatedContent = legacyImports.reduce((acc, curr) => {
                const index = curr.indexOf('@stitch-it');
                if (index !== -1) {
                    legacyPackages.push(curr.slice(index));
                }

                const updatedLine = curr.replace('stitch-it', 'sr-connect');
                acc = acc.replace(curr, updatedLine);
                return acc;
            }, content);

            if (monaco) {
                const uri = monaco.Uri.file(`/${selectedScript?.name}.ts`);
                const model = monaco.editor.getModel(uri);
                if (model) {
                    model.setValue(updatedContent);
                }
            }

            const workspaceUid = selectedWorkspaceUid$.value;
            if (workspaceUid && legacyPackages.length) {
                upgradeWorkspaceLegacyPackagesAction$.next({ workspaceUid, legacyPackages });
            } else {
                legacyPackagesDialogOpen$.next(false);
            }
        }
    };

    const scriptKey = `${selectedScriptUid ?? ''}_${selectedEnvironmentUid ?? ''}`;
    const selectedScript = unsavedScriptDetails$.value[scriptKey];
    const hasUnsavedChanges = scriptHasUnsavedChanges[selectedScriptUid ?? ''];
    const handleScriptSave = (): void => {
        saveScriptAction$.next();

        if (!selectedScript?.content.match(legacyImportsRegex)) {
            legacyPackageImports$.next(null);
        }
    };

    const handleLegacyPackagesDialogOpen = (isOpen: boolean): void => {
        legacyPackagesDialogOpen$.next(isOpen);
    };

    const handleCopyImports = (imports: string): void => {
        void navigator.clipboard.writeText(imports);
        publishLocalFeedbackEventAction$.next({
            level: 'SUCCESS',
            message: 'Import statement(s) copied to clipboard (paste it into your script).',
        });
    };

    const handleTriggerScript = (uid: string): void => triggerScriptAction$.next(uid);

    useSubscription(prependEditorContentAction$, ({ scriptName, content }) => {
        if (monaco) {
            const uri = monaco.Uri.file(`/${scriptName}.ts`);
            const model = monaco.editor.getModel(uri);

            if (model) {
                model.setValue(content + model.getValue());
            }
        }
    });

    useSubscription(workspaceEditControlAssumedForReplayInvocationAction$, () => {
        // Ugly hack to refresh the page: https://github.com/TanStack/react-location/discussions/269
        // TODO: find a fix
        const workspaceUid = selectedWorkspaceUid$.value;
        const environmentUid = selectedEnvironmentUid$.value;

        navigate({ to: getBasePath() });
        setTimeout(
            () =>
                navigate({
                    to: `${getBasePath()}workspace/${workspaceUid}/environment/${environmentUid}/script/${selectedScriptUid}`,
                }),
            100
        );
    });

    const editorHeaderMessage =
        currentTypeDeclarationsLoaded > 0
            ? `Loading type declarations: ${Math.round(
                  totalTypeDeclarationsToLoad > 0
                      ? (currentTypeDeclarationsLoaded * 100) / totalTypeDeclarationsToLoad
                      : 0
              )}% (${currentTypeDeclarationsLoaded}/${totalTypeDeclarationsToLoad})`
            : editorAlertMessage;

    const handleAssumeWorkspaceEditControlForReplayInvocation = (): void => {
        assumeWorkspaceEditControlAction$.next(true);
    };

    const invocationUid = currentReplayInvocationDetails?.invocationUid ?? '';

    const handleStoreScriptPosition = (scriptPosition: ScriptPosition): void => {
        storeScriptPositionAction$.next(scriptPosition);
    };

    const storedPosition = storedScriptsPosition?.get(`${selectedEnvironmentUid}/${selectedScriptUid}`);

    return (
        <ScriptDetails
            content={selectedScript?.content ?? ''}
            editorReadOnly={!userDisabledEditorReadOnly && editorReadOnly}
            environmentUid={selectedEnvironmentUid}
            executing={triggeringScript[selectedScriptUid ?? ''] ?? false}
            impersonating={!!loggedInUserDetails?.impersonating}
            legacyPackagesDialogErrors={legacyPackagesDialogErrors}
            legacyPackagesDialogLoading={legacyPackagesDialogLoading}
            legacyPackagesDialogOpen={legacyPackagesDialogOpen}
            readOnlyTemplateMode={readOnlyTemplateMode}
            replayInvocationDialogOpen={replayInvocationDialogOpen}
            replayInvocationPayload={replayInvocationPayload}
            replayInvocationPayloadLoading={loadingReplayInvocationPayload}
            saving={savingScript[selectedScriptUid ?? ''] ?? false}
            scriptName={selectedScript?.name ?? ''}
            scriptStoredPosition={storedPosition}
            scriptUid={selectedScriptUid ?? ''}
            workspaceLocked={workspaceLocked}
            selectedEnvironment={selectedWorkspaceEnvironment}
            editorAlertMessage={editorHeaderMessage}
            hasUnsavedChanges={!!hasUnsavedChanges}
            legacyImports={legacyImports}
            scriptHelperPopupOpen={scriptHelperPopupOpen}
            scriptHelperPopupVisible={scriptHelperPopupVisible && !readOnlyTemplateMode}
            onAssumeWorkspaceEditControlForReplayInvocation={handleAssumeWorkspaceEditControlForReplayInvocation}
            onClickMondayCodeExamplesUrl={() => {
                segmentAnalyticsTrack('Monday Code Examples Link Clicked', {
                    userId: loggedInUserDetails?.uid,
                    stitchTeamMember: loggedInUserDetails?.stitchTeamMember,
                    userOrigin: loggedInUserDetails?.userOrigin,
                });
            }}
            onCloseReplayInvocationDialog={() => {
                replayInvocationDialogOpen$.next(false);
                replayInvocationPayload$.next('');
                currentReplayInvocationDetails$.next(null);
            }}
            onContentChange={handleContentChange}
            onCopyImports={handleCopyImports}
            onEditorDisableReadOnly={() => editorDisableReadOnlyMode$.next()}
            onImportApiHandler={() => openApiConnectionImportDialogAction$.next()}
            onRenameScript={() => openEditScriptNameDialogAction$.next()}
            onLegacyPackagesDialogOpen={handleLegacyPackagesDialogOpen}
            onPackageNameReplacement={handlePackageNameReplacement}
            onReplayInvocationTriggered={(payload) => triggerReplayInvocationAction$.next({ invocationUid, payload })}
            onSave={handleScriptSave}
            onScriptHelperPopupClose={() => scriptHelperPopupOpen$.next(false)}
            onScriptHelperPopupOpen={() => scriptHelperPopupOpen$.next(true)}
            onStoreScriptPosition={handleStoreScriptPosition}
            onTriggerScript={handleTriggerScript}
        />
    );
};
