import { BehaviorSubject, Subject, map } from 'rxjs';
import {
    acceptOrganizationMemberInvite,
    getLoggedInUserDetailsAndInitialize,
    startImpersonation,
    stopImpersonation,
    updateUserDetails,
    logoutFromApp,
    addUserDeleteDate,
    cancelUserDelete,
    loadLoggedInUserDetails,
    generateApiKey,
    ApiKeys,
    deleteApiKey,
} from '../data/user';
import { resetAccountPassword } from '../utils/account';
import { publishLocalFeedbackEventAction$ } from './feedback';
import { monitor } from './monitor';
import { saveLocalStorage } from '../utils/localStorage';
import { getFetchOptions } from '../utils/fetch';
import { configTopic$, featureFlagsTopic$ } from './config';
import { Auth0Error, InformativeError, PermissionError, VerificationRequiredError } from '../utils/error';
import { getBasePath } from '../utils/path';
import { promptMessage, promptQuestion } from './confirm';
import { loadFeatureFlagsForUser } from '../data/feature-flags';
import { loadErrorPage } from './error';
import { removeSessionLock } from '..';
import { updateUserInAuth0, FullUserDetails } from '../utils/auth';

export interface UserUpdatePayload {
    firstName?: string;
    lastName?: string;
    company?: string;
    roleUid?: string;
    customIndustryRole?: string;
    enableEmailNotifications: boolean;
    enableMfa: boolean;
    scriptingFamiliarityUid?: string;
    userAppPreference?: string[];
}

interface AnonUser {
    uid: string;
    ip?: string;
    originUid?: string;
    utmMedium?: string;
    utmSource?: string;
    utmCampaign?: string;
    referrerSite?: string;
}

export interface StartImpersonationEvent {
    impersonateeUserUid: string;
    password: string;
}

export const loggedInUserDetails$ = monitor('loggedInUserDetails$', new BehaviorSubject<FullUserDetails | null>(null));
export const updateUserDetailsAction$ = monitor('updateUserDetailsAction$', new Subject<UserUpdatePayload>());
export const customRoleValidationError$ = monitor('customRoleValidationError$', new BehaviorSubject<string>(''));
export const userDetailsUpdating$ = monitor('userDetailsUpdating$', new BehaviorSubject<boolean>(false));
export const userAuthenticatedAction$ = monitor('userAuthenticatedAction$', new Subject<string>());
export const resetUserPasswordAction$ = monitor('resetUserPasswordAction$', new Subject<string>());
export const acceptInviteAction$ = monitor('acceptInviteAction$', new Subject<string>());
export const registerAnonUserAction$ = monitor('registerAnonUserAction$', new Subject<AnonUser>());
export const unregisterAnonUserAction$ = monitor('unregisterAnonUserAction$', new Subject<string>());
export const startImpersonationAction$ = monitor(
    'initiateImpersonationAction$',
    new Subject<StartImpersonationEvent>()
);
export const stopImpersonationAction$ = monitor('stopImpersonationAction$', new Subject<void>());
export const askImpersonationPasswordAction$ = monitor('askImpersonationPasswordAction$', new Subject<string>());
export const closeImpersonationPasswordDialogAction$ = monitor(
    'closeImpersonationPasswordDialogAction$',
    new Subject<void>()
);
export const askImpersonationPasswordDialogOpen$ = monitor(
    'askImpersonationPasswordDialogOpen$',
    new BehaviorSubject(false)
);
export const selectedImpersonateeUid$ = monitor(
    'selectedImpersonateeUid$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const impersonationPasswordError$ = monitor(
    'impersonationPasswordError$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const askImpersonationPasswordDialogSaving$ = monitor(
    'askImpersonationPasswordDialogSaving$',
    new BehaviorSubject(false)
);
export const logoutFromAppAction$ = monitor('logoutFromAppAction$', new Subject<void>());
export const userLoggedOutFromApp$ = monitor(
    'userLoggedOutFromApp$',
    new BehaviorSubject<boolean | undefined>(undefined)
);
export const deleteAccountAction$ = monitor('deleteAccountAction$', new Subject<void>());
export const cancelDeleteAccountAction$ = monitor('cancelDeleteAccountAction$', new Subject<void>());
export const createNewApiKeyAction$ = monitor('createNewApiKeyAction$', new Subject<void>());
export const generateNewApiKeyAction$ = monitor('generateNewApiKeyAction$', new Subject<string>());
export const newApiKeyGeneratedAction$ = monitor('newApiKeyGeneratedAction$', new Subject<void>());
export const apiKeys$ = monitor('apiKeys$', new BehaviorSubject<ApiKeys>([]));
export const deleteApiKeyAction$ = monitor('deleteApiKeyAction$', new Subject<string>());
export const apiKeyDeletedAction$ = monitor('apiKeyDeletedAction$', new Subject<string>());
export const askNewApiKeyNameAction$ = monitor('askNewApiKeyNameAction$', new Subject<void>());
export const closeAskNewApiKeyNameAction$ = monitor('closeAskNewApiKeyNameAction$', new Subject<void>());
export const askNewApiKeyNameDialogOpen$ = monitor('askNewApiKeyNameDialogOpen$', new BehaviorSubject(false));
export const askNewApiKeyNameDialogErrors$ = monitor(
    'askNewApiKeyNameDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const askNewApiKeyNameDialogSaving$ = monitor('askNewApiKeyNameDialogSaving$', new BehaviorSubject(false));
export const generatedApiKey$ = monitor('generatedApiKey$', new BehaviorSubject(''));
export const newApiKeyGeneratedDialogOpen$ = monitor('newApiKeyGeneratedDialogOpen$', new BehaviorSubject(false));
export const closeNewApiKeyGeneratedDialogAction$ = monitor(
    'closeNewApiKeyGeneratedDialogAction$',
    new Subject<void>()
);

logoutFromAppAction$
    .pipe(
        map(async () => {
            try {
                await logoutFromApp();
                userLoggedOutFromApp$.next(true);
            } catch (error) {
                console.error('Could not log user out', error);
            }
        })
    )
    .subscribe();

unregisterAnonUserAction$
    .pipe(
        map(async (stitchInitialUid) => {
            try {
                const fetchOptions = getFetchOptions({}, { uid: stitchInitialUid });
                const url = configTopic$.value.trigger?.unregisterAnonUser;
                if (!url) {
                    throw new Error('No unregister url configured in meta');
                }
                await fetch(url, fetchOptions);
                saveLocalStorage('stitchInitialUid', 'N/A');
            } catch (err) {
                console.error('Could not unregister user', err);
            }
        })
    )
    .subscribe();

registerAnonUserAction$
    .pipe(
        map(async (anonUser) => {
            try {
                const fetchOptions = getFetchOptions({}, anonUser);
                const url = configTopic$.value.trigger?.registerAnonUser;
                if (!url) {
                    throw new Error('No register user url configured in meta');
                }
                const response = await fetch(url, fetchOptions);
                if (!response.ok) {
                    console.warn('Could not initiate new user');
                    localStorage.removeItem('stitchInitialUid');
                }
            } catch (err) {
                console.error('Could not initiate new user', err);
            }
        })
    )
    .subscribe();

acceptInviteAction$
    .pipe(
        map(async (inviteId) => {
            try {
                const { organizationName } = await acceptOrganizationMemberInvite(inviteId);
                await loadLoggedInUserDetails();
                promptMessage({ title: `Successfully joined team: ${organizationName}` });
            } catch (e) {
                const errorMessage = e instanceof InformativeError ? `. ${e.message}` : '';

                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message:
                        `Could not join team ${errorMessage}. Click on the invite link again ` +
                        'and if the issue persists please contact support.',
                    toastOptions: {
                        autoClose: false,
                    },
                });
            }
        })
    )
    .subscribe();

updateUserDetailsAction$
    .pipe(
        map(async (updateData) => {
            userDetailsUpdating$.next(true);
            const existingMfaFlag = loggedInUserDetails$.value?.mfaEnabled;
            const hasMfaFlagChanged = existingMfaFlag !== updateData.enableMfa;

            if (hasMfaFlagChanged) {
                try {
                    await updateUserInAuth0(updateData.enableMfa);
                } catch (error) {
                    console.error('Could not update the user flag in Auth0');
                    publishLocalFeedbackEventAction$.next({
                        toastOptions: {
                            autoClose: false,
                        },
                        level: 'ERROR',
                        message:
                            'Could not update your Multi-Factor Authentication preference. Please try again and if the issues persists, please contact support.',
                    });
                }
            }

            try {
                const response = await updateUserDetails(updateData);
                if (response) {
                    customRoleValidationError$.next(response);
                } else {
                    customRoleValidationError$.next('');
                    await loadLoggedInUserDetails();

                    publishLocalFeedbackEventAction$.next({
                        level: 'SUCCESS',
                        message: 'Saved changes to profile.',
                    });
                }
            } catch {
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: 'Error occurred while updating profile.',
                });
            }
            userDetailsUpdating$.next(false);
        })
    )
    .subscribe();

resetUserPasswordAction$
    .pipe(
        map(async (email) => {
            await resetAccountPassword(email);
        })
    )
    .subscribe();

startImpersonationAction$
    .pipe(
        map(async ({ impersonateeUserUid, password }) => {
            impersonationPasswordError$.next('');
            askImpersonationPasswordDialogSaving$.next(true);
            try {
                await startImpersonation(impersonateeUserUid, password);
                closeImpersonationPasswordDialogAction$.next();
                removeSessionLock();
                window.location.replace(`${window.location.origin}${getBasePath()}`);
            } catch (e) {
                if (e instanceof PermissionError) {
                    console.error('Error starting impersonation:', e);
                    impersonationPasswordError$.next(e.message);
                } else {
                    console.error('Error when starting impersonation session', e);
                    impersonationPasswordError$.next('Error occurred while trying to start impersonation session.');
                }
            }
            askImpersonationPasswordDialogSaving$.next(false);
        })
    )
    .subscribe();

stopImpersonationAction$
    .pipe(
        map(async () => {
            try {
                await stopImpersonation();
                window.location.replace(`${window.location.origin}${getBasePath()}`);
            } catch (e) {
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: 'Error occurred while trying to stop impersonation session.',
                });
            }
        })
    )
    .subscribe();

userAuthenticatedAction$
    .pipe(
        map(async (auth0UserId) => {
            try {
                const userDetails = await getLoggedInUserDetailsAndInitialize();
                loggedInUserDetails$.next(userDetails);
            } catch (e) {
                if (e instanceof VerificationRequiredError) {
                    loggedInUserDetails$.next({
                        accountVerified: false, // Need to explicitly set to false, because it's before we even fetch user details
                    } as FullUserDetails);
                } else if (e instanceof Auth0Error) {
                    loadErrorPage({
                        error: e,
                        genericMessage: 'Failed to load account details',
                        skipRefreshMessageOverride: 'Try logging out and back in to resolve the issue.',
                        showDashboardLink: false,
                    });
                    throw e;
                } else {
                    loadErrorPage({
                        error: e,
                        genericMessage: 'Failed to load user details.',
                    });

                    throw e;
                }
            }
            const userUid = loggedInUserDetails$.value?.uid;
            const emailDomain = loggedInUserDetails$.value?.email?.split('@').pop();

            const launchDarklyClientId = configTopic$.value.launchDarkly?.clientId;
            if (launchDarklyClientId && auth0UserId && userUid && emailDomain) {
                try {
                    console.debug('Loading feature flags for user');
                    const flags = await loadFeatureFlagsForUser(launchDarklyClientId, {
                        auth0UserId,
                        userUid,
                        emailDomain,
                    });
                    featureFlagsTopic$.next(flags);
                } catch (error) {
                    console.error('Error occurred whilst loading feature flags', error);
                }
            }
        })
    )
    .subscribe();

askImpersonationPasswordAction$.subscribe((impersonateeUserUid) => {
    selectedImpersonateeUid$.next(impersonateeUserUid);
    askImpersonationPasswordDialogOpen$.next(true);
});

closeImpersonationPasswordDialogAction$.subscribe(() => {
    askImpersonationPasswordDialogOpen$.next(false);
    selectedImpersonateeUid$.next(undefined);
    askImpersonationPasswordDialogSaving$.next(false);
    impersonationPasswordError$.next(undefined);
});

deleteAccountAction$
    .pipe(
        map(async () => {
            userDetailsUpdating$.next(true);
            try {
                await addUserDeleteDate();
                await loadLoggedInUserDetails();
            } catch (e) {
                if (e instanceof InformativeError) {
                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message: e.message,
                    });
                } else {
                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message: 'Error occurred while trying to delete your account.',
                    });
                }
            }
            userDetailsUpdating$.next(false);
        })
    )
    .subscribe();

cancelDeleteAccountAction$
    .pipe(
        map(async () => {
            userDetailsUpdating$.next(true);
            try {
                await cancelUserDelete();
                await loadLoggedInUserDetails();
                publishLocalFeedbackEventAction$.next({
                    level: 'INFO',
                    message: 'Account deletion request has been cancelled.',
                    toastOptions: {
                        autoClose: false,
                    },
                });
            } catch (e) {
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: 'Error occurred while trying to cancel your account deletion.',
                });
            }
            userDetailsUpdating$.next(false);
        })
    )
    .subscribe();

createNewApiKeyAction$.subscribe(() => {
    askNewApiKeyNameDialogErrors$.next(undefined);
    askNewApiKeyNameDialogSaving$.next(false);
    askNewApiKeyNameDialogOpen$.next(true);
});

closeAskNewApiKeyNameAction$.subscribe(() => {
    askNewApiKeyNameDialogOpen$.next(false);
    askNewApiKeyNameDialogSaving$.next(false);
    askNewApiKeyNameDialogErrors$.next(undefined);
});

closeNewApiKeyGeneratedDialogAction$.subscribe(() => {
    generatedApiKey$.next('');
    newApiKeyGeneratedDialogOpen$.next(false);
});

generateNewApiKeyAction$
    .pipe(
        map(async (keyName) => {
            try {
                askNewApiKeyNameDialogSaving$.next(true);
                askNewApiKeyNameDialogErrors$.next(undefined);

                const { key } = await generateApiKey(keyName);

                newApiKeyGeneratedAction$.next();
                askNewApiKeyNameDialogOpen$.next(false);

                generatedApiKey$.next(key);
                newApiKeyGeneratedDialogOpen$.next(true);
            } catch (e) {
                if (e instanceof InformativeError) {
                    askNewApiKeyNameDialogErrors$.next(e.message);
                } else {
                    askNewApiKeyNameDialogErrors$.next(
                        'Error occurred while trying to generate new API key, try again later, if the error persist please contact support.'
                    );
                }
            } finally {
                askNewApiKeyNameDialogSaving$.next(false);
            }
        })
    )
    .subscribe();

deleteApiKeyAction$
    .pipe(
        map(async (uid) => {
            try {
                promptQuestion({
                    title: 'Are you sure you want to delete the API key?',
                    onProceed: async () => {
                        await deleteApiKey(uid);
                        apiKeyDeletedAction$.next(uid);
                    },
                });
            } catch (e) {
                if (e instanceof InformativeError) {
                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message: e.message,
                    });
                } else {
                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message: 'Error occurred while trying to delete API key.',
                    });
                }
            }
        })
    )
    .subscribe();
