import { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import HearingOutlinedIcon from '@mui/icons-material/HearingOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import { IconButton } from '../../../common/buttons/IconButton';
import { Alert } from '../../../common/alerts/Alert';
import { Button } from '../../../common/buttons/Button';
import { InformationChip } from '../../../common/chips/InformationChip';
import { Dropdown } from '../../../common/inputs/dropdown/Dropdown';
import { InfoIcon } from '../../../icons/InfoIcon';
import { IconCircle } from '../../../common/IconCircle';
import { ProductIcon } from '../../../icons/ProductIcon';
import {
    StyledBorderBox,
    StyledBorderBoxContent,
    StyledBorderBoxHeader,
    StyledBorderBoxSectionContainer,
    StyledBorderBoxTitleContainer,
} from '../../../common/LayoutComponents';
import { StyledMainActions } from '../../../setup-guide/SetupGuideComponents';
import { TextField } from '../../../common/inputs/TextField';
import { SetupGuideConnection } from '../../../setup-guide/types';
import { getScriptNameFromEventType, SaveEventListenerEvent } from '../../event-listeners/EventListenerDetails';
import { autoFocus } from '../../../../utils/input';
import { APP } from '@avst-stitch/repository-lib/constants';
import { generate } from 'short-uuid';

export interface OpenSetupInstructionsEvent {
    connectionUid?: string;
    eventTypeUid?: string;
    uid: string;
    urlId?: string;
}

interface EventListenerDetailsProps {
    appName: string;
    connectionRequired?: boolean;
    createdConnectionUid?: string;
    connections?: SetupGuideConnection[];
    disabled?: boolean;
    errors?: string;
    eventTypes?: { name: string; uid: string; category?: string | null }[];
    saving?: boolean;
    scripts?: { name: string; uid: string }[];
    selectedConnectionUid?: string;
    selectedEventTypeUid?: string;
    selectedScriptUid?: string;
    templateMode?: boolean;
    uid: string;
    urlId?: string;
    webhookSetup?: boolean;
    workspaceLocked?: boolean;
    existingEvent?: boolean;
    hasImplicitlySharedConnectionAttached?: boolean;
    environmentDeployed?: boolean;
    templatePreviewMode?: boolean;
    warnings?: string[];
    testPayload?: {
        requiresSetup?: boolean;
    };
    onNewConnection?: () => void;
    onCancel?: () => void;
    onOpenSetupInstructions?: (event: OpenSetupInstructionsEvent) => void;
    onSave?: (event: SaveEventListenerEvent) => void;
}

const StyledBorderBoxHeaderSmall = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    height: 54,
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1.5),
}));

const StyledBorderBoxContentGrey = styled(StyledBorderBoxContent)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
}));

const StyledStatusController = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    justifyContent: 'space-between',
    padding: theme.spacing(1.5),
}));

const StyledStatusControllerLabelContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(1.5),
    justifyContent: 'center',
    '& .MuiSvgIcon-root': {
        height: 24,
        weight: 24,
    },
}));

export const EventListenerDetails: React.FC<EventListenerDetailsProps> = ({
    appName,
    connectionRequired = false,
    connections = [],
    disabled = false,
    errors,
    eventTypes = [],
    selectedConnectionUid = '',
    selectedEventTypeUid = '',
    selectedScriptUid = '',
    saving = false,
    scripts = [],
    templateMode = false,
    uid,
    urlId,
    webhookSetup = false,
    workspaceLocked = false,
    createdConnectionUid,
    existingEvent,
    hasImplicitlySharedConnectionAttached = false,
    environmentDeployed = false,
    templatePreviewMode = false,
    warnings = [],
    testPayload,
    onNewConnection,
    onCancel,
    onOpenSetupInstructions,
    onSave,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const [scriptAttachMode, setScriptAttachMode] = useState<'new' | 'existing'>(
        templateMode || !!urlId ? 'existing' : 'new'
    );
    const [currentSelectedConnectionUid, setCurrentSelectedConnectionUid] = useState(
        createdConnectionUid ?? selectedConnectionUid
    );
    const [currentSelectedScriptUid, setCurrentSelectedScriptUid] = useState(selectedScriptUid);
    const [currentSelectedEventTypeUid, setCurrentSelectedEventTypeUid] = useState(selectedEventTypeUid);
    const [currentScriptName, setCurrentScriptName] = useState(
        templateMode || !currentSelectedEventTypeUid
            ? ''
            : getScriptNameFromEventType(appName, eventTypes, selectedEventTypeUid ?? '')
    );
    const [currentDisabled, setCurrentDisabled] = useState(disabled);
    const [disabledSummaryOpen, setDisabledSummaryOpen] = useState(false);
    const [urlPath, setUrlPath] = useState(urlId || generate());
    const [showUrlPathWarning, setShowUrlPathWarning] = useState(false);

    const isGenericApp = appName === APP.GENERIC.NAME;

    const inputRef = useRef<HTMLInputElement | null>(null);
    const selectRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        setCurrentSelectedConnectionUid(createdConnectionUid ?? selectedConnectionUid);
    }, [createdConnectionUid, selectedConnectionUid]);

    useEffect(() => {
        setCurrentSelectedScriptUid(selectedScriptUid);
    }, [selectedScriptUid]);

    useEffect(() => {
        setShowUrlPathWarning(false);
    }, []);

    useEffect(() => {
        if (scriptAttachMode === 'new') {
            if (currentSelectedEventTypeUid) {
                const newScriptName = getScriptNameFromEventType(appName, eventTypes, currentSelectedEventTypeUid);
                setCurrentScriptName(newScriptName);
            }
            setCurrentSelectedScriptUid('');
            if (currentSelectedEventTypeUid) {
                autoFocus(inputRef);
            }
        }
    }, [scriptAttachMode, currentSelectedEventTypeUid]);

    useEffect(() => {
        if (scriptAttachMode === 'existing') {
            setCurrentScriptName('');
            autoFocus(selectRef);
        }
    }, [scriptAttachMode]);

    const hasScript = scriptAttachMode === 'new' ? !!currentScriptName : !!currentSelectedScriptUid;
    const foundConnection = connections.find((con) => con.uid === currentSelectedConnectionUid);
    const isConnectionUnauthorized = !!foundConnection && !foundConnection.authorized;
    const selectedEventType = eventTypes.find((et) => et.uid === currentSelectedEventTypeUid)?.name;
    const selectedEventTypeCategory = eventTypes.find((et) => et.uid === currentSelectedEventTypeUid)?.category;

    const selectedConnection = connections.find((c) => c.uid === currentSelectedConnectionUid);
    const hasRequiredConnection = connectionRequired || templateMode ? selectedConnection?.authorized : true;
    const hasUnsavedChanges =
        currentDisabled !== disabled ||
        currentSelectedConnectionUid !== selectedConnectionUid ||
        currentSelectedEventTypeUid !== selectedEventTypeUid ||
        currentSelectedScriptUid !== selectedScriptUid ||
        !urlId ||
        urlPath !== urlId;

    const canSave =
        !!hasRequiredConnection && !!currentSelectedEventTypeUid && hasScript && hasUnsavedChanges && !workspaceLocked;

    const typographyColor = templateMode || workspaceLocked || templatePreviewMode ? 'text.disabled' : undefined;
    const showSetupInstructions =
        !!urlId && !!selectedEventTypeUid && currentSelectedEventTypeUid === selectedEventTypeUid;

    const handleOpenSetupInstructions = (): void => {
        onOpenSetupInstructions?.({
            connectionUid: currentSelectedConnectionUid,
            eventTypeUid: currentSelectedEventTypeUid,
            uid,
            urlId,
        });
    };

    const actions = (
        <>
            <Button variant="outlined" onClick={onCancel}>
                Back
            </Button>
            {!templateMode ? (
                <Button
                    data-pendo={'eventListenerSaved'}
                    busy={saving}
                    disabled={!canSave}
                    onClick={() =>
                        onSave?.({
                            connectionUid: currentSelectedConnectionUid,
                            disabled: currentDisabled,
                            eventTypeUid: currentSelectedEventTypeUid,
                            scriptName: currentScriptName,
                            scriptUid: currentSelectedScriptUid ? currentSelectedScriptUid : selectedScriptUid,
                            uid,
                            urlId: isGenericApp ? urlPath : undefined,
                        })
                    }
                >
                    Save
                </Button>
            ) : (
                <Button onClick={handleOpenSetupInstructions}>
                    {webhookSetup ? 'View webhook instructions' : 'Continue to webhook'}
                </Button>
            )}
        </>
    );

    return (
        <>
            <StyledBorderBoxContent gap={3}>
                {!templatePreviewMode && errors && <Alert severity="error" title={errors} />}
                {!templatePreviewMode &&
                    warnings.map((w) => {
                        return <Alert severity="warning" title={w} />;
                    })}
                {!templatePreviewMode && isConnectionUnauthorized && connectionRequired && (
                    <Alert severity="warning" title="Selected connector is not authorized." />
                )}
                {!templatePreviewMode &&
                    !templateMode &&
                    selectedEventTypeUid &&
                    !!selectedConnectionUid &&
                    selectedConnectionUid !== currentSelectedConnectionUid &&
                    environmentDeployed && (
                        <Alert
                            severity="warning"
                            title="Changing the connector will take effect immediately after saving"
                        />
                    )}
                {!templatePreviewMode && !templateMode && showUrlPathWarning && (
                    <Alert
                        severity="warning"
                        title="Changing the URL path to be more predicable makes it easier to be guessed. Make sure to secure your HTTP Event Listener with authentication if you don't intend it to be anonymously accessible."
                    />
                )}
                {!templatePreviewMode &&
                    !templateMode &&
                    selectedEventTypeUid &&
                    selectedEventTypeUid !== currentSelectedEventTypeUid && (
                        <>
                            <Alert
                                severity="warning"
                                title="Test event payloads need to be re-configured because the event type changed."
                            />
                            <Alert
                                severity="warning"
                                title="You might need to setup the webhook again because the event type changed."
                            />
                        </>
                    )}
                {!templatePreviewMode && !templateMode && scriptAttachMode === 'existing' && !selectedScriptUid && (
                    <Alert
                        severity="warning"
                        title="Selecting existing script might result in selecting a script that is using different
                                event type that you're listening to, which you may need to fix manually."
                    />
                )}
                {!templatePreviewMode && !templateMode && testPayload?.requiresSetup && (
                    <Alert
                        severity="info"
                        title="You haven't completed the Event Listener setup. Click on 'Save' to receive instructions how to
                                complete the setup."
                    />
                )}
                {!templatePreviewMode && !templateMode && isGenericApp && selectedEventType === 'Async HTTP Event' && (
                    <Alert
                        severity="info"
                        text={
                            <p>
                                Asynchronous HTTP Events gets processed in fire and forget manner, meaning that you
                                won't be able to send back a HTTP response, but invocations can run for much longer than
                                synchronous events. <strong>This is the recommended option.</strong>
                            </p>
                        }
                    />
                )}
                {!templatePreviewMode && !templateMode && isGenericApp && selectedEventType === 'Sync HTTP Event' && (
                    <Alert
                        severity="info"
                        text={
                            <p>
                                Synchronous HTTP Events allow you to send back a HTTP response after function invocation
                                has finished,{' '}
                                <strong>but the maximum invocation time will be capped to 25 seconds.</strong> Consider
                                using Async HTTP Event if you don't need to send back a response.
                            </p>
                        }
                    />
                )}
                {!templatePreviewMode &&
                    !templateMode &&
                    appName === APP.SLACK.NAME &&
                    selectedEventTypeCategory === 'Interactive Component' &&
                    existingEvent && (
                        <Alert
                            severity="warning"
                            closeable={true}
                            text={
                                <>
                                    Slack only allows a single interactive event webhook to be active at any given time.
                                    An Event Listener is already setup to listen to Interactive Component events. You do
                                    not need to create a new Event Listener to listen to Interactive Component events
                                    but you can proceed if you wish to do so.
                                </>
                            }
                        />
                    )}
                {!templatePreviewMode && !templateMode && hasImplicitlySharedConnectionAttached && (
                    <Alert
                        severity="info"
                        title={`This event listener is currently using implicitly shared connector owned by someone else, if you change it, you won't be able to change it back on your own.`}
                    />
                )}
                {appName !== APP.GENERIC.NAME && (
                    <StyledBorderBoxSectionContainer>
                        <StyledBorderBoxTitleContainer>
                            <Typography color={typographyColor} variant="subtitle1">
                                {templateMode ? 'Select connector' : 'Connector'}
                            </Typography>
                            <InfoIcon tooltip="Select a connector to link your event listener with a third-party service." />
                        </StyledBorderBoxTitleContainer>
                        <Dropdown
                            disabled={templateMode || templatePreviewMode || workspaceLocked}
                            fullWidth
                            items={connections.map((c) => ({
                                icon: <ProductIcon name={appName} />,
                                name: c.name,
                                value: c.uid,
                            }))}
                            label="Uses connector"
                            required={connectionRequired || templateMode || templatePreviewMode}
                            selectedItem={templatePreviewMode ? undefined : currentSelectedConnectionUid}
                            onSelectItem={(value) => setCurrentSelectedConnectionUid(value)}
                            onCreateNew={onNewConnection}
                        />
                    </StyledBorderBoxSectionContainer>
                )}
                {!templateMode && isGenericApp && (
                    <StyledBorderBoxSectionContainer>
                        <StyledBorderBoxTitleContainer>
                            <Typography color={typographyColor} variant="subtitle1">
                                URL Path
                            </Typography>
                            <InfoIcon tooltip="URL Path is a unique identifier that makes up the URL for the event listener." />
                        </StyledBorderBoxTitleContainer>
                        <TextField
                            required
                            fullWidth
                            disabled={workspaceLocked || templatePreviewMode}
                            variant="outlined"
                            inputRef={templatePreviewMode ? undefined : inputRef}
                            label="URL Path"
                            placeholder="Enter a path"
                            value={templatePreviewMode ? 'URL Path' : urlPath}
                            onChange={(e) => {
                                setUrlPath(e.target.value);
                                setShowUrlPathWarning(true);
                            }}
                        />
                    </StyledBorderBoxSectionContainer>
                )}
                <>
                    <StyledBorderBoxSectionContainer>
                        <StyledBorderBoxTitleContainer>
                            <Typography color={typographyColor} variant="subtitle1">
                                {templateMode ? 'Select listener event type' : 'Listener event type'}
                            </Typography>
                            <InfoIcon tooltip="Select the event type you want to listen to." />
                        </StyledBorderBoxTitleContainer>
                        <Dropdown
                            disabled={templateMode || templatePreviewMode || environmentDeployed || workspaceLocked}
                            fullWidth
                            groupByCategory
                            items={eventTypes.map((et) => ({
                                name: et.name,
                                value: et.uid,
                                category: et.category ?? undefined,
                            }))}
                            label="Listens event type"
                            required
                            selectedItem={currentSelectedEventTypeUid}
                            onSelectItem={(value) => setCurrentSelectedEventTypeUid(value)}
                        />
                    </StyledBorderBoxSectionContainer>
                    <StyledBorderBoxSectionContainer>
                        <StyledBorderBoxTitleContainer>
                            <Typography color={typographyColor} variant="subtitle1">
                                {templateMode ? 'Select script' : 'Script'}
                            </Typography>
                            <InfoIcon
                                tooltip={
                                    templateMode || templatePreviewMode
                                        ? 'Select a script to link with your event listener.'
                                        : 'Select if you wish to generate a new script with the correct event type for this event listener or enable it to trigger an existing script instead.'
                                }
                            />
                        </StyledBorderBoxTitleContainer>
                        {!templatePreviewMode &&
                        !templateMode &&
                        !environmentDeployed &&
                        !workspaceLocked &&
                        !selectedScriptUid ? (
                            <>
                                <StyledBorderBox>
                                    <StyledBorderBoxHeaderSmall>
                                        <FormControlLabel
                                            control={
                                                <Radio
                                                    checked={scriptAttachMode === 'new'}
                                                    onClick={() => setScriptAttachMode('new')}
                                                />
                                            }
                                            label={templateMode ? 'Create new script' : 'New script'}
                                        />
                                        <InformationChip label="Recommended" severity="success" />
                                    </StyledBorderBoxHeaderSmall>
                                    <Divider />
                                    <StyledBorderBoxContent>
                                        <TextField
                                            disabled={scriptAttachMode === 'existing'}
                                            fullWidth
                                            inputRef={inputRef}
                                            label="New script name"
                                            placeholder="Enter script name"
                                            required={scriptAttachMode === 'existing'}
                                            value={currentScriptName}
                                            onChange={(e) => setCurrentScriptName(e.target.value)}
                                        />
                                    </StyledBorderBoxContent>
                                </StyledBorderBox>
                                <StyledBorderBox>
                                    <StyledBorderBoxHeaderSmall>
                                        <FormControlLabel
                                            control={
                                                <Radio
                                                    checked={scriptAttachMode === 'existing'}
                                                    onClick={() => setScriptAttachMode('existing')}
                                                />
                                            }
                                            label={templateMode ? 'Use existing script' : 'Existing script'}
                                        />
                                    </StyledBorderBoxHeaderSmall>
                                    <Divider />
                                    <StyledBorderBoxContent>
                                        <Dropdown
                                            disabled={scriptAttachMode === 'new'}
                                            fullWidth
                                            inputRef={selectRef}
                                            items={scripts.map((s) => ({ name: s.name, value: s.uid }))}
                                            label="Select script"
                                            required={scriptAttachMode === 'new'}
                                            selectedItem={currentSelectedScriptUid}
                                            onSelectItem={(value) => setCurrentSelectedScriptUid(value)}
                                        />
                                    </StyledBorderBoxContent>
                                    {!selectedScriptUid && (
                                        <Alert
                                            severity="warning"
                                            text="If you choose to use an existing script, you may need to make some changes to it if it uses a different type of event."
                                        />
                                    )}
                                </StyledBorderBox>
                            </>
                        ) : (
                            <Dropdown
                                disabled={
                                    templateMode ||
                                    !selectedScriptUid ||
                                    templatePreviewMode ||
                                    environmentDeployed ||
                                    workspaceLocked
                                }
                                fullWidth
                                inputRef={templatePreviewMode ? undefined : selectRef}
                                items={scripts.map((s) => ({ name: s.name, value: s.uid }))}
                                label="Select script"
                                required
                                selectedItem={currentSelectedScriptUid ? currentSelectedScriptUid : selectedScriptUid}
                                onSelectItem={
                                    !selectedScriptUid ? undefined : (value) => setCurrentSelectedScriptUid(value)
                                }
                            />
                        )}
                    </StyledBorderBoxSectionContainer>
                    {!templatePreviewMode && (
                        <StyledBorderBox>
                            <StyledBorderBoxHeader>
                                <StyledBorderBoxTitleContainer>
                                    <IconCircle icon={<ReportOutlinedIcon />} severity="error" />
                                    <Typography color={typographyColor} variant="subtitle1">
                                        Disable event listener
                                    </Typography>
                                    <InfoIcon tooltip="Disable an event listener in order to stop it from processing external events." />
                                </StyledBorderBoxTitleContainer>
                                <IconButton
                                    aria-label="Open summary"
                                    icon={
                                        disabledSummaryOpen ? (
                                            <KeyboardArrowUpOutlinedIcon />
                                        ) : (
                                            <KeyboardArrowDownOutlinedIcon />
                                        )
                                    }
                                    tooltip="Open summary"
                                    onClick={() => setDisabledSummaryOpen(!disabledSummaryOpen)}
                                />
                            </StyledBorderBoxHeader>
                            {disabledSummaryOpen && (
                                <>
                                    <Divider />
                                    <StyledBorderBoxContentGrey>
                                        <StyledStatusController>
                                            <StyledStatusControllerLabelContainer>
                                                <HearingOutlinedIcon />
                                                <Box>
                                                    <Typography color={typographyColor} fontWeight="bold">
                                                        Current status
                                                    </Typography>
                                                    <Typography color="primary.main" variant="body2">
                                                        {currentDisabled ? 'Disabled' : 'Active'}
                                                    </Typography>
                                                </Box>
                                            </StyledStatusControllerLabelContainer>
                                            <Switch
                                                aria-label={
                                                    currentDisabled
                                                        ? 'Activate event listener'
                                                        : 'Disable event listener'
                                                }
                                                checked={!currentDisabled}
                                                disabled={templateMode}
                                                onChange={() => setCurrentDisabled(!currentDisabled)}
                                            />
                                        </StyledStatusController>
                                    </StyledBorderBoxContentGrey>
                                </>
                            )}
                        </StyledBorderBox>
                    )}
                    {!templateMode && showSetupInstructions && (
                        <StyledBorderBoxSectionContainer>
                            <StyledBorderBoxTitleContainer>
                                <Typography color={workspaceLocked ? undefined : typographyColor} variant="subtitle1">
                                    External webhook
                                </Typography>
                                <InfoIcon tooltip="Set up a webhook in your instance to listen to events." />
                            </StyledBorderBoxTitleContainer>
                            <StyledBorderBox>
                                <StyledBorderBoxContent alignItems="flex-start">
                                    <Button variant="outlined" onClick={handleOpenSetupInstructions}>
                                        View webhook instructions
                                    </Button>
                                </StyledBorderBoxContent>
                            </StyledBorderBox>
                        </StyledBorderBoxSectionContainer>
                    )}
                </>
            </StyledBorderBoxContent>
            {!templatePreviewMode && <Divider />}
            {!templatePreviewMode && <StyledMainActions>{actions}</StyledMainActions>}
        </>
    );
};
