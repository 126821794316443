import { styled } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import MuiTextField, { TextFieldProps } from '@mui/material/TextField';

export type CustomTextFieldProps = Omit<TextFieldProps, 'variant'> & {
    endIcon?: JSX.Element;
    label: string | null; // Omit label only explicitly, need to specify aria-label instead in this case
    placeholder: string;
    size?: 'small' | 'medium';
    startIcon?: JSX.Element;
    variant?: TextFieldProps['variant'];
};

// Get rid and add styles to theme
const StyledTextField = styled(MuiTextField)<{ fullWidth: boolean }>(({ fullWidth, theme }) => ({
    margin: 0,
    '& .MuiInputBase-root': {
        width: fullWidth ? '100%' : 'unset',
    },
    '& .MuiInputAdornment-root': {
        color: theme.palette.text.primary,
    },
    '& .MuiInputAdornment-positionEnd': {
        marginRight: theme.spacing(-1),
    },
    '& .Mui-disabled .MuiInputAdornment-root': {
        color: theme.palette.text.disabled,
    },
}));

export const TextField: React.FC<CustomTextFieldProps> = ({
    endIcon,
    fullWidth = false,
    label,
    placeholder,
    size = 'medium',
    startIcon,
    variant = 'outlined',
    ...props
}) => {
    return (
        <StyledTextField
            fullWidth={fullWidth}
            label={label}
            placeholder={placeholder}
            size={size}
            variant={variant}
            InputProps={{
                endAdornment: endIcon && <InputAdornment position="end">{endIcon}</InputAdornment>,
                startAdornment: startIcon && <InputAdornment position="start">{startIcon}</InputAdornment>,
            }}
            {...props}
        />
    );
};
