import { useObservableState } from 'observable-hooks';
import { confirmDialogDetails$, confirmDialogOpen$ } from '../../store/confirm';
import { Button } from '../../components/common/buttons/Button';
import { Dialog } from '../../components/common/Dialog';
import { IconCircle } from '../../components/common/IconCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { handleKeyDown } from '../../utils/input';

export const ConfirmDialogContainer: React.FC = () => {
    const { cancelLabel, icon, messages, proceedLabel, subtitle, title, onCancel, onClose, onProceed } =
        useObservableState(confirmDialogDetails$) ?? {};
    const open = useObservableState(confirmDialogOpen$);

    const buttons = [
        ...(onCancel
            ? [
                  <Button variant="outlined" onClick={() => void onCancel?.()}>
                      {cancelLabel ?? 'No'}
                  </Button>,
              ]
            : []),
        <Button onClick={() => void onProceed?.()}>{proceedLabel ?? 'OK'}</Button>,
    ];

    const getDisplayedIcon = (): JSX.Element | undefined => {
        switch (icon) {
            case 'success':
                return (
                    <IconCircle
                        size={subtitle ? 'medium' : 'small'}
                        icon={<CheckCircleOutlineIcon />}
                        severity="success"
                    />
                );
            case 'info':
                return <IconCircle size={subtitle ? 'medium' : 'small'} icon={<InfoOutlinedIcon />} severity="info" />;
            case 'warning':
                return (
                    <IconCircle
                        size={subtitle ? 'medium' : 'small'}
                        icon={<WarningAmberOutlinedIcon />}
                        severity="warning"
                    />
                );
            case 'error':
                return (
                    <IconCircle size={subtitle ? 'medium' : 'small'} icon={<ReportOutlinedIcon />} severity="error" />
                );
            default:
                return icon;
        }
    };

    return (
        <Dialog
            buttons={buttons}
            icon={getDisplayedIcon()}
            messages={messages}
            open={open}
            subtitle={subtitle}
            title={title ?? ''}
            onClose={onClose}
            onKeyDown={(event) =>
                handleKeyDown({
                    event,
                    enterFn: () => void onProceed?.(),
                    //Escape and Enter actions identical for "OK" dialogs.
                    escFn: () => (onClose ? void onClose() : void onProceed?.()),
                })
            }
        />
    );
};
