import { useObservableState, useSubscription } from 'observable-hooks';
import { useNavigate } from 'react-location';
import { Workspace } from '../../components/workspace-layouts/Workspace';
import { NewDeploymentDialog } from '../../components/workspace-dialogs/NewDeploymentDialog';
import { NewScriptDialog } from '../../components/workspace-dialogs/NewScriptDialog';
import {
    apiHandlerDeletedAction$,
    apiHandlerSavedAction$,
    apiHandlersBeingDeleted$,
    createApiHandlerAction$,
    deleteApiHandlerAction$,
    navigateToApiHandlerAction$,
    selectedApiHandlerDetails$,
} from '../../store/workspace/api-handler';
import { openAppSelectorAction$ } from '../../store/apps';
import {
    ignoreWorkspaceExitUnsavedChangesCheck$,
    loadingWorkspaceEnvironments$,
    loadingWorkspaceResources$,
    navigateToPlanManagementAction$,
    selectedEnvironmentUid$,
    selectedWorkspace$,
    selectedWorkspaceEnvironments$,
    selectedWorkspaceResources$,
    selectedWorkspaceSelectedResource$,
    selectedWorkspaceUid$,
    showInvocationsLimitAlert$,
    switchWorkspaceAction$,
} from '../../store/workspace';
import {
    creatingNewDeployment$,
    loadingNewDeploymentDialog$,
    newDeploymentCurrentVersion$,
    newDeploymentDialogOpen$,
    newDeploymentErrors$,
    newDeploymentInitiatedAction$,
    newDeploymentSuggestedVersion$,
    openNewDeploymentDialogAction$,
} from '../../store/workspace/deployment';
import {
    createNewScriptAction$,
    triggerScriptAction$,
    navigateToScriptAction$,
    newScriptDialogOpen$,
    newScriptErrors$,
    saveNewScriptAction$,
    savingNewScript$,
    scriptHasUnsavedChanges$,
    scriptExecutionInProgress$,
    scriptsBeingDeleted$,
    deleteScriptAction$,
    scriptDeletedAction$,
    scriptHelperPopupVisible$,
} from '../../store/workspace/script';
import { ConsoleContainer } from './ConsoleContainer';
import {
    createEventListenerAction$,
    deleteEventListenerAction$,
    eventListenerDeletedAction$,
    eventListenerSavedAction$,
    eventListenersBeingDeleted$,
    navigateToEventListenerAction$,
    triggerEventListenerAction$,
    eventListenerExecutionInProgress$,
} from '../../store/workspace/event-listener';
import {
    environmentDeploymentTargetsSavedAction$,
    navigateToDifferentWorkspaceEnvironmentAction$,
    openCreateNewWorkspaceEnvironmentDialogAction$,
    openManageEnvironmentDeploymentTargetsDialogAction$,
    switchingWorkspaceEnvironment$,
    switchWorkspaceEnvironmentAction$,
} from '../../store/workspace/environment';
import { getBasePath } from '../../utils/path';
import {
    assumeWorkspaceEditControlAction$,
    editWorkspaceAction$,
    loggedInUserWorkspaces$,
    workspaceEditControlAssumedAction$,
} from '../../store/workspaces';
import { loadWorkspaceResources, workspaceHasUnsavedChanges } from '../../store/workspace/utils';
import { publishLocalFeedbackEventAction$ } from '../../store/feedback';
import {
    publishingTemplate$,
    publishTemplateAction$,
    templatePublishedAction$,
    templateUnpublishedAction$,
    unpublishingTemplate$,
    unpublishTemplateAction$,
} from '../../store/workspace/template';
import { configTopic$ } from '../../store/config';
import { navigateToReadmeFileAction$, readmeFileHasUnsavedChanges$ } from '../../store/workspace/readme';
import { openPackageManagerAction$ } from '../../store/workspace/packages';
import { openEnvironmentManagerAction$ } from '../../store/workspace/environment-manager';
import {
    createScheduledTriggerAction$,
    creatingScriptForScheduledTrigger$,
    deleteScheduledTriggerAction$,
    navigateToScheduledTriggerAction$,
    scheduledTriggerDeletedAction$,
    scheduledTriggerSavedAction$,
    scheduledTriggersBeingDeleted$,
    selectedScheduledTriggerDetails$,
} from '../../store/workspace/scheduled-trigger';
import { useState } from 'react';
import { segmentAnalyticsTrack } from '../../data/segment-analytics';
import { openWorkspaceSharingDialogAction$ } from '../../store/workspace/sharing';
import { languageSelectorOpen$ } from '../../store/workspace/language-selector';
import { loggedInUserDetails$ } from '../../store/user';
import { promptMessage, promptQuestion } from '../../store/confirm';
import { WorkspaceLanguage } from '../../components/workspace-layouts/resource-tree/types';
import {
    environmentVariablesHaveMissingInformation$,
    environmentVariablesHaveUnsavedChanges$,
    navigateToEnvironmentVariablesAction$,
} from '../../store/workspace/environment-variable';
import { organizationSwitchedAction$ } from '../../store/organization';
import { consoleFullScreen$ } from '../../store/workspace/console';
import { openHelpAndSupportModalAction$ } from '../../store/helpAndSupport';

export const WorkspaceContainer: React.FC = () => {
    const navigate = useNavigate();

    const loadingWorkspaceResources = useObservableState(loadingWorkspaceResources$);
    const workspaces = useObservableState(loggedInUserWorkspaces$);
    const workspaceResources = useObservableState(selectedWorkspaceResources$);
    const workspaceEnvironments = useObservableState(selectedWorkspaceEnvironments$);
    const newScriptDialogOpen = useObservableState(newScriptDialogOpen$);
    const savingNewScript = useObservableState(savingNewScript$);
    const newScriptErrors = useObservableState(newScriptErrors$);
    const selectedResource = useObservableState(selectedWorkspaceSelectedResource$);
    const scriptHasUnsavedChanges = useObservableState(scriptHasUnsavedChanges$);
    const scriptExecutionInProgress = useObservableState(scriptExecutionInProgress$);
    const newDeploymentDialogOpen = useObservableState(newDeploymentDialogOpen$);
    const loadingNewDeploymentDialog = useObservableState(loadingNewDeploymentDialog$);
    const newDeploymentCurrentVersion = useObservableState(newDeploymentCurrentVersion$);
    const newDeploymentSuggestedVersion = useObservableState(newDeploymentSuggestedVersion$);
    const newDeploymentErrors = useObservableState(newDeploymentErrors$);
    const creatingNewDeployment = useObservableState(creatingNewDeployment$);
    const selectedWorkspace = useObservableState(selectedWorkspace$);
    const selectedEnvironmentUid = useObservableState(selectedEnvironmentUid$);
    const switchingEnvironment = useObservableState(switchingWorkspaceEnvironment$);
    const loadingWorkspaceEnvironments = useObservableState(loadingWorkspaceEnvironments$);
    const apiHandlersBeingDeleted = useObservableState(apiHandlersBeingDeleted$);
    const eventListenersBeingDeleted = useObservableState(eventListenersBeingDeleted$);
    const scriptsBeingDeleted = useObservableState(scriptsBeingDeleted$);
    const scheduledTriggersBeingDeleted = useObservableState(scheduledTriggersBeingDeleted$);
    const publishingTemplate = useObservableState(publishingTemplate$);
    const unpublishingTemplate = useObservableState(unpublishingTemplate$);
    const config = useObservableState(configTopic$);
    const selectedWorkspaceSelectedResource = useObservableState(selectedWorkspaceSelectedResource$);
    const eventListenerExecutionInProgress = useObservableState(eventListenerExecutionInProgress$);
    const readmeFileHasUnsavedChanges = useObservableState(readmeFileHasUnsavedChanges$);
    const scriptHelperPopupVisible = useObservableState(scriptHelperPopupVisible$);
    const creatingScriptForScheduledTrigger = useObservableState(creatingScriptForScheduledTrigger$);
    const loggedInUserDetails = useObservableState(loggedInUserDetails$);
    const environmentVariablesHaveUnsavedChanges = useObservableState(environmentVariablesHaveUnsavedChanges$);
    const environmentVariablesHaveMissingInformation = useObservableState(environmentVariablesHaveMissingInformation$);
    const showInvocationsLimitAlert = useObservableState(showInvocationsLimitAlert$);
    const consoleFullScreen = useObservableState(consoleFullScreen$);
    const [selectedWorkspaceUid, setSelectedWorkspaceUid] = useState<string | undefined>(selectedWorkspaceUid$.value);

    const navigateToSameWorkspaceRoot = (): void => {
        navigate({
            to: `${getBasePath()}workspace/${selectedWorkspaceUid$.value}/environment/${selectedEnvironmentUid$.value}`,
        });
        void loadWorkspaceResources(selectedWorkspaceUid$.value ?? '', selectedEnvironmentUid$.value ?? '');
        selectedWorkspaceSelectedResource$.next(undefined);
    };

    const handleScriptHelperPopupVisibility = (open: boolean): void => {
        scriptHelperPopupVisible$.next(open);
    };

    const refreshWorkspace = (): void => {
        // Ugly hack to refresh the page: https://github.com/TanStack/react-location/discussions/269
        // TODO: find a fix
        const workspaceUid = selectedWorkspaceUid$.value;
        const environmentUid = selectedEnvironmentUid$.value;

        navigate({ to: getBasePath() });
        setTimeout(
            () =>
                navigate({
                    to: `${getBasePath()}workspace/${workspaceUid}/environment/${environmentUid}`,
                }),
            100
        );
    };

    useSubscription(selectedWorkspaceUid$, (uid) => setSelectedWorkspaceUid(uid));
    useSubscription(switchWorkspaceAction$, ({ workspaceUid, environmentUid }) => {
        navigate({ to: `${getBasePath()}workspace/${workspaceUid}/environment/${environmentUid}` });
    });
    useSubscription(workspaceEditControlAssumedAction$, () => {
        refreshWorkspace();
    });
    useSubscription(scriptDeletedAction$, (uid) => {
        if (selectedWorkspaceSelectedResource$.value?.uid === uid) {
            navigateToSameWorkspaceRoot();
        } else {
            void loadWorkspaceResources(selectedWorkspaceUid$.value ?? '', selectedEnvironmentUid$.value ?? '');
        }
    });
    // eslint-disable-next-line sonarjs/no-identical-functions
    useSubscription(apiHandlerDeletedAction$, (uid) => {
        if (selectedWorkspaceSelectedResource$.value?.uid === uid) {
            navigateToSameWorkspaceRoot();
        } else {
            void loadWorkspaceResources(selectedWorkspaceUid$.value ?? '', selectedEnvironmentUid$.value ?? '');
        }
    });
    useSubscription(apiHandlerSavedAction$, () => {
        const uid = selectedApiHandlerDetails$.value?.uid;
        // Ugly hack to refresh the page: https://github.com/TanStack/react-location/discussions/269
        // TODO: find a fix
        navigate({ to: './' });
        setTimeout(() => navigate({ to: `./api/${uid}` }), 100);
    });
    // eslint-disable-next-line sonarjs/no-identical-functions
    useSubscription(eventListenerDeletedAction$, (uid) => {
        if (selectedWorkspaceSelectedResource?.uid === uid) {
            navigateToSameWorkspaceRoot();
        } else {
            void loadWorkspaceResources(selectedWorkspaceUid$.value ?? '', selectedEnvironmentUid$.value ?? '');
        }
    });
    // eslint-disable-next-line sonarjs/no-identical-functions
    useSubscription(scheduledTriggerDeletedAction$, (uid) => {
        if (selectedWorkspaceSelectedResource$.value?.uid === uid) {
            navigateToSameWorkspaceRoot();
        } else {
            void loadWorkspaceResources(selectedWorkspaceUid$.value ?? '', selectedEnvironmentUid$.value ?? '');
        }
    });
    useSubscription(eventListenerSavedAction$, ({ eventListenerUid, scriptUid }) => {
        if (!scriptUid) {
            // Ugly hack to refresh the page: https://github.com/TanStack/react-location/discussions/269
            // TODO: find a fix
            navigate({ to: './' });
            setTimeout(() => navigate({ to: `./event/${eventListenerUid}` }), 100);
        }
    });
    useSubscription(scheduledTriggerSavedAction$, () => {
        // Ugly hack to refresh the page: https://github.com/TanStack/react-location/discussions/269
        // TODO: find a fix
        const uid = selectedScheduledTriggerDetails$.value?.uid;
        navigate({ to: './' });
        setTimeout(() => navigate({ to: `./scheduled/${uid}` }), 100);
    });
    useSubscription(environmentDeploymentTargetsSavedAction$, () => {
        navigateToSameWorkspaceRoot();
    });
    useSubscription(navigateToScriptAction$, (uid) => {
        navigate({
            to: `${getBasePath()}workspace/${selectedWorkspaceUid$.value ?? ''}/environment/${
                selectedEnvironmentUid$.value
            }/script/${uid}`,
        });
    });
    useSubscription(navigateToApiHandlerAction$, (uid) => navigate({ to: `./api/${uid}` }));
    useSubscription(navigateToEnvironmentVariablesAction$, (overwriteHistory) =>
        navigate({ to: './parameters', replace: overwriteHistory })
    );
    useSubscription(navigateToEventListenerAction$, (uid) => navigate({ to: `./event/${uid}` }));
    useSubscription(navigateToScheduledTriggerAction$, (uid) => navigate({ to: `./scheduled/${uid}` }));
    useSubscription(navigateToDifferentWorkspaceEnvironmentAction$, (uid) => {
        selectedWorkspaceSelectedResource$.next(undefined);
        navigate({
            to: `${getBasePath()}workspace/${selectedWorkspaceUid$.value ?? ''}/environment/${uid}`,
        });
    });
    useSubscription(navigateToReadmeFileAction$, (uid) =>
        navigate({
            to: `${getBasePath()}workspace/${selectedWorkspaceUid$.value ?? ''}/environment/${
                selectedEnvironmentUid$.value
            }/readme/${uid}`,
        })
    );
    useSubscription(templatePublishedAction$, () => {
        if (selectedWorkspace$.value) {
            selectedWorkspace$.next({ ...selectedWorkspace$.value, draft: false });
        }
    });
    useSubscription(templateUnpublishedAction$, () => {
        if (selectedWorkspace$.value) {
            selectedWorkspace$.next({ ...selectedWorkspace$.value, draft: true });
        }
    });

    useSubscription(navigateToPlanManagementAction$, () => {
        navigate({
            to: `${getBasePath()}team/${selectedWorkspace?.organization?.uid}/plan`,
        });
    });

    useSubscription(organizationSwitchedAction$, (organizationUid) => {
        if (selectedWorkspace$.value?.organization?.uid !== organizationUid) {
            navigate({ to: `${getBasePath()}workspaces` });
        }
    });

    const handleApiHandlerPathCopy = (uid: string): void => {
        try {
            const apiHandler = workspaceResources.apiHandlers.find((ah) => ah.uid === uid);
            if (!apiHandler) {
                throw new Error('API Handler not found');
            }

            const appName = apiHandler.appName ?? '';

            let importName;
            switch (appName) {
                case 'Jira Service Management On-Premise':
                    importName = 'JSMOnPremise';
                    break;
                case 'Jira Service Management On-Premise Assets':
                    importName = 'JSMOnPremiseAssets';
                    break;
                default:
                    importName = `${appName[0]?.toUpperCase()}${appName.slice(1)}`;
                    break;
            }

            void navigator.clipboard.writeText(
                `import ${importName.replace(/\s|-|.com/g, '')} from "./api/${apiHandler.path}";`
            );
            publishLocalFeedbackEventAction$.next({
                level: 'SUCCESS',
                message: 'Import statement copied to clipboard (paste it into your script).',
            });
        } catch (e) {
            console.error(`Error while copying import statement for API Connection: ${uid}`, e);
            publishLocalFeedbackEventAction$.next({
                level: 'ERROR',
                message:
                    'Failed to copy API Connection import statement, please try again, if the issue persists please contact support.',
            });
        }
    };

    const handleEventListenerUrlCopy = (uid: string): void => {
        try {
            const eventListener = workspaceResources.eventListeners.find((el) => el.uid === uid);
            if (!eventListener) {
                throw new Error('Event Listener not found');
            }

            // TODO: replace BASE_URL
            void navigator.clipboard.writeText(`${config.trigger?.srcExternalUrl}/${eventListener.urlId}`);
            publishLocalFeedbackEventAction$.next({
                level: 'SUCCESS',
                message: 'Event Listener URL copied to clipboard.',
            });
        } catch (e) {
            console.error(`Error while copying Event Listener URL: ${uid}`, e);
            publishLocalFeedbackEventAction$.next({
                level: 'ERROR',
                message:
                    'Failed to copy Event Listener URL, please try again, if the issue persists please contact support.',
            });
        }
    };

    const templateActionsInProgress = publishingTemplate || unpublishingTemplate;
    const templateUid = selectedWorkspace?.templateUid;
    const isDraft = selectedWorkspace?.draft;

    const readmeFile = {
        name: workspaceResources.readmeFile?.name ?? '',
        uid: workspaceResources.readmeFile?.uid ?? '',
        unsaved: readmeFileHasUnsavedChanges[workspaceResources.readmeFile?.uid ?? ''] ?? false,
    };

    const workspaceHasNoResources = workspaceResources.scripts.length === 0 && !workspaceResources.readmeFile;

    const handleInvocationsLimitAlertClose = (): void => {
        const currentSession = sessionStorage.getItem('STITCH_SESSION_ID');
        sessionStorage.setItem('SRC_INVOCATION_LIMIT_SESSION', currentSession ?? '');
        showInvocationsLimitAlert$.next(false);
    };

    const remainingInvocations =
        workspaceResources.invocationsUsage &&
        Math.max(0, workspaceResources.invocationsUsage.limit - workspaceResources.invocationsUsage.consumed);

    return (
        <>
            <Workspace
                apiHandlersBeingDeleted={apiHandlersBeingDeleted}
                isConsoleFullScreen={consoleFullScreen}
                console={<ConsoleContainer />}
                deploymentButtonDisabled={workspaceEnvironments.length === 0 || workspaceHasNoResources}
                environments={workspaceEnvironments.map(({ uid, name, deployment }) => ({
                    uid,
                    name: `${name} (${deployment?.version ?? 'HEAD'})`,
                    deployed: !!deployment,
                    deploymentVersion: deployment?.version,
                }))}
                environmentVariablesHaveMissingInformation={environmentVariablesHaveMissingInformation}
                environmentVariablesHaveUnsavedChanges={environmentVariablesHaveUnsavedChanges}
                eventListenersBeingDeleted={eventListenersBeingDeleted}
                eventListenerExecutionInProgress={eventListenerExecutionInProgress}
                externalTriggerBaseUrl={config.trigger?.srcExternalUrl}
                isDraft={!!isDraft}
                isTemplate={!!templateUid}
                loadingEnvironments={switchingEnvironment || loadingWorkspaceEnvironments}
                loadingWorkspaceResources={loadingWorkspaceResources}
                organizationName={selectedWorkspace?.organization?.name}
                readmeFile={readmeFile}
                remainingInvocations={remainingInvocations}
                scheduledTriggersBeingDeleted={scheduledTriggersBeingDeleted}
                scriptsBeingDeleted={scriptsBeingDeleted}
                scriptExecutionInProgress={scriptExecutionInProgress}
                scriptHasUnsavedChanges={scriptHasUnsavedChanges}
                selectedEnvironmentUid={selectedEnvironmentUid}
                selectedNode={selectedResource}
                selectedWorkspaceLanguage={selectedWorkspace?.language as WorkspaceLanguage}
                selectedWorkspaceUid={selectedWorkspaceUid}
                scriptHelperPopupVisible={scriptHelperPopupVisible}
                // showAiAssistance={featureFlags.aiAssistance}
                // showAiAssistance={featureFlags.aiAssistance}
                // showEnvironmentVariables={featureFlags.environmentVariables}
                showInvocationsLimitAlert={showInvocationsLimitAlert}
                templateActionsInProgress={templateActionsInProgress}
                workspaceLockedBy={selectedWorkspace?.locked}
                workspaceResources={workspaceResources}
                workspaces={workspaces.map(({ uid, name, environments }) => ({
                    uid,
                    name,
                    environmentUid: (environments.find((e) => e.selected) ?? environments[0])?.uid ?? '',
                }))}
                onAssumeWorkspaceEditControl={() => assumeWorkspaceEditControlAction$.next(false)}
                onChangeEnvironment={(uid) => switchWorkspaceEnvironmentAction$.next(uid)}
                onCopyApiHandlerPath={handleApiHandlerPathCopy}
                onCopyEventListenerUrl={handleEventListenerUrlCopy}
                onCloseInvocationsLimitAlert={handleInvocationsLimitAlertClose}
                onCreateNewApiHandler={() =>
                    openAppSelectorAction$.next({
                        nextAction: (app) =>
                            createApiHandlerAction$.next({
                                appUid: app.uid,
                                apiHandlerTypeUid: app.connectionType.apiHandlerTypes[0]?.uid ?? '',
                            }),
                        mode: 'API_HANDLER',
                    })
                }
                onCreateNewEnvironment={() => openCreateNewWorkspaceEnvironmentDialogAction$.next()}
                onCreateNewEventListener={() =>
                    openAppSelectorAction$.next({
                        nextAction: (app) =>
                            createEventListenerAction$.next({
                                appUid: app.uid,
                                eventListenerTypeUid: app.connectionType.eventListenerTypes[0]?.uid ?? '',
                            }),
                        mode: 'EVENT_LISTENER',
                    })
                }
                onCreateNewScheduledTrigger={() => createScheduledTriggerAction$.next()}
                onCreateNewScript={() => createNewScriptAction$.next()}
                onDeleteApiHandler={(uid) => deleteApiHandlerAction$.next({ uid })}
                onDeleteEventListener={(uid) => deleteEventListenerAction$.next({ uid })}
                onDeleteScheduledTrigger={(uid) => deleteScheduledTriggerAction$.next({ uid })}
                onDeleteScript={(uid) => deleteScriptAction$.next({ uid })}
                onDeploy={() => openNewDeploymentDialogAction$.next()}
                onEditWorkspace={() => {
                    if (selectedWorkspace && selectedWorkspaceUid) {
                        editWorkspaceAction$.next({
                            uid: selectedWorkspaceUid,
                            name: selectedWorkspace.name,
                            description: selectedWorkspace?.description,
                            createdFromTemplate: selectedWorkspace?.createdFromTemplate,
                            template: selectedWorkspace?.template,
                            organizationUid: selectedWorkspace.organization?.uid ?? '',
                            useCaseUids: [],
                        });
                        segmentAnalyticsTrack('Workspace EditClicked', {
                            workspace: {
                                uid: selectedWorkspaceUid,
                                name: selectedWorkspace.name,
                                userOrigin: loggedInUserDetails?.userOrigin,
                            },
                        });
                    }
                }}
                onOpenEnvironmentManager={() => openEnvironmentManagerAction$.next()}
                onOpenLanguageSelector={() => languageSelectorOpen$.next(true)}
                onOpenManageEnvironmentDeploymentTargets={() =>
                    openManageEnvironmentDeploymentTargetsDialogAction$.next()
                }
                onOpenPackageManager={() => {
                    if (workspaceEnvironments.find((env) => env.uid === selectedEnvironmentUid)?.deployment) {
                        promptMessage({
                            title: 'Package Manager can only be used from an environment that is targeting HEAD version.',
                        });
                    } else {
                        openPackageManagerAction$.next();
                    }
                }}
                onPublishTemplate={() => publishTemplateAction$.next(templateUid ?? '')}
                onSelectApiHandler={(uid) => navigateToApiHandlerAction$.next(uid)}
                onSelectEnvironmentVariables={() => navigateToEnvironmentVariablesAction$.next(false)}
                onSelectEventListener={(uid) => navigateToEventListenerAction$.next(uid)}
                onSelectReadmeFile={(uid) => navigateToReadmeFileAction$.next(uid ?? '')}
                onSelectScheduledTrigger={(uid) => navigateToScheduledTriggerAction$.next(uid)}
                onSelectScript={(uid) => navigateToScriptAction$.next(uid)}
                onShareWorkspace={(workspaceUid) => openWorkspaceSharingDialogAction$.next(workspaceUid)}
                onSwitchWorkspace={(workspaceUid, environmentUid) => {
                    if (workspaceHasUnsavedChanges()) {
                        promptQuestion({
                            title: 'Workspace has unsaved changes, are you sure you want to switch to another workspace?',
                            messages: ['Changes will be lost if you proceed.'],
                            onProceed: () => {
                                ignoreWorkspaceExitUnsavedChangesCheck$.next(true);
                                switchWorkspaceAction$.next({
                                    workspaceUid,
                                    environmentUid,
                                });
                            },
                            onCancel: () => {
                                // Force workspace switcher dropdown to switch back to current workspace value
                                setSelectedWorkspaceUid('');
                                setTimeout(() => setSelectedWorkspaceUid(selectedWorkspaceUid$.value), 100);
                            },
                        });
                    } else {
                        switchWorkspaceAction$.next({
                            workspaceUid,
                            environmentUid,
                        });
                    }
                }}
                onToggleScriptHelperPopupVisibility={handleScriptHelperPopupVisibility}
                onTriggerEventListener={(uid) => triggerEventListenerAction$.next(uid)}
                onTriggerScript={(uid) => triggerScriptAction$.next(uid)}
                onUnpublishTemplate={() => unpublishTemplateAction$.next(templateUid ?? '')}
                onUpgradePlan={() => navigateToPlanManagementAction$.next()}
                onOpenHelpAndSupportDialog={() => openHelpAndSupportModalAction$.next()}
            />
            <NewScriptDialog
                open={newScriptDialogOpen}
                saving={savingNewScript}
                errors={newScriptErrors}
                onCreate={(name) =>
                    saveNewScriptAction$.next({
                        name: name ?? '',
                        forScheduledTrigger: creatingScriptForScheduledTrigger,
                    })
                }
                onCancel={() => {
                    newScriptDialogOpen$.next(false);
                    creatingScriptForScheduledTrigger$.next(false);
                }}
            />
            <NewDeploymentDialog
                open={newDeploymentDialogOpen}
                errors={newDeploymentErrors}
                loading={loadingNewDeploymentDialog}
                saving={creatingNewDeployment}
                currentVersion={newDeploymentCurrentVersion}
                newVersion={newDeploymentSuggestedVersion}
                environments={workspaceEnvironments.map(({ uid, name, deployment }) => ({
                    uid,
                    name: `${name} (${deployment?.version ?? 'HEAD'})`,
                    deployed: !!deployment,
                }))}
                onCancel={() => newDeploymentDialogOpen$.next(false)}
                onDeploy={(event) => newDeploymentInitiatedAction$.next(event)}
            />
        </>
    );
};
