import { LoaderFn } from 'react-location';
import { LocationGenerics } from '../../../router';
import { loadErrorPage } from '../../../store/error';
import { loadWorkspaceSetupGuideStep } from '../../../store/setup-guide';
import { selectedEnvironmentUid$ } from '../../../store/workspace';
import { loadWorkspaceEnvironments, loadWorkspaceResources } from '../../../store/workspace/utils';
import { getUnsavedScriptCached, selectScriptAction$ } from '../../../store/workspace/script';

export const setupGuideScriptStepLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const workspaceUid = routeMatch.params.workspaceUid!;

    try {
        await loadWorkspaceSetupGuideStep(workspaceUid, 'SCRIPTS');

        if (!selectedEnvironmentUid$.value) {
            await loadWorkspaceEnvironments(workspaceUid);
            await loadWorkspaceResources(workspaceUid, selectedEnvironmentUid$.value ?? '');
        }
    } catch (e) {
        loadErrorPage({
            error: e,
            genericMessage: 'Failed to load scripts step.',
        });

        throw e;
    }

    return {};
};

export const setupGuideEditScriptScreenLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const workspaceUid = routeMatch.params.workspaceUid!;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const scriptUid = routeMatch.params.scriptUid!;

    try {
        await loadWorkspaceSetupGuideStep(workspaceUid, 'SCRIPTS');

        if (!selectedEnvironmentUid$.value) {
            await loadWorkspaceEnvironments(workspaceUid);
            await loadWorkspaceResources(workspaceUid, selectedEnvironmentUid$.value ?? '');
        }

        await getUnsavedScriptCached(scriptUid, selectedEnvironmentUid$.value ?? '');
        selectScriptAction$.next({ scriptUid, environmentUid: selectedEnvironmentUid$.value ?? '' });
    } catch (e) {
        loadErrorPage({
            error: e,
            genericMessage: 'Failed to load script.',
        });

        throw e;
    }

    return {};
};
