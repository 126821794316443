import { styled } from '@mui/material';
import Accordion, { AccordionProps } from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import CircularProgress from '@mui/material/CircularProgress';
import ListItemButton from '@mui/material/ListItemButton';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { resourceTreeActionButtonIconWrapperSize, resourceTreeIconSize, resourceTreeIconWrapperSize } from '.';

export const StyledAccordion = styled((props: AccordionProps) => (
    <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    '&:before': {
        display: 'none',
    },
    '& .MuiTypography-root': {
        ...theme.typography.overflowLine,
    },
}));

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    flexDirection: 'row-reverse',
    height: 40,
    minHeight: 0,
    padding: theme.spacing(0, 0.5, 0, 1),
    position: 'relative',
    '& .MuiAccordionSummary-content': {
        ...theme.typography.flexAlignCenter,
        justifyContent: 'space-between',
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
        height: resourceTreeActionButtonIconWrapperSize,
        zIndex: 20,
        '& .MuiSvgIcon-root': {
            color: theme.palette.text.primary,
            height: resourceTreeIconSize,
            width: resourceTreeIconSize,
        },
        '& .MuiButtonBase-root': {
            height: resourceTreeActionButtonIconWrapperSize,
            width: resourceTreeActionButtonIconWrapperSize,
        },
    },
}));

export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    padding: theme.spacing(0),
    '&.folder:after': {
        borderLeft: `2px solid ${theme.palette.action.selected}`,
        content: '""',
        height: `calc(100% - 34px)`,
        left: 23,
        position: 'absolute',
        top: 26,
        width: 1,
        zIndex: 10,
    },
    '&.folder': {
        paddingLeft: theme.spacing(4),
    },
}));

export const StyledResourceListItemButton = styled(ListItemButton)<{
    selected?: boolean;
}>(({ selected, theme }) => ({
    backgroundColor: selected ? theme.palette.action.selected : undefined,
    border: selected ? theme.constants.borderSelected : undefined,
    display: 'flex',
    cursor: 'pointer',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0.5, 1, 1.5),
    position: 'relative',
    width: '100%',
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
    '& .MuiTypography-root': {
        color: selected ? theme.palette.primary.main : undefined,
    },
}));

export const StyledResourceInfo = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    zIndex: 20,
}));

export const StyledResourceIcon = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    height: resourceTreeIconWrapperSize,
    width: resourceTreeIconWrapperSize,
    minWidth: resourceTreeIconWrapperSize,
    justifyContent: 'center',
    marginRight: theme.spacing(1),
    position: 'relative',
    '& .MuiSvgIcon-root, img': {
        left: '50%',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        height: resourceTreeIconWrapperSize,
        width: resourceTreeIconWrapperSize,
    },
    '& img': {
        padding: 1,
    },
}));

export const StyledResourceTitle = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
}));

export const StyledResourceSubtitle = styled('div')<{ selected?: boolean }>(({ selected, theme }) => ({
    ...theme.typography.flexAlignCenter,
    '&.main': {
        marginLeft: theme.spacing(4),
        '& .MuiTypography-root': {
            color: theme.palette.primary.main,
            '&:first-of-type': {
                color: selected ? theme.palette.primary.main : theme.palette.text.primary,
                overflow: 'visible',
            },
        },
        '& .MuiSvgIcon-root': {
            color: selected ? theme.palette.primary.main : theme.palette.text.primary,
        },
    },
    '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(1),
    },
    '&.warning': {
        marginLeft: theme.spacing(4),
        '& .MuiTypography-root': {
            color: theme.palette.warning.main,
            fontStyle: 'italic',
        },
        '& .MuiSvgIcon-root': {
            color: theme.palette.warning.light,
        },
    },
    '&.info': {
        marginLeft: theme.spacing(4),
        '& .MuiTypography-root': {
            color: theme.palette.text.secondary,
            fontStyle: 'italic',
        },
    },
    '&.empty': {
        marginLeft: theme.spacing(1.5),
        paddingBottom: theme.spacing(1),
        '& .MuiTypography-root, .MuiSvgIcon-root': {
            color: theme.palette.text.secondary,
        },
    },
}));

export const StyledResourceActionButtons = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    marginLeft: theme.spacing(1),
    gap: theme.spacing(1),
}));

export const StyledResourceActionButton = styled(IconButton)(() => ({
    height: resourceTreeActionButtonIconWrapperSize,
    width: resourceTreeActionButtonIconWrapperSize,
    '&.busy': {
        '& .MuiSvgIcon-root': {
            opacity: 0,
        },
    },
}));

const StyledLoadingSpinner = styled(CircularProgress)(() => ({
    height: `calc(${resourceTreeIconSize}px + 2px) !important`,
    position: 'absolute',
    width: `calc(${resourceTreeIconSize}px + 2px) !important`,
}));

interface ResourceActionButtonProps {
    busy?: boolean;
    icon: JSX.Element;
    tooltipTitle: string;
    uid: string;
    onClick?(uid: string): void;
}

export const ResourceActionButton: React.FC<ResourceActionButtonProps> = ({
    busy = false,
    icon,
    tooltipTitle,
    uid,
    onClick,
}) => {
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.stopPropagation();
        onClick?.(uid);
    };

    return (
        <Tooltip title={tooltipTitle} placement="top">
            <StyledResourceActionButton className={busy ? 'busy' : ''} disabled={busy} onClick={handleClick}>
                {icon}
                {busy && <StyledLoadingSpinner aria-label="loading spinner" />}
            </StyledResourceActionButton>
        </Tooltip>
    );
};
