import { LoaderFn } from 'react-location';
import { LocationGenerics } from '../../../router';
import { loadErrorPage } from '../../../store/error';
import { loadWorkspaceSetupGuideStep } from '../../../store/setup-guide';
import { selectedEnvironmentUid$ } from '../../../store/workspace';
import { loadWorkspaceEnvironments, loadWorkspaceResources } from '../../../store/workspace/utils';
import { loadLoggedInUserConnections } from '../../../store/connection/utils';
import { loggedInUserConnections$ } from '../../../store/connections';
import { loadApps } from '../../../store/apps';

export const setupGuideConnectionStepLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const workspaceUid = routeMatch.params.workspaceUid!;

    try {
        await Promise.all([loadWorkspaceSetupGuideStep(workspaceUid, 'CONNECTIONS'), loadApps()]);

        if (!loggedInUserConnections$.value.length) {
            await loadLoggedInUserConnections();
        }

        if (!selectedEnvironmentUid$.value) {
            await loadWorkspaceEnvironments(workspaceUid);
            await loadWorkspaceResources(workspaceUid, selectedEnvironmentUid$.value ?? '');
        }
    } catch (e) {
        loadErrorPage({
            error: e,
            genericMessage: 'Failed to load connectors step.',
        });

        throw e;
    }

    return {};
};
