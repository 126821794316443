import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LinkIcon from '@mui/icons-material/Link';
import WarningOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { ProductIcon } from '../../icons/ProductIcon';
import {
    ResourceActionButton,
    StyledResourceListItemButton,
    StyledResourceActionButtons,
    StyledResourceIcon,
    StyledResourceInfo,
    StyledResourceSubtitle,
    StyledResourceTitle,
} from './ResourceTreeComponents';

import { InfoIcon } from '../../icons/InfoIcon';

interface ResourceTreeApiHandlerProps {
    appName?: string;
    connectionName?: string;
    deleting?: boolean;
    deployedEnvironmentMode?: boolean;
    existingRecommendedApiVersion?: boolean;
    needsSetup?: boolean;
    path?: string;
    readOnlyMode?: boolean;
    selectedApiHandlerLibrary?: {
        uid: string;
        label?: string | null;
        deprecated: boolean;
        recommended: boolean;
    } | null;
    selectedNode?: string;
    uid: string;
    onDelete(uid: string, fullPath?: string): void;
    onPathCopy?(uid: string): void;
    onOpen(uid: string): void;
}

export const ResourceTreeApiHandler: React.FC<ResourceTreeApiHandlerProps> = ({
    appName,
    connectionName,
    deleting = false,
    deployedEnvironmentMode = false,
    needsSetup = false,
    path,
    readOnlyMode = false,
    existingRecommendedApiVersion = false,
    selectedApiHandlerLibrary,
    selectedNode,
    uid,
    onDelete,
    onOpen,
    onPathCopy,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const selected = selectedNode === uid;
    const showSetupWarning = needsSetup && !readOnlyMode;
    const canCopyPath = !deployedEnvironmentMode && !!path && !readOnlyMode;
    const canDelete = !deployedEnvironmentMode && !readOnlyMode;

    return (
        <StyledResourceListItemButton key={uid} onClick={() => onOpen(uid)} selected={selected}>
            <StyledResourceInfo>
                <StyledResourceTitle>
                    <Tooltip title={appName} placement="top">
                        <StyledResourceIcon role="img">
                            <ProductIcon name={appName} />
                        </StyledResourceIcon>
                    </Tooltip>
                    <Typography>{readOnlyMode ? appName : connectionName ?? 'New API connection'}</Typography>
                </StyledResourceTitle>
                {path && (
                    <Tooltip title={`./api/${path}`} placement="top-start">
                        <StyledResourceSubtitle selected={selected} className="main" role="note">
                            <LinkIcon />
                            <Typography pr={1}>API path:</Typography>
                            <Typography>{path}</Typography>
                        </StyledResourceSubtitle>
                    </Tooltip>
                )}
                {showSetupWarning && (
                    <StyledResourceSubtitle className="warning">
                        <WarningOutlinedIcon />
                        <Typography>Complete API connection setup</Typography>
                    </StyledResourceSubtitle>
                )}
                {!showSetupWarning && !readOnlyMode && selectedApiHandlerLibrary?.deprecated && (
                    <StyledResourceSubtitle className="warning">
                        <WarningOutlinedIcon />
                        <Typography>Using deprecated Vendor API</Typography>
                    </StyledResourceSubtitle>
                )}
                {!showSetupWarning &&
                    !readOnlyMode &&
                    existingRecommendedApiVersion &&
                    selectedApiHandlerLibrary &&
                    selectedApiHandlerLibrary.label &&
                    !selectedApiHandlerLibrary.recommended &&
                    !selectedApiHandlerLibrary.deprecated && (
                        <StyledResourceSubtitle className="info">
                            <InfoIcon />
                            <Typography>Recommended Vendor API available</Typography>
                        </StyledResourceSubtitle>
                    )}
            </StyledResourceInfo>
            <StyledResourceActionButtons>
                {canCopyPath && (
                    <ResourceActionButton
                        icon={<ContentCopyIcon fontSize="small" color="primary" />}
                        tooltipTitle="Copy import path statement"
                        uid={uid}
                        onClick={() => onPathCopy?.(uid)}
                    />
                )}
                {canDelete && (
                    <ResourceActionButton
                        busy={deleting}
                        icon={<DeleteOutlineIcon fontSize="small" color="primary" />}
                        tooltipTitle="Delete"
                        uid={uid}
                        onClick={onDelete}
                    />
                )}
            </StyledResourceActionButtons>
        </StyledResourceListItemButton>
    );
};
