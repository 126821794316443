import { useState } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import {
    StyledListItemButton,
    StyledMenu,
    StyledMenuItem,
    StyledMenuItemContent,
    StyledMenuItemText,
} from './SidenavComponents';
import { ThemeMode } from '../../../theme';

interface SidenavThemeModeSelectorProps {
    collapsed: boolean;
    selectedThemeMode: ThemeMode;
    onSelectThemeMode(mode: ThemeMode): void;
}

export const SidenavThemeModeSelector: React.FC<SidenavThemeModeSelectorProps> = ({
    collapsed,
    selectedThemeMode,
    onSelectThemeMode,
}) => {
    const [anchor, setAnchor] = useState<HTMLDivElement | null>(null);

    const displayedThemeModeIcon =
        selectedThemeMode === 'light' ? (
            <LightModeOutlinedIcon />
        ) : selectedThemeMode === 'dark' ? (
            <DarkModeOutlinedIcon />
        ) : (
            <DesktopWindowsOutlinedIcon />
        );

    const menuItems = [
        { icon: <LightModeOutlinedIcon />, label: 'Light mode', value: 'light' as const },
        { icon: <DarkModeOutlinedIcon />, label: 'Dark mode', value: 'dark' as const },
        { icon: <DesktopWindowsOutlinedIcon />, label: 'System default', value: 'system' as const },
    ].map((m) => (
        <StyledMenuItem
            key={m.value}
            selected={selectedThemeMode === m.value}
            onClick={() => {
                onSelectThemeMode(m.value);
                setAnchor(null);
            }}
        >
            <StyledMenuItemContent>
                {m.icon}
                <StyledMenuItemText>{m.label}</StyledMenuItemText>
            </StyledMenuItemContent>
            {selectedThemeMode === m.value && <CheckIcon />}
        </StyledMenuItem>
    ));

    return (
        <>
            <StyledListItemButton
                className={collapsed ? 'collapsed' : undefined}
                selected={!!anchor}
                onClick={(event) => {
                    event.stopPropagation();
                    setAnchor(anchor ? null : event.currentTarget);
                }}
                aria-label="Select theme mode"
            >
                <Tooltip title="App theme">{displayedThemeModeIcon}</Tooltip>
                <Typography>App theme</Typography>
            </StyledListItemButton>
            <ClickAwayListener
                onClickAway={() => {
                    setAnchor(null);
                }}
            >
                <StyledMenu
                    open={!!anchor}
                    anchorEl={anchor}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={() => setAnchor(null)}
                >
                    {menuItems}
                </StyledMenu>
            </ClickAwayListener>
        </>
    );
};
