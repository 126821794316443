import { LoaderFn } from 'react-location';
import { getMessageForArgs } from '@avst-stitch/invocation-lib/runtime';
import { getInvocationDataUrl, InvocationLog } from '../../data/invocationLogs';
import { LocationGenerics } from '../../router';
import { selectedInvocationLogId$, selectedInvocationLogContent$ } from '../../store/invocationlogs';
import { loadErrorPage } from '../../store/error';
import { InformativeError } from '../../utils/error';

export const largeInvocationLogPayloadLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    selectedInvocationLogId$.next(undefined);
    selectedInvocationLogContent$.next(undefined);

    try {
        const invocationId = routeMatch.params.invocationId;
        const workspaceUid = routeMatch.params.workspaceUid;
        const logId = routeMatch.params.logId;

        if (invocationId && workspaceUid && logId) {
            selectedInvocationLogId$.next(logId);
            const invocationLogUrl = await getInvocationDataUrl({
                workspaceUid,
                invocationId,
                logId,
                dataType: 'largePayload',
            });
            const response = await fetch(invocationLogUrl);

            if (!response.ok) {
                throw new InformativeError('Failed to fetch invocation log');
            }

            const invocationLogStringified = await response.text();
            const invocationLog = JSON.parse(invocationLogStringified) as InvocationLog;
            const content = getMessageForArgs(invocationLog.args);

            selectedInvocationLogContent$.next(content);
        }
    } catch (e) {
        loadErrorPage({
            error: e,
            genericMessage: 'Failed to load invocation logs.',
        });

        throw e;
    }

    return {};
};
