import { sendMessageToAIAssistance as sendMessageToAIAssistanceRpc } from '@avst-stitch/repository-lib/lib';
import { saveAIAssistanceResponse as saveAIAssistanceResponseRpc } from '@avst-stitch/repository-lib/lib';
import { updateAiAssistanceOnboardingCompletion as updateAiAssistanceOnboardingCompletionRpc } from '@avst-stitch/repository-lib/lib';
import { rateAiAssistanceResponse as rateAiAssistanceResponseRpc } from '@avst-stitch/repository-lib/lib';
import { Request as sendMessageToAIRequest } from '@avst-stitch/repository-lib/lib/rpcs/sendMessageToAIAssistance';
import { Request as SaveAIResponseRequest } from '@avst-stitch/repository-lib/lib/rpcs/saveAIAssistanceResponse';
import { Request as RateMessageRequest } from '@avst-stitch/repository-lib/lib/rpcs/rateAiAssistanceResponse';
import { repositoryInvoker } from '../utils/repository';
export type { RateMessageRequest };
export const sendMessageToAIAssistance = async (request: sendMessageToAIRequest): Promise<void> => {
    return await sendMessageToAIAssistanceRpc(repositoryInvoker, request);
};

export const saveAIAssistanceResponse = async (request: SaveAIResponseRequest): Promise<void> => {
    return await saveAIAssistanceResponseRpc(repositoryInvoker, request);
};

export const updateAiAssistanceOnboardingCompletion = async (request: { completed: boolean }): Promise<void> => {
    return await updateAiAssistanceOnboardingCompletionRpc(repositoryInvoker, request);
};

export const rateAiAssistanceResponse = async (request: RateMessageRequest): Promise<void> => {
    return await rateAiAssistanceResponseRpc(repositoryInvoker, request);
};
