import { styled } from '@mui/material';
import { PropsWithChildren, useEffect } from 'react';
import { ReflexContainer, ReflexElement } from 'react-reflex';

interface Dimensions {
    height: number;
    width: number;
}

export const SizeAwareContainer: React.FC<
    PropsWithChildren<{ dimensions?: Dimensions; onDimensionsChange: (width: number) => void }>
> = ({ children, dimensions, onDimensionsChange }) => {
    useEffect(() => {
        onDimensionsChange(dimensions?.width || 1000);
    }, [dimensions]);
    return <StyledSizeAwareContainer>{children}</StyledSizeAwareContainer>;
};

export const StyledReflexContainer = styled(ReflexContainer)(({ theme }) => ({
    '&.vertical': {
        '&>.reflex-splitter ': {
            width: theme.spacing(1.5) + ' !important',
            '& .MuiSvgIcon-root': {
                left: theme.spacing(-0.5),
                top: '50%',
                transform: 'translateY(-50%)',
            },
        },
    },
    '&.horizontal': {
        '&>.reflex-splitter ': {
            height: theme.spacing(1.5) + ' !important',
            '& .MuiSvgIcon-root': {
                left: '50%',
                top: theme.spacing(-0.5),
                transform: 'translateX(-50%) rotate(90deg)',
            },
        },
    },
    // Keep the reflex-splitter styling here, otherwise the functionality breaks
    '& .reflex-splitter': {
        backgroundColor: theme.palette.background.default,
        border: 'none !important',
        position: 'relative',
        zIndex: 0,
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
        '&.active': {
            backgroundColor: theme.palette.action.selected,
        },
        '& .MuiSvgIcon-root': {
            color: theme.palette.text.secondary,
            height: 20,
            position: 'absolute',
            width: 20,
        },
    },
}));

//Keeping the empty styled element in order to customise it with the sx prop
export const StyledReflexElement = styled(ReflexElement)(() => ({}));

export const StyledReflexFooter = styled(ReflexElement)(() => ({
    minHeight: 60,
}));

const StyledSizeAwareContainer = styled('div')(({ theme }) => ({
    height: '100%',
    overflow: 'auto',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    background: theme.palette.background.default,
}));
