import { useObservableState } from 'observable-hooks';
import {
    readmeFileBeingSaved$,
    readmeFileHasUnsavedChanges$,
    revertReadmeFileChangesAction$,
    saveReadmeFileAction$,
    selectedReadmeFileContentChangedAction$,
    selectedReadmeFileUid$,
    unsavedReadmeFileDetails$,
} from '../../../../store/workspace/readme';
import { selectedReadOnlyTemplate$ } from '../../../../store/templates';
import {
    selectedEnvironmentUid$,
    selectedWorkspace$,
    selectedWorkspaceEnvironment$,
} from '../../../../store/workspace';
import { SetupGuideReadme } from '../../../../components/setup-guide/readme/SetupGuideReadme';
import { getBasePath } from '../../../../utils/path';

export const ReadmeFileDetailsContainer: React.FC = () => {
    const selectedReadmeFileUid = useObservableState(selectedReadmeFileUid$);
    const readmeFileBeingSaved = useObservableState(readmeFileBeingSaved$);
    const readmeFileHasUnsavedChanges = useObservableState(readmeFileHasUnsavedChanges$);
    const selectedReadOnlyTemplate = useObservableState(selectedReadOnlyTemplate$);
    const selectedWorkspaceEnvironment = useObservableState(selectedWorkspaceEnvironment$);
    const selectedWorkspace = useObservableState(selectedWorkspace$);
    const environmentUid = useObservableState(selectedEnvironmentUid$);

    const readOnlyMode = !!selectedReadOnlyTemplate?.templateUid;
    const environmentDeployed = selectedWorkspaceEnvironment?.deployment !== undefined;

    const readmeKey = `${selectedReadmeFileUid ?? ''}_${environmentUid ?? ''}`;

    return (
        <SetupGuideReadme
            content={unsavedReadmeFileDetails$.value[readmeKey]?.content ?? ''}
            fullScreenReadmeLink={`${getBasePath()}fullscreen-readme/${selectedReadmeFileUid}/workspace/${
                selectedWorkspace?.uid
            }/environment/${environmentUid}`}
            hasUnsavedChanges={!!readmeFileHasUnsavedChanges[selectedReadmeFileUid ?? '']}
            templateMode={false}
            saving={readmeFileBeingSaved}
            templatePreviewMode={readOnlyMode || environmentDeployed}
            workspaceLocked={!!selectedWorkspace?.locked}
            onCancel={() => revertReadmeFileChangesAction$.next()}
            onChangeContent={(content) => selectedReadmeFileContentChangedAction$.next(content)}
            onSave={() => saveReadmeFileAction$.next()}
        />
    );
};
