import { WorkspaceSetupGuideStep } from '@avst-stitch/repository-lib/lib/types';
import { SetupGuideAppInstance, SetupGuideStep, SetupGuideStepName } from '../components/setup-guide/types';
import { ApiHandler, EventListener } from '@avst-stitch/repository-lib/lib/rpcs/getWorkspaceResources';
import { UserConnections } from '../data/connection';
import { APP } from '@avst-stitch/repository-lib/constants';
import { workspaceSetupGuideConnectionsHaveUnsavedChanges$ } from '../store/setup-guide';

const isStepRequired = (step: WorkspaceSetupGuideStep, optionalSteps: SetupGuideStepName[]): boolean =>
    !optionalSteps.includes(step.name as SetupGuideStepName);

const isStepCompleted = (step: WorkspaceSetupGuideStep, completedSteps: WorkspaceSetupGuideStep[]): boolean => {
    return !!completedSteps.find((cs) => cs.name === step.name);
};

const getNextStep = (
    steps: WorkspaceSetupGuideStep[],
    completedSteps: WorkspaceSetupGuideStep[]
): WorkspaceSetupGuideStep | undefined => {
    if (completedSteps.length >= steps.length) {
        return;
    }

    if (completedSteps.length === 0) {
        return steps[0];
    }

    const lastCompletedStep = completedSteps.reduce((maxStep, currentStep) =>
        currentStep.index > maxStep.index ? currentStep : maxStep
    );

    return steps.find((s) => s.index === lastCompletedStep.index + 1);
};

export const isStepDisabled = (
    step: WorkspaceSetupGuideStep,
    steps: WorkspaceSetupGuideStep[],
    completedSteps: WorkspaceSetupGuideStep[],
    optionalSteps: SetupGuideStepName[],
    nextStep?: WorkspaceSetupGuideStep
): boolean => {
    const isCompleted = isStepCompleted(step, completedSteps);
    const isNext = step.uid === nextStep?.uid;

    if (!nextStep || isNext || isCompleted) {
        return false;
    }

    const precedingSteps = steps.filter((s) => s.index < step.index);

    const stepAllowed = precedingSteps.every(
        (s) => isStepCompleted(s, completedSteps) || optionalSteps.includes(s.name as SetupGuideStepName)
    );

    return !stepAllowed;
};

export const getRemappedSetupGuideSteps = (
    steps: WorkspaceSetupGuideStep[],
    completedSteps: WorkspaceSetupGuideStep[],
    optionalSteps: SetupGuideStepName[]
): SetupGuideStep[] => {
    const nextStep = getNextStep(steps, completedSteps);

    return steps.map((s) => ({
        completed: isStepCompleted(s, completedSteps),
        description: s.description,
        disabled: isStepDisabled(s, steps, completedSteps, optionalSteps, nextStep),
        name: s.name as SetupGuideStepName,
        next: s.uid === nextStep?.uid,
        number: s.index + 1,
        required: isStepRequired(s, optionalSteps),
        title: s.title,
        uid: s.uid,
    }));
};

export const getAppInstances = (
    apiHandlers: ApiHandler[],
    eventListeners: EventListener[],
    connections: UserConnections,
    selectedConnections: Record<string, string | undefined>
): SetupGuideAppInstance[] => {
    const keySet = new Set();

    const nonGenericEventListeners = eventListeners.filter((el) => el.appName !== APP.GENERIC.NAME);

    let hasUnsavedSelectedConnections = false;

    const appInstances = [...apiHandlers, ...nonGenericEventListeners]
        .filter((item) => {
            const key = `${item.appName}:${item.group}`;
            if (keySet.has(key)) {
                return false;
            } else {
                keySet.add(key);
                return true;
            }
        })
        .map((ai) => {
            const key = ai.appName + (ai.group ? ` ${ai.group}` : '');
            const selectedConnectionUid = selectedConnections?.[key];

            if (ai.connectionUid !== selectedConnectionUid) {
                hasUnsavedSelectedConnections = true;
            }

            return {
                appName: ai.appName,
                connections: connections
                    .filter((c) => c.connectionType.name === ai.appName)
                    .map((c) => ({
                        authorized: c.authorized,
                        name: c.name,
                        uid: c.uid,
                    })),
                group: ai.group,
                selectedConnectionUid,
            };
        });

    workspaceSetupGuideConnectionsHaveUnsavedChanges$.next(hasUnsavedSelectedConnections);

    return appInstances;
};

export const getAppInstanceSelectedConnections = (
    apiHandlers: ApiHandler[],
    eventListeners: EventListener[]
): Record<string, string | undefined> => {
    return [...apiHandlers, ...eventListeners].reduce<Record<string, string | undefined>>((acc, c) => {
        if (c.appName === 'Generic' && 'eventType' in c && !c.connectionUid) {
            return acc;
        }
        const key = c.appName + (c.group ? ` ${c.group}` : '');
        acc[key] = c.connectionUid;
        return acc;
    }, {});
};

export const getSetupGuideStepPath = (stepName: SetupGuideStepName): string => {
    switch (stepName) {
        case 'CONNECTIONS':
            return 'connectors';
        case 'API_HANDLERS':
            return 'api';
        case 'TRIGGERS':
            return 'triggers';
        case 'ENVIRONMENT_VARIABLES':
            return 'parameters';
        case 'SCRIPTS':
            return 'scripts';
        case 'REVIEW':
            return 'review';
        case 'RUN':
            return 'run';
        default:
            return '';
    }
};

export const getPreviousEnabledStep = (
    steps: SetupGuideStep[],
    selectedStep?: SetupGuideStep
): SetupGuideStep | undefined => {
    return selectedStep && [...steps].reverse().find((s) => s.number < selectedStep.number && !s.disabled);
};
