import { BehaviorSubject, map, Subject } from 'rxjs';
import { getFetchOptions } from '../../../utils/fetch';
import { InformativeError } from '../../../utils/error';
import { configTopic$, stitchSession$ } from '../../config';
import { publishLocalFeedbackEventAction$ } from '../../feedback';
import { monitor } from '../../monitor';
import { SESSION_ID } from '../../..';

export const microsoftEventListenerSetupDialogOpenAction$ = monitor(
    'microsoftEventListenerSetupDialogOpenAction$',
    new Subject<MicrosoftEventListenerSetupBaseDetails>()
);
export const microsoftEventListenerSetupDialogCloseAction$ = monitor(
    'microsoftEventListenerSetupDialogCloseAction$',
    new Subject<void>()
);
export const microsoftEventListenerSetupDialogSaveTokenAction$ = monitor(
    'microsoftEventListenerSetupDialogSaveTokenAction$',
    new Subject<MicrosoftEventListenerSaveTokenDetails>()
);

export const microsoftEventListenerSetupDialogSaving$ = monitor(
    'microsoftEventListenerSetupDialogSaving$',
    new BehaviorSubject(false)
);

export const microsoftEventListenerSetupDialogLoading$ = monitor(
    'microsoftEventListenerSetupDialogLoading$',
    new BehaviorSubject(false)
);
export const microsoftEventListenerSetupDialogOpen$ = monitor(
    'microsoftEventListenerSetupDialogOpen$',
    new BehaviorSubject(false)
);
export const microsoftEventListenerSetupDialogDetails$ = monitor(
    'microsoftEventListenerSetupDialogDetails$',
    new BehaviorSubject<MicrosoftEventListenerSetupBaseDetails | undefined>(undefined)
);
export const microsoftEventListenerSetupDialogErrors$ = monitor(
    'microsoftEventListenerSetupDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

export type MicrosoftEventListenerSetupBaseDetails = {
    uid: string;
    environmentUid: string;
    webhookUrl: string;
    token: string;
};

interface MicrosoftEventListenerSaveTokenDetails {
    uid: string;
    environmentUid: string;
    token: string;
}

microsoftEventListenerSetupDialogOpenAction$
    .pipe(
        map(async (details) => {
            microsoftEventListenerSetupDialogLoading$.next(true);
            microsoftEventListenerSetupDialogOpen$.next(true);

            try {
                microsoftEventListenerSetupDialogDetails$.next(details);
                microsoftEventListenerSetupDialogLoading$.next(false);
            } catch (e) {
                console.error('Error while loading Event Listener setup instructions', e);
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: `Failed to load Event Listener setup instructions, please try again, if the issue persists please contact support.`,
                    toastOptions: {
                        autoClose: false,
                    },
                });

                microsoftEventListenerSetupDialogCloseAction$.next();
            }
        })
    )
    .subscribe();

microsoftEventListenerSetupDialogSaveTokenAction$
    .pipe(
        map(async (details) => {
            microsoftEventListenerSetupDialogSaving$.next(true);
            try {
                const baseUrl = configTopic$.value.connection?.microsoft?.baseUrl;
                if (!baseUrl) {
                    throw new Error('No Microsoft Connector URL configured in meta');
                }
                const saveTokenUrl = `${baseUrl}/save-token`;

                const fetchOptions = getFetchOptions(
                    { Authorization: stitchSession$.value?.jwt ?? '', 'x-stitch-session-id': SESSION_ID },
                    details
                );
                await fetch(saveTokenUrl, fetchOptions);
                microsoftEventListenerSetupDialogCloseAction$.next();
            } catch (e) {
                if (e instanceof InformativeError) {
                    microsoftEventListenerSetupDialogErrors$.next(e.message);
                } else {
                    console.error('Error while saving Event Listener token', e);
                    microsoftEventListenerSetupDialogErrors$.next(
                        'Failed to save Microsoft Teams Outgoing Webhook event token. Please try again, if the issue persists please contact support.'
                    );
                }
            }
            microsoftEventListenerSetupDialogSaving$.next(false);
        })
    )
    .subscribe();

microsoftEventListenerSetupDialogCloseAction$.subscribe(() => {
    microsoftEventListenerSetupDialogOpen$.next(false);
    microsoftEventListenerSetupDialogSaving$.next(false);
    microsoftEventListenerSetupDialogDetails$.next(undefined);
});
