// Needs deprecation
import { useRef } from 'react';
import { styled } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { Copy } from '../../common/Copy';

const StyledTextField = styled(TextField)(() => ({
    '& .MuiInputBase-root': {
        width: '100%',
    },
}));

export const ReadOnlyTextField: React.FC<TextFieldProps> = (props) => {
    const input = useRef<HTMLInputElement>();

    return (
        <StyledTextField
            {...props}
            inputRef={input}
            fullWidth
            margin="normal"
            variant="standard"
            InputProps={{
                disableUnderline: true,
                readOnly: true,
                endAdornment: (
                    <InputAdornment position="end">
                        <Copy label="Copy" value={`${props.value}`} />
                    </InputAdornment>
                ),
            }}
        />
    );
};
