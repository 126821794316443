import { BehaviorSubject, map, Subject } from 'rxjs';
import {
    connectionCreatedAction$,
    ConnectionDetails,
    connectionCreatedFirstTimeAction$,
    connectionCreatedFirstTime$,
} from '..';
import { createConnection, saveConnection } from '../../../data/connection';
import { InformativeError } from '../../../utils/error';
import { configTopic$ } from '../../config';
import { publishLocalFeedbackEventAction$ } from '../../feedback';
import { monitor } from '../../monitor';
import {
    initiate,
    loadLoggedInUserConnectionsWhenEventIsEmitted,
    saveConnectionAllowed,
    openConsentWindow,
} from '../utils';
import { promptQuestion } from '../../confirm';
import { AYSYWTSAETCWHAI } from '../../../i18n';

interface GitlabConnectionDetails extends ConnectionDetails {
    instanceUrl?: string;
    clientId?: string;
    clientSecret?: string;
}

interface GitlabAppSetupSaveProps {
    instanceUrl: string;
}

export const gitlabManageConnectionOpenDialogAction$ = monitor(
    'gitlabManageConnectionOpenDialogAction$',
    new Subject<GitlabConnectionDetails>()
);
export const gitlabManageConnectionCloseDialogAction$ = monitor(
    'gitlabManageConnectionCloseDialogAction$',
    new Subject<void>()
);

export const gitlabAppSetupOpenDialogAction$ = monitor(
    'gitlabAppSetupOpenDialogAction$',
    new Subject<GitlabConnectionDetails>()
);
export const gitlabAppSetupCloseDialogAction$ = monitor('gitlabAppSetupCloseDialogAction$', new Subject<void>());
export const gitlabAppSetupDialogOpen$ = monitor('gitlabAppSetupDialogOpen$', new BehaviorSubject(false));
export const gitlabAppSetupAuthorizeAction$ = monitor(
    'gitlabAppSetupAuthorizeAction$',
    new Subject<GitlabAppSetupSaveProps>()
);
export const gitlabAppSetupDialogErrors$ = monitor(
    'gitlabAppSetupDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const gitlabSaveConnectionAction$ = monitor('gitlabSaveConnectionAction$', new Subject<string>());
export const gitlabConnectionCreatedAction$ = monitor('gitlabConnectionCreatedAction$', new Subject<string>());
export const gitlabConnectionSavedAction$ = monitor(
    'gitlabConnectionSavedAction$',
    new Subject<{ uid: string; connectionTypeUid: string; url: string }>()
);
export const gitlabManageConnectionInitiateSetupAction$ = monitor(
    'gitlabManageConnectionInitiateSetupAction$',
    new Subject<string>()
);

export const gitlabManageConnectionDialogOpen$ = monitor(
    'gitlabManageConnectionDialogOpen$',
    new BehaviorSubject(false)
);
export const gitlabConnectionSaving$ = monitor('gitlabConnectionSaving$', new BehaviorSubject(false));
export const gitlabManageConnectionAuthorizeLoading$ = monitor(
    'gitlabManageConnectionAuthorizeLoading$',
    new BehaviorSubject(false)
);
export const gitlabManageConnectionDetails$ = monitor(
    'gitlabManageConnectionDetails$',
    new BehaviorSubject<GitlabConnectionDetails | undefined>(undefined)
);
export const gitlabManageConnectionDialogErrors$ = monitor(
    'gitlabManageConnectionDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const gitlabAppSetupDialogStage$ = monitor('gitlabAppSetupDialogStage$', new BehaviorSubject(0));

gitlabAppSetupOpenDialogAction$.subscribe((details) => {
    gitlabAppSetupDialogErrors$.next(undefined);
    gitlabManageConnectionDialogErrors$.next(undefined);
    gitlabAppSetupDialogOpen$.next(true);
    gitlabAppSetupDialogStage$.next(0);
    gitlabManageConnectionDetails$.next(details);
});

gitlabAppSetupCloseDialogAction$.subscribe(() => {
    gitlabAppSetupDialogOpen$.next(false);
    gitlabManageConnectionAuthorizeLoading$.next(false);
});

gitlabManageConnectionOpenDialogAction$.subscribe((details) => {
    gitlabAppSetupDialogErrors$.next(undefined);
    gitlabManageConnectionDialogErrors$.next(undefined);
    gitlabManageConnectionDialogOpen$.next(true);
    gitlabManageConnectionDetails$.next(details);
});

gitlabManageConnectionCloseDialogAction$.subscribe(() => {
    gitlabManageConnectionDialogOpen$.next(false);
    gitlabAppSetupDialogOpen$.next(false);
    gitlabManageConnectionDetails$.next(undefined);
});

gitlabConnectionCreatedAction$.subscribe((uid) => connectionCreatedAction$.next(uid));

gitlabAppSetupAuthorizeAction$
    .pipe(
        map(async (appInfoProps) => {
            gitlabConnectionSaving$.next(true);
            gitlabAppSetupDialogErrors$.next(undefined);

            const { uid, connectionType } = gitlabManageConnectionDetails$.value ?? {};
            try {
                if (!connectionType) {
                    // eslint-disable-next-line sonarjs/no-duplicate-string
                    throw Error('GitLab Connector type not defined');
                }

                if (!uid) {
                    throw Error('GitLab Connector has not been created so cannot configure it');
                }

                const baseUrl = configTopic$.value.connection?.gitlab?.baseUrl;
                if (!baseUrl) {
                    throw new Error('No GitLab Connector URL configured in meta');
                }

                const response = await initiate(
                    {
                        ...appInfoProps,
                        url: appInfoProps.instanceUrl,
                        connectionId: uid,
                        connectionType: 'gitlab',
                    },
                    `${baseUrl}/initiate`
                );
                openConsentWindow(response.location, gitlabManageConnectionAuthorizeLoading$);

                gitlabConnectionCreatedAction$.next(uid);
            } catch (e) {
                if (e instanceof InformativeError) {
                    gitlabAppSetupDialogErrors$.next(e.message);
                } else {
                    gitlabAppSetupDialogErrors$.next(
                        'Failed to save GitLab application details, please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while saving GitLab application info', e);
                }
            }

            gitlabConnectionSaving$.next(false);
            gitlabManageConnectionAuthorizeLoading$.next(false);
        })
    )
    .subscribe();

gitlabSaveConnectionAction$
    .pipe(
        map(async (name) => {
            gitlabConnectionSaving$.next(true);
            gitlabManageConnectionDialogErrors$.next(undefined);

            const { uid, connectionType, instanceUrl = '' } = gitlabManageConnectionDetails$.value ?? {};

            try {
                if (!connectionType) {
                    throw Error('GitLab Connector type not defined');
                }
                const connectionTypeUid = connectionType.uid;

                if (uid) {
                    if (saveConnectionAllowed(uid)) {
                        await saveConnection(uid, name);

                        gitlabConnectionSavedAction$.next({ uid, connectionTypeUid, url: instanceUrl });
                        gitlabManageConnectionCloseDialogAction$.next();
                        publishLocalFeedbackEventAction$.next({
                            level: 'SUCCESS',
                            message: 'Connector saved.',
                        });
                    } else {
                        promptQuestion({
                            title: AYSYWTSAETCWHAI,
                            onProceed: async () => {
                                await saveConnection(uid, name);

                                gitlabConnectionSavedAction$.next({ uid, connectionTypeUid, url: instanceUrl });
                                gitlabManageConnectionCloseDialogAction$.next();
                                publishLocalFeedbackEventAction$.next({
                                    level: 'SUCCESS',
                                    message: 'Connector saved.',
                                });
                            },
                        });
                    }
                    if (connectionCreatedFirstTime$.value) {
                        connectionCreatedFirstTimeAction$.next();
                        connectionCreatedFirstTime$.next(false);
                    }
                } else {
                    promptQuestion({
                        title: AYSYWTSAETCWHAI,
                        onProceed: async () => {
                            const { uid } = await createConnection(
                                connectionType.uid,
                                name,
                                connectionType.apiHandlerTypes[0]?.uid ?? '',
                                connectionType.eventListenerTypes[0]?.uid ?? ''
                            );

                            gitlabConnectionCreatedAction$.next(uid);
                            gitlabConnectionSavedAction$.next({ uid, connectionTypeUid, url: instanceUrl });
                            gitlabManageConnectionCloseDialogAction$.next();
                            publishLocalFeedbackEventAction$.next({
                                level: 'SUCCESS',
                                message: 'Connector created.',
                            });
                            connectionCreatedFirstTimeAction$.next();
                        },
                    });
                }
            } catch (e) {
                if (e instanceof InformativeError) {
                    gitlabManageConnectionDialogErrors$.next(e.message);
                } else {
                    gitlabManageConnectionDialogErrors$.next(
                        'Failed to save Connector, please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while saving GitLab Connector', e);
                }
            }

            gitlabConnectionSaving$.next(false);
        })
    )
    .subscribe();

gitlabManageConnectionInitiateSetupAction$
    .pipe(
        map(async (name) => {
            gitlabConnectionSaving$.next(true);
            gitlabManageConnectionAuthorizeLoading$.next(true);
            gitlabManageConnectionDialogErrors$.next(undefined);

            const {
                uid,
                connectionType,
                instanceUrl = '',
                name: currentName,
                ...restOfDetails
            } = gitlabManageConnectionDetails$.value ?? {};
            try {
                if (!connectionType) {
                    throw Error('GitLab Connector type not defined');
                }
                const connectionTypeUid = connectionType.uid;

                if (uid) {
                    if (currentName !== name) {
                        await saveConnection(uid, name);
                    }

                    gitlabConnectionSavedAction$.next({ uid, connectionTypeUid, url: instanceUrl });
                    gitlabAppSetupOpenDialogAction$.next({
                        ...restOfDetails,
                        uid,
                        connectionType,
                        name,
                    });
                } else {
                    const { uid } = await createConnection(
                        connectionType.uid,
                        name,
                        connectionType.apiHandlerTypes[0]?.uid ?? '',
                        connectionType.eventListenerTypes[0]?.uid ?? ''
                    );

                    gitlabConnectionCreatedAction$.next(uid);
                    gitlabConnectionSavedAction$.next({ uid, connectionTypeUid, url: instanceUrl });
                    gitlabManageConnectionDetails$.next({
                        connectionType,
                        name,
                        uid,
                    });

                    gitlabConnectionSaving$.next(false);

                    gitlabAppSetupOpenDialogAction$.next({
                        connectionType,
                        name,
                        uid,
                    });
                    connectionCreatedFirstTime$.next(true);
                }
            } catch (e) {
                if (e instanceof InformativeError) {
                    gitlabManageConnectionDialogErrors$.next(e.message);
                } else {
                    gitlabManageConnectionDialogErrors$.next(
                        'Failed to save Connector, please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while saving GitLab Connector', e);
                }
                gitlabManageConnectionAuthorizeLoading$.next(false);
            }

            gitlabConnectionSaving$.next(false);
        })
    )
    .subscribe();

loadLoggedInUserConnectionsWhenEventIsEmitted(gitlabConnectionSavedAction$);
