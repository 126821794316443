import { APP } from '@avst-stitch/repository-lib/constants';
import { BehaviorSubject, Subject } from 'rxjs';
import { ConnectionType } from '../../data/apps';
import { monitor } from '../monitor';
import { atlassianCloudManageConnectionOpenDialogAction$ } from './atlassian-cloud/manage-connection';
import { bitbucketCloudManageConnectionOpenDialogAction$ } from './bitbucket-cloud/setup-connection';
import { genericAppManageConnectionOpenDialogAction$ } from './generic/setup-connection';
import { githubManageConnectionOpenDialogAction$ } from './github/authorize-connection';
import { gitlabManageConnectionOpenDialogAction$ } from './gitlab/setup-connection';
import { googleManageConnectionOpenDialogAction$ } from './google/authorize-connection';
import { atlassianOnPremiseManageConnectionOpenDialogAction$ } from './atlassian-on-premise/setup-connection';
import { mondayManageConnectionOpenDialogAction$ } from './monday/setup-connection';
import { serviceNowManageConnectionOpenDialogAction$ } from './servicenow/setup-connection';
import { slackManageConnectionOpenDialogAction$ } from './slack/setup-connection';
import { tempoCloudManageConnectionOpenDialogAction$ } from './tempo-cloud/setup-connection';
import { bitbucketOnPremiseManageConnectionOpenDialogAction$ } from './bitbucket-on-premise/setup-connection';
import { jsmCloudAssetsManageConnectionOpenDialogAction$ } from './jsmCloudAssets/setup-connection';
import { microsoftManageConnectionOpenDialogAction$ } from './microsoft/setup-connection';
import { zoomManageConnectionOpenDialogAction$ } from './zoom/setup-connection';
import { hotjarEvent } from '../../data/hotjar-events';
import { salesforceManageConnectionOpenDialogAction$ } from './salesforce/setup-connection';
import { segmentAnalyticsTrack } from '../../data/segment-analytics';
import { statuspageManageConnectionOpenDialogAction$ } from './statuspage/setup-connection';
import { trelloManageConnectionOpenDialogAction$ } from './trello/setup-connection';
import { awsManageConnectionOpenDialogAction$ } from './aws/setup-connection';
import { opsgenieManageConnectionOpenDialogAction$ } from './opsgenie/setup-connection';
import { loggedInUserDetails$ } from '../user';
import { netSuiteManageConnectionOpenDialogAction$ } from './netsuite/setup-connection';
import { azureDevopsManageConnectionOpenDialogAction$ } from './azure-devops/setup-connection';
import { zenDeskManageConnectionOpenDialogAction$ } from './zendesk/setup-connection';

export const openConnectionDetailsDialogAction$ = monitor(
    'openConnectionDetailsDialogAction$',
    new Subject<ConnectionDetails>()
);
export const connectionCreatedAction$ = monitor('connectionCreatedAction$', new Subject<string>());

openConnectionDetailsDialogAction$.subscribe((details) => {
    // eslint-disable-next-line sonarjs/no-small-switch
    switch (details.connectionType.name) {
        case APP.JIRA_CLOUD.NAME:
        case APP.CONFLUENCE_CLOUD.NAME:
        case APP.JIRA_SERVICE_MANAGEMENT_CLOUD.NAME:
            atlassianCloudManageConnectionOpenDialogAction$.next(details);
            break;
        case APP.BITBUCKET_CLOUD.NAME:
            bitbucketCloudManageConnectionOpenDialogAction$.next(details);
            break;
        case APP.SLACK.NAME:
            slackManageConnectionOpenDialogAction$.next(details);
            break;
        case APP.SERVICENOW.NAME:
            serviceNowManageConnectionOpenDialogAction$.next(details);
            break;
        case APP.BITBUCKET_ON_PREMISE.NAME:
            bitbucketOnPremiseManageConnectionOpenDialogAction$.next(details);
            break;
        case APP.JIRA_ON_PREMISE.NAME:
        case APP.CONFLUENCE_ON_PREMISE.NAME:
            atlassianOnPremiseManageConnectionOpenDialogAction$.next(details);
            break;
        case APP.GOOGLE_SHEETS.NAME:
        case APP.GOOGLE_CALENDAR.NAME:
            googleManageConnectionOpenDialogAction$.next(details);
            break;
        case APP.GENERIC.NAME:
            genericAppManageConnectionOpenDialogAction$.next(details);
            break;
        case APP.GITHUB.NAME:
            githubManageConnectionOpenDialogAction$.next(details);
            break;
        case APP.MONDAY.NAME:
            mondayManageConnectionOpenDialogAction$.next(details);
            break;
        case APP.GITLAB.NAME:
            gitlabManageConnectionOpenDialogAction$.next(details);
            break;
        case APP.TEMPO_CLOUD.NAME:
            tempoCloudManageConnectionOpenDialogAction$.next(details);
            break;
        case APP.NETSUITE.NAME:
            netSuiteManageConnectionOpenDialogAction$.next(details);
            break;
        case APP.JIRA_SERVICE_MANAGEMENT_CLOUD_ASSETS.NAME:
            jsmCloudAssetsManageConnectionOpenDialogAction$.next(details);
            break;
        case APP.MICROSOFT.NAME:
            microsoftManageConnectionOpenDialogAction$.next(details);
            break;
        case APP.AZURE_DEVOPS.NAME:
            azureDevopsManageConnectionOpenDialogAction$.next(details);
            break;
        case APP.ZOOM.NAME:
            zoomManageConnectionOpenDialogAction$.next(details);
            break;
        case APP.SALESFORCE.NAME:
            salesforceManageConnectionOpenDialogAction$.next(details);
            break;
        case APP.STATUSPAGE.NAME:
            statuspageManageConnectionOpenDialogAction$.next(details);
            break;
        case APP.TRELLO.NAME:
            trelloManageConnectionOpenDialogAction$.next(details);
            break;
        case APP.OPSGENIE.NAME:
            opsgenieManageConnectionOpenDialogAction$.next(details);
            break;
        case APP.ZENDESK.NAME:
            zenDeskManageConnectionOpenDialogAction$.next(details);
            break;
        case APP.AWS.NAME:
            awsManageConnectionOpenDialogAction$.next(details);
            break;
        default:
            alert('Connector type not implemented');
            break;
    }
    if (details.uid) {
        segmentAnalyticsTrack('Connector EditClicked', {
            connector: {
                uid: details.uid,
                name: details.name,
                connectorType: details.connectionType.name,
            },
            userOrigin: loggedInUserDetails$.value?.userOrigin,
        });
    }
});

export type ConnectionDetails = {
    connectionType: ConnectionType;
    uid?: string;
    name?: string;
};

export const connectionCreatedFirstTime$ = monitor('connectionCreatedFirstTime$', new BehaviorSubject<boolean>(false));

export const connectionCreatedFirstTimeAction$ = monitor('connectionCreatedFirstTimeAction$', new Subject<void>());

connectionCreatedFirstTimeAction$.subscribe(() => {
    const event = 'connector-created';
    try {
        hotjarEvent(event);
    } catch (e) {
        console.error(`Error while triggering Hotjar event ${event}, error: ${(e as Error).message}`);
    }
});
