import {
    EnvironmentVariable,
    EnvironmentVariableProps,
} from '../../../workspace-resources/environment-variables/EnvironmentVariable';
import { SetupGuideReviewScreenItem } from '../SetupGuideReviewScreenItem';
import { ToggleVariableExpandEvent } from '../../../workspace-resources/environment-variables/types';

export interface SetupGuideReviewScreenEnvironmentVariablesProps {
    environmentVariables: EnvironmentVariableProps[];
    onEdit: () => void;
    onToggleVariableExpand(event: ToggleVariableExpandEvent): void;
}

export const SetupGuideReviewScreenEnvironmentVariables: React.FC<SetupGuideReviewScreenEnvironmentVariablesProps> = ({
    environmentVariables,
    onEdit,
    onToggleVariableExpand,
}) => {
    return (
        <SetupGuideReviewScreenItem itemCount={environmentVariables.length} itemName="Parameters" onEdit={onEdit}>
            {environmentVariables.map((ev) => {
                return (
                    <EnvironmentVariable
                        childVariables={ev.childVariables}
                        choices={ev.choices}
                        defaultValue={ev.defaultValue as string | undefined}
                        description={ev.description}
                        disabled
                        expanded={ev.expanded}
                        id={ev.id}
                        keyName={ev.keyName}
                        parentId={ev.parentId}
                        passwordValueFilled={ev.passwordValueFilled}
                        required={ev.required}
                        sameLevelKeyNames={ev.sameLevelKeyNames}
                        type={ev.type}
                        uid={ev.uid}
                        value={ev.value as string | undefined}
                        onToggleExpand={onToggleVariableExpand}
                        key={ev.uid}
                    />
                );
            })}
        </SetupGuideReviewScreenItem>
    );
};
