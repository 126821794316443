import { useState } from 'react';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { Button } from '../../common/buttons/Button';
import {
    StyledOnboardingActions,
    StyledOnboardingBox,
    StyledOnboardingBoxContent,
    StyledOnboardingBoxHeader,
} from '../OnboardingComponents';
import { TextField } from '../../common/inputs/TextField';
import { Alert } from '../../common/alerts/Alert';
import { handleKeyDown } from '../../../utils/input';

export interface OnboardingWorkspaceNamingScreenProps {
    errors?: string;
    name?: string;
    saving?: boolean;
    onGoBack: () => void;
    onSave: (name: string) => void;
}

export const OnboardingWorkspaceNamingScreen: React.FC<OnboardingWorkspaceNamingScreenProps> = ({
    errors,
    name = '',
    saving = false,
    onGoBack,
    onSave,
}) => {
    const [currentName, setCurrentName] = useState(name);

    return (
        <StyledOnboardingBox
            onKeyDown={(event) =>
                handleKeyDown({ event, enterCondition: !!currentName && !saving, enterFn: () => onSave(currentName) })
            }
        >
            <StyledOnboardingBoxHeader>
                <Typography component="h2" variant="h5">
                    Name your integration
                </Typography>
                <Typography color="text.secondary" fontWeight="normal" variant="subtitle1">
                    You can always change it later.
                </Typography>
            </StyledOnboardingBoxHeader>
            <Divider />
            <StyledOnboardingBoxContent>
                {errors && <Alert severity="error" title={errors} />}
                <TextField
                    label="Name"
                    placeholder="Enter a name"
                    required
                    value={currentName}
                    onChange={(e) => setCurrentName(e.currentTarget.value)}
                />
            </StyledOnboardingBoxContent>
            <Divider />
            <StyledOnboardingActions>
                <Button variant="text" onClick={onGoBack}>
                    Back
                </Button>
                <Button busy={saving} disabled={!currentName} onClick={() => onSave(currentName)}>
                    Next
                </Button>
            </StyledOnboardingActions>
        </StyledOnboardingBox>
    );
};
