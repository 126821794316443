import { styled } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectProps } from '@mui/material/Select';
import AddIcon from '@mui/icons-material/Add';
import DisplaySettingsRoundedIcon from '@mui/icons-material/DisplaySettingsRounded';

export interface DropdownItem {
    value: string;
    name: string;
    category?: string;
    icon?: JSX.Element;
}

interface DropdownProps extends SelectProps {
    actionsDisabled?: boolean;
    items: DropdownItem[];
    label: string;
    groupByCategory?: boolean;
    selectedItem?: string;
    onCreateNew?(): void;
    onManageEnvironments?(): void; // Make this part generic
    onSelectItem?(value: string): void;
}

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
    marginRight: theme.spacing(1.5),
    '& .MuiSvgIcon-root': {
        color: theme.palette.primary.main,
        fontSize: 24,
    },
    minWidth: 'unset', // Determine the icon size only by font-size
}));

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
    '& .MuiTypography-root': {
        ...theme.typography.overflowLine,
    },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    '& .MuiListItemIcon-root': {
        minWidth: 'unset', // Determine the icon size only by font-size
        paddingBottom: theme.spacing(0.1),
    },
}));

const StyledSelect = styled(Select)<{ fullWidth: boolean }>(({ fullWidth, theme }) => ({
    '& .MuiSelect-select': {
        ...theme.typography.overflowLine,
        ...theme.typography.flexAlignCenter,
    },
    '&.MuiInputBase-root': {
        width: fullWidth ? '100%' : undefined, // Later change to 'unset'
    },
}));

export const Dropdown: React.FC<DropdownProps> = ({
    actionsDisabled = false,
    fullWidth = false,
    groupByCategory = false,
    items,
    label,
    selectedItem = '',
    onCreateNew,
    onManageEnvironments,
    onSelectItem,
    ...props
}) => {
    const addCreateNewItem = !!onCreateNew;
    const manageEnvironments = !!onManageEnvironments;

    const groupedByCategory = (): JSX.Element[] => {
        const grouped = items
            .sort((a, b) => a.name.localeCompare(b.name))
            .reduce<Record<string, DropdownItem[]>>((acc, curr) => {
                const category = curr.category ?? 'Basic';
                const items = acc[category] ?? [];
                items.push(curr);
                acc[category] = items;

                return acc;
            }, {});

        const elementList: JSX.Element[] = [];
        Object.entries(grouped).forEach(([category, dropdownItems]) => {
            if (!(Object.keys(grouped).length === 1 && category === 'Basic')) {
                elementList.push(<ListSubheader key={category}>{category}</ListSubheader>);
            }

            const options = dropdownItems.map((option) => (
                <StyledMenuItem key={option.value} value={option.value}>
                    {option.icon && <StyledListItemIcon>{option.icon}</StyledListItemIcon>}
                    <StyledListItemText>{option.name}</StyledListItemText>
                </StyledMenuItem>
            ));
            elementList.push(...options);
        });

        return elementList;
    };

    const menuItems = groupByCategory
        ? groupedByCategory()
        : items.map((item) => {
              return (
                  <StyledMenuItem key={item.value} value={item.value}>
                      {item.icon && <StyledListItemIcon>{item.icon}</StyledListItemIcon>}
                      <StyledListItemText>{item.name}</StyledListItemText>
                  </StyledMenuItem>
              );
          });

    // html form labels cannot have spaces, and lowercase is good practice
    const labelId = label.replace(/\s/g, '').toLowerCase() + '-label';

    return (
        <>
            <FormControl disabled={props.disabled} fullWidth={fullWidth} size={props.size}>
                <InputLabel disabled={props.disabled} required={props.required} id={labelId}>
                    {label}
                </InputLabel>
                <StyledSelect
                    {...props}
                    fullWidth={fullWidth}
                    labelId={labelId}
                    label={label}
                    value={selectedItem}
                    onChange={(event) => {
                        if (addCreateNewItem && event.target.value === 'onCreateNew') {
                            onCreateNew();
                        } else if (manageEnvironments && event.target.value === 'onManageEnvironments') {
                            onManageEnvironments();
                        } else {
                            onSelectItem?.(event.target.value as string);
                        }
                    }}
                >
                    {addCreateNewItem && (
                        <StyledMenuItem value="onCreateNew" disabled={actionsDisabled}>
                            <StyledListItemIcon>
                                <AddIcon />
                            </StyledListItemIcon>
                            Create New
                        </StyledMenuItem>
                    )}
                    {/* Make this part generic */}
                    {manageEnvironments && (
                        <StyledMenuItem value="onManageEnvironments" disabled={actionsDisabled}>
                            <StyledListItemIcon>
                                <DisplaySettingsRoundedIcon />
                            </StyledListItemIcon>
                            Manage Environments
                        </StyledMenuItem>
                    )}
                    {menuItems}
                </StyledSelect>
            </FormControl>
        </>
    );
};
