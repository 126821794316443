import Typography from '@mui/material/Typography';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Button } from '../common/buttons/Button';
import { Dialog } from '../common/Dialog';
import { IconCircle } from '../common/IconCircle';
import { handleKeyDown } from '../../utils/input';

interface VerifyAccountDialogProps {
    loading?: boolean;
    onRetry(): void;
    onCancel(): void;
}

export const VerifyAccountDialog: React.FC<VerifyAccountDialogProps> = ({ loading = false, onRetry, onCancel }) => {
    const buttons = [
        <Button onClick={onCancel} variant="outlined" data-pendo={'emailVerificationCancelled'}>
            Cancel
        </Button>,
        <Button
            busy={loading}
            onClick={onRetry}
            variant="contained"
            color="primary"
            data-pendo={'emailVerificationDone'}
        >
            Done
        </Button>,
    ];
    return (
        <Dialog
            buttons={buttons}
            icon={<IconCircle icon={<WarningAmberIcon />} severity="warning" />}
            messages={[
                <Typography>
                    Please check your email and verify your new account, then click <strong>Done</strong> below.
                </Typography>,
            ]}
            open={true}
            title="Email verification required"
            onKeyDown={(event) => handleKeyDown({ event, enterFn: onRetry, escFn: onCancel })}
        ></Dialog>
    );
};
