import { useEffect, useRef, useState } from 'react';
import { useDraggable, useDroppable } from '@dnd-kit/core';
import { styled } from '@mui/material';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button } from '../../../common/buttons/Button';
import { DialogAlert } from '../../../for-deprecation/dialog/DialogComponents';
import { Dropdown } from '../../../common/inputs/dropdown/Dropdown';
import {
    emptyWarning,
    DragButton,
    StyledDropArea,
    StyledEditModeActions,
    StyledEditModeContent,
    StyledReadOnlyExpandButton,
    StyledHeaderActions,
    StyledVariable,
    StyledReadOnlyVariableHeader,
    StyledVariableWrapper,
    StyledReadOnlyValueField,
    StyledReadOnlyKeyField,
    StyledReadOnlyRow,
    StyledReadOnlyMultipleValuesField,
    StyledEditModeVariableHeader,
    StyledEditModeDivider,
} from '../EnvironmentVariableComponents';
import { IconButton } from '../../../common/buttons/IconButton';
import { InformationChip } from '../../../common/chips/InformationChip';
import { ToggleButtonGroup } from '../../../common/buttons/button-groups/ToggleButtonGroup';
import { EnvironmentVariableType } from '@avst-stitch/repository-lib/lib/types';
import {
    ChangeVariableTypeEvent,
    DeleteVariableEvent,
    ToggleVariableEditModeEvent,
    ToggleVariableExpandEvent,
} from '../types';
import { autoFocus, handleKeyDown, isFocused, isScriptOrEnvVariableNameValid } from '../../../../utils/input';
import {
    DESCRIPTION_MAX_LENGTH,
    duplicateKeyNameHelperText,
    environmentVariableTypes,
    getVariableHeight,
    invalidKeyNameHelperText,
    isDescriptionTooLong,
} from '../utils';

interface UpdateMapVariableEvent {
    defaultValue?: Record<string, string>;
    description?: string;
    id: string;
    keyName: string;
    parentId?: string;
    required?: boolean;
    type: EnvironmentVariableType;
    value?: Record<string, string>;
}

interface EnvironmentVariableMapVariantProps {
    defaultValue?: Record<string, string>;
    description?: string;
    disabled?: boolean;
    dragOverlay?: boolean;
    editMode?: boolean;
    expanded?: boolean;
    hasBeenEdited?: boolean;
    id: string;
    keyName?: string;
    parentId?: string;
    readOnlyTemplateMode?: boolean;
    required?: boolean;
    sameLevelKeyNames?: string[];
    setupGuideTemplateMode?: boolean;
    value?: Record<string, string>;
    onChangeType?(event: ChangeVariableTypeEvent): void;
    onDelete?(event: DeleteVariableEvent): void;
    onToggleEditMode?(event: ToggleVariableEditModeEvent): void;
    onToggleExpand?(event: ToggleVariableExpandEvent): void;
    onUpdate?(event: UpdateMapVariableEvent): void;
}

const StyledEditModeMapItemsContainer = styled('div')(({ theme }) => ({
    flexDirection: 'column',
    display: 'flex',
    gap: theme.spacing(1),
}));

const StyledEditModeMapItem = styled('div')(({ theme }) => ({
    alignItems: 'flex-start',
    display: 'flex',
    gap: theme.spacing(1.5),
    width: '100%',
    '& .MuiIconButton-root': {
        marginTop: theme.spacing(1),
    },
}));

const StyledReadOnlyMapItem = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    backgroundColor: theme.palette.action.selected,
    border: `1px solid ${theme.palette.action.selected}`,
    borderRadius: theme.constants.borderRadius,
    position: 'relative',
}));

const StyledReadOnlyMapField = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    borderRadius: `0 ${theme.constants.borderRadius}px ${theme.constants.borderRadius}px 0 `,
    flexGrow: 1,
    height: '100%',
    padding: theme.spacing(0.1, 1.5),
    '&:last-of-type': {
        backgroundColor: theme.palette.background.paper,
    },
}));

const getMapItems = (value: Record<string, string>): { key: string; value: string }[] => {
    return Object.entries(value).map(([k, v]) => ({ key: k, value: v }));
};

export const EnvironmentVariableMapVariant: React.FC<EnvironmentVariableMapVariantProps> = ({
    defaultValue = {},
    description = '',
    disabled = false,
    dragOverlay = false,
    editMode = false,
    expanded = false,
    hasBeenEdited = false,
    id,
    keyName = '',
    parentId,
    readOnlyTemplateMode = false,
    required = false,
    sameLevelKeyNames = [],
    setupGuideTemplateMode = false,
    value = {},
    onChangeType,
    onDelete,
    onToggleEditMode,
    onToggleExpand,
    onUpdate,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const [currentKeyName, setCurrentKeyName] = useState(keyName);
    const [currentMapItems, setCurrentMapItems] = useState(getMapItems(value));
    const [currentDefaultMapItems, setCurrentDefaultMapItems] = useState(getMapItems(defaultValue));
    const [currentDescription, setCurrentDescription] = useState(description);
    const [currentRequired, setCurrentRequired] = useState(required);

    const variableRef = useRef<HTMLDivElement>(null);
    const typeInputRef = useRef<HTMLInputElement>(null);
    const mapItemInputRef = useRef<HTMLInputElement>(null);
    const defaultMapItemInputRef = useRef<HTMLInputElement>(null);
    const descriptionInputRef = useRef<HTMLInputElement>(null);

    const draggable = useDraggable({
        id,
        data: { height: getVariableHeight(variableRef), keyName, parentId, type: 'variable' },
    });

    const droppable = useDroppable({ id });

    useEffect(() => {
        if (editMode) {
            autoFocus(typeInputRef);
        }
    }, [editMode]);

    const handleCancel = (): void => {
        setCurrentKeyName(keyName);
        setCurrentMapItems(getMapItems(value));
        setCurrentDefaultMapItems(getMapItems(defaultValue));
        setCurrentDescription(description);
        setCurrentRequired(required);
        onToggleEditMode?.({ editMode: false, id, parentId });
    };

    const handleUpdate = (): void => {
        onUpdate?.({
            defaultValue: Object.fromEntries(currentDefaultMapItems.map((mi) => [mi.key, mi.value])),
            description: currentDescription,
            id,
            keyName: currentKeyName,
            parentId,
            required: currentRequired,
            type: 'MAP',
            value: Object.fromEntries(currentMapItems.map((mi) => [mi.key, mi.value])),
        });
    };

    const handleAddMapItem = (): void => {
        setCurrentMapItems([...currentMapItems, { key: '', value: '' }]);
        autoFocus(mapItemInputRef);
    };

    const handleAddDefaultMapItem = (): void => {
        setCurrentDefaultMapItems([...currentDefaultMapItems, { key: '', value: '' }]);
        autoFocus(defaultMapItemInputRef);
    };

    const isCurrentMapItemKeyDuplicate = (index: number, forDefaultValue?: boolean): boolean => {
        const items = forDefaultValue ? currentDefaultMapItems : currentMapItems;
        return items.filter((mi) => mi.key === items[index]?.key).length > 1;
    };

    const hasDuplicateMapItemKeys =
        currentMapItems.some((_, i) => isCurrentMapItemKeyDuplicate(i)) ||
        currentDefaultMapItems.some((_, i) => isCurrentMapItemKeyDuplicate(i, true));

    const hasChanged =
        JSON.stringify(currentMapItems) !== JSON.stringify(getMapItems(value)) ||
        JSON.stringify(currentDefaultMapItems) !== JSON.stringify(getMapItems(defaultValue)) ||
        currentDescription !== description ||
        currentKeyName !== keyName ||
        currentRequired !== required;

    const isKeyNameDuplicate =
        !!currentKeyName && sameLevelKeyNames.filter((kn) => kn !== keyName).includes(currentKeyName);

    const isCurrentRequiredValueMissing = currentRequired && !currentMapItems.length;

    const hasEmptyKeys = currentMapItems.some((mi) => !mi.key) || currentDefaultMapItems.some((mi) => !mi.key);

    const canUpdate =
        !isKeyNameDuplicate &&
        isScriptOrEnvVariableNameValid(currentKeyName) &&
        !hasDuplicateMapItemKeys &&
        !isDescriptionTooLong(currentDescription) &&
        (!hasBeenEdited || hasChanged);

    const areMapItemValuesMissing =
        getMapItems(value).some((mi) => !mi) || getMapItems(defaultValue).some((dmi) => !dmi);
    const isRequiredValueMissing = required && !getMapItems(value).length;
    const missingInformation = !keyName || isRequiredValueMissing || hasEmptyKeys || areMapItemValuesMissing;

    const displayPasteValueButton = !!currentMapItems.length && !currentDefaultMapItems.length;

    const draggedVariable = droppable.active?.data.current;
    const dropAreaHeight = (draggedVariable?.height ?? 0) as number;

    const isDraggedVariableDuplicate =
        !!draggedVariable?.keyName &&
        draggedVariable.parentId !== parentId &&
        sameLevelKeyNames.includes(draggedVariable.keyName);

    const forbiddenFolderSort = parentId ? draggedVariable && draggedVariable.type === 'folder' : false;
    const sortForbidden = draggable.isDragging || isDraggedVariableDuplicate || forbiddenFolderSort;

    const readOnlyMapItems = getMapItems(value).length
        ? getMapItems(value).map((mi) => (
              <StyledReadOnlyMapItem>
                  <StyledReadOnlyMapField>
                      {mi.key ? (
                          <Typography variant="body1">
                              <strong>{mi.key}</strong>
                          </Typography>
                      ) : (
                          emptyWarning(true)
                      )}
                  </StyledReadOnlyMapField>
                  <StyledReadOnlyMapField>
                      {mi.value ? <Typography variant="body1">{mi.value}</Typography> : emptyWarning()}
                  </StyledReadOnlyMapField>
              </StyledReadOnlyMapItem>
          ))
        : emptyWarning(required);

    const readOnlyDefaultMapItems = getMapItems(defaultValue).length
        ? getMapItems(defaultValue).map((dmi) => (
              <StyledReadOnlyMapItem>
                  <StyledReadOnlyMapField>
                      {dmi.key ? (
                          <Typography variant="body1">
                              <strong>{dmi.key}</strong>
                          </Typography>
                      ) : (
                          emptyWarning(true)
                      )}
                  </StyledReadOnlyMapField>
                  <StyledReadOnlyMapField>
                      {dmi.value ? <Typography variant="body1">{dmi.value}</Typography> : emptyWarning()}
                  </StyledReadOnlyMapField>
              </StyledReadOnlyMapItem>
          ))
        : emptyWarning();

    const editModeMapItems = (
        <StyledEditModeMapItemsContainer>
            {isCurrentRequiredValueMissing && (
                <DialogAlert severity="warning" text="Please add at least one list item" />
            )}
            {currentMapItems.map((mi, i) => (
                <StyledEditModeMapItem key={'EditModeItem' + i}>
                    <TextField
                        label="Key"
                        inputRef={mapItemInputRef}
                        required
                        size="small"
                        placeholder="Enter key name"
                        error={isCurrentMapItemKeyDuplicate(i)}
                        helperText={
                            isCurrentMapItemKeyDuplicate(i)
                                ? 'Duplicate keys not allowed'
                                : !currentMapItems[i]?.key
                                ? 'Please enter a key name'
                                : undefined
                        }
                        value={mi.key}
                        onChange={(e) =>
                            setCurrentMapItems(
                                currentMapItems.map((v, ix) => (ix === i ? { ...v, key: e.target.value ?? '' } : v))
                            )
                        }
                    />
                    <TextField
                        label="Value"
                        size="small"
                        placeholder="Enter value"
                        value={mi.value}
                        onChange={(e) =>
                            setCurrentMapItems(
                                currentMapItems.map((v, ix) => (ix === i ? { ...v, value: e.target.value ?? '' } : v))
                            )
                        }
                    />
                    <IconButton
                        aria-label="Delete map item"
                        icon={<DeleteOutlineIcon />}
                        size="small"
                        tooltip="Delete map item"
                        onClick={() => setCurrentMapItems(currentMapItems.filter((_, ix) => ix !== i))}
                    />
                </StyledEditModeMapItem>
            ))}
            <StyledEditModeMapItem>
                <Button startIcon={<AddIcon />} variant="text" onClick={handleAddMapItem}>
                    Add key pair
                </Button>
            </StyledEditModeMapItem>
        </StyledEditModeMapItemsContainer>
    );

    const editModeDefaultMapItems = (
        <StyledEditModeMapItemsContainer>
            {currentDefaultMapItems.map((mi, i) => (
                <StyledEditModeMapItem key={'EditModeDefaultItem' + i}>
                    <TextField
                        disabled={setupGuideTemplateMode}
                        label="Key"
                        inputRef={defaultMapItemInputRef}
                        required
                        size="small"
                        placeholder="Enter key name"
                        error={isCurrentMapItemKeyDuplicate(i, true)}
                        helperText={
                            isCurrentMapItemKeyDuplicate(i, true)
                                ? 'Duplicate keys not allowed'
                                : !currentDefaultMapItems[i]?.key
                                ? 'Please enter a key name'
                                : undefined
                        }
                        value={mi.key}
                        onChange={(e) =>
                            setCurrentDefaultMapItems(
                                currentDefaultMapItems.map((v, ix) => (ix === i ? { ...v, key: e.target.value } : v))
                            )
                        }
                    />
                    <TextField
                        disabled={setupGuideTemplateMode}
                        label="Value"
                        size="small"
                        placeholder="Enter value"
                        value={mi.value}
                        onChange={(e) =>
                            setCurrentDefaultMapItems(
                                currentDefaultMapItems.map((v, ix) => (ix === i ? { ...v, value: e.target.value } : v))
                            )
                        }
                    />
                    <IconButton
                        disabled={setupGuideTemplateMode}
                        aria-label="Delete default map item"
                        icon={<DeleteOutlineIcon />}
                        size="small"
                        tooltip="Delete default map item"
                        onClick={() => setCurrentDefaultMapItems(currentDefaultMapItems.filter((_, ix) => ix !== i))}
                    />
                </StyledEditModeMapItem>
            ))}
            <StyledEditModeMapItem>
                <Button
                    disabled={setupGuideTemplateMode}
                    startIcon={<AddIcon />}
                    variant="text"
                    onClick={handleAddDefaultMapItem}
                >
                    Add default key pair
                </Button>
                {displayPasteValueButton && (
                    <Button
                        disabled={setupGuideTemplateMode}
                        startIcon={<ContentPasteIcon />}
                        variant="text"
                        onClick={() => setCurrentDefaultMapItems(currentMapItems)}
                    >
                        Paste the value as default value
                    </Button>
                )}
            </StyledEditModeMapItem>
        </StyledEditModeMapItemsContainer>
    );

    return (
        <StyledVariableWrapper
            ref={(node) => {
                draggable.setNodeRef(node);
                droppable.setNodeRef(sortForbidden ? null : node);
            }}
            isDragging={draggable.isDragging}
            nested={!!parentId}
        >
            {!sortForbidden && (
                <StyledDropArea height={dropAreaHeight} visible={droppable.isOver} nested={!!parentId} />
            )}
            <StyledVariable
                className={dragOverlay ? 'dragOverlay' : ''}
                ref={variableRef}
                onKeyDown={(event) =>
                    handleKeyDown({
                        event,
                        enterCondition: canUpdate && editMode && !isFocused(descriptionInputRef),
                        enterFn: handleUpdate,
                        escFn: handleCancel,
                    })
                }
            >
                {editMode ? (
                    <>
                        <StyledEditModeVariableHeader>
                            <Typography>{hasBeenEdited ? 'Edit parameter' : 'New parameter'}</Typography>
                            <StyledHeaderActions>
                                <ToggleButtonGroup
                                    buttons={[
                                        { label: 'Optional', selected: !currentRequired, value: !currentRequired },
                                        { label: 'Required', selected: currentRequired, value: currentRequired },
                                    ]}
                                    disabled={setupGuideTemplateMode}
                                    exclusive
                                    size="small"
                                    value={currentRequired}
                                    onChange={() => setCurrentRequired(!currentRequired)}
                                />
                                <IconButton
                                    aria-label="Cancel editing"
                                    icon={<CloseIcon />}
                                    size="small"
                                    tooltip="Cancel editing"
                                    onClick={handleCancel}
                                />
                            </StyledHeaderActions>
                        </StyledEditModeVariableHeader>
                        <StyledEditModeContent>
                            <Dropdown
                                disabled={hasBeenEdited || setupGuideTemplateMode}
                                inputRef={typeInputRef}
                                items={environmentVariableTypes}
                                label="Type"
                                required
                                selectedItem={'MAP'}
                                size="small"
                                onSelectItem={(v) =>
                                    onChangeType?.({ id, parentId, type: v as EnvironmentVariableType })
                                }
                            />
                            <TextField
                                disabled={setupGuideTemplateMode}
                                label="Key"
                                required
                                size="small"
                                error={!isScriptOrEnvVariableNameValid(currentKeyName)}
                                helperText={
                                    !isScriptOrEnvVariableNameValid(currentKeyName)
                                        ? invalidKeyNameHelperText
                                        : isKeyNameDuplicate
                                        ? duplicateKeyNameHelperText(!!parentId)
                                        : !currentKeyName
                                        ? 'Please enter key name'
                                        : undefined
                                }
                                placeholder="Enter key name"
                                value={currentKeyName}
                                onChange={(e) => setCurrentKeyName(e.target.value)}
                            />
                            <TextField
                                disabled={setupGuideTemplateMode}
                                label="Notes"
                                inputRef={descriptionInputRef}
                                size="small"
                                error={isDescriptionTooLong(currentDescription)}
                                helperText={
                                    isDescriptionTooLong(currentDescription)
                                        ? `Notes length exceeds ${DESCRIPTION_MAX_LENGTH} characters`
                                        : `${DESCRIPTION_MAX_LENGTH - currentDescription.length} characters remaining`
                                }
                                multiline
                                rows={2}
                                placeholder="Enter notes"
                                value={currentDescription}
                                onChange={(e) => setCurrentDescription(e.target.value)}
                            />
                            {editModeMapItems}
                            <StyledEditModeDivider>
                                <Typography>Developer Options</Typography>
                            </StyledEditModeDivider>
                            {editModeDefaultMapItems}
                        </StyledEditModeContent>
                        <StyledEditModeActions>
                            <Button size="small" variant="outlined" onClick={handleCancel}>
                                Cancel
                            </Button>
                            <Button size="small" disabled={!canUpdate} onClick={handleUpdate}>
                                {hasBeenEdited ? 'Update' : 'Create'}
                            </Button>
                        </StyledEditModeActions>
                    </>
                ) : (
                    <>
                        <StyledReadOnlyVariableHeader>
                            <StyledHeaderActions>
                                <DragButton
                                    disabled={disabled || readOnlyTemplateMode || setupGuideTemplateMode}
                                    {...draggable.attributes}
                                    {...draggable.listeners}
                                />
                                <IconButton
                                    aria-label="Edit parameter"
                                    data-pendo={'editParameter'}
                                    disabled={disabled || readOnlyTemplateMode}
                                    icon={<EditOutlinedIcon />}
                                    size="small"
                                    tooltip="Edit parameter"
                                    onClick={() => {
                                        onToggleEditMode?.({ editMode: true, id, parentId });
                                    }}
                                />
                                <IconButton
                                    aria-label="Delete parameter"
                                    disabled={disabled || readOnlyTemplateMode || setupGuideTemplateMode}
                                    icon={<DeleteOutlineIcon />}
                                    size="small"
                                    tooltip="Delete parameter"
                                    onClick={() => onDelete?.({ id, parentId })}
                                />
                            </StyledHeaderActions>
                            <StyledHeaderActions>
                                {missingInformation && (
                                    <InformationChip label="Missing information" severity="warning" />
                                )}
                                {!readOnlyTemplateMode && (
                                    <InformationChip
                                        label={required ? 'Required' : 'Optional'}
                                        severity={required ? 'primary' : 'grey'}
                                    />
                                )}
                                <StyledReadOnlyExpandButton
                                    aria-label={expanded ? 'Collapse all fields' : 'Expand all fields'}
                                    icon={expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    tooltip={expanded ? 'Collapse all fields' : 'Expand all fields'}
                                    onClick={() => onToggleExpand?.({ expanded: !expanded, id, parentId })}
                                >
                                    <Typography>Show all</Typography>
                                </StyledReadOnlyExpandButton>
                            </StyledHeaderActions>
                        </StyledReadOnlyVariableHeader>
                        <StyledReadOnlyRow>
                            <StyledReadOnlyKeyField>
                                <Typography>Key *</Typography>
                            </StyledReadOnlyKeyField>
                            <StyledReadOnlyValueField>
                                {keyName ? <Typography>{keyName}</Typography> : emptyWarning(true)}
                            </StyledReadOnlyValueField>
                        </StyledReadOnlyRow>
                        <StyledReadOnlyRow>
                            <StyledReadOnlyKeyField>
                                <Typography>{'Value' + (required ? ' *' : '')}</Typography>
                            </StyledReadOnlyKeyField>
                            <StyledReadOnlyMultipleValuesField>
                                {getMapItems(value).length
                                    ? readOnlyTemplateMode
                                        ? readOnlyDefaultMapItems
                                        : readOnlyMapItems
                                    : emptyWarning(true)}
                            </StyledReadOnlyMultipleValuesField>
                        </StyledReadOnlyRow>
                        <StyledReadOnlyRow>
                            <StyledReadOnlyKeyField>
                                <Typography>Notes</Typography>
                            </StyledReadOnlyKeyField>
                            <StyledReadOnlyValueField>
                                {description ? <Typography>{description}</Typography> : emptyWarning()}
                            </StyledReadOnlyValueField>
                        </StyledReadOnlyRow>
                        {expanded && (
                            <>
                                <StyledReadOnlyRow>
                                    <StyledReadOnlyKeyField>
                                        <Typography>Type</Typography>
                                    </StyledReadOnlyKeyField>
                                    <StyledReadOnlyValueField>
                                        <Typography>Map</Typography>
                                    </StyledReadOnlyValueField>
                                </StyledReadOnlyRow>
                                {!readOnlyTemplateMode && (
                                    <StyledReadOnlyRow>
                                        <StyledReadOnlyKeyField>
                                            <Typography>Default value</Typography>
                                        </StyledReadOnlyKeyField>
                                        <StyledReadOnlyMultipleValuesField>
                                            {getMapItems(defaultValue).length
                                                ? readOnlyDefaultMapItems
                                                : emptyWarning()}
                                        </StyledReadOnlyMultipleValuesField>
                                    </StyledReadOnlyRow>
                                )}
                            </>
                        )}
                    </>
                )}
            </StyledVariable>
        </StyledVariableWrapper>
    );
};
