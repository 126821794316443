import { useEffect, useRef, useState } from 'react';
import { InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, styled } from '@mui/material';
import Box from '@mui/system/Box';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined';
import { LoadingSpinner } from '../common/LoadingSpinner';
import { Button } from '../common/buttons/Button';
import { DialogAlert, DialogTitleMain } from '../for-deprecation/dialog/DialogComponents';
import { Dropdown } from '../common/inputs/dropdown/Dropdown';
import { IconCircle } from '../for-deprecation/IconCircle';
import { autoFocus, handleKeyDown, isFocused } from '../../utils/input';
import { UserDetails } from '@avst-stitch/repository-lib/lib/types';

interface EditWorkspaceDialogProps {
    defaultOrganizationUid?: string;
    description?: string;
    errors?: string;
    isTemplate?: boolean;
    isWorkspaceOwner?: boolean;
    loading?: boolean;
    name?: string;
    open?: boolean;
    organizations?: {
        value: string;
        name: string;
    }[];
    organizationSelectorDisabled?: boolean;
    originalOwner?: UserDetails;
    saving?: boolean;
    selectedOrganizationUid?: string;
    templateSelectorDisabled?: boolean;
    useCases?: TemplateCategory[];
    complexities?: TemplateCategory[];
    selectedUseCaseUids?: string[];
    selectedComplexityUid?: string;
    onCancel(): void;
    onSave(event: WorkspaceUpdateEvent): void;
}

export interface TemplateCategory {
    uid: string;
    name: string;
}

export interface WorkspaceUpdateEvent {
    description?: string;
    name: string;
    selectedOrganizationUid?: string;
    template?: boolean;
    useCases: string[];
    complexity?: string;
}

const StyledTextFieldMultiline = styled(TextField)(() => ({
    '& .MuiInputBase-root': {
        minHeight: 82,
    },
}));

export const EditWorkspaceDialog: React.FC<EditWorkspaceDialogProps> = ({
    description,
    errors,
    isTemplate = false,
    isWorkspaceOwner = true,
    loading = false,
    name,
    open = false,
    organizations = [],
    organizationSelectorDisabled,
    saving = false,
    selectedOrganizationUid,
    templateSelectorDisabled = true,
    useCases = [],
    complexities = [],
    selectedUseCaseUids = [],
    selectedComplexityUid = '',
    onCancel,
    onSave,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const [currentOrganization, setCurrentOrganization] = useState(selectedOrganizationUid || organizations[0]?.value);
    const [currentOrganizations, setCurrentOrganizations] = useState(organizations);
    const [currentName, setCurrentName] = useState(name);
    const [templateChecked, setTemplateChecked] = useState(isTemplate);
    const [currentDescription, setCurrentDescription] = useState(description);
    const [selectedUseCases, setSelectedUseCases] = useState(selectedUseCaseUids);
    const [selectedComplexity, setSelectedComplexity] = useState(selectedComplexityUid);

    const nameRef = useRef<HTMLInputElement>(null);
    const descriptionRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setCurrentOrganization(selectedOrganizationUid || organizations[0]?.value);
        setCurrentOrganizations(organizations);
    }, [open, selectedOrganizationUid, organizations.length]);

    useEffect(() => {
        setCurrentName(name);
        setCurrentDescription(description);
        setTemplateChecked(isTemplate);
    }, [open]);

    useEffect(() => {
        setSelectedUseCases(selectedUseCaseUids);
    }, [open, selectedUseCaseUids, useCases.length]);

    useEffect(() => {
        setSelectedComplexity(selectedComplexityUid);
    }, [open, selectedComplexityUid, complexities.length]);

    useEffect(() => {
        if (open && !loading) {
            autoFocus(nameRef);
        }
    }, [loading]);

    const changingTeam = !!selectedOrganizationUid && selectedOrganizationUid !== currentOrganization;
    const ownerWarning = changingTeam && !isWorkspaceOwner;

    const handleSave = (): void => {
        onSave({
            description: currentDescription,
            name: currentName ?? '',
            selectedOrganizationUid: currentOrganization,
            template: templateChecked,
            useCases: selectedUseCases,
            complexity: selectedComplexity ?? undefined,
        });
    };

    const handleUseCaseChange = (event: SelectChangeEvent<typeof selectedUseCases>): void => {
        const {
            target: { value },
        } = event;

        setSelectedUseCases(typeof value === 'string' ? value.split(',') : value);
    };

    const handleComplexityChange = (event: SelectChangeEvent): void => {
        setSelectedComplexity(event.target.value);
    };

    const hasChanged =
        currentName !== name ||
        currentDescription !== description ||
        templateChecked !== isTemplate ||
        changingTeam ||
        selectedComplexityUid !== selectedComplexity ||
        selectedUseCaseUids.toString() !== selectedUseCases.toString();

    const canSave = !!currentName && !loading && !saving && hasChanged && !!currentOrganization;

    return (
        <Dialog
            open={open}
            onKeyDown={(event) =>
                handleKeyDown({
                    event,
                    enterCondition: canSave && !isFocused(descriptionRef),
                    enterFn: handleSave,
                    escFn: onCancel,
                })
            }
        >
            <DialogTitleMain title="Edit workspace" icon={<IconCircle icon={<WorkspacesOutlinedIcon />} />} />
            {loading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <DialogContent>
                        {changingTeam && (
                            <DialogAlert
                                severity="warning"
                                title="Privacy and collaboration"
                                text="You are about to move the workspace from one team to another. Doing so will result in a removal of all the attached Connectors and invalidation of webhooks for all of the Event Listeners. API Connections and Event Listeners would have to be set up again. This is done for safety reasons."
                            />
                        )}
                        {ownerWarning && (
                            <DialogAlert
                                severity="warning"
                                title="Warning"
                                text="This action makes the workspace inaccessible by the original user if they are not part of the team you are about to associate the workspace with."
                            />
                        )}
                        {errors && <DialogAlert severity="error" title={errors} />}
                        <TextField
                            id="name-text-field"
                            label="Workspace name"
                            inputRef={nameRef}
                            value={currentName}
                            onChange={(e) => setCurrentName(e.target.value)}
                            required
                            placeholder="Enter a name"
                        />
                        <StyledTextFieldMultiline
                            inputRef={descriptionRef}
                            value={currentDescription}
                            onChange={(e) => setCurrentDescription(e.target.value)}
                            label="Description"
                            multiline
                            maxRows={2}
                            placeholder="Enter a description"
                        />
                        <Box paddingBottom={2}>
                            <Dropdown
                                disabled={organizationSelectorDisabled}
                                items={currentOrganizations}
                                label="Change team"
                                required
                                selectedItem={currentOrganization}
                                onSelectItem={(val) => setCurrentOrganization(val)}
                            />
                        </Box>
                        {!templateSelectorDisabled && (
                            <>
                                <FormControl sx={{ width: 300 }}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={templateChecked}
                                                    onChange={() => setTemplateChecked(!templateChecked)}
                                                />
                                            }
                                            label="Workspace is a template"
                                        />
                                    </FormGroup>
                                </FormControl>
                                {templateChecked && (
                                    <>
                                        <FormControl sx={{ width: 300 }}>
                                            <InputLabel id="use-cases-label">Use Cases</InputLabel>
                                            <Select
                                                labelId="use-cases-label"
                                                multiple
                                                value={selectedUseCases}
                                                onChange={handleUseCaseChange}
                                                input={<OutlinedInput label="Use Cases" />}
                                                renderValue={(selected) =>
                                                    useCases
                                                        .filter((uc) => selected.includes(uc.uid))
                                                        .map((uc) => uc.name)
                                                        .join(', ')
                                                }
                                            >
                                                {useCases.map(({ uid, name }) => (
                                                    <MenuItem key={uid} value={uid}>
                                                        <Checkbox checked={selectedUseCases.includes(uid)} />
                                                        <ListItemText primary={name} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <FormControl sx={{ width: 300 }}>
                                            <InputLabel id="complexity-label">Complexity</InputLabel>
                                            <Select
                                                labelId="complexity-label"
                                                value={selectedComplexity}
                                                label="Complexity"
                                                onChange={handleComplexityChange}
                                            >
                                                <MenuItem value="">None</MenuItem>
                                                {complexities.map(({ uid, name }) => (
                                                    <MenuItem value={uid}>{name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </>
                                )}
                            </>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => onCancel()} variant="outlined">
                            Cancel
                        </Button>

                        <Button busy={saving} disabled={!canSave} onClick={handleSave}>
                            Save
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};
