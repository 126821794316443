import { BehaviorSubject, Subject, map } from 'rxjs';
import { monitor } from './monitor';
import { CreateDiscountCodeRequest, DiscountCodeDetails, createDiscountCode } from '../data/discountCodeDetails';
import { InformativeError, PermissionError } from '../utils/error';

export const createDiscountCodeAction$ = monitor('createDiscountCodeAction$', new Subject<CreateDiscountCodeRequest>());
export const discountCodeCreatedAction$ = monitor('discountCodeCreatedAction$', new Subject<void>());

export const discountCodeDetails$ = monitor(
    'discountCodeDetails$',
    new BehaviorSubject<DiscountCodeDetails | undefined>(undefined)
);
export const createDiscountCodeError$ = monitor(
    'createDiscountCodeError$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const createDiscountCodeInProgress$ = monitor(
    'createDiscountCodeInProgress$',
    new BehaviorSubject<boolean>(false)
);
export const deleteDiscountCodeInProgress$ = monitor(
    'deleteDiscountCodeInProgress$',
    new BehaviorSubject<boolean>(false)
);
export const deleteDiscountCodeError$ = monitor(
    'deleteDiscountCodeError$',
    new BehaviorSubject<string | undefined>(undefined)
);

createDiscountCodeAction$
    .pipe(
        map(async (request) => {
            createDiscountCodeError$.next(undefined);
            createDiscountCodeInProgress$.next(true);
            try {
                await createDiscountCode(request);
                discountCodeCreatedAction$.next();
            } catch (error) {
                const errorMessage =
                    error instanceof InformativeError || error instanceof PermissionError
                        ? error.message
                        : 'Could not create discount code';
                createDiscountCodeError$.next(errorMessage);
            }
            createDiscountCodeInProgress$.next(false);
        })
    )
    .subscribe();
