import { useObservableState, useSubscription } from 'observable-hooks';
import { OnboardingWorkspaceNamingScreen } from '../../../components/onboarding/wizard/OnboardingWorkspaceNamingScreen';
import { navigateToOnboardingStepAction$, toggleOnboardingCompletionAction$ } from '../../../store/onboarding';
import { selectedReadOnlyTemplate$ } from '../../../store/templates';
import {
    openWorkspacePreviewDialogErrors$,
    saveNewBlankWorkspaceAction$,
    savingNewBlankWorkspace$,
    workspaceCreatedAction$,
} from '../../../store/workspaces';
import { loggedInUserDetails$ } from '../../../store/user';

export const OnboardingWorkspaceNamingScreenContainer: React.FC = () => {
    const saving = useObservableState(savingNewBlankWorkspace$);
    const selectedTemplate = useObservableState(selectedReadOnlyTemplate$);
    const errors = useObservableState(openWorkspacePreviewDialogErrors$);
    const loggedInUserDetails = useObservableState(loggedInUserDetails$);

    const defaultOrganization = loggedInUserDetails?.organizations?.find((o) => o.ownedDefaultOrganization);

    useSubscription(workspaceCreatedAction$, () => {
        toggleOnboardingCompletionAction$.next(true);
    });

    return (
        <OnboardingWorkspaceNamingScreen
            errors={errors}
            name={selectedTemplate?.name}
            saving={saving}
            onGoBack={() => {
                if (selectedTemplate) {
                    navigateToOnboardingStepAction$.next({
                        route: 'template',
                        templateUid: selectedTemplate?.templateUid,
                    });
                } else {
                    navigateToOnboardingStepAction$.next({ route: 'flow' });
                }
            }}
            onSave={(name) =>
                saveNewBlankWorkspaceAction$.next({
                    description: selectedTemplate?.description,
                    organizationUid: defaultOrganization?.uid ?? '',
                    name,
                    setupGuide: selectedTemplate && 'TEMPLATE',
                    templateUid: selectedTemplate?.templateUid,
                })
            }
        />
    );
};
