import { LoaderFn } from 'react-location';
import { LocationGenerics } from '../../router';
import { getIndustryRoles, loadLoggedInUserDetails } from '../../data/user';
import { industryRoles$ } from '../../store/roles';
import { onboardingWizardError$ } from '../../store/onboarding';
import { navigateAction$ } from '../../store/navigation';
import { getBasePath } from '../../utils/path';

export const onboardingIndustryRoleSelectorLoader: LoaderFn<LocationGenerics> = async () => {
    onboardingWizardError$.next(undefined);

    try {
        const [roles] = await Promise.all([getIndustryRoles(), loadLoggedInUserDetails()]);

        industryRoles$.next(roles);
    } catch (e) {
        navigateAction$.next(getBasePath());
    }
    return {};
};
