import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';

export interface OnboardingTemplateListingQuickFilterButtonProps {
    count: number;
    icon?: JSX.Element;
    label: string;
    selected?: boolean;
    onClick: () => void;
}

const StyledQuickFilterButton = styled(ButtonBase, { shouldForwardProp: (prop) => prop !== 'selected' })<{
    selected: boolean;
}>(({ theme, selected }) => ({
    backgroundColor: selected ? theme.palette.action.selected : theme.palette.background.paper,
    border: `1px solid ${selected ? theme.palette.primary.main : theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    color: selected ? theme.palette.primary.main : undefined,
    display: 'flex',
    gap: theme.spacing(1),
    justifyContent: 'space-between',
    padding: theme.spacing(1, 1, 1, 1.5),
    textAlign: 'start',
    width: '100%',
    '& .MuiTypography-root': {
        fontWeight: selected ? theme.typography.fontWeightBold : undefined,
    },
    '& .MuiSvgIcon-root': {
        height: 16,
        width: 16,
    },
}));

const StyledQuickFilterButtonLeft = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    flexGrow: 1,
    gap: theme.spacing(1),
}));

export const OnboardingTemplateListingQuickFilterButton: React.FC<OnboardingTemplateListingQuickFilterButtonProps> = ({
    count,
    icon,
    label,
    selected = false,
    onClick,
}) => {
    return (
        <StyledQuickFilterButton selected={selected} onClick={onClick}>
            <StyledQuickFilterButtonLeft>
                {icon} <Typography>{label}</Typography>
            </StyledQuickFilterButtonLeft>
            <Typography>{count}</Typography>
        </StyledQuickFilterButton>
    );
};
