import { BehaviorSubject, Subject } from 'rxjs';
import { monitor } from './monitor';

type ConfirmDialogIcon = 'info' | 'success' | 'warning' | 'error' | JSX.Element;

interface PromptMessageOptions {
    icon?: ConfirmDialogIcon;
    messages?: (JSX.Element | string)[];
    proceedLabel?: string;
    subtitle?: string;
    title: string; // Add proceedLabel and onProceed if necessary
}

interface PromptQuestionOptions {
    cancelLabel?: string;
    icon?: ConfirmDialogIcon;
    messages?: (string | JSX.Element)[];
    proceedLabel?: string;
    subtitle?: string;
    title: string;
    onCancel?: () => void | Promise<void>;
    onClose?: () => void | Promise<void>;
    onProceed?: () => void | Promise<void>;
}

export const confirmDialogDetails$ = monitor(
    'confirmDialogDetails$',
    new BehaviorSubject<PromptQuestionOptions | undefined>(undefined)
);

export const confirmDialogOpen$ = monitor('confirmDialogOpen$', new BehaviorSubject(false));

export const openConfirmDialogAction$ = monitor('openConfirmDialogAction$', new Subject<PromptQuestionOptions>());
export const closeConfirmDialogAction$ = monitor('closeConfirmDialogAction$', new Subject<void>());

openConfirmDialogAction$.subscribe((details) => {
    confirmDialogDetails$.next(details);
    confirmDialogOpen$.next(true);
});

closeConfirmDialogAction$.subscribe(() => {
    confirmDialogOpen$.next(false);
    confirmDialogDetails$.next(undefined);
});

export const promptMessage = (options: PromptMessageOptions): void => {
    openConfirmDialogAction$.next({
        icon: options.icon,
        messages: options.messages,
        subtitle: options.subtitle,
        proceedLabel: 'Continue',
        title: options.title,
        onClose: () => {
            closeConfirmDialogAction$.next();
        },
        onProceed: () => {
            closeConfirmDialogAction$.next();
        },
    });
    confirmDialogOpen$.next(true);
};

export const promptQuestion = (options: PromptQuestionOptions): void => {
    openConfirmDialogAction$.next({
        cancelLabel: options.cancelLabel ?? 'Cancel',
        icon: options.icon,
        messages: options.messages,
        proceedLabel: options.proceedLabel ?? 'Continue',
        subtitle: options.subtitle,
        title: options.title,
        onProceed: async () => {
            closeConfirmDialogAction$.next();
            await options.onProceed?.();
        },
        onCancel: async () => {
            closeConfirmDialogAction$.next();
            await options.onCancel?.();
        },
        onClose: () => {
            closeConfirmDialogAction$.next();
        },
    });
};
