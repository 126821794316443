import { BehaviorSubject, map, Subject } from 'rxjs';
import {
    CancelOrganizationPlanRequest,
    OrganizationPlan,
    cancelOrganizationPlan,
    saveCancellationFeedback,
    SaveCancellationFeedbackRequest,
} from '../../data/organization';
import { InformativeError } from '../../utils/error';
import { monitor } from '../monitor';
import { organizationPlanChangedAction$, selectedOrganizationPlan$, selectedOrganizationUid$ } from '../organization';

export const cancelOrganizationPlanAction$ = monitor(
    'cancelOrganizationPlanAction$',
    new Subject<CancelOrganizationPlanRequest>()
);

export const closeCancelOrganizationPlanFlowDialogAction$ = monitor(
    'closeCancelOrganizationPlanFlowDialogAction$',
    new Subject<void>()
);
export const openCancelOrganizationPlanFlowDialogAction$ = monitor(
    'openCancelOrganizationPlanFlowDialogAction$',
    new Subject<void>()
);
export const cancelOrganizationPlanFlowDialogOpen$ = monitor(
    'cancelOrganizationPlanFlowDialogOpen$',
    new BehaviorSubject<boolean>(false)
);
export const cancelOrganizationPlanFlowDialogError$ = monitor(
    'cancelOrganizationPlanFlowDialogError$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const cancelOrganizationPlanFlowDialogSaving$ = monitor(
    'cancelOrganizationPlanFlowDialogSaving$',
    new BehaviorSubject<boolean>(false)
);

export const cancelOrganizationPlanSuccessStateDialogOpen$ = monitor(
    'cancelOrganizationPlanSuccessStateDialogOpen$',
    new BehaviorSubject<boolean>(false)
);

export const cancelOrganizationPlanSuccessStatePlan$ = monitor(
    'cancelOrganizationPlanSuccessStatePlan$',
    new BehaviorSubject<OrganizationPlan | undefined>(undefined)
);

export const closeCancelOrganizationPlanSuccessStateDialogAction$ = monitor(
    'closeCancelOrganizationPlanSuccessStateDialogAction$',
    new Subject<void>()
);
export const openCancelOrganizationPlanSuccessStateDialogAction$ = monitor(
    'openCancelOrganizationPlanSuccessStateDialogAction$',
    new Subject<OrganizationPlan>()
);

export const cancelOrganizationPlanFeedbackDialogOpen$ = monitor(
    'cancelOrganizationPlanFeedbackDialogOpen$',
    new BehaviorSubject<boolean>(false)
);

export const saveOrganizationPlanCancellationFeedbackAction$ = monitor(
    'saveOrganizationPlanCancellationFeedbackAction$',
    new Subject<Omit<SaveCancellationFeedbackRequest, 'organizationUid'>>()
);

export const saveOrganizationPlanCancellationFeedbackError$ = monitor(
    'saveOrganizationPlanCancellationFeedbackError$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const saveOrganizationPlanCancellationFeedbackSaving$ = monitor(
    'saveOrganizationPlanCancellationFeedbackSaving$',
    new BehaviorSubject<boolean>(false)
);

openCancelOrganizationPlanFlowDialogAction$.subscribe(() => {
    cancelOrganizationPlanFlowDialogOpen$.next(true);
    cancelOrganizationPlanFlowDialogError$.next(undefined);
    cancelOrganizationPlanFlowDialogSaving$.next(false);
});

closeCancelOrganizationPlanFlowDialogAction$.subscribe(() => {
    cancelOrganizationPlanFlowDialogOpen$.next(false);
    cancelOrganizationPlanFlowDialogError$.next(undefined);
    cancelOrganizationPlanFlowDialogSaving$.next(false);
});

cancelOrganizationPlanAction$
    .pipe(
        map(async (event) => {
            try {
                const plan = selectedOrganizationPlan$.value;
                if (plan) {
                    cancelOrganizationPlanFlowDialogSaving$.next(true);
                    cancelOrganizationPlanFlowDialogError$.next(undefined);
                    await cancelOrganizationPlan(event);
                    cancelOrganizationPlanFlowDialogSaving$.next(false);
                    organizationPlanChangedAction$.next();

                    closeCancelOrganizationPlanFlowDialogAction$.next();
                    openCancelOrganizationPlanSuccessStateDialogAction$.next(plan);
                }
            } catch (e) {
                cancelOrganizationPlanFlowDialogSaving$.next(false);
                if (e instanceof InformativeError) {
                    cancelOrganizationPlanFlowDialogError$.next(e.message);
                } else {
                    console.error('Failed to cancel plan', e);
                    cancelOrganizationPlanFlowDialogError$.next(
                        'Error occurred while cancelling plan. Please try again, if the issue persists please contact support.'
                    );
                }
            }
        })
    )
    .subscribe();

openCancelOrganizationPlanSuccessStateDialogAction$.subscribe((plan) => {
    cancelOrganizationPlanSuccessStateDialogOpen$.next(true);
    cancelOrganizationPlanSuccessStatePlan$.next(plan);
});

closeCancelOrganizationPlanSuccessStateDialogAction$.subscribe(() => {
    cancelOrganizationPlanSuccessStateDialogOpen$.next(false);
    cancelOrganizationPlanSuccessStatePlan$.next(undefined);
});

saveOrganizationPlanCancellationFeedbackAction$
    .pipe(
        map(async (event) => {
            try {
                const organizationUid = selectedOrganizationUid$.value;
                if (organizationUid) {
                    saveOrganizationPlanCancellationFeedbackSaving$.next(true);
                    saveOrganizationPlanCancellationFeedbackError$.next(undefined);
                    await saveCancellationFeedback({ ...event, organizationUid });
                    saveOrganizationPlanCancellationFeedbackSaving$.next(false);

                    cancelOrganizationPlanFeedbackDialogOpen$.next(false);
                }
            } catch (e) {
                saveOrganizationPlanCancellationFeedbackSaving$.next(false);
                if (e instanceof InformativeError) {
                    saveOrganizationPlanCancellationFeedbackError$.next(e.message);
                } else {
                    console.error('Failed to save cancellation feedback', e);
                    saveOrganizationPlanCancellationFeedbackError$.next(
                        'Error occurred while saving cancellation feedback. Please try again, if the issue persists please contact support.'
                    );
                }
            }
        })
    )
    .subscribe();
