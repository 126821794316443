import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { CustomTextFieldProps, TextField } from '../TextField';
import { useState } from 'react';
import { IconButton } from '../../buttons/IconButton';

type SecretTextFieldProps = Omit<CustomTextFieldProps, 'type'>;

export const SecretTextField: React.FC<SecretTextFieldProps> = (props) => {
    const [visible, setVisible] = useState(false);
    return (
        <TextField
            type={visible ? 'text' : 'password'}
            {...props}
            endIcon={
                <IconButton
                    aria-label={visible ? 'hide' : 'show'}
                    icon={visible ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                    tooltip={visible ? 'hide' : 'show'}
                    onClick={() => setVisible(!visible)}
                />
            }
        />
    );
};
