import { BehaviorSubject, map, Subject } from 'rxjs';
import { getEventListenerSetupDetails } from '../../../data/event-listener';
import { publishLocalFeedbackEventAction$ } from '../../feedback';
import { monitor } from '../../monitor';
import { configTopic$, stitchSession$ } from '../../config';
import { getFetchOptions } from '../../../utils/fetch';
import { SESSION_ID } from '../../..';
import { InformativeError } from '../../../utils/error';

interface AtlassianCloudEventListenerSaveTokenDetails {
    uid: string;
    environmentUid: string;
    token: string;
}

export const atlassianCloudEventListenerSetupDialogOpenAction$ = monitor(
    'atlassianCloudEventListenerSetupDialogOpenAction$',
    new Subject<AtlassianCloudEventListenerSetupBaseDetails>()
);
export const atlassianCloudEventListenerSetupDialogCloseAction$ = monitor(
    'atlassianCloudEventListenerSetupDialogCloseAction$',
    new Subject<void>()
);

export const atlassianCloudEventListenerSetupDialogLoading$ = monitor(
    'atlassianCloudEventListenerSetupDialogLoading$',
    new BehaviorSubject(false)
);
export const atlassianCloudEventListenerSetupDialogOpen$ = monitor(
    'atlassianCloudEventListenerSetupDialogOpen$',
    new BehaviorSubject(false)
);
export const atlassianCloudEventListenerSetupDialogDetails$ = monitor(
    'atlassianCloudEventListenerSetupDialogDetails$',
    new BehaviorSubject<AtlassianCloudEventListenerSetupDetails | undefined>(undefined)
);

export const atlassianCloudEventListenerSetupDialogSaveTokenAction$ = monitor(
    'atlassianCloudEventListenerSetupDialogSaveTokenAction$',
    new Subject<AtlassianCloudEventListenerSaveTokenDetails>()
);

export const atlassianCloudEventListenerSetupDialogSaving$ = monitor(
    'atlassianCloudEventListenerSetupDialogSaving$',
    new BehaviorSubject(false)
);

export const atlassianCloudEventListenerSetupDialogErrors$ = monitor(
    'atlassianCloudEventListenerSetupDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

export type AtlassianCloudEventListenerSetupBaseDetails = {
    uid: string;
    environmentUid: string;
    webhookUrl: string;
    eventTypeName: string;
    connectionType: 'Jira' | 'Confluence' | 'Jira Service Management';
};

export type AtlassianCloudEventListenerSetupDetails = {
    setupBaseUrl?: string;
    hasToken?: boolean;
} & AtlassianCloudEventListenerSetupBaseDetails;

atlassianCloudEventListenerSetupDialogOpenAction$
    .pipe(
        map(async (details) => {
            atlassianCloudEventListenerSetupDialogOpen$.next(true);
            atlassianCloudEventListenerSetupDialogLoading$.next(true);

            try {
                const { connectionBaseUrl, hasToken } = await getEventListenerSetupDetails(
                    details.uid,
                    details.environmentUid
                );

                const setupDetails: AtlassianCloudEventListenerSetupDetails = {
                    ...details,
                    hasToken,
                };
                if (connectionBaseUrl) {
                    setupDetails.setupBaseUrl = connectionBaseUrl;
                }
                atlassianCloudEventListenerSetupDialogDetails$.next(setupDetails);

                atlassianCloudEventListenerSetupDialogLoading$.next(false);
            } catch (e) {
                console.error('Error while loading Event Listener setup instructions', e);
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: `Failed to load Event Listener setup instructions, please try again, if the issue persists please contact support.`,
                    toastOptions: {
                        autoClose: false,
                    },
                });

                atlassianCloudEventListenerSetupDialogCloseAction$.next();
            }
        })
    )
    .subscribe();

atlassianCloudEventListenerSetupDialogSaveTokenAction$
    .pipe(
        map(async (details) => {
            atlassianCloudEventListenerSetupDialogSaving$.next(true);
            try {
                const baseUrl = configTopic$.value.connection?.atlassianCloud?.baseUrl;
                if (!baseUrl) {
                    throw new Error('No Atlassian Cloud Connector URL configured in meta');
                }
                const saveTokenUrl = `${baseUrl}/save-token`;

                const fetchOptions = getFetchOptions(
                    { Authorization: stitchSession$.value?.jwt ?? '', 'x-stitch-session-id': SESSION_ID },
                    details
                );
                await fetch(saveTokenUrl, fetchOptions);
                atlassianCloudEventListenerSetupDialogCloseAction$.next();
            } catch (e) {
                if (e instanceof InformativeError) {
                    atlassianCloudEventListenerSetupDialogErrors$.next(e.message);
                } else {
                    console.error('Error while saving event listener token', e);
                    atlassianCloudEventListenerSetupDialogErrors$.next(
                        'Failed to save outgoing webhook event token. Please try again, if the issue persists please contact support.'
                    );
                }
            }
            atlassianCloudEventListenerSetupDialogSaving$.next(false);
        })
    )
    .subscribe();

atlassianCloudEventListenerSetupDialogCloseAction$.subscribe(() => {
    atlassianCloudEventListenerSetupDialogOpen$.next(false);
    atlassianCloudEventListenerSetupDialogDetails$.next(undefined);
});
