import { LoaderFn } from 'react-location';
import { LocationGenerics } from '../../router';
import { loadErrorPage } from '../../store/error';
import {
    selectedWorkspace$,
    selectedWorkspaceManualScriptTriggerBundle$,
    selectedWorkspaceManualBundledScripts$,
    selectedWorkspaceUid$,
} from '../../store/workspace';
import { getWorkspace } from '../../data/workspace';
import { PermissionError } from '../../utils/error';
import { loadWorkspaceEnvironments } from '../../store/workspace/utils';

export const setupGuideCongratulationsScreenLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const workspaceUid = routeMatch.params.workspaceUid!;

    try {
        const [workspace] = await Promise.all([getWorkspace(workspaceUid), loadWorkspaceEnvironments(workspaceUid)]);

        if (!workspace.setupGuide) {
            throw new PermissionError('Setup guide not available for this workspace.');
        }

        selectedWorkspaceUid$.next(workspaceUid);
        selectedWorkspace$.next(workspace);
        selectedWorkspaceManualScriptTriggerBundle$.next(true);
        selectedWorkspaceManualBundledScripts$.next([]);
    } catch (e) {
        loadErrorPage({
            error: e,
            genericMessage: 'Failed to load congratulations screen.',
        });

        throw e;
    }

    return {};
};
