import { useEffect, useState } from 'react';
import loader from '@monaco-editor/loader';
import type * as monacoApi from 'monaco-editor/esm/vs/editor/editor.api';

export const useMonaco = (): typeof monacoApi | undefined => {
    const [monaco, setMonaco] = useState<typeof monacoApi>();

    useEffect(() => {
        if (!monaco) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            loader.init().then(setMonaco);
        }
    }, []);

    return monaco;
};
