import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import styled from '@mui/system/styled';
import React, { useState } from 'react';
import { Button } from '../../../common/buttons/Button';
import {
    ConnectionModalSecretTextField,
    ConnectionModalTextField,
} from '../../../for-deprecation/textfield/ConnectionModalTextField';
import { AtlassianCloudWizardStage, AtlassianCloudWizardSteps, StageType } from './AtlassianCloudWizardSteps';
import { OpenInNewLink } from '../../../common/OpenInNewLink';
import { ReadOnlyTextField } from '../../../for-deprecation/textfield/ReadOnlyTextField';
import { DialogAlert, DialogTitleMain } from '../../../for-deprecation/dialog/DialogComponents';
import { getAtlassianCloudIcon } from './ManageConnectionDialog';

export interface AtlassianCloudConnection {
    clientInfo: ClientInfo;
    connectionType?: string;
    open: boolean;
    onClose: () => void;
    saving: boolean;
    currentStage: AtlassianCloudWizardStage;
    setStage: (stage: AtlassianCloudWizardStage) => void;
    currentInstanceType: InstanceType;
    setInstanceType: (instanceType: InstanceType) => void;
    onSave: (props: SaveProps) => void;
    errors?: string;
    setError: (error: string) => void;
    clearErrors: () => void;
    callbackUrl: string;
    proxyAccount?: string;
}

export interface ClientInfo {
    clientId?: string;
    clientSecret?: string;
    scopes?: string;
}

interface SaveProps extends ClientInfo {
    proxyAccount?: string;
}

export type InstanceType = 'SAAS' | 'SELF-MANAGED';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(3.5),
    '&:first-of-type': {
        marginTop: theme.spacing(2),
    },
}));

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
    '& .MuiSvgIcon-root': {
        fontSize: 20,
    },
}));

const StageContent: StageType = (props) => {
    const [clientId, setClientId] = useState(props.clientInfo.clientId);
    const [clientSecret, setClientSecret] = useState(props.clientInfo.clientSecret);
    const [proxyAccount, setServiceAccount] = useState(props.proxyAccount ?? '');
    const [authorizationUrl, setAuthorizationUrl] = useState('');
    const [scopes, setScopes] = useState(props.clientInfo.scopes);
    const instanceType = props.instanceType;

    const handleChooseInstanceType = async (chosenInstanceType: InstanceType): Promise<void> => {
        if (chosenInstanceType === 'SAAS') {
            setScopes(undefined);
            setClientId(undefined);
            setClientSecret(undefined);
        }
        props.onChooseInstanceType(chosenInstanceType);
    };

    const handleSave = (): void => {
        props.onSave({
            scopes,
            clientId,
            clientSecret,
            proxyAccount,
        });
    };

    const ATLASSIAN_DEV_CONSOLE = 'https://developer.atlassian.com/console/myapps/';

    switch (props.stage) {
        case AtlassianCloudWizardStage.SPECIFY_SERVICE_ACCOUNT:
            return (
                <>
                    <DialogAlert
                        severity="info"
                        text={`If you intend to use the account that you normally use to log into Atlassian then skip this step by clicking on next`}
                    />
                    <DialogContent>
                        <DialogContentText>
                            If you want to use a service account (or not your regular account) to authorize ScriptRunner
                            Connect instead of your own account then enter the service account email or identifier
                            below. We will use this to distinguish between connectors authorized with your account
                            versus service accounts.
                        </DialogContentText>
                        <ConnectionModalTextField
                            label="Service Account Email"
                            value={proxyAccount}
                            onUpdate={(e) => {
                                if (props.errors) props.clearErrors();
                                setServiceAccount(e.target.value.trim());
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => props.onClose?.()}>
                            Close
                        </Button>
                        <Button onClick={() => props.setStage(AtlassianCloudWizardStage.CHOOSE_INSTANCE_TYPE)}>
                            Next
                        </Button>
                    </DialogActions>
                </>
            );
        case AtlassianCloudWizardStage.CHOOSE_INSTANCE_TYPE:
            return (
                <>
                    <DialogContent>
                        <StyledFormControl>
                            {instanceType === 'SAAS' && (
                                <DialogAlert
                                    severity="warning"
                                    text={`Due to technical reasons, ScriptRunner Connect app does not come with a permission to perform all actions, you should select self-managed app if you need to additional capabilities`}
                                />
                            )}
                            <DialogContentText>
                                Do you want to use the ScriptRunner Connect app or use a self-managed one?
                            </DialogContentText>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={instanceType}
                                onChange={(e) => {
                                    void handleChooseInstanceType(e.target.value as InstanceType);
                                }}
                            >
                                <StyledFormControlLabel
                                    value="SAAS"
                                    control={<Radio />}
                                    label="ScriptRunner Connect managed Atlassian Cloud app"
                                />
                                <StyledFormControlLabel
                                    value="SELF-MANAGED"
                                    control={<Radio />}
                                    label="Self-managed Atlassian Cloud app"
                                />
                            </RadioGroup>
                        </StyledFormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => props.setStage(AtlassianCloudWizardStage.SPECIFY_SERVICE_ACCOUNT)}
                        >
                            Back
                        </Button>
                        <Button
                            //eslint-disable-next-line @typescript-eslint/no-misused-promises
                            onClick={() =>
                                props.setStage(
                                    instanceType === 'SELF-MANAGED'
                                        ? AtlassianCloudWizardStage.CREATE
                                        : AtlassianCloudWizardStage.AUTHORIZE
                                )
                            }
                        >
                            Next
                        </Button>
                    </DialogActions>
                </>
            );
        case AtlassianCloudWizardStage.CREATE:
            return (
                <>
                    <DialogAlert
                        severity="info"
                        text={`If you already have an OAuth app in Atlassian, skip the steps below and click next`}
                    />
                    <DialogContent>
                        <DialogContentText component="ol">
                            <li>
                                Visit the{' '}
                                <OpenInNewLink url={ATLASSIAN_DEV_CONSOLE}>Atlassian Developer Console</OpenInNewLink>.
                            </li>
                            <li>
                                Click on the <strong>Create</strong> dropdown and select{' '}
                                <strong>OAuth 2.0 integration</strong>.
                            </li>
                            <li>Specify a name for your app and agree to Atlassian's developer terms.</li>
                            <li>
                                Click <strong>Create</strong>.
                            </li>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => props.setStage(AtlassianCloudWizardStage.CHOOSE_INSTANCE_TYPE)}
                        >
                            Back
                        </Button>
                        <Button onClick={() => props.setStage(AtlassianCloudWizardStage.CONFIGURE)}>Next</Button>
                    </DialogActions>
                </>
            );
        case AtlassianCloudWizardStage.CONFIGURE:
            return (
                <>
                    <DialogAlert
                        severity="info"
                        text={`If you already have an OAuth app in Atlassian, skip the steps below and click next`}
                    />
                    <DialogContent>
                        <DialogContentText component="ol">
                            <li>
                                On the left hand side, click on the <strong>Distribution</strong>.
                            </li>
                            <li>
                                If others will be using your app then it needs to be distributed. Otherwise, skip this
                                step. To distribute the app, click on <strong>Edit</strong> then click on{' '}
                                <strong>Sharing</strong> under <strong>Distribution Status</strong>. Fill in the rest of
                                the details on the form and click <strong>Save changes</strong>.
                            </li>
                            <li>
                                On the left hand side, click on the <strong>Permissions</strong>.
                            </li>
                            <li>
                                Add the necessary scopes for your app.{' '}
                                <i>
                                    <strong>
                                        Note that due to a limitation in Atlassian, the API cannot process more than 41
                                        scopes. It is also advisable not to mix the classical and granular scopes.
                                    </strong>
                                </i>
                            </li>
                            <li>
                                On the left hand side, click on the <strong>Authorization</strong>.
                            </li>
                            <li>
                                Click on <strong>Add</strong> next to the <strong>OAuth 2.0 (3LO)</strong> authorization
                                type.
                            </li>
                            <li>
                                Paste the URL below into the <strong>Callback URL</strong> field then click on{' '}
                                <strong>Save changes</strong>.
                            </li>
                            <ReadOnlyTextField label="Callback URL" value={props.callbackUrl} />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => props.setStage(AtlassianCloudWizardStage.CREATE)}>
                            Back
                        </Button>
                        <Button onClick={() => props.setStage(AtlassianCloudWizardStage.DETAILS)}>Next</Button>
                    </DialogActions>
                </>
            );
        case AtlassianCloudWizardStage.DETAILS:
            return (
                <>
                    <DialogContent>
                        <DialogContentText component="ol">
                            <li>
                                Whilst still in the <strong>Authorization</strong> page, copy the{' '}
                                <strong>{props.connectionType} API Authorization URL</strong> generated under{' '}
                                <strong>Authorization URL generator</strong> and paste it into the form below.
                            </li>
                            <li>
                                On the left hand side, click on the <strong>Settings</strong>.
                            </li>
                            <li>
                                Copy the <strong>Client ID</strong> into the form below.
                            </li>
                            <li>
                                Click on the <strong>Copy</strong> button next to <strong>Secret</strong> and copy the
                                value into the form below.
                            </li>
                        </DialogContentText>
                        <ConnectionModalTextField
                            label="Authorization URL"
                            value={authorizationUrl}
                            onUpdate={(e) => {
                                if (props.errors) props.clearErrors();
                                setAuthorizationUrl(e.target.value.trim());
                            }}
                            onBlur={() => {
                                if (authorizationUrl) {
                                    if (props.errors) props.clearErrors();
                                    const scopesInUrl = new URLSearchParams(authorizationUrl).get('scope');
                                    if (!scopesInUrl) {
                                        props.setError(
                                            'No scope specified in the authorization url. Please ensure you have configured some scopes and try again. If the issue persists, please contact support'
                                        );
                                        setScopes(undefined);
                                    } else {
                                        setScopes(scopesInUrl);
                                    }
                                }
                            }}
                        />
                        <ConnectionModalTextField
                            label="Client ID"
                            value={clientId}
                            onUpdate={(e) => {
                                if (props.errors) props.clearErrors();
                                setClientId(e.target.value.trim());
                            }}
                            onBlur={() => {
                                if (authorizationUrl && clientId) {
                                    if (props.errors) props.clearErrors();
                                    const clientIdInUrl = new URLSearchParams(authorizationUrl).get('client_id');
                                    if (clientId !== clientIdInUrl) {
                                        props.setError(
                                            'The Client ID entered here does not match the Client ID in the authorization url. Please ensure you have copied the correct Client ID and authorization URL and try again. If the issue persists, please contact support'
                                        );
                                    }
                                }
                            }}
                        />
                        <ConnectionModalSecretTextField
                            label="Secret"
                            value={clientSecret}
                            onUpdate={(e) => {
                                if (props.errors) props.clearErrors();
                                setClientSecret(e.target.value.trim());
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(AtlassianCloudWizardStage.CONFIGURE);
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            busy={props.saving}
                            disabled={!clientId || !clientSecret || !authorizationUrl || !scopes}
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(AtlassianCloudWizardStage.AUTHORIZE);
                            }}
                        >
                            Next
                        </Button>
                    </DialogActions>
                </>
            );
        case AtlassianCloudWizardStage.AUTHORIZE:
            return (
                <>
                    <DialogContent>
                        <DialogContentText>
                            To access information in Atlassian you need to authorize our app to be able to make requests
                            on your behalf.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(
                                    instanceType === 'SAAS'
                                        ? AtlassianCloudWizardStage.CHOOSE_INSTANCE_TYPE
                                        : AtlassianCloudWizardStage.DETAILS
                                );
                            }}
                        >
                            Back
                        </Button>
                        <Button data-pendo={'connectorAuthorised'} busy={props.saving} onClick={handleSave}>
                            Authorize
                        </Button>
                    </DialogActions>
                </>
            );
        case AtlassianCloudWizardStage.SUCCESS:
            return (
                <>
                    <DialogContentText>Success</DialogContentText>
                </>
            );
    }
};

export const AtlassianCloudAppConfigureDialog: React.FC<AtlassianCloudConnection> = (props) => {
    const error = <DialogAlert severity="error" title={props.errors} />;

    return (
        <Dialog open={props.open} onClose={() => props.onClose()}>
            <DialogTitleMain
                title="Configure Connector"
                variant="h6"
                icon={getAtlassianCloudIcon(props.connectionType)}
            />
            {props.errors && error}
            <AtlassianCloudWizardSteps stage={props.currentStage} instanceType={props.currentInstanceType} />
            <StageContent
                {...props}
                stage={props.currentStage}
                setStage={props.setStage}
                instanceType={props.currentInstanceType}
                onChooseInstanceType={props.setInstanceType}
            />
        </Dialog>
    );
};
