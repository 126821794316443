export class InformativeError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'InformativeError';
    }
}

export class PermissionError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'PermissionError';
    }
}

export class VerificationRequiredError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'VerificationRequiredError';
    }
}

export class CompilationError extends InformativeError {
    constructor(message: string) {
        super(message);
        this.name = 'CompilationError';
    }
}

export class BundlingError extends InformativeError {
    constructor(message: string) {
        super(message);
        this.name = 'BundlingError';
    }
}

export class Auth0Error extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'Auth0Error';
    }
}
