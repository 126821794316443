import { BehaviorSubject, map, Subject } from 'rxjs';
import { monitor } from './monitor';
import {
    saveIndustryRole,
    saveIndustryRoleRequest,
    saveScriptingFamiliarity,
    saveUserAppPreference,
    scriptingFamiliarityOptions,
    toggleOnboardingCompletion,
} from '../data/onboarding';
import { segmentAndPendoAnalyticsTrack } from '../data/analytics';
import { loggedInUserDetails$ } from './user';
import { OnboardingStep } from '../containers/onboarding/wizard/OnboardingContainer';
import { loadLoggedInUserDetails } from '../data/user';
import { InformativeError } from '../utils/error';
import { publishLocalFeedbackEventAction$ } from './feedback';

export const onboardingWizardSaving$ = monitor('onboardingWizardSaving$', new BehaviorSubject(false));
export const onboardingWizardError$ = monitor(
    'onboardingWizardError$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const scriptingFamiliarityOptions$ = monitor(
    'scriptingFamiliarityOptions$',
    new BehaviorSubject<scriptingFamiliarityOptions | undefined>(undefined)
);
export const onboardingStartTime$ = monitor('onboardingStartTime$', new BehaviorSubject<Date>(new Date()));

export const navigateToOnboardingStepAction$ = monitor(
    'navigateToOnboardingStepAction$',
    new Subject<{ route: OnboardingStep; templateUid?: string }>()
);
export const navigateToReadOnlyTemplateFromOnboarding$ = monitor(
    'navigateToReadOnlyTemplateFromOnboarding$',
    new Subject<string>()
);
export const saveUserIndustryRoleAction$ = monitor(
    'saveUserIndustryRoleAction$',
    new Subject<saveIndustryRoleRequest>()
);
export const saveUserScriptingFamiliarityAction$ = monitor(
    'saveUserScriptingFamiliarityAction$',
    new Subject<string>()
);
export const saveUserAppPreferenceAction$ = monitor('saveUserAppPreferenceAction$', new Subject<string[]>());
export const toggleOnboardingCompletionAction$ = monitor('toggleOnboardingCompletionAction$', new Subject<boolean>());
export const onboardingTemplateUid$ = monitor(
    'onboardingTemplateUid$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const createWorkspaceFromOnboardingTemplate$ = monitor(
    'createWorkspaceFromOnboardingTemplate$',
    new BehaviorSubject(false)
);

const genericErrorMessage = 'Failed to save details, please try again, if the issue persists please contact support.';

saveUserIndustryRoleAction$
    .pipe(
        map(async (details) => {
            onboardingWizardError$.next(undefined);
            onboardingWizardSaving$.next(true);

            try {
                await saveIndustryRole(details);
                await loadLoggedInUserDetails();

                onboardingWizardSaving$.next(false);
                navigateToOnboardingStepAction$.next({ route: 'scripting' });
            } catch (e) {
                onboardingWizardSaving$.next(false);

                if (e instanceof InformativeError) {
                    onboardingWizardError$.next(e.message);
                } else {
                    onboardingWizardError$.next(genericErrorMessage);
                    console.error('Error saving industry role', e);
                }
            }
        })
    )
    .subscribe();

saveUserScriptingFamiliarityAction$
    .pipe(
        map(async (uid) => {
            onboardingWizardError$.next(undefined);
            onboardingWizardSaving$.next(true);

            try {
                await saveScriptingFamiliarity({ scriptingFamiliarityUid: uid });
                await loadLoggedInUserDetails();

                onboardingWizardSaving$.next(false);

                navigateToOnboardingStepAction$.next({ route: 'apps' });
            } catch (e) {
                onboardingWizardSaving$.next(false);

                if (e instanceof InformativeError) {
                    onboardingWizardError$.next(e.message);
                } else onboardingWizardError$.next(genericErrorMessage);

                console.error('Error saving script familiarity', e);
            }
        })
    )
    .subscribe();

saveUserAppPreferenceAction$
    .pipe(
        map(async (apps) => {
            onboardingWizardError$.next(undefined);
            onboardingWizardSaving$.next(true);

            try {
                await saveUserAppPreference({ selectedApps: apps });
                await loadLoggedInUserDetails();
                segmentAndPendoAnalyticsTrack('Onboarding Completed', {
                    userOrigin: loggedInUserDetails$.value?.userOrigin,
                    completionTime: (new Date().getTime() - onboardingStartTime$.value?.getTime()) / 1000,
                });

                onboardingWizardSaving$.next(false);

                if (onboardingTemplateUid$.value) {
                    toggleOnboardingCompletionAction$.next(true);
                } else {
                    navigateToOnboardingStepAction$.next({ route: 'flow' });
                }
            } catch (e) {
                onboardingWizardSaving$.next(false);

                if (e instanceof InformativeError) {
                    onboardingWizardError$.next(e.message);
                } else onboardingWizardError$.next(genericErrorMessage);

                console.error('Error saving app preferences', e);
            }
        })
    )
    .subscribe();

toggleOnboardingCompletionAction$
    .pipe(
        map(async (completed) => {
            try {
                await toggleOnboardingCompletion({ completed });
                await loadLoggedInUserDetails();
                if (completed && onboardingTemplateUid$.value) {
                    navigateToReadOnlyTemplateFromOnboarding$.next(onboardingTemplateUid$.value);
                }
            } catch (e) {
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: 'Error saving onboarding progress',
                });
                console.error('Error saving onboarding progress', e);
            }
        })
    )
    .subscribe();
