import { useObservableState } from 'observable-hooks';
import { OnboardingTemplateView } from '../../../components/onboarding/wizard/OnboardingTemplateView';
import { selectedReadOnlyTemplate$ } from '../../../store/templates';
import { navigateToOnboardingStepAction$ } from '../../../store/onboarding';
import { selectedReadmeFileUid$, unsavedReadmeFileDetails$ } from '../../../store/workspace/readme';

export const OnboardingTemplateViewContainer: React.FC = () => {
    const template = useObservableState(selectedReadOnlyTemplate$);
    const selectedReadmeFileUid = useObservableState(selectedReadmeFileUid$);
    const unsavedReadmeFileDetails = useObservableState(unsavedReadmeFileDetails$);

    const readmeKey = `${selectedReadmeFileUid ?? ''}_${template?.environmentUid ?? ''}`;

    return (
        <OnboardingTemplateView
            content={unsavedReadmeFileDetails[readmeKey]?.content}
            template={{
                apps: Array.from(new Set([...(template?.incomingApps ?? []), ...(template?.outgoingApps ?? [])])),
                description: template?.description,
                name: template?.name ?? '',
            }}
            onGoBack={() => navigateToOnboardingStepAction$.next({ route: 'templates' })}
            onUseTemplate={() =>
                navigateToOnboardingStepAction$.next({ route: 'naming', templateUid: template?.templateUid })
            }
        />
    );
};
