import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { IconCircle } from '../../common/IconCircle';
import { ProductIcon } from '../../icons/ProductIcon';
import { SetupGuideSummaryBox } from '../SetupGuideSummaryBox';
import { StyledBorderBox } from '../../common/LayoutComponents';
import { SetupGuideStep, SetupGuideWorkspace } from '../types';
import { getSetupGuideStepIcon } from '../utils';

export interface SetupGuideReviewScreenSummaryProps {
    steps: SetupGuideStep[];
    workspace: SetupGuideWorkspace;
}

const StyledItem = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(2),
    maxWidth: 688,
    minHeight: 68,
    padding: theme.spacing(1.5),
    width: '100%',
}));

const StyledSummaryHeader = styled(StyledBorderBox)(({ theme }) => ({
    padding: theme.spacing(2),
    width: '100%',
}));

const StyledMediaContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(2),
    justifyContent: 'space-between',
}));

const StyledIconStepContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[700], // Need new colour in theme
    borderRadius: theme.constants.borderRadius,
    height: 44,
    justifyContent: 'center',
    width: 44,
    '& .MuiSvgIcon-root': {
        height: 24,
        width: 24,
    },
}));

const StyledTextStepContainer = styled(Box)(({ theme }) => ({
    flexGrow: 1,
    textAlign: 'start',
    '& .MuiTypography-root:first-of-type': {
        fontWeight: theme.typography.fontWeightBold,
    },
    '& .MuiTypography-root:last-of-type': {
        color: theme.palette.text.secondary,
    },
}));

const StyledIconContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
}));

const StyledSteps = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    width: '100%',
}));

export const SetupGuideReviewScreenSummary: React.FC<SetupGuideReviewScreenSummaryProps> = ({ steps, workspace }) => {
    const appNames = [...new Set([...workspace.apps.incoming, ...workspace.apps.outgoing])].join(' · ');
    const icons = [...new Set([...workspace.apps.incoming, ...workspace.apps.outgoing])].map((a) => (
        <IconCircle key={a} icon={<ProductIcon name={a} />} />
    ));

    const summaryItems = steps.map((s, i) => {
        const icon = getSetupGuideStepIcon(s.name);

        return (
            <div key={s.name}>
                <SetupGuideReviewScreenSummaryItem description={s.description} icon={icon} title={s.title} />
                {i !== steps.length - 1 && <Divider />}
            </div>
        );
    });

    return (
        <SetupGuideSummaryBox title="Summary">
            <StyledSummaryHeader>
                {icons.length > 0 && <StyledIconContainer>{icons}</StyledIconContainer>}
                <Box>
                    <Typography color="text.secondary" mb={1}>
                        {appNames}
                    </Typography>
                    <Typography sx={{ textWrap: 'auto' }} variant="subtitle1">
                        {workspace.name}
                    </Typography>
                </Box>
            </StyledSummaryHeader>
            <StyledSteps>{summaryItems}</StyledSteps>
        </SetupGuideSummaryBox>
    );
};

export const SetupGuideReviewScreenSummaryItem: React.FC<{
    description?: string;
    icon: JSX.Element;
    title: string;
}> = ({ description, icon, title }) => {
    return (
        <StyledItem key={title}>
            <StyledMediaContainer>
                <StyledIconStepContainer>{icon}</StyledIconStepContainer>
            </StyledMediaContainer>
            <StyledTextStepContainer>
                <Typography>{title}</Typography>
                <Typography>{description}</Typography>
            </StyledTextStepContainer>
        </StyledItem>
    );
};
