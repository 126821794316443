// Convert to use StyledBorderBox and add separate files for steps
import { useEffect, useState } from 'react';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import { Alert } from '../../common/alerts/Alert';
import { Button } from '../../common/buttons/Button';
import { TextField } from '../../common/inputs/TextField';
import {
    StyledOnboardingActions,
    StyledOnboardingBox,
    StyledOnboardingBoxContent,
    StyledOnboardingBoxHeader,
    StyledOnboardingOption,
    StyledOnboardingOptionGroup,
} from '../OnboardingComponents';
import { saveIndustryRoleRequest } from '../../../data/onboarding';

export interface OnboardingIndustryRoleSelectorProps {
    customRole?: string;
    errors?: string;
    roles?: {
        description?: string;
        label: string;
        uid: string;
    }[];
    roleUid?: string;
    saving?: boolean;
    onSave: (details: saveIndustryRoleRequest) => void;
}

export const OnboardingIndustryRoleSelector: React.FC<OnboardingIndustryRoleSelectorProps> = ({
    customRole = '',
    errors,
    roles = [],
    roleUid = '',
    saving = false,
    onSave,

    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const [currentRoleUid, setCurrentRoleUid] = useState(roleUid ?? '');
    const [currentCustomRole, setCurrentCustomRole] = useState(customRole ?? '');

    useEffect(() => {
        if (currentRoleUid) {
            setCurrentCustomRole('');
        }
    }, [currentRoleUid]);

    const roleOptions = roles.map((role) => (
        <StyledOnboardingOption onClick={() => setCurrentRoleUid(role.uid)} key={role.uid}>
            <FormControlLabel
                value={role.uid}
                aria-label={role.label}
                control={<Radio checked={currentRoleUid === role.uid} />}
                label={role.label}
            />
            <Typography color="text.secondary">{role.description}</Typography>
        </StyledOnboardingOption>
    ));

    const isRoleSelected = !!currentRoleUid || !!currentCustomRole;
    const canSave = isRoleSelected && !saving;

    return (
        <StyledOnboardingBox>
            <StyledOnboardingBoxHeader>
                <Typography component="h2" variant="h5">
                    What is your profession?
                </Typography>
                <Typography color="text.secondary" fontWeight="normal" variant="subtitle1">
                    Personalise your experience by providing details about yourself
                </Typography>
            </StyledOnboardingBoxHeader>
            <Divider />
            <StyledOnboardingBoxContent>
                {errors && <Alert severity="error" title={errors} />}
                <Typography variant="subtitle1">Select one of the following options</Typography>
                <StyledOnboardingOptionGroup
                    onChange={(e) => {
                        setCurrentRoleUid(e.target.value);
                    }}
                >
                    {roleOptions}
                </StyledOnboardingOptionGroup>
                <Divider>or</Divider>
                <TextField
                    label="Custom role"
                    placeholder="Enter role"
                    value={currentCustomRole}
                    onChange={(e) => setCurrentCustomRole(e.target.value)}
                    onFocus={() => setCurrentRoleUid('')}
                />
            </StyledOnboardingBoxContent>
            <Divider />
            <StyledOnboardingActions sx={{ justifyContent: 'flex-end' }}>
                <Button
                    busy={saving}
                    data-pendo="professionSubmitted"
                    disabled={!canSave}
                    onClick={() => onSave({ customRole: currentCustomRole, roleUid: currentRoleUid })}
                >
                    Next
                </Button>
            </StyledOnboardingActions>
        </StyledOnboardingBox>
    );
};
