import {
    Box,
    Button,
    FormControl,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { UsersUtilizationRequest, UsersUtilizationData } from '../../data/report/usersUtilization';
import { formatBytesToString } from '../../utils/miscellaneous';
import { PageContainer } from '../app-main/PageComponentsForDeprecation';

export interface UsersUtilizationPageProps extends UsersUtilizationRequest {
    data?: UsersUtilizationData;
    onSearch(timePeriod: string): void;
}

export const UsersUtilizationPage: React.FC<UsersUtilizationPageProps> = ({
    data,
    timePeriod = `${new Date().getFullYear()}.${new Date().getMonth() + 1}`,
    onSearch,
}) => {
    const [timePeriodFilterValue, setTimePeriodFilterValue] = useState('');

    useEffect(() => {
        setTimePeriodFilterValue(timePeriod);
    }, []);

    // Ugly hack to make sure the router does not treat the time period as a number
    const handleSearch = (): void => onSearch(`d${timePeriodFilterValue}`);

    return (
        <PageContainer sx={{ bgcolor: 'background.paper' }}>
            <Box pb={3}>
                <Typography variant="h3">Users Utilization</Typography>
            </Box>
            <Box
                sx={{
                    '& .MuiFormControl-root': { m: 1 },
                }}
            >
                <div>
                    <FormControl size="small">
                        <TextField
                            label="Time Period"
                            variant="outlined"
                            value={timePeriodFilterValue}
                            onChange={(event) => setTimePeriodFilterValue(event.target.value)}
                        />
                    </FormControl>
                </div>
                <div>
                    <Button variant="contained" onClick={handleSearch} sx={{ m: 2 }}>
                        Search
                    </Button>
                </div>
            </Box>
            {data && (
                <>
                    <Box sx={{ paddingLeft: 2 }}>
                        <Typography>Total Invocations: {data.totalInvocations}</Typography>
                        <Typography>Total GB Seconds: {data.totalGbSeconds}</Typography>
                        <Typography>Users: {data.users.length}</Typography>
                        {data.costs.map((c, i) => (
                            <Typography key={i}>
                                Cost for {c.service}: {c.amount}
                            </Typography>
                        ))}
                        <Typography>Total Cost: {data.totalCost}</Typography>
                        <Typography>Cost per Invocation: {data.costPerInvocation}</Typography>
                        <Typography>Cost per GB Second: {data.costPerGbSecond}</Typography>
                        <Typography>Currency: {data.costUnit}</Typography>
                    </Box>
                    {data.users.length > 0 ? (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>User ID</TableCell>
                                    <TableCell>First Name</TableCell>
                                    <TableCell>Last Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Script Invocations</TableCell>
                                    <TableCell>Script GB Seconds</TableCell>
                                    <TableCell>AVG GB Seconds</TableCell>
                                    <TableCell>Record Storage Capacity</TableCell>
                                    <TableCell>Cost (Invocations)</TableCell>
                                    <TableCell>Cost (GB Seconds)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.users.map((user) => (
                                    <TableRow id={user.details.uid} key={user.details.uid}>
                                        <TableCell>{user.details.uid}</TableCell>
                                        <TableCell>{user.details.firstName}</TableCell>
                                        <TableCell>{user.details.lastName}</TableCell>
                                        <TableCell>{user.details.email}</TableCell>
                                        <TableCell>{user.utilization.script.invocations}</TableCell>
                                        <TableCell>{user.utilization.script.gbSeconds}</TableCell>
                                        <TableCell>{user.utilization.script.avgGbSeconds}</TableCell>
                                        <TableCell>{formatBytesToString(user.recordStorageCapacity, 'B')}</TableCell>
                                        <TableCell>{user.cost.invocation}</TableCell>
                                        <TableCell>{user.cost.gbSeconds}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    ) : (
                        <div>No Users found</div>
                    )}
                </>
            )}
        </PageContainer>
    );
};
