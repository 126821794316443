import { useState } from 'react';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import { Alert } from '../../common/alerts/Alert';
import { Button } from '../../common/buttons/Button';
import {
    StyledOnboardingActions,
    StyledOnboardingBox,
    StyledOnboardingBoxContent,
    StyledOnboardingBoxHeader,
    StyledOnboardingOption,
    StyledOnboardingOptionGroup,
} from '../OnboardingComponents';

interface OnboardingScriptingFamiliaritySelectorProps {
    errors?: string;
    saving?: boolean;
    scriptingFamiliarityOptions?: {
        description?: string;
        label: string;
        uid: string;
    }[];
    selectedScriptingFamiliarityUid?: string;
    onGoBack: () => void;
    onSave: (familiarityUid: string) => void;
}

export const OnboardingScriptingFamiliaritySelector: React.FC<OnboardingScriptingFamiliaritySelectorProps> = ({
    errors,
    saving = false,
    scriptingFamiliarityOptions = [],
    selectedScriptingFamiliarityUid = '',
    onGoBack,
    onSave,
}) => {
    const [currentFamiliarity, setCurrentFamiliarity] = useState(selectedScriptingFamiliarityUid);

    const canSave = !!currentFamiliarity && !saving;

    const codingLevelOptions = scriptingFamiliarityOptions.map((option) => (
        <StyledOnboardingOption key={option.uid} onClick={() => setCurrentFamiliarity(option.uid)}>
            <FormControlLabel
                control={<Radio checked={currentFamiliarity === option.uid} />}
                label={option.label}
                value={option.uid}
            />
            <Typography color="text.secondary">{option.description}</Typography>
        </StyledOnboardingOption>
    ));

    return (
        <StyledOnboardingBox>
            <StyledOnboardingBoxHeader>
                <Typography component="h2" variant="h5">
                    How well do you know JavaScript?
                </Typography>
                <Typography color="text.secondary" fontWeight="normal" variant="subtitle1">
                    SR Connect uses JavaScript to build integration business logic
                </Typography>
            </StyledOnboardingBoxHeader>
            <Divider />
            <StyledOnboardingBoxContent>
                {errors && <Alert severity="error" title={errors} />}
                <Typography variant="subtitle1">Select one of the following options</Typography>
                <StyledOnboardingOptionGroup onChange={(e) => setCurrentFamiliarity(e.target.value)}>
                    {codingLevelOptions}
                </StyledOnboardingOptionGroup>
            </StyledOnboardingBoxContent>
            <Divider />
            <StyledOnboardingActions>
                <Button variant="text" onClick={() => onGoBack()}>
                    Back
                </Button>
                <Button
                    busy={saving}
                    data-pendo="scriptingLevelSubmitted"
                    disabled={!canSave}
                    onClick={() => onSave(currentFamiliarity)}
                >
                    Next
                </Button>
            </StyledOnboardingActions>
        </StyledOnboardingBox>
    );
};
