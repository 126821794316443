import ReactHtmlParser from 'react-html-parser';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import TerminalOutlinedIcon from '@mui/icons-material/TerminalOutlined';
import { Button } from '../../common/buttons/Button';
import { IconCircle } from '../../common/IconCircle';
import { ProductIcon } from '../../icons/ProductIcon';
import { StyledBorderBoxContent } from '../../common/LayoutComponents';
import {
    StyledTemplateBox,
    StyledTemplateBoxContentRight,
    StyledTemplateBoxHeaderRight,
    StyledTemplateBoxLeftSide,
    StyledTemplateBoxRightSide,
} from '../OnboardingComponents';
import { getSanitizedParsedMarkdown } from '../../../utils/readme';
import {
    documentationUrl,
    scriptingDocumentationUrl,
    supportPortalUrl,
    youtubeChannelUrl,
} from '../../../utils/documentation';

export interface OnboardingTemplateViewProps {
    content?: string;
    template: {
        apps: string[];
        description?: string;
        name: string;
    };
    onGoBack(): void;
    onUseTemplate(): void;
}

const StyledSideNavigation = styled(StyledBorderBoxContent)(() => ({
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '100%',
}));

const StyledReadmeContent = styled(Box)(() => ({
    flexGrow: 1,
    flexShrink: 1,
    overflow: 'auto',
}));

const StyledActions = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(2),
    justifyContent: 'flex-end',
    padding: theme.spacing(2),
}));

const StyledIconContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(1),
}));

const StyledList = styled(List)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    padding: 0,
}));

const StyledListItem = styled(ListItem)(() => ({
    padding: 0,
}));

// Might be worth adding a common component soon
const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.constants.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    gap: theme.spacing(1),
    height: 40,
    justifyContent: 'flex-start',
    width: '100%',
    padding: theme.spacing(1.25),
    '& .MuiSvgIcon-root': {
        height: 24,
        width: 24,
    },
})) as typeof ListItemButton; // Error with MUI types passing the component prop. Solution: https://github.com/mui/material-ui/issues/13921#issuecomment-484133463

export const OnboardingTemplateView: React.FC<OnboardingTemplateViewProps> = ({
    content = '',
    template,
    onGoBack,
    onUseTemplate,
}) => {
    const icons = template.apps.map((a) => (
        <IconCircle size="medium" key={a} icon={<ProductIcon name={a} />} severity="grey" />
    ));
    const appNames = template.apps.length > 0 && (
        <Typography color="text.secondary">{template.apps.join(' · ')}</Typography>
    );

    return (
        <StyledTemplateBox>
            <StyledTemplateBoxLeftSide>
                <StyledSideNavigation>
                    <Button size="small" startIcon={<ArrowBackOutlinedIcon />} variant="text" onClick={onGoBack}>
                        Back
                    </Button>
                    <Box width="100%">
                        <Typography variant="subtitle1" mb={2}>
                            Having trouble with a template?
                        </Typography>
                        <StyledList>
                            <StyledListItem>
                                <StyledListItemButton
                                    component="a"
                                    data-pendo="videos"
                                    href={youtubeChannelUrl}
                                    target="_blank"
                                >
                                    <Tooltip title="Video tutorials">
                                        <OndemandVideoOutlinedIcon />
                                    </Tooltip>
                                    <Typography>Video tutorials</Typography>
                                </StyledListItemButton>
                            </StyledListItem>
                            <StyledListItem>
                                <StyledListItemButton
                                    component="a"
                                    data-pendo="docs"
                                    href={documentationUrl}
                                    target="_blank"
                                >
                                    <Tooltip title="Documentation">
                                        <MenuBookOutlinedIcon />
                                    </Tooltip>
                                    <Typography>Documentation</Typography>
                                </StyledListItemButton>
                            </StyledListItem>
                            <StyledListItem>
                                <StyledListItemButton
                                    component="a"
                                    data-pendo="scriptingHelp"
                                    href={scriptingDocumentationUrl}
                                    target="_blank"
                                >
                                    <Tooltip title="Need help scripting?">
                                        <TerminalOutlinedIcon />
                                    </Tooltip>
                                    <Typography>Need help scripting?</Typography>
                                </StyledListItemButton>
                            </StyledListItem>
                            <StyledListItem>
                                <StyledListItemButton
                                    component="a"
                                    data-pendo="support"
                                    href={supportPortalUrl}
                                    target="_blank"
                                >
                                    <Tooltip title="Contact support">
                                        <QuestionAnswerOutlinedIcon />
                                    </Tooltip>
                                    <Typography>Contact support</Typography>
                                </StyledListItemButton>
                            </StyledListItem>
                        </StyledList>
                    </Box>
                </StyledSideNavigation>
            </StyledTemplateBoxLeftSide>
            <StyledTemplateBoxRightSide>
                <StyledTemplateBoxHeaderRight sx={{ flex: 0 }}>
                    {icons.length > 0 && <StyledIconContainer>{icons}</StyledIconContainer>}
                    <Box>
                        {appNames}
                        <Typography mb={1} variant="h6">
                            {template.name}
                        </Typography>
                        <Typography fontWeight="normal" variant="subtitle1">
                            {template.description}
                        </Typography>
                    </Box>
                </StyledTemplateBoxHeaderRight>
                <Divider />
                <StyledTemplateBoxContentRight>
                    <StyledReadmeContent tabIndex={0}>
                        {ReactHtmlParser(getSanitizedParsedMarkdown(content))}
                    </StyledReadmeContent>
                </StyledTemplateBoxContentRight>
                <Divider />
                <StyledActions>
                    <Button onClick={onUseTemplate} data-pendo={'createWorkspaceFromTemplate'}>
                        Use template
                    </Button>
                </StyledActions>
            </StyledTemplateBoxRightSide>
        </StyledTemplateBox>
    );
};
