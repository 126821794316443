import {
    Box,
    Button,
    FormControl,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import {
    OrganisationsUtilizationRequest,
    OrganisationsUtilizationData,
} from '../../data/report/organisationsUtilization';
import { formatBytesToString } from '../../utils/miscellaneous';
import { PageContainer } from '../app-main/PageComponentsForDeprecation';

type UserType = 'all' | 'adaptavist' | 'non-adaptavist' | 'non-stitch' | 'monday';

export interface OrganisationsUtilizationPageProps extends OrganisationsUtilizationRequest {
    data?: OrganisationsUtilizationData;
    onSearch(timePeriod: string, userType: UserType): void;
}

export const OrganisationsUtilizationPage: React.FC<OrganisationsUtilizationPageProps> = ({
    data,
    timePeriod = `${new Date().getFullYear()}.${new Date().getMonth() + 1}`,
    userType = 'all',
    onSearch,
}) => {
    const [timePeriodFilterValue, setTimePeriodFilterValue] = useState('');
    const [userTypeFilterValue, setUserTypeFilterValue] = useState<UserType>('all');

    useEffect(() => {
        setTimePeriodFilterValue(timePeriod);
        setUserTypeFilterValue(userType);
    }, []);

    // Ugly hack to make sure the router does not treat the time period as a number
    const handleSearch = (): void => onSearch(`d${timePeriodFilterValue}`, userTypeFilterValue);

    return (
        <PageContainer sx={{ bgcolor: 'background.paper' }}>
            <Box pb={3}>
                <Typography variant="h3">Teams Utilization</Typography>
            </Box>
            <Box
                sx={{
                    '& .MuiFormControl-root': { m: 1 },
                }}
            >
                <div>
                    <FormControl size="small">
                        <TextField
                            label="Time Period"
                            variant="outlined"
                            value={timePeriodFilterValue}
                            onChange={(event) => setTimePeriodFilterValue(event.target.value)}
                        />
                    </FormControl>
                </div>
                <div>
                    <FormControl>
                        <TextField
                            select
                            variant="outlined"
                            label="Filter by User Type"
                            value={userTypeFilterValue}
                            onChange={(event) => setUserTypeFilterValue(event.target.value as UserType)}
                        >
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="non-adaptavist">Non-Adaptavist</MenuItem>
                            <MenuItem value="adaptavist">Adaptavist Only</MenuItem>
                            <MenuItem value="non-stitch">Non-Stitch</MenuItem>
                            <MenuItem value="monday">monday.com</MenuItem>
                        </TextField>
                    </FormControl>
                </div>
                <div>
                    <Button variant="contained" onClick={handleSearch} sx={{ m: 2 }}>
                        Search
                    </Button>
                </div>
            </Box>
            {data && (
                <>
                    <Box sx={{ paddingLeft: 2 }}>
                        <Typography>Total Invocations: {data.totalInvocations}</Typography>
                        <Typography sx={{ paddingLeft: 2 }}>
                            Script Invocations: {data.totalScriptInvocations}
                        </Typography>
                        <Typography sx={{ paddingLeft: 2 }}>
                            Denied Invocations: {data.totalDeniedInvocations}
                        </Typography>
                        <br />
                        <Typography>Total GB Seconds: {data.totalGbSeconds}</Typography>
                        <Typography>Total teams: {data.totalOrganisationCount}</Typography>
                        <Typography>Percent active: {data.percentActive}%</Typography>
                        {data.costs.map((c, i) => (
                            <Typography key={i}>
                                Cost for {c.service}: {c.amount}
                            </Typography>
                        ))}
                        <Typography>Total Cost: {data.totalCost}</Typography>
                        <Typography>Cost per Invocation: {data.costPerInvocation}</Typography>
                        <Typography>Cost per GB Second: {data.costPerGbSecond}</Typography>
                        <Typography>Currency: {data.costUnit}</Typography>
                        <br />
                        {data.organisations.length !== 0 && (
                            <Typography>Teams found: {data.organisations.length}</Typography>
                        )}
                    </Box>
                    {data.organisations.length > 0 ? (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Team ID</TableCell>
                                    <TableCell>Team Name</TableCell>
                                    <TableCell>Created By</TableCell>
                                    <TableCell>Billing Contact</TableCell>
                                    <TableCell>Plan</TableCell>
                                    <TableCell>Total Script Invocations</TableCell>
                                    <TableCell>Script Invocations</TableCell>
                                    <TableCell>Denied Script Invocations</TableCell>
                                    <TableCell>Script GB Seconds</TableCell>
                                    <TableCell>AVG GB Seconds</TableCell>
                                    <TableCell>Record Storage Capacity</TableCell>
                                    <TableCell>Cost (Invocations)</TableCell>
                                    <TableCell>Cost (GB Seconds)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.organisations.map((organisation) => {
                                    const billingEntityEmail = organisation.details.billingEntity.email
                                        ? ` (${organisation.details.billingEntity.email})`
                                        : '';

                                    return (
                                        <TableRow id={organisation.details.uid} key={organisation.details.uid}>
                                            <TableCell>{organisation.details.uid}</TableCell>
                                            <TableCell>{organisation.details.name}</TableCell>
                                            <TableCell>{organisation.details.owner.name}</TableCell>
                                            <TableCell>{`${
                                                organisation.details.billingEntity.name ?? ''
                                            }${billingEntityEmail}`}</TableCell>
                                            <TableCell>{organisation.details.planName ?? ''}</TableCell>
                                            <TableCell>
                                                {organisation.utilization.script.invocations +
                                                    organisation.utilization.script.deniedInvocations}
                                            </TableCell>
                                            <TableCell>{organisation.utilization.script.invocations}</TableCell>
                                            <TableCell>{organisation.utilization.script.deniedInvocations}</TableCell>
                                            <TableCell>{organisation.utilization.script.gbSeconds}</TableCell>
                                            <TableCell>{organisation.utilization.script.avgGbSeconds}</TableCell>
                                            <TableCell>
                                                {formatBytesToString(organisation.recordStorageCapacity, 'B')}
                                            </TableCell>
                                            <TableCell>{organisation.cost.invocation}</TableCell>
                                            <TableCell>{organisation.cost.gbSeconds}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    ) : (
                        <div>No Teams found</div>
                    )}
                </>
            )}
        </PageContainer>
    );
};
