import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import React, { useState } from 'react';
import { Button } from '../../../common/buttons/Button';
import { TempoIcon } from '../../../icons/product-icons/TempoIcon';
import { OpenInNewLink } from '../../../common/OpenInNewLink';
import {
    ConnectionModalTextField,
    ConnectionModalSecretTextField,
} from '../../../for-deprecation/textfield/ConnectionModalTextField';
import { ReadOnlyTextField } from '../../../for-deprecation/textfield/ReadOnlyTextField';
import { TempoCloudWizardStage, TempoCloudWizardSteps, StageType } from './TempoCloudWizardSteps';
import { DialogAlert, DialogTitleMain } from '../../../for-deprecation/dialog/DialogComponents';

export interface TempoCloudClientInfo {
    instanceUrl: string;
    clientId: string;
    clientSecret: string;
}

export interface TempoCloudConnection {
    clientInfo: TempoCloudClientInfo;
    open: boolean;
    onClose: () => void;
    saving: boolean;
    currentStage: TempoCloudWizardStage;
    setStage: (stage: TempoCloudWizardStage) => void;
    onSave: (props: onSaveProps) => void;
    errors?: string;
    setError: (error: string) => void;
    clearErrors: () => void;
    callbackUrl: string;
}

export interface onSaveProps {
    instanceUrl: string;
    clientId: string;
    clientSecret: string;
}

const testUrl = async (instanceUrl: string): Promise<boolean> => {
    try {
        const url = new URL(instanceUrl);
        await fetch(instanceUrl, {
            method: 'GET',
            mode: 'no-cors',
        });
        return url.protocol === 'http:' || url.protocol === 'https:';
    } catch {
        return false;
    }
};

const StageContent: StageType = (props) => {
    const [instanceUrl, setInstanceUrl] = useState(props.clientInfo.instanceUrl);
    const [clientId, setClientId] = useState(props.clientInfo.clientId);
    const [clientSecret, setClientSecret] = useState(props.clientInfo.clientSecret);

    const [badUrlAttempted, setBadUrlAttempted] = useState<boolean>(false);

    const TEMPO_CLOUD_PATH = '/plugins/servlet/ac/io.tempo.jira/tempo-app#!/configuration/identity-service';

    const generateSettingsUrl = (): string => {
        return instanceUrl.endsWith(TEMPO_CLOUD_PATH)
            ? instanceUrl
            : instanceUrl.endsWith('/')
            ? instanceUrl.substring(0, instanceUrl.length - 1) + TEMPO_CLOUD_PATH
            : instanceUrl + TEMPO_CLOUD_PATH;
    };

    switch (props.stage) {
        case TempoCloudWizardStage.ADDURL:
            return (
                <>
                    <DialogContentText>This connector requires Jira Cloud instance url.</DialogContentText>
                    <ConnectionModalTextField
                        label="Enter the Jira Cloud instance URL"
                        value={instanceUrl}
                        onUpdate={(e) => {
                            if (badUrlAttempted) {
                                props.clearErrors();
                                setBadUrlAttempted(false);
                            }
                            setInstanceUrl(e.target.value);
                        }}
                    />
                    <DialogActions>
                        <Button variant="outlined" onClick={() => props.onClose?.()}>
                            Close
                        </Button>
                        <Button
                            //eslint-disable-next-line @typescript-eslint/no-misused-promises
                            onClick={async () => {
                                if (await testUrl(instanceUrl)) {
                                    setInstanceUrl(instanceUrl);
                                    props.setStage(TempoCloudWizardStage.CREATE);
                                    setBadUrlAttempted(false);
                                } else {
                                    props.setError('Please enter a valid URL starting with "https://"');
                                    setBadUrlAttempted(true);
                                }
                            }}
                            disabled={!instanceUrl}
                        >
                            Next
                        </Button>
                    </DialogActions>
                </>
            );
        case TempoCloudWizardStage.CREATE:
            return (
                <>
                    <DialogAlert
                        severity="info"
                        text="If you already have an application, skip the steps below and click next"
                    />
                    <DialogContent>
                        <DialogContentText component="ol">
                            <li>
                                Visit the <OpenInNewLink url={generateSettingsUrl()}>Tempo settings</OpenInNewLink> page
                                in Jira and click <strong>New Application</strong>.
                            </li>
                            <li>Copy the values below into the form.</li>
                            <li>
                                Click <strong>Submit</strong>.
                            </li>

                            <ReadOnlyTextField label="Name" value="ScriptRunnerConnect" />
                            <ReadOnlyTextField label="Redirect URL" value={props.callbackUrl} />
                            <ReadOnlyTextField label="Client type" value="Public" />
                            <ReadOnlyTextField label="Authorization grant type" value="Authorization code" />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => props.setStage(TempoCloudWizardStage.ADDURL)}>
                            Back
                        </Button>
                        <Button onClick={() => props.setStage(TempoCloudWizardStage.DETAILS)}>Next</Button>
                    </DialogActions>
                </>
            );
        case TempoCloudWizardStage.DETAILS:
            return (
                <>
                    <DialogContent>
                        <DialogContentText>
                            Copy the <strong>Client ID</strong> and <strong>Client secret</strong> into the form below.{' '}
                            <br />
                            <strong>NOTE:</strong> These credentials will be stored securely in our platform.
                        </DialogContentText>
                        <ConnectionModalTextField
                            label="Client ID"
                            value={clientId}
                            onUpdate={(e) => {
                                if (props.errors) props.clearErrors();
                                setClientId(e.target.value.trim());
                            }}
                        />
                        <ConnectionModalSecretTextField
                            label="Client Secret"
                            value={clientSecret}
                            onUpdate={(e) => {
                                if (props.errors) props.clearErrors();
                                setClientSecret(e.target.value.trim());
                            }}
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(TempoCloudWizardStage.CREATE);
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            busy={props.saving}
                            disabled={!clientId || !clientSecret}
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(TempoCloudWizardStage.AUTHORIZE);
                            }}
                        >
                            Next
                        </Button>
                    </DialogActions>
                </>
            );
        case TempoCloudWizardStage.AUTHORIZE:
            return (
                <>
                    <DialogContent>
                        <DialogContentText>
                            To access information in Jira you need to authorize ScriptRunner Connect to be able to make
                            requests on your behalf.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(TempoCloudWizardStage.DETAILS);
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            data-pendo={'connectorAuthorised'}
                            busy={props.saving}
                            disabled={!clientId || !clientSecret}
                            onClick={() =>
                                props.onSave({
                                    clientId,
                                    clientSecret,
                                    instanceUrl,
                                })
                            }
                        >
                            Authorize
                        </Button>
                    </DialogActions>
                </>
            );
        case TempoCloudWizardStage.SUCCESS:
            return (
                <>
                    <DialogContentText>Success</DialogContentText>
                </>
            );
    }
};

export const TempoCloudConnectionAppConfigureDialog: React.FC<TempoCloudConnection> = (props) => {
    const error = <DialogAlert severity="error" title={props.errors} />;
    return (
        <Dialog open={props.open} onClose={() => props.onClose()}>
            <DialogTitleMain title="Configure Connector" variant="h6" icon={<TempoIcon />} />
            {props.errors && error}
            <TempoCloudWizardSteps stage={props.currentStage} />
            <StageContent {...props} stage={props.currentStage} setStage={props.setStage} />
        </Dialog>
    );
};
