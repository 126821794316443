import {
    atlassianCloudInstanceSelectionCloseDialogAction$,
    atlassianCloudInstanceSelectionInstances$,
    atlassianCloudInstanceSelectionOpen$,
} from '../store/connection/atlassian-cloud/manage-connection';
import {
    atlassianCloudManageConnectionAuthorizeLoading$,
    atlassianCloudManageConnectionDetails$,
} from '../store/connection/atlassian-cloud/manage-connection';
import {
    bitbucketCloudAppSetupCloseDialogAction$,
    bitbucketCloudAppSetupDialogStage$,
    bitbucketCloudConnectionSavedAction$,
    bitbucketCloudManageConnectionAuthorizeLoading$,
    bitbucketCloudManageConnectionDetails$,
} from '../store/connection/bitbucket-cloud/setup-connection';
import {
    githubConnectionSavedAction$,
    githubManageConnectionDetails$,
} from '../store/connection/github/authorize-connection';
import {
    gitlabConnectionSavedAction$,
    gitlabAppSetupCloseDialogAction$,
    gitlabManageConnectionAuthorizeLoading$,
    gitlabManageConnectionDetails$,
    gitlabAppSetupDialogStage$,
} from '../store/connection/gitlab/setup-connection';
import {
    googleConnectionSavedAction$,
    googleManageConnectionDetails$,
} from '../store/connection/google/authorize-connection';
import {
    atlassianOnPremiseManageConnectionDetails$,
    atlassianOnPremiseAppSetupCloseDialogAction$,
    atlassianOnPremiseAppSetupDialogStage$,
    atlassianOnPremiseConnectionSavedAction$,
    atlassianOnPremiseManageConnectionAuthorizeLoading$,
} from '../store/connection/atlassian-on-premise/setup-connection';
import {
    mondayAppSetupCloseDialogAction$,
    mondayAppSetupDialogStage$,
    mondayConnectionSavedAction$,
    mondayManageConnectionAuthorizeLoading$,
    mondayManageConnectionDetails$,
} from '../store/connection/monday/setup-connection';
import {
    serviceNowConnectionSavedAction$,
    serviceNowAppSetupCloseDialogAction$,
    serviceNowManageConnectionAuthorizeLoading$,
    serviceNowManageConnectionDetails$,
    serviceNowAppSetupDialogStage$,
} from '../store/connection/servicenow/setup-connection';
import {
    zenDeskConnectionSavedAction$,
    zenDeskAppSetupCloseDialogAction$,
    zenDeskManageConnectionAuthorizeLoading$,
    zenDeskManageConnectionDetails$,
    zenDeskAppSetupDialogStage$,
} from '../store/connection/zendesk/setup-connection';
import {
    bitbucketOnPremiseConnectionSavedAction$,
    bitbucketOnPremiseAppSetupCloseDialogAction$,
    bitbucketOnPremiseManageConnectionAuthorizeLoading$,
    bitbucketOnPremiseManageConnectionDetails$,
    bitbucketOnPremiseAppSetupDialogStage$,
} from '../store/connection/bitbucket-on-premise/setup-connection';
import {
    tempoCloudConnectionSavedAction$,
    tempoCloudAppSetupCloseDialogAction$,
    tempoCloudManageConnectionAuthorizeLoading$,
    tempoCloudManageConnectionDetails$,
    tempoCloudAppSetupDialogStage$,
} from '../store/connection/tempo-cloud/setup-connection';
import {
    netSuiteConnectionSavedAction$,
    netSuiteAppSetupCloseDialogAction$,
    netSuiteManageConnectionAuthorizeLoading$,
    netSuiteManageConnectionDetails$,
    netSuiteAppSetupDialogStage$,
} from '../store/connection/netsuite/setup-connection';
import {
    microsoftAppSetupCloseDialogAction$,
    microsoftAppSetupDialogStage$,
    microsoftConnectionSavedAction$,
    microsoftManageConnectionAuthorizeLoading$,
    microsoftManageConnectionDetails$,
} from '../store/connection/microsoft/setup-connection';
import {
    azureDevopsAppSetupCloseDialogAction$,
    azureDevopsAppSetupDialogStage$,
    azureDevopsConnectionSavedAction$,
    azureDevopsManageConnectionAuthorizeLoading$,
    azureDevopsManageConnectionDetails$,
} from '../store/connection/azure-devops/setup-connection';
import {
    zoomAppSetupCloseDialogAction$,
    zoomManageConnectionAuthorizeLoading$,
    zoomConnectionSavedAction$,
    zoomManageConnectionDetails$,
    zoomAppSetupDialogStage$,
} from '../store/connection/zoom/setup-connection';
import { publishLocalFeedbackEventAction$ } from '../store/feedback';
import { FeedbackInterceptor } from './types';
import { workspaceHasUnsavedChanges } from '../store/workspace/utils';
import { selectedWorkspace$, selectedWorkspaceUid$ } from '../store/workspace';
import {
    salesforceAppSetupCloseDialogAction$,
    salesforceAppSetupDialogStage$,
    salesforceConnectionSavedAction$,
    salesforceManageConnectionAuthorizeLoading$,
    salesforceManageConnectionDetails$,
} from '../store/connection/salesforce/setup-connection';
import {
    aiAssistanceAnswering$,
    aiAssistanceAvailableCredits$,
    aiAssistanceConversationUid$,
    aiAssistanceResponseMessageUid$,
    createAiAssistanceGeneratedMessageAction$,
} from '../store/workspace/ai-assistance';
import {
    statuspageConnectionSavedAction$,
    statuspageManageConnectionAuthorizeLoading$,
    statuspageManageConnectionDetails$,
} from '../store/connection/statuspage/setup-connection';
import { promptMessage } from '../store/confirm';
import {
    closeCreateOrganizationWizardDialogAction$,
    createOrganizationValidationError$,
    createOrganizationWizardInviteEmails$,
    createOrganizationWizardSaving$,
    organizationCreatedAction$,
    sendOrganizationInvitesAction$,
} from '../store/organizations';
import {
    selectedOrganizationUid$,
    organizationPlanChangedAction$,
    selectedOrganizationPlan$,
} from '../store/organization';
import {
    changeOrganizationPlanConfirmPurchaseDialogError$,
    changeOrganizationPlanConfirmPurchaseDialogLoading$,
    closeChangeOrganizationPlanConfirmPurchaseDialogAction$,
    closeChangeOrganizationPlanWizardDialogAction$,
    openOrganizationPlanSuccessStateDialogAction$,
    organizationPlanUpdating$,
} from '../store/organization/changeOrganizationPlan';
import {
    closeSelectBillingDetailsDialogAction$,
    refreshBillingEntitiesAction$,
    selectBillingDetailsDialogOpen$,
    selectBillingDetailsDialogSaving$,
    selectBillingDetailsError$,
} from '../store/billing-details';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { IconCircle } from '../components/common/IconCircle';
import { WorkspaceLockDetails } from '@avst-stitch/repository-lib/lib/types';

const WRONG_CONNECTION_TYPE = 'Wrong Connector type';
const WRONG_CONNECTION_SELECTED = 'Selected Connector is wrong';
const WRONG_ORGANIZATION_SELECTED = 'Selected Team is wrong';
const AUTHORIZATION_FAILED = 'Authorization failed. Please try again, if the issue persists please contact support.';

const createAiAssistanceResponseInterceptor = (): FeedbackInterceptor<{
    message: string;
    index: number;
    isLast?: boolean;
    id: string;
    finish_reason?: string;
    conversationUid: string;
    availableCredits: number;
    responseMessageUid: string;
}> => ({
    type: 'AIAssistanceResponse',
    processEvent: async (event) => {
        const payload = event.payload;

        if (payload && payload.isLast) {
            aiAssistanceAvailableCredits$.next(payload.availableCredits);
            aiAssistanceResponseMessageUid$.next(payload.responseMessageUid);
        }

        if (payload && payload.conversationUid === aiAssistanceConversationUid$.value) {
            createAiAssistanceGeneratedMessageAction$.next(payload);
        }

        return true;
    },
});

const createAiAssistanceResponseFailedInterceptor = (): FeedbackInterceptor<{ workspaceUid: string; id: string }> => ({
    type: 'AIAssistanceResponseFailed',
    processEvent: async (event) => {
        const payload = event.payload;
        if (payload && payload.workspaceUid === selectedWorkspaceUid$.value) {
            aiAssistanceAnswering$.next(false);
            publishLocalFeedbackEventAction$.next({
                level: 'ERROR',
                message: `Failed to generate AI response.`,
            });
        }
        return true;
    },
});

const createWorkspaceEditSessionLockInterceptor = (): FeedbackInterceptor<{ lockDetails: WorkspaceLockDetails }> => ({
    type: 'WorkspaceEditSessionLost',
    processEvent: async (event) => {
        const payload = event.payload;
        const hasUnsavedChanges = workspaceHasUnsavedChanges();

        if (payload?.lockDetails) {
            const workspaceDetails = selectedWorkspace$.value;

            if (workspaceDetails) {
                selectedWorkspace$.next({
                    ...workspaceDetails,
                    locked: payload.lockDetails,
                });
            }

            if (hasUnsavedChanges) {
                promptMessage({
                    icon: <IconCircle icon={<LockOutlinedIcon />} size="medium" />,
                    messages: [
                        'This workspace session was taken over by you in another browser tab or by someone else in your team.',
                        'Looks like you have some unsaved changes, please make sure to store these changes externally and merge them back into the workspace manually when you have the edit control.',
                    ],
                    subtitle: 'The workspace is currently set to read-only mode.',
                    title: 'Workspace is locked',
                });
            } else {
                promptMessage({
                    icon: <IconCircle icon={<LockOutlinedIcon />} size="medium" />,
                    messages: [
                        'This workspace session was taken over by you in another browser tab or by someone else in your team.',
                    ],
                    subtitle: 'The workspace is currently set to read-only mode.',
                    title: 'Workspace is locked',
                });
            }
        }

        return true;
    },
});

const createAtlassianCloudOauthSuccessInterceptor = (type: string): FeedbackInterceptor => ({
    type,
    processEvent: async (event) => {
        const payload = event.payload;

        if (
            payload?.connectionType !== 'jira-cloud' &&
            payload?.connectionType !== 'confluence-cloud' &&
            payload?.connectionType !== 'jira-service-management-cloud'
        ) {
            throw new Error(WRONG_CONNECTION_TYPE);
        }

        const details = atlassianCloudManageConnectionDetails$.value;
        if (payload?.connectionId && payload.connectionId !== details?.uid) {
            throw new Error(WRONG_CONNECTION_SELECTED);
        }

        if (!Array.isArray(payload?.authorizedResources) || payload?.authorizedResources.length === 0) {
            publishLocalFeedbackEventAction$.next({
                level: 'ERROR',
                message: `Authorization failed.`,
            });
            throw new Error('Missing authorized instances');
        }

        atlassianCloudManageConnectionAuthorizeLoading$.next(true);
        atlassianCloudInstanceSelectionInstances$.next(payload.authorizedResources);
        atlassianCloudInstanceSelectionOpen$.next(true);

        return true;
    },
});

const createAtlassianCloudOauthFailedInterceptor = (type: string): FeedbackInterceptor => ({
    type,
    processEvent: async (event) => {
        const payload = event.payload;

        if (
            payload?.connectionType !== 'jira-cloud' &&
            payload?.connectionType !== 'confluence-cloud' &&
            payload?.connectionType !== 'jira-service-management-cloud'
        ) {
            throw new Error(WRONG_CONNECTION_TYPE);
        }

        const details = atlassianCloudManageConnectionDetails$.value;
        if (payload?.connectionId && payload.connectionId !== details?.uid) {
            throw new Error(WRONG_CONNECTION_SELECTED);
        }

        const message = typeof payload?.errorMessage === 'string' ? payload.errorMessage : AUTHORIZATION_FAILED;
        publishLocalFeedbackEventAction$.next({
            level: 'ERROR',
            message,
        });

        atlassianCloudInstanceSelectionCloseDialogAction$.next();
        atlassianCloudManageConnectionAuthorizeLoading$.next(false);

        return true;
    },
});

const createAtlassianOnPremiseOauthSuccessInterceptor = (type: string): FeedbackInterceptor => ({
    type,
    processEvent: async (event) => {
        const payload = event.payload;

        if (payload?.connectionType !== 'jira-on-premise' && payload?.connectionType !== 'confluence-on-premise') {
            throw new Error(WRONG_CONNECTION_TYPE);
        }

        const details = atlassianOnPremiseManageConnectionDetails$.value;
        if (payload?.connectionId && payload.connectionId !== details?.uid) {
            throw new Error(WRONG_CONNECTION_SELECTED);
        }

        atlassianOnPremiseAppSetupCloseDialogAction$.next();
        atlassianOnPremiseAppSetupDialogStage$.next(0);

        publishLocalFeedbackEventAction$.next({
            level: 'SUCCESS',
            message: 'Connector has been configured.',
        });

        if (details?.uid)
            atlassianOnPremiseConnectionSavedAction$.next({
                uid: details?.uid,
                connectionTypeUid: details?.connectionType.uid,
            });

        return true;
    },
});

const createAtlassianOnPremiseOauthFailedInterceptor = (type: string): FeedbackInterceptor => ({
    type,
    processEvent: async (event) => {
        const payload = event.payload;

        if (payload?.connectionType !== 'jira-on-premise' && payload?.connectionType !== 'confluence-on-premise') {
            throw new Error(WRONG_CONNECTION_TYPE);
        }

        const details = atlassianOnPremiseManageConnectionDetails$.value;
        if (payload?.connectionId && payload.connectionId !== details?.uid) {
            throw new Error(WRONG_CONNECTION_SELECTED);
        }

        const message = typeof payload?.errorMessage === 'string' ? payload.errorMessage : AUTHORIZATION_FAILED;
        publishLocalFeedbackEventAction$.next({
            level: 'ERROR',
            message,
        });

        atlassianOnPremiseManageConnectionAuthorizeLoading$.next(false);
        atlassianOnPremiseAppSetupCloseDialogAction$.next();

        return true;
    },
});

export const FEEDBACK_INTERCEPTORS: FeedbackInterceptor[] = [
    {
        type: '@avst-stitch/bitbucket-cloud/OAuthSuccess',
        processEvent: async (event) => {
            const payload = event.payload;

            if (payload?.connectionType !== 'bitbucket-cloud') {
                throw new Error(WRONG_CONNECTION_TYPE);
            }

            const details = bitbucketCloudManageConnectionDetails$.value;
            if (payload?.connectionId && payload.connectionId !== details?.uid) {
                throw new Error(WRONG_CONNECTION_SELECTED);
            }
            publishLocalFeedbackEventAction$.next({
                level: 'SUCCESS',
                message: 'BitbucketCloud connection has been configured.',
            });
            bitbucketCloudAppSetupCloseDialogAction$.next();
            bitbucketCloudAppSetupDialogStage$.next(0);

            if (details?.uid)
                bitbucketCloudConnectionSavedAction$.next({
                    uid: details?.uid,
                    connectionTypeUid: details?.connectionType.uid,
                });

            return true;
        },
    },
    {
        type: '@avst-stitch/bitbucket-cloud/OAuthFailed',
        processEvent: async (event) => {
            const payload = event.payload;

            if (payload?.connectionType !== 'bitbucket-cloud') {
                throw new Error(WRONG_CONNECTION_TYPE);
            }

            const details = bitbucketCloudManageConnectionDetails$.value;
            if (payload?.connectionId && payload.connectionId !== details?.uid) {
                throw new Error(WRONG_CONNECTION_SELECTED);
            }

            const message = typeof payload?.errorMessage === 'string' ? payload.errorMessage : AUTHORIZATION_FAILED;
            publishLocalFeedbackEventAction$.next({
                level: 'ERROR',
                message,
            });

            bitbucketCloudManageConnectionAuthorizeLoading$.next(false);
            bitbucketCloudAppSetupCloseDialogAction$.next();

            return true;
        },
    },
    {
        type: '@avst-stitch/servicenow/OAuthSuccess',
        processEvent: async (event) => {
            const payload = event.payload;

            if (payload?.connectionType !== 'servicenow') {
                throw new Error(WRONG_CONNECTION_TYPE);
            }

            const details = serviceNowManageConnectionDetails$.value;
            if (payload?.connectionId && payload.connectionId !== details?.uid) {
                throw new Error(WRONG_CONNECTION_SELECTED);
            }
            publishLocalFeedbackEventAction$.next({
                level: 'SUCCESS',
                message: 'ServiceNow Connector has been configured.',
            });
            serviceNowAppSetupCloseDialogAction$.next();
            serviceNowAppSetupDialogStage$.next(0);

            if (details?.uid)
                serviceNowConnectionSavedAction$.next({
                    uid: details?.uid,
                    connectionTypeUid: details?.connectionType.uid,
                });

            return true;
        },
    },
    {
        type: '@avst-stitch/servicenow/OAuthFailed',
        processEvent: async (event) => {
            const payload = event.payload;

            if (payload?.connectionType !== 'servicenow') {
                throw new Error(WRONG_CONNECTION_TYPE);
            }

            const details = serviceNowManageConnectionDetails$.value;
            if (payload?.connectionId && payload.connectionId !== details?.uid) {
                throw new Error(WRONG_CONNECTION_SELECTED);
            }

            const message = typeof payload?.errorMessage === 'string' ? payload.errorMessage : AUTHORIZATION_FAILED;
            publishLocalFeedbackEventAction$.next({
                level: 'ERROR',
                message,
            });

            serviceNowManageConnectionAuthorizeLoading$.next(false);
            serviceNowAppSetupCloseDialogAction$.next();

            return true;
        },
    },
    {
        type: '@avst-stitch/google-sheets/OAuthSuccess',
        processEvent: async (event) => {
            const payload = event.payload;

            if (payload?.connectionType !== 'google-sheets') {
                throw new Error(WRONG_CONNECTION_TYPE);
            }

            const details = googleManageConnectionDetails$.value;
            if (payload?.connectionId && payload.connectionId !== details?.uid) {
                throw new Error(WRONG_CONNECTION_SELECTED);
            }
            publishLocalFeedbackEventAction$.next({
                level: 'SUCCESS',
                message: 'Google Sheets Connector has been configured.',
            });

            if (details?.uid)
                googleConnectionSavedAction$.next({
                    uid: details?.uid,
                    connectionTypeUid: details?.connectionType.uid,
                });

            return true;
        },
    },
    {
        type: '@avst-stitch/google-sheets/OAuthFailed',
        processEvent: async (event) => {
            const payload = event.payload;

            if (payload?.connectionType !== 'google-sheets') {
                throw new Error(WRONG_CONNECTION_TYPE);
            }

            const details = googleManageConnectionDetails$.value;
            if (payload?.connectionId && payload.connectionId !== details?.uid) {
                throw new Error(WRONG_CONNECTION_SELECTED);
            }

            const message = typeof payload?.errorMessage === 'string' ? payload.errorMessage : AUTHORIZATION_FAILED;
            publishLocalFeedbackEventAction$.next({
                level: 'ERROR',
                message,
            });

            return true;
        },
    },
    {
        type: '@avst-stitch/github/OAuthSuccess',
        processEvent: async (event) => {
            const payload = event.payload;

            if (payload?.connectionType !== 'github') {
                throw new Error(WRONG_CONNECTION_TYPE);
            }

            const details = githubManageConnectionDetails$.value;
            if (payload?.connectionId && payload.connectionId !== details?.uid) {
                throw new Error(WRONG_CONNECTION_SELECTED);
            }
            publishLocalFeedbackEventAction$.next({
                level: 'SUCCESS',
                message: 'GitHub Connector has been configured.',
            });

            if (details?.uid)
                githubConnectionSavedAction$.next({
                    uid: details?.uid,
                    connectionTypeUid: details?.connectionType.uid,
                });

            return true;
        },
    },
    {
        type: '@avst-stitch/github/OAuthFailed',
        processEvent: async (event) => {
            const payload = event.payload;

            if (payload?.connectionType !== 'github') {
                throw new Error(WRONG_CONNECTION_TYPE);
            }

            const details = githubManageConnectionDetails$.value;
            if (payload?.connectionId && payload.connectionId !== details?.uid) {
                throw new Error(WRONG_CONNECTION_SELECTED);
            }

            const message = typeof payload?.errorMessage === 'string' ? payload.errorMessage : AUTHORIZATION_FAILED;
            publishLocalFeedbackEventAction$.next({
                level: 'ERROR',
                message,
            });

            return true;
        },
    },
    {
        type: '@avst-stitch/monday/OAuthSuccess',
        processEvent: async (event) => {
            const payload = event.payload;

            if (payload?.connectionType !== 'monday') {
                throw new Error(WRONG_CONNECTION_TYPE);
            }

            const details = mondayManageConnectionDetails$.value;
            if (payload?.connectionId && payload.connectionId !== details?.uid) {
                throw new Error(WRONG_CONNECTION_SELECTED);
            }
            publishLocalFeedbackEventAction$.next({
                level: 'SUCCESS',
                message: 'monday.com Connector has been configured.',
            });

            mondayAppSetupCloseDialogAction$.next();
            mondayAppSetupDialogStage$.next(0);

            if (details?.uid)
                mondayConnectionSavedAction$.next({
                    uid: details?.uid,
                    connectionTypeUid: details?.connectionType.uid,
                });

            return true;
        },
    },
    {
        type: '@avst-stitch/monday/OAuthFailed',
        processEvent: async (event) => {
            const payload = event.payload;

            if (payload?.connectionType !== 'monday') {
                throw new Error(WRONG_CONNECTION_TYPE);
            }

            const details = mondayManageConnectionDetails$.value;
            if (payload?.connectionId && payload.connectionId !== details?.uid) {
                throw new Error(WRONG_CONNECTION_SELECTED);
            }

            mondayManageConnectionAuthorizeLoading$.next(false);
            mondayAppSetupCloseDialogAction$.next();
            mondayAppSetupDialogStage$.next(0);

            const message = typeof payload?.errorMessage === 'string' ? payload.errorMessage : AUTHORIZATION_FAILED;
            publishLocalFeedbackEventAction$.next({
                level: 'ERROR',
                message,
            });

            return true;
        },
    },
    {
        type: '@avst-stitch/gitlab/OAuthSuccess',
        processEvent: async (event) => {
            const payload = event.payload;

            if (payload?.connectionType !== 'gitlab') {
                throw new Error(WRONG_CONNECTION_TYPE);
            }

            const details = gitlabManageConnectionDetails$.value;
            if (payload?.connectionId && payload.connectionId !== details?.uid) {
                throw new Error(WRONG_CONNECTION_SELECTED);
            }
            publishLocalFeedbackEventAction$.next({
                level: 'SUCCESS',
                message: 'GitLab Connector has been configured.',
            });

            gitlabAppSetupCloseDialogAction$.next();
            gitlabAppSetupDialogStage$.next(0);

            if (details?.uid)
                gitlabConnectionSavedAction$.next({
                    uid: details?.uid,
                    connectionTypeUid: details?.connectionType.uid,
                    url: details?.instanceUrl ?? '',
                });

            return true;
        },
    },
    {
        type: '@avst-stitch/gitlab/OAuthFailed',
        processEvent: async (event) => {
            const payload = event.payload;

            if (payload?.connectionType !== 'gitlab') {
                throw new Error(WRONG_CONNECTION_TYPE);
            }

            const details = gitlabManageConnectionDetails$.value;
            if (payload?.connectionId && payload.connectionId !== details?.uid) {
                throw new Error(WRONG_CONNECTION_SELECTED);
            }

            const message = typeof payload?.errorMessage === 'string' ? payload.errorMessage : AUTHORIZATION_FAILED;
            publishLocalFeedbackEventAction$.next({
                level: 'ERROR',
                message,
            });

            gitlabManageConnectionAuthorizeLoading$.next(false);
            gitlabAppSetupCloseDialogAction$.next();

            return true;
        },
    },
    {
        type: '@avst-stitch/tempo-cloud/OAuthSuccess',
        processEvent: async (event) => {
            const payload = event.payload;

            if (payload?.connectionType !== 'tempo-cloud') {
                throw new Error(WRONG_CONNECTION_TYPE);
            }

            const details = tempoCloudManageConnectionDetails$.value;
            if (payload?.connectionId && payload.connectionId !== details?.uid) {
                throw new Error(WRONG_CONNECTION_SELECTED);
            }
            publishLocalFeedbackEventAction$.next({
                level: 'SUCCESS',
                message: 'Tempo Cloud Connector has been configured.',
            });
            tempoCloudAppSetupCloseDialogAction$.next();
            tempoCloudAppSetupDialogStage$.next(0);

            if (details?.uid)
                tempoCloudConnectionSavedAction$.next({
                    uid: details?.uid,
                    connectionTypeUid: details?.connectionType.uid,
                });

            return true;
        },
    },
    {
        type: '@avst-stitch/tempo-cloud/OAuthFailed',
        processEvent: async (event) => {
            const payload = event.payload;

            if (payload?.connectionType !== 'tempo-cloud') {
                throw new Error(WRONG_CONNECTION_TYPE);
            }

            const details = tempoCloudManageConnectionDetails$.value;
            if (payload?.connectionId && payload.connectionId !== details?.uid) {
                throw new Error(WRONG_CONNECTION_SELECTED);
            }

            const message = typeof payload?.errorMessage === 'string' ? payload.errorMessage : AUTHORIZATION_FAILED;
            publishLocalFeedbackEventAction$.next({
                level: 'ERROR',
                message,
            });

            tempoCloudManageConnectionAuthorizeLoading$.next(false);
            tempoCloudAppSetupCloseDialogAction$.next();

            return true;
        },
    },
    {
        type: '@avst-stitch/netsuite/OAuthSuccess',
        processEvent: async (event) => {
            const payload = event.payload;

            if (payload?.connectionType !== 'netsuite') {
                throw new Error(WRONG_CONNECTION_TYPE);
            }

            const details = netSuiteManageConnectionDetails$.value;
            if (payload?.connectionId && payload.connectionId !== details?.uid) {
                throw new Error(WRONG_CONNECTION_SELECTED);
            }
            publishLocalFeedbackEventAction$.next({
                level: 'SUCCESS',
                message: 'NetSuite Connector has been configured.',
            });
            netSuiteAppSetupCloseDialogAction$.next();
            netSuiteAppSetupDialogStage$.next(0);

            if (details?.uid)
                netSuiteConnectionSavedAction$.next({
                    uid: details?.uid,
                    connectionTypeUid: details?.connectionType.uid,
                });

            return true;
        },
    },
    {
        type: '@avst-stitch/netsuite/OAuthFailed',
        processEvent: async (event) => {
            const payload = event.payload;

            if (payload?.connectionType !== 'netsuite') {
                throw new Error(WRONG_CONNECTION_TYPE);
            }

            const details = netSuiteManageConnectionDetails$.value;
            if (payload?.connectionId && payload.connectionId !== details?.uid) {
                throw new Error(WRONG_CONNECTION_SELECTED);
            }

            const message = typeof payload?.errorMessage === 'string' ? payload.errorMessage : AUTHORIZATION_FAILED;
            publishLocalFeedbackEventAction$.next({
                level: 'ERROR',
                message,
            });

            netSuiteManageConnectionAuthorizeLoading$.next(false);
            netSuiteAppSetupCloseDialogAction$.next();

            return true;
        },
    },
    {
        type: '@avst-stitch/bitbucket-on-premise/OAuthSuccess',
        processEvent: async (event) => {
            const payload = event.payload;
            if (payload?.connectionType !== 'bitbucket-on-premise') {
                throw new Error(WRONG_CONNECTION_TYPE);
            }

            const details = bitbucketOnPremiseManageConnectionDetails$.value;
            if (payload?.connectionId && payload.connectionId !== details?.uid) {
                throw new Error(WRONG_CONNECTION_SELECTED);
            }
            publishLocalFeedbackEventAction$.next({
                level: 'SUCCESS',
                message: 'Bitbucket On-Premise Connector has been configured.',
            });
            bitbucketOnPremiseAppSetupCloseDialogAction$.next();
            bitbucketOnPremiseAppSetupDialogStage$.next(0);

            if (details?.uid)
                bitbucketOnPremiseConnectionSavedAction$.next({
                    uid: details?.uid,
                    connectionTypeUid: details?.connectionType.uid,
                });

            return true;
        },
    },
    {
        type: '@avst-stitch/bitbucket-on-premise/OAuthFailed',
        processEvent: async (event) => {
            const payload = event.payload;
            if (payload?.connectionType !== 'bitbucket-on-premise') {
                throw new Error(WRONG_CONNECTION_TYPE);
            }

            const details = bitbucketOnPremiseManageConnectionDetails$.value;
            if (payload?.connectionId && payload.connectionId !== details?.uid) {
                throw new Error(WRONG_CONNECTION_SELECTED);
            }

            const message = typeof payload?.errorMessage === 'string' ? payload.errorMessage : AUTHORIZATION_FAILED;
            publishLocalFeedbackEventAction$.next({
                level: 'ERROR',
                message,
            });

            bitbucketOnPremiseManageConnectionAuthorizeLoading$.next(false);
            bitbucketOnPremiseAppSetupCloseDialogAction$.next();

            return true;
        },
    },
    {
        type: '@avst-stitch/google-calendar/OAuthSuccess',
        processEvent: async (event) => {
            const payload = event.payload;

            if (payload?.connectionType !== 'google-calendar') {
                throw new Error(WRONG_CONNECTION_TYPE);
            }

            const details = googleManageConnectionDetails$.value;
            if (payload?.connectionId && payload.connectionId !== details?.uid) {
                throw new Error(WRONG_CONNECTION_SELECTED);
            }
            publishLocalFeedbackEventAction$.next({
                level: 'SUCCESS',
                message: 'Google Calendar Connector has been configured.',
            });

            if (details?.uid)
                googleConnectionSavedAction$.next({
                    uid: details?.uid,
                    connectionTypeUid: details?.connectionType.uid,
                });

            return true;
        },
    },
    {
        type: '@avst-stitch/google-calendar/OAuthFailed',
        processEvent: async (event) => {
            const payload = event.payload;

            if (payload?.connectionType !== 'google-calendar') {
                throw new Error(WRONG_CONNECTION_TYPE);
            }

            const details = googleManageConnectionDetails$.value;
            if (payload?.connectionId && payload.connectionId !== details?.uid) {
                throw new Error(WRONG_CONNECTION_SELECTED);
            }

            const message = typeof payload?.errorMessage === 'string' ? payload.errorMessage : AUTHORIZATION_FAILED;
            publishLocalFeedbackEventAction$.next({
                level: 'ERROR',
                message,
            });

            return true;
        },
    },
    {
        type: '@avst-stitch/microsoft/OAuthSuccess',
        processEvent: async (event) => {
            const payload = event.payload;

            if (payload?.connectionType !== 'microsoft') {
                throw new Error(WRONG_CONNECTION_TYPE);
            }

            const details = microsoftManageConnectionDetails$.value;
            if (payload?.connectionId && payload.connectionId !== details?.uid) {
                throw new Error(WRONG_CONNECTION_SELECTED);
            }
            publishLocalFeedbackEventAction$.next({
                level: 'SUCCESS',
                message: 'Microsoft Connector has been configured.',
            });

            microsoftAppSetupCloseDialogAction$.next();
            microsoftAppSetupDialogStage$.next(0);

            if (details?.uid)
                microsoftConnectionSavedAction$.next({
                    uid: details?.uid,
                    connectionTypeUid: details?.connectionType.uid,
                    tenantId: details?.tenantId ?? '',
                });

            return true;
        },
    },
    {
        type: '@avst-stitch/microsoft/OAuthFailed',
        processEvent: async (event) => {
            const payload = event.payload;

            if (payload?.connectionType !== 'microsoft') {
                throw new Error(WRONG_CONNECTION_TYPE);
            }

            const details = microsoftManageConnectionDetails$.value;
            if (payload?.connectionId && payload.connectionId !== details?.uid) {
                throw new Error(WRONG_CONNECTION_SELECTED);
            }

            const message = typeof payload?.errorMessage === 'string' ? payload.errorMessage : AUTHORIZATION_FAILED;
            publishLocalFeedbackEventAction$.next({
                level: 'ERROR',
                message,
            });

            microsoftManageConnectionAuthorizeLoading$.next(false);
            microsoftAppSetupCloseDialogAction$.next();

            return true;
        },
    },
    {
        type: '@avst-stitch/azure-devops/OAuthSuccess',
        processEvent: async (event) => {
            const payload = event.payload;

            if (payload?.connectionType !== 'azure-devops') {
                throw new Error(WRONG_CONNECTION_TYPE);
            }

            const details = azureDevopsManageConnectionDetails$.value;
            if (payload?.connectionId && payload.connectionId !== details?.uid) {
                throw new Error(WRONG_CONNECTION_SELECTED);
            }
            publishLocalFeedbackEventAction$.next({
                level: 'SUCCESS',
                message: 'Azure DevOps Connector has been configured.',
            });

            azureDevopsAppSetupCloseDialogAction$.next();
            azureDevopsAppSetupDialogStage$.next(0);

            if (details?.uid)
                azureDevopsConnectionSavedAction$.next({
                    uid: details?.uid,
                    connectionTypeUid: details?.connectionType.uid,
                    tenantId: details?.tenantId ?? '',
                });

            return true;
        },
    },
    {
        type: '@avst-stitch/azure-devops/OAuthFailed',
        processEvent: async (event) => {
            const payload = event.payload;

            if (payload?.connectionType !== 'azure-devops') {
                throw new Error(WRONG_CONNECTION_TYPE);
            }

            const details = azureDevopsManageConnectionDetails$.value;
            if (payload?.connectionId && payload.connectionId !== details?.uid) {
                throw new Error(WRONG_CONNECTION_SELECTED);
            }

            const message = typeof payload?.errorMessage === 'string' ? payload.errorMessage : AUTHORIZATION_FAILED;
            publishLocalFeedbackEventAction$.next({
                level: 'ERROR',
                message,
            });

            azureDevopsManageConnectionAuthorizeLoading$.next(false);
            azureDevopsAppSetupCloseDialogAction$.next();

            return true;
        },
    },
    {
        type: '@avst-stitch/zoom/OAuthSuccess',
        processEvent: async (event) => {
            const payload = event.payload;

            if (payload?.connectionType !== 'zoom') {
                throw new Error(WRONG_CONNECTION_TYPE);
            }

            const details = zoomManageConnectionDetails$.value;
            if (payload?.connectionId && payload.connectionId !== details?.uid) {
                throw new Error(WRONG_CONNECTION_SELECTED);
            }
            publishLocalFeedbackEventAction$.next({
                level: 'SUCCESS',
                message: 'Zoom Connector has been configured.',
            });

            zoomAppSetupCloseDialogAction$.next();
            zoomAppSetupDialogStage$.next(0);

            if (details?.uid)
                zoomConnectionSavedAction$.next({
                    uid: details?.uid,
                    connectionTypeUid: details?.connectionType.uid,
                });

            return true;
        },
    },
    {
        type: '@avst-stitch/zoom/OAuthFailed',
        processEvent: async (event) => {
            const payload = event.payload;

            if (payload?.connectionType !== 'zoom') {
                throw new Error(WRONG_CONNECTION_TYPE);
            }

            const details = zoomManageConnectionDetails$.value;
            if (payload?.connectionId && payload.connectionId !== details?.uid) {
                throw new Error(WRONG_CONNECTION_SELECTED);
            }

            const message = typeof payload?.errorMessage === 'string' ? payload.errorMessage : AUTHORIZATION_FAILED;
            publishLocalFeedbackEventAction$.next({
                level: 'ERROR',
                message,
            });

            zoomManageConnectionAuthorizeLoading$.next(false);
            zoomAppSetupCloseDialogAction$.next();

            return true;
        },
    },
    {
        type: '@avst-stitch/zendesk/OAuthSuccess',
        processEvent: async (event) => {
            const payload = event.payload;

            if (payload?.connectionType !== 'zendesk') {
                throw new Error(WRONG_CONNECTION_TYPE);
            }

            const details = zenDeskManageConnectionDetails$.value;
            if (payload?.connectionId && payload.connectionId !== details?.uid) {
                throw new Error(WRONG_CONNECTION_SELECTED);
            }
            publishLocalFeedbackEventAction$.next({
                level: 'SUCCESS',
                message: 'ZenDesk Connector has been configured.',
            });

            zenDeskAppSetupCloseDialogAction$.next();
            zenDeskAppSetupDialogStage$.next(0);

            if (details?.uid)
                zenDeskConnectionSavedAction$.next({
                    uid: details?.uid,
                    connectionTypeUid: details?.connectionType.uid,
                });

            return true;
        },
    },
    {
        type: '@avst-stitch/zendesk/OAuthFailed',
        processEvent: async (event) => {
            const payload = event.payload;

            if (payload?.connectionType !== 'zendesk') {
                throw new Error(WRONG_CONNECTION_TYPE);
            }

            const details = zenDeskManageConnectionDetails$.value;
            if (payload?.connectionId && payload.connectionId !== details?.uid) {
                throw new Error(WRONG_CONNECTION_SELECTED);
            }

            const message = typeof payload?.errorMessage === 'string' ? payload.errorMessage : AUTHORIZATION_FAILED;
            publishLocalFeedbackEventAction$.next({
                level: 'ERROR',
                message,
            });

            zenDeskManageConnectionAuthorizeLoading$.next(false);
            zenDeskAppSetupCloseDialogAction$.next();

            return true;
        },
    },
    {
        type: '@avst-stitch/salesforce/OAuthSuccess',
        processEvent: async (event) => {
            const payload = event.payload;
            if (payload?.connectionType !== 'salesforce') {
                throw new Error(WRONG_CONNECTION_TYPE);
            }

            const details = salesforceManageConnectionDetails$.value;
            if (payload?.connectionId && payload.connectionId !== details?.uid) {
                throw new Error(WRONG_CONNECTION_SELECTED);
            }
            publishLocalFeedbackEventAction$.next({
                level: 'SUCCESS',
                message: 'Salesforce Connector has been configured.',
            });
            salesforceAppSetupCloseDialogAction$.next();
            salesforceAppSetupDialogStage$.next(0);

            if (details?.uid)
                salesforceConnectionSavedAction$.next({
                    uid: details?.uid,
                    connectionTypeUid: details?.connectionType.uid,
                });

            return true;
        },
    },
    {
        type: '@avst-stitch/salesforce/OAuthFailed',
        processEvent: async (event) => {
            const payload = event.payload;
            if (payload?.connectionType !== 'salesforce') {
                throw new Error(WRONG_CONNECTION_TYPE);
            }

            const details = salesforceManageConnectionDetails$.value;
            if (payload?.connectionId && payload.connectionId !== details?.uid) {
                throw new Error(WRONG_CONNECTION_SELECTED);
            }

            const message = typeof payload?.errorMessage === 'string' ? payload.errorMessage : AUTHORIZATION_FAILED;
            publishLocalFeedbackEventAction$.next({
                level: 'ERROR',
                message,
            });

            salesforceManageConnectionAuthorizeLoading$.next(false);
            salesforceAppSetupCloseDialogAction$.next();

            return true;
        },
    },
    {
        type: '@avst-stitch/statuspage/OAuthSuccess',
        processEvent: async (event) => {
            const payload = event.payload;

            if (payload?.connectionType !== 'statuspage') {
                throw new Error(WRONG_CONNECTION_TYPE);
            }

            const details = statuspageManageConnectionDetails$.value;
            if (payload?.connectionId && payload.connectionId !== details?.uid) {
                throw new Error(WRONG_CONNECTION_SELECTED);
            }
            publishLocalFeedbackEventAction$.next({
                level: 'SUCCESS',
                message: 'Statuspage Connector has been configured.',
            });

            if (details?.uid)
                statuspageConnectionSavedAction$.next({
                    uid: details?.uid,
                    connectionTypeUid: details?.connectionType.uid,
                });

            return true;
        },
    },
    {
        type: '@avst-stitch/statuspage/OAuthFailed',
        processEvent: async (event) => {
            const payload = event.payload;

            if (payload?.connectionType !== 'statuspage') {
                throw new Error(WRONG_CONNECTION_TYPE);
            }

            const details = statuspageManageConnectionDetails$.value;
            if (payload?.connectionId && payload.connectionId !== details?.uid) {
                throw new Error(WRONG_CONNECTION_SELECTED);
            }

            const message = typeof payload?.errorMessage === 'string' ? payload.errorMessage : AUTHORIZATION_FAILED;
            publishLocalFeedbackEventAction$.next({
                level: 'ERROR',
                message,
            });

            statuspageManageConnectionAuthorizeLoading$.next(false);
            return true;
        },
    },
    createAtlassianCloudOauthSuccessInterceptor('@avst-stitch/confluence-cloud/OAuthSuccess'),
    createAtlassianCloudOauthFailedInterceptor('@avst-stitch/confluence-cloud/OAuthFailed'),
    createAtlassianCloudOauthSuccessInterceptor('@avst-stitch/jira-cloud/OAuthSuccess'),
    createAtlassianCloudOauthFailedInterceptor('@avst-stitch/jira-cloud/OAuthFailed'),
    createAtlassianCloudOauthSuccessInterceptor('@avst-stitch/jira-service-management-cloud/OAuthSuccess'),
    createAtlassianCloudOauthFailedInterceptor('@avst-stitch/jira-service-management-cloud/OAuthFailed'),
    createAtlassianOnPremiseOauthSuccessInterceptor('@avst-stitch/confluence-on-premise/OAuthSuccess'),
    createAtlassianOnPremiseOauthFailedInterceptor('@avst-stitch/confluence-on-premise/OAuthFailed'),
    createAtlassianOnPremiseOauthSuccessInterceptor('@avst-stitch/jira-on-premise/OAuthSuccess'),
    createAtlassianOnPremiseOauthFailedInterceptor('@avst-stitch/jira-on-premise/OAuthFailed'),
    createAtlassianOnPremiseOauthSuccessInterceptor('@avst-stitch/jira-service-management-on-premise/OAuthSuccess'),
    createAtlassianOnPremiseOauthFailedInterceptor('@avst-stitch/jira-service-management-on-premise/OAuthFailed'),
    createWorkspaceEditSessionLockInterceptor(),
    createAiAssistanceResponseInterceptor(),
    createAiAssistanceResponseFailedInterceptor(),
    {
        type: 'SalableCallbackCreateOrganization',
        processEvent: async (event) => {
            const payload = event.payload;

            createOrganizationValidationError$.next(undefined);
            const emails = createOrganizationWizardInviteEmails$.value;
            if (emails && emails.length) {
                sendOrganizationInvitesAction$.next({ emails, organizationUid: payload.organizationUid });
            }

            createOrganizationWizardInviteEmails$.next(undefined);
            closeCreateOrganizationWizardDialogAction$.next();
            organizationCreatedAction$.next(payload.organizationUid);
            createOrganizationWizardSaving$.next(false);
            openOrganizationPlanSuccessStateDialogAction$.next({
                tier: payload.tier,
                state: 'create',
            });

            return true;
        },
    },
    {
        type: 'SalableCallbackCreateOrganizationFailed',
        processEvent: async () => {
            createOrganizationWizardSaving$.next(false);
            createOrganizationValidationError$.next('Error occurred while creating team.');
            refreshBillingEntitiesAction$.next();

            return true;
        },
    },
    {
        type: 'SalableCallbackChangeOrganization',
        processEvent: async (event) => {
            const payload = event.payload;

            const currentPlan = selectedOrganizationPlan$.value;
            const organizationUid = selectedOrganizationUid$.value;
            if (payload?.organizationUid && payload.organizationUid !== organizationUid) {
                throw new Error(WRONG_ORGANIZATION_SELECTED);
            }

            const PLAN_TIERS = ['free', 'silver', 'gold', 'platinum'];
            organizationPlanUpdating$.next(false);
            selectBillingDetailsDialogSaving$.next(false);
            closeSelectBillingDetailsDialogAction$.next();
            closeChangeOrganizationPlanWizardDialogAction$.next();
            organizationPlanChangedAction$.next();
            closeChangeOrganizationPlanConfirmPurchaseDialogAction$.next();
            openOrganizationPlanSuccessStateDialogAction$.next({
                tier: payload.tier,
                state:
                    PLAN_TIERS.indexOf(payload.tier ?? '') < PLAN_TIERS.indexOf(currentPlan?.tier ?? '')
                        ? 'downgrade'
                        : 'upgrade',
                lastTier: currentPlan?.tier,
            });

            return true;
        },
    },
    {
        type: 'SalableCallbackChangeOrganizationFailed',
        processEvent: async () => {
            organizationPlanUpdating$.next(false);
            selectBillingDetailsDialogSaving$.next(false);
            changeOrganizationPlanConfirmPurchaseDialogLoading$.next(false);

            const errorMessage =
                'Error occurred while changing plan. Please try again, if the issue persists please contact support.';

            if (selectBillingDetailsDialogOpen$.value) {
                selectBillingDetailsError$.next(errorMessage);
            } else {
                changeOrganizationPlanConfirmPurchaseDialogError$.next(errorMessage);
            }

            return true;
        },
    },
];
