import { useObservableState } from 'observable-hooks';
import { selectedApiHandlerDetails$ } from '../../../../store/workspace/api-handler';
import { selectedWorkspaceResources$ } from '../../../../store/workspace';
import { apps$ } from '../../../../store/apps';
import { ApiConnectionDetails } from '../../../../components/workspace-resources/advanced-view/api-connections/ApiConnectionDetails';

export const ReadOnlyApiHandlerDetailsContainer: React.FC = () => {
    const workspaceResources = useObservableState(selectedWorkspaceResources$);
    const details = useObservableState(selectedApiHandlerDetails$);
    const apps = useObservableState(apps$);

    const vendorApiVersions = workspaceResources.apiHandlers
        .flatMap((handler) =>
            handler.uid === details?.uid
                ? handler.libraries.map((library) => ({
                      value: library.uid,
                      name: library.deprecated
                          ? `${library.label} (deprecated)`
                          : library.recommended
                          ? `${library.label} (recommended)`
                          : library.label || '',
                      deprecated: library.deprecated,
                      recommended: library.recommended,
                  }))
                : []
        )
        .sort((l1, l2) => (l2.recommended ? 1 : 0) - (l1.recommended ? 1 : 0));

    const appName = apps.find((a) => a.connectionType.uid === details?.connectionTypeUid)?.connectionType.name;

    return (
        <ApiConnectionDetails
            appName={appName ?? ''}
            path={details?.path ?? ''}
            uid={details?.uid ?? ''}
            templatePreviewMode={true}
            selectedApiVersionUid={details?.apiHandlerLibraryUid}
            vendorApiVersions={vendorApiVersions}
        />
    );
};
