import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import React, { useState } from 'react';
import { Button } from '../../../common/buttons/Button';
import { JiraOnPremiseIcon } from '../../../icons/product-icons/JiraOnPremiseIcon';
import { OpenInNewLink } from '../../../common/OpenInNewLink';
import { ConnectionModalTextField } from '../../../for-deprecation/textfield/ConnectionModalTextField';
import { ReadOnlyTextField } from '../../../for-deprecation/textfield/ReadOnlyTextField';
import {
    AtlassianOnPremiseWizardStage,
    AtlassianOnPremiseWizardSteps,
    StageType,
} from './AtlassianOnPremiseWizardSteps';
import { ConfluenceIcon } from '../../../icons/product-icons/ConfluenceIcon';
import Link from '@mui/material/Link';
import { DialogAlert, DialogTitleMain } from '../../../for-deprecation/dialog/DialogComponents';
import { testUrl } from '../../../../utils/input';

interface SetupDetails {
    nonLegacyConsumerKey: string;
    consumerName: string;
    publicKey: string;
}

export interface AtlassianOnPremiseConnection {
    atlassianOnPremiseUrl: string;
    open: boolean;
    onClose: () => void;
    saving: boolean;
    currentStage: AtlassianOnPremiseWizardStage;
    setStage: (stage: AtlassianOnPremiseWizardStage) => void;
    onSave: (props: onSaveProps) => void;
    errors?: string;
    setError: (error: string) => void;
    clearErrors: () => void;
    callbackUrl: string;
    setupDetails: SetupDetails;
    connectionType?: string;
}

export interface onSaveProps {
    instanceUrl: string;
}

const getConnectionTypeValue = (type?: string): string => {
    switch (type) {
        case 'Jira On-Premise':
        case 'Confluence On-Premise':
            return type;
        default:
            return 'Your Instance';
    }
};

const StageContent: StageType = (props) => {
    const [instanceUrl, setInstanceUrl] = useState(props.atlassianOnPremiseUrl);
    const [badUrlAttempted, setBadUrlAttempted] = useState<boolean>(false);
    const [testingUrl, setTestingUrl] = useState<boolean>(false);

    const APPLICATION_LINKS_PATH = '/plugins/servlet/applinks/listApplicationLinks';

    const generateApplicationLinksUrl = (): string => {
        return instanceUrl.endsWith(APPLICATION_LINKS_PATH)
            ? instanceUrl
            : instanceUrl.endsWith('/')
            ? instanceUrl.substring(0, instanceUrl.length - 1) + APPLICATION_LINKS_PATH
            : instanceUrl + APPLICATION_LINKS_PATH;
    };

    const connectionType = getConnectionTypeValue(props.connectionType);

    switch (props.stage) {
        case AtlassianOnPremiseWizardStage.ADDURL:
            return (
                <>
                    <DialogAlert
                        severity="info"
                        text={`To setup an application link you need ${connectionType} administrator privileges.`}
                    />
                    <DialogContent>
                        <DialogContentText>The connector requires the {connectionType} instance url.</DialogContentText>
                        <ConnectionModalTextField
                            label={`Enter the ${connectionType} URL`}
                            value={instanceUrl}
                            onUpdate={(e) => {
                                if (badUrlAttempted) {
                                    props.clearErrors();
                                    setBadUrlAttempted(false);
                                }
                                setInstanceUrl(e.target.value);
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => props.onClose?.()}>
                            Close
                        </Button>
                        <Button
                            //eslint-disable-next-line @typescript-eslint/no-misused-promises
                            onClick={async () => {
                                setTestingUrl(true);
                                if (await testUrl(instanceUrl)) {
                                    setInstanceUrl(instanceUrl);
                                    props.setStage(AtlassianOnPremiseWizardStage.CREATE);
                                    setBadUrlAttempted(false);
                                } else {
                                    props.setError('Please enter a valid URL starting with "https://"');
                                    setBadUrlAttempted(true);
                                }
                                setTestingUrl(false);
                            }}
                            busy={testingUrl}
                            disabled={!instanceUrl || badUrlAttempted}
                        >
                            Next
                        </Button>
                    </DialogActions>
                </>
            );
        case AtlassianOnPremiseWizardStage.CREATE:
            return (
                <>
                    <DialogAlert
                        severity="info"
                        text={`If you already have an application link to ScriptRunner Connect, skip the steps below and click next`}
                    />

                    <DialogAlert
                        severity="info"
                        text={`To setup an application link you need ${connectionType} administrator privileges.`}
                    />

                    <DialogContent>
                        <DialogContentText component="ol">
                            <li>
                                Visit the{' '}
                                <OpenInNewLink url={generateApplicationLinksUrl()}>Application links</OpenInNewLink>{' '}
                                page in Atlassian.
                            </li>
                            <li>
                                If an application url field exists next to the <strong>Create new link</strong> button,
                                then enter any URL and then click <strong>Create new link</strong>. Skip to{' '}
                                <strong>Enter Details</strong>.
                            </li>
                            <li>
                                Otherwise, click on <strong>Create link</strong>.
                            </li>
                            <li>
                                If prompted to select an application type, select <strong>Atlassian product</strong>.
                            </li>
                            <li>
                                Enter <strong>https://app.scriptrunnerconnect.com</strong> in the{' '}
                                <strong>Application URL</strong> field and then click <strong>Continue</strong>. Ignore
                                the <em>No response was received from the URL you entered</em> warning that is displayed
                                and click <strong>Continue</strong>.
                            </li>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => props.setStage(AtlassianOnPremiseWizardStage.ADDURL)}>
                            Back
                        </Button>
                        <Button onClick={() => props.setStage(AtlassianOnPremiseWizardStage.DETAILS)}>Next</Button>
                    </DialogActions>
                </>
            );
        case AtlassianOnPremiseWizardStage.DETAILS:
            return (
                <>
                    <DialogAlert
                        severity="info"
                        text={`If you already have an application link to ScriptRunner Connect, skip the steps below and click next.`}
                    />

                    <DialogAlert
                        severity="info"
                        text={`To setup an application link you need ${connectionType} administrator privileges.`}
                    />

                    <DialogContent>
                        <DialogContentText component="ol">
                            <li>
                                On the first screen of the <strong>Link applications</strong> dialog, Enter{' '}
                                <strong>ScriptRunnerConnect</strong> in <strong>Application Name</strong>. Then select
                                the <strong>Create incoming link</strong> checkbox.
                            </li>
                            <li>
                                It does not matter what you enter in the remaining fields, because you only need to set
                                up an incoming link.
                            </li>
                            <li>
                                Click <strong>Continue</strong>.
                            </li>
                            <li>
                                <Link
                                    href={URL.createObjectURL(new Blob([props.setupDetails.publicKey]))}
                                    download={'SRC-public-key.pem'}
                                >
                                    Download the public key
                                </Link>{' '}
                                and paste it in the <strong>Public Key</strong> field.
                            </li>
                            <strong>NOTE:</strong> Please remember that the public key will be{' '}
                            <strong>valid for 5 years</strong>. After that time, you will need to re-authorize your
                            connector.
                            <li>Copy the following details to the {connectionType} application link configuration.</li>
                            <li>
                                Click <strong>Continue</strong>.
                            </li>
                            <ReadOnlyTextField label="Consumer Key" value={props.setupDetails?.nonLegacyConsumerKey} />
                            <ReadOnlyTextField label="Consumer Name" value={props.setupDetails?.consumerName} />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(AtlassianOnPremiseWizardStage.CREATE);
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            busy={props.saving}
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(AtlassianOnPremiseWizardStage.AUTHORIZE);
                            }}
                        >
                            Next
                        </Button>
                    </DialogActions>
                </>
            );
        case AtlassianOnPremiseWizardStage.AUTHORIZE:
            return (
                <>
                    <DialogContent>
                        <DialogContentText>
                            To access information in {connectionType} you need to authorize our app to be able to make
                            requests on your behalf.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(AtlassianOnPremiseWizardStage.DETAILS);
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            data-pendo={'connectorAuthorised'}
                            busy={props.saving}
                            onClick={() =>
                                props.onSave({
                                    instanceUrl,
                                })
                            }
                        >
                            Authorize
                        </Button>
                    </DialogActions>
                </>
            );
        case AtlassianOnPremiseWizardStage.SUCCESS:
            return (
                <>
                    <DialogContentText>Success</DialogContentText>
                </>
            );
    }
};

export const AtlassianOnPremiseConnectionAppConfigureDialog: React.FC<AtlassianOnPremiseConnection> = (props) => {
    const alertError = props.errors?.includes('firewall') ? (
        <Link
            target="_blank"
            href={'https://docs.adaptavist.com/src/get-started/connect-to-services-behind-the-firewall'}
        >
            Read more about connecting to services behind the firewall.
        </Link>
    ) : undefined;
    const error = <DialogAlert severity="error" title={props.errors} text={alertError} />;
    const connectionType = getConnectionTypeValue(props.connectionType);
    return (
        <Dialog open={props.open} onClose={() => props.onClose()}>
            <DialogTitleMain
                title="Configure Connector"
                variant="h6"
                icon={connectionType === 'Jira On-Premise' ? <JiraOnPremiseIcon /> : <ConfluenceIcon />}
            />
            {props.errors && error}
            <AtlassianOnPremiseWizardSteps stage={props.currentStage} connectionType={connectionType} />
            <StageContent {...props} stage={props.currentStage} setStage={props.setStage} />
        </Dialog>
    );
};
