import { BehaviorSubject, Subject, combineLatest, map } from 'rxjs';
import { monitor } from '../monitor';
import { ulid } from 'ulid';
import { saveEnvironmentVariables } from '../../data/environment-variable';
import { selectedEnvironmentUid$, selectedWorkspaceUid$ } from '.';
import {
    ChangeVariableTypeEvent,
    DeleteVariableEvent,
    FullEnvironmentVariable,
    RepositionVariableEvent,
    ToggleVariableEditModeEvent,
    ToggleVariableExpandEvent,
    UpdateVariableEvent,
} from '../../components/workspace-resources/environment-variables/types';
import { arrayMove } from '@dnd-kit/sortable';
import { InformativeError, PermissionError } from '../../utils/error';

import { promptQuestion } from '../confirm';
import { publishLocalFeedbackEventAction$ } from '../feedback';
import { EnvironmentVariable } from '@avst-stitch/repository-lib/lib/types';
import { getEnvironmentVariablesMissingInformation } from '../../components/workspace-resources/environment-variables/utils';
import { loadEnvironmentVariables } from './utils';

export const navigateToEnvironmentVariablesAction$ = monitor(
    'navigateToEnvironmentVariablesAction$',
    new Subject<boolean>()
);

export const createEnvironmentVariableAction$ = monitor(
    'createEnvironmentVariableAction$',
    new Subject<string | undefined>()
);

export const createEnvironmentVariableFolderAction$ = monitor(
    'createEnvironmentVariableFolderAction$',
    new Subject<void>()
);

export const changeEnvironmentVariableTypeAction$ = monitor(
    'changeEnvironmentVariableTypeAction$',
    new Subject<ChangeVariableTypeEvent>()
);

export const deleteEnvironmentVariableAction$ = monitor(
    'deleteEnvironmentVariableAction$',
    new Subject<DeleteVariableEvent>()
);

export const repositionEnvironmentVariableAction$ = monitor(
    'repositionEnvironmentVariableAction$',
    new Subject<RepositionVariableEvent>()
);

export const saveEnvironmentVariablesAction$ = monitor('saveEnvironmentVariablesAction$', new Subject<void>());

export const updateEnvironmentVariableAction$ = monitor(
    'updateEnvironmentVariableAction$',
    new Subject<UpdateVariableEvent>()
);

export const toggleEnvironmentVariableEditModeAction$ = monitor(
    'toggleEnvironmentVariableEditModeAction$',
    new Subject<ToggleVariableEditModeEvent>()
);

export const toggleEnvironmentVariableExpandAction$ = monitor(
    'toggleEnvironmentVariableExpandAction$',
    new Subject<ToggleVariableExpandEvent>()
);

export const savedEnvironmentVariables$ = monitor(
    'savedEnvironmentVariables$',
    new BehaviorSubject<FullEnvironmentVariable[] | undefined>(undefined)
);

export const unsavedEnvironmentVariables$ = monitor(
    'unsavedEnvironmentVariables$',
    new BehaviorSubject<FullEnvironmentVariable[] | undefined>(undefined)
);

export const environmentVariablesSavedAction$ = monitor('environmentVariablesSavedAction$', new Subject<void>());

export const environmentVariablesFormSaving$ = monitor('environmentVariablesFormSaving$', new BehaviorSubject(false));

export const environmentVariablesFormErrors$ = monitor(
    'environmentVariablesFormErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const environmentVariablesHaveUnsavedChanges$ = monitor(
    'environmentVariablesHaveUnsavedChanges$',
    new BehaviorSubject(false)
);

export const environmentVariablesHaveMissingInformation$ = monitor(
    'environmentVariablesHaveMissingInformation$',
    new BehaviorSubject(false)
);

createEnvironmentVariableAction$.subscribe((parentId) => {
    const variables = unsavedEnvironmentVariables$.value ?? [];

    let updatedVariableList: FullEnvironmentVariable[] = variables;

    if (parentId) {
        updatedVariableList = variables.map((v) =>
            v.id === parentId
                ? {
                      ...v,
                      childVariables: [...(v.childVariables ?? []), { editMode: true, id: ulid() }],
                      expanded: true,
                  }
                : v
        );
    } else {
        updatedVariableList = [...variables, { editMode: true, id: ulid() }];
    }
    unsavedEnvironmentVariables$.next(updatedVariableList);
});

changeEnvironmentVariableTypeAction$.subscribe(({ id, parentId, type }) => {
    const variables = unsavedEnvironmentVariables$.value ?? [];

    let updatedVariableList: FullEnvironmentVariable[] = variables;

    if (parentId) {
        updatedVariableList = variables.map((v) =>
            v.id === parentId
                ? {
                      ...v,
                      childVariables: v.childVariables?.map((cv) => (cv.id === id ? { ...cv, type } : cv)),
                  }
                : v
        );
    } else {
        updatedVariableList = variables.map((v) => (v.id === id ? { ...v, type } : v));
    }
    unsavedEnvironmentVariables$.next(updatedVariableList);
});

createEnvironmentVariableFolderAction$.subscribe(() => {
    const variables = unsavedEnvironmentVariables$.value ?? [];
    unsavedEnvironmentVariables$.next([...variables, { editMode: true, id: ulid(), type: 'FOLDER' }]);
});

deleteEnvironmentVariableAction$.subscribe(({ id, parentId }) => {
    const variables = unsavedEnvironmentVariables$.value ?? [];

    let updatedVariableList: FullEnvironmentVariable[] = variables;

    if (parentId) {
        updatedVariableList = variables.map((v) =>
            v.id === parentId ? { ...v, childVariables: v.childVariables?.filter((cv) => cv.id !== id) } : v
        );
    } else {
        updatedVariableList = variables.filter((v) => v.id !== id);
    }

    unsavedEnvironmentVariables$.next(updatedVariableList);
});

updateEnvironmentVariableAction$.subscribe((event) => {
    const variables = unsavedEnvironmentVariables$.value ?? [];

    let updatedVariableList: FullEnvironmentVariable[] = variables;

    if (event.parentId) {
        updatedVariableList = variables.map((v) =>
            v.id === event.parentId
                ? {
                      ...v,
                      childVariables: v.childVariables?.map((cv) =>
                          cv.id === event.id
                              ? {
                                    ...cv,
                                    choices: event.choices,
                                    editMode: false,
                                    defaultValue: event.defaultValue,
                                    description: event.description,
                                    hasBeenEdited: true,
                                    keyName: event.keyName,
                                    required: event.required,
                                    type: event.type,
                                    saved: true,
                                    value: event.value,
                                }
                              : cv
                      ),
                  }
                : v
        );
    } else {
        updatedVariableList = variables.map((v) =>
            v.id === event.id
                ? {
                      ...v,
                      choices: event.choices,
                      editMode: false,
                      defaultValue: event.defaultValue,
                      description: event.description,
                      hasBeenEdited: true,
                      keyName: event.keyName,
                      required: event.required,
                      saved: true,
                      type: event.type,
                      value: event.value,
                  }
                : v
        );
    }

    unsavedEnvironmentVariables$.next(updatedVariableList);
});

toggleEnvironmentVariableEditModeAction$.subscribe(({ editMode, id, parentId }) => {
    const variables = unsavedEnvironmentVariables$.value ?? [];

    let updatedVariableList: FullEnvironmentVariable[] = variables;

    if (parentId) {
        updatedVariableList = variables.map((v) =>
            v.id === parentId
                ? { ...v, childVariables: v.childVariables?.map((cv) => (cv.id === id ? { ...cv, editMode } : cv)) }
                : v
        );
    } else {
        updatedVariableList = variables.map((v) => (v.id === id ? { ...v, editMode } : v));
    }

    unsavedEnvironmentVariables$.next(updatedVariableList);
});

toggleEnvironmentVariableExpandAction$.subscribe(({ expanded, id, parentId }) => {
    const variables = unsavedEnvironmentVariables$.value ?? [];

    let updatedVariableList: FullEnvironmentVariable[] = variables;

    if (parentId) {
        updatedVariableList = variables.map((v) =>
            v.id === parentId
                ? { ...v, childVariables: v.childVariables?.map((cv) => (cv.id === id ? { ...cv, expanded } : cv)) }
                : v
        );
    } else {
        updatedVariableList = variables.map((v) => (v.id === id ? { ...v, expanded } : v));
    }

    unsavedEnvironmentVariables$.next(updatedVariableList);
});

// eslint-disable-next-line sonarjs/cognitive-complexity
repositionEnvironmentVariableAction$.subscribe(({ newIndex, newParentId, originalIndex, originalParentId }) => {
    const variables = unsavedEnvironmentVariables$.value ?? [];

    let repositionedVariables: FullEnvironmentVariable[] = variables;

    if (newParentId === originalParentId) {
        if (newParentId) {
            repositionedVariables = variables.map((v) =>
                v.id === newParentId
                    ? {
                          ...v,
                          childVariables: arrayMove(v.childVariables ?? [], originalIndex, newIndex),
                      }
                    : v
            );
        } else {
            repositionedVariables = arrayMove(variables, originalIndex, newIndex);
        }
    } else {
        if (newParentId) {
            if (originalParentId) {
                const movedVariable = variables
                    .find((v) => v.id === originalParentId)
                    ?.childVariables?.find((_, i) => i === originalIndex);

                repositionedVariables = movedVariable
                    ? variables.map((v) =>
                          v.id === originalParentId
                              ? { ...v, childVariables: v.childVariables?.filter((_, i) => i !== originalIndex) }
                              : v.id === newParentId
                              ? {
                                    ...v,
                                    childVariables: [
                                        ...(v.childVariables ?? []).slice(0, newIndex),
                                        movedVariable,
                                        ...(v.childVariables ?? []).slice(newIndex),
                                    ],
                                    expanded: true,
                                }
                              : v
                      )
                    : variables;
            } else {
                const movedVariable = variables.find((_, i) => i === originalIndex);
                repositionedVariables = movedVariable
                    ? variables
                          .filter((_, i) => i !== originalIndex)
                          .map((v) =>
                              v.id === newParentId
                                  ? {
                                        ...v,
                                        childVariables: [
                                            ...(v.childVariables ?? []).slice(0, newIndex),
                                            movedVariable,
                                            ...(v.childVariables ?? []).slice(newIndex),
                                        ],
                                        expanded: true,
                                    }
                                  : v
                          )
                    : variables;
            }
        } else if (originalParentId) {
            const movedVariable = variables
                .find((v) => v.id === originalParentId)
                ?.childVariables?.find((_, i) => i === originalIndex);

            repositionedVariables = movedVariable
                ? [
                      ...variables.slice(0, newIndex),
                      movedVariable as FullEnvironmentVariable,
                      ...variables.slice(newIndex),
                  ].map((v) =>
                      v.id === originalParentId
                          ? { ...v, childVariables: v.childVariables?.filter((_, ix) => ix !== originalIndex) }
                          : v
                  )
                : variables;
        }
    }

    unsavedEnvironmentVariables$.next(repositionedVariables);
});

saveEnvironmentVariablesAction$
    .pipe(
        map(async () => {
            const unsavedVariables = unsavedEnvironmentVariables$.value ?? [];
            const savedVariables = savedEnvironmentVariables$.value ?? [];
            const workspaceUid = selectedWorkspaceUid$.value ?? '';
            const environmentUid = selectedEnvironmentUid$.value ?? '';

            const remappedVariables = unsavedVariables.map((v) => ({
                key: v.keyName ?? '',
                type: v.type ?? 'TEXT',
                required: !!v.required,
                description: v.description,
                defaultValue: v.defaultValue,
                uid: v.uid,
                value: v.value,
                choices: v.choices,
                children: v.childVariables?.map((cv) => ({
                    key: cv.keyName ?? '',
                    type: cv.type ?? 'TEXT',
                    required: !!cv.required,
                    description: cv.description,
                    defaultValue: cv.defaultValue,
                    uid: cv.uid,
                    value: cv.value,
                    choices: cv.choices,
                })),
            }));

            const deletedVariablesWarning = getDeletedVariablesWarning(savedVariables, unsavedVariables);

            environmentVariablesFormErrors$.next(undefined);

            const initiateSaveEnvironmentVariables = async (
                ignoreEditModeWarning?: boolean,
                ignoreDeletionWarning?: boolean
                // eslint-disable-next-line sonarjs/cognitive-complexity
            ): Promise<void> => {
                const flattenedVariables = [
                    ...unsavedVariables,
                    ...unsavedVariables.flatMap((v) => (v.childVariables ? v.childVariables : [])),
                ];
                if (!ignoreEditModeWarning && flattenedVariables.some((v) => v.editMode)) {
                    promptQuestion({
                        title: 'One or more parameters are still being edited. Are you sure you wish to proceed and potentially lose your changes?',
                        onProceed: async () => await initiateSaveEnvironmentVariables(true),
                    });
                } else if (!ignoreDeletionWarning && deletedVariablesWarning) {
                    promptQuestion({
                        title: 'Are you sure you wish to proceed? This will permanently delete the following parameters:',
                        messages: [deletedVariablesWarning],
                        cancelLabel: 'Revert all changes',
                        proceedLabel: 'Save',
                        onProceed: async () => await initiateSaveEnvironmentVariables(true, true),
                        onCancel: () => {
                            unsavedEnvironmentVariables$.next(savedVariables);
                        },
                    });
                } else {
                    try {
                        environmentVariablesFormSaving$.next(true);
                        await saveEnvironmentVariables({ environmentUid, variables: remappedVariables, workspaceUid });
                        await loadEnvironmentVariables(
                            selectedWorkspaceUid$.value ?? '',
                            selectedEnvironmentUid$.value ?? ''
                        );
                        environmentVariablesSavedAction$.next();
                        publishLocalFeedbackEventAction$.next({
                            level: 'SUCCESS',
                            message: 'Parameters saved.',
                        });
                    } catch (e) {
                        let message: string;

                        if (e instanceof InformativeError || e instanceof PermissionError) {
                            message = e.message;
                        } else {
                            message =
                                'Error occured while saving parameters. Please try again, if the issue persists please contact support.';
                            console.error('Error saving parameters', e);
                        }
                        environmentVariablesFormErrors$.next(message);
                    }
                    environmentVariablesFormSaving$.next(false);
                }
            };

            await initiateSaveEnvironmentVariables();
        })
    )
    .subscribe();

combineLatest([unsavedEnvironmentVariables$, savedEnvironmentVariables$]).subscribe(
    ([unsavedVariables, savedVariables]) => {
        const getRemappedVariables = (variables: FullEnvironmentVariable[]): EnvironmentVariable[] => {
            return variables.map((v) => ({
                children: v.childVariables && getRemappedVariables(v.childVariables),
                choices: v.choices,
                defaultValue: v.defaultValue,
                description: v.description,
                key: v.keyName ?? '',
                passwordValueFilled: v.passwordValueFilled,
                required: v.required,
                type: v.type ?? 'TEXT',
                uid: v.uid,
                value: v.value,
            }));
        };

        if (unsavedVariables && savedVariables) {
            const remappedUnsavedVariables = getRemappedVariables(unsavedVariables);
            const remappedSavedVariables = getRemappedVariables(savedVariables);

            const hasUnsavedChanges =
                JSON.stringify(remappedSavedVariables) !== JSON.stringify(remappedUnsavedVariables);
            environmentVariablesHaveUnsavedChanges$.next(hasUnsavedChanges);

            const flattenedVariables = [
                ...unsavedVariables,
                ...unsavedVariables.flatMap((v) => (v.childVariables ? v.childVariables : [])),
            ];

            const missingInformation = getEnvironmentVariablesMissingInformation(flattenedVariables);

            environmentVariablesHaveMissingInformation$.next(missingInformation);
        }
    }
);

const getDeletedVariablesWarning = (
    savedVariables: FullEnvironmentVariable[],
    unsavedVariables: FullEnvironmentVariable[]
): string | undefined => {
    const remappedVariables = savedVariables.map((sv) => ({
        ...sv,
        childVariables: sv.childVariables && sv.childVariables.map((cv) => ({ ...cv, parentName: sv.keyName })),
        parentName: '',
    }));

    const flattenedVariables = [...remappedVariables, ...remappedVariables.flatMap((rv) => rv.childVariables ?? [])];

    const deletedVariables = flattenedVariables.filter(
        (sv) => !unsavedVariables.some((v) => v.uid === sv.uid || v.childVariables?.some((cv) => cv.uid === sv.uid))
    );

    return deletedVariables.length
        ? deletedVariables
              .map((v) => (v.parentName ? `${v.parentName}/` : '') + (v.keyName ?? '[KEYNAME MISSING]'))
              .join(', ')
        : undefined;
};
