import { LoaderFn } from 'react-location';
import { getOrganizationsReport } from '../../data/report/organizations';
import { LocationGenerics } from '../../router';
import {
    OrganizationsReportRequestPeriod,
    OrganizationsReportRequestPlan,
    OrganizationsReportRequestStatus,
    OrganizationsReportRequestUserType,
} from '@avst-stitch/repository-lib/lib/rpcs/report/getOrganisations';
import { organizationReportErrors$, organizationsReport$ } from '../../store/report/organizations';

export const organizationsReportLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    organizationReportErrors$.next(undefined);

    const organizationsReport = await getOrganizationsReport({
        userType: routeMatch.search.userType as OrganizationsReportRequestUserType,
        status: routeMatch.search.status as OrganizationsReportRequestStatus,
        plan: routeMatch.search.plan as OrganizationsReportRequestPlan,
        planPeriod: routeMatch.search.planPeriod as OrganizationsReportRequestPeriod,
    });
    organizationsReport$.next(organizationsReport);

    return {};
};
