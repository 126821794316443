import { horizonDark, horizonLight, ThemeMode, useThemeMode } from './index';
import CssBaseline from '@mui/material/CssBaseline';
import { Theme, ThemeProvider } from '@mui/material/styles';
import { StylesProvider } from '@mui/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/de-at';
import 'dayjs/locale/de-ch';
import 'dayjs/locale/en';
import 'dayjs/locale/en-au';
import 'dayjs/locale/en-ca';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/es';
import 'dayjs/locale/et';
import 'dayjs/locale/eu';
import 'dayjs/locale/fr';
import 'dayjs/locale/fr-ca';
import 'dayjs/locale/fr-ch';
import 'dayjs/locale/pt';
import 'dayjs/locale/pt-br';

//TODO we need to support more locales but this probably needs to be done with supporting more languages
const localeMap: Record<string, string> = {
    de: 'de',
    'de-AT': 'de-at',
    'de-CH': 'de-ch',
    'de-DE': 'de-de',
    en: 'en',
    'en-AU': 'en-au',
    'en-CA': 'en-ca',
    'en-GB': 'en-gb',
    es: 'es',
    et: 'et',
    eu: 'eu',
    fr: 'fr',
    'fr-CA': 'fr',
    'fr-CH': 'fr-ch',
    pt: 'pt',
    'pt-BR': 'pt-br',
};

export const stitchThemeModeKey = 'stitchThemeMode';

export interface AppThemeProps {
    /**
     * Select an alternative light theme
     * Defaults to the Horizon theme
     * null will explicitly exclude the ThemeProvider
     */
    readonly theme?: Partial<Theme> | ((theme: Theme) => Theme);

    /**
     * Select an alternative dark theme
     * Defaults to the Horizon theme
     */
    readonly darkTheme?: Partial<Theme> | ((theme: Theme) => Theme);

    /**
     * Select the theme mode: 'light', 'dark', or 'system'
     * Defaults to 'system' (which defaults to 'light' if not detectable)
     */
    readonly themeMode: ThemeMode;

    /**
     * Children of the component
     */
    readonly children?: React.ReactNode;

    /**
     * add stylesprovider - lets us use the theme wrapper in multiple places
     */
    readonly stylesInject?: boolean;
}

export const AppTheme: React.FC<AppThemeProps> = ({
    theme = horizonLight,
    darkTheme = horizonDark,
    themeMode,
    children,
    stylesInject = false,
}) => {
    const actualTheme = useThemeMode(themeMode, theme, darkTheme);

    return (
        <StylesProvider injectFirst={stylesInject}>
            <ThemeProvider theme={actualTheme}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={localeMap[navigator.language] ?? 'en'}>
                    <CssBaseline />
                    {children}
                </LocalizationProvider>
            </ThemeProvider>
        </StylesProvider>
    );
};
