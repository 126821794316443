import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import CodeIcon from '@mui/icons-material/Code';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LinkIcon from '@mui/icons-material/Link';
import PlayCircleOutlineRoundedIcon from '@mui/icons-material/PlayCircleOutlineRounded';
import WarningOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { ProductIcon } from '../../icons/ProductIcon';
import {
    ResourceActionButton,
    StyledResourceListItemButton,
    StyledResourceActionButtons,
    StyledResourceIcon,
    StyledResourceInfo,
    StyledResourceSubtitle,
    StyledResourceTitle,
} from './ResourceTreeComponents';
import { APP } from '@avst-stitch/repository-lib/constants';

interface ResourceTreeEventListenerProps {
    appName?: string;
    deleting?: boolean;
    deployedEnvironmentMode?: boolean;
    eventType?: string;
    executing?: boolean;
    externalTriggerBaseUrl?: string;
    inactive?: boolean;
    needsSetup?: boolean;
    readOnlyMode?: boolean;
    scriptName?: string;
    selectedNode?: string;
    uid: string;
    urlId?: string;
    disabled?: boolean;
    onDelete(uid: string): void;
    onOpen(uid: string): void;
    onTrigger?(uid: string): void;
    onUrlCopy?(uid: string): void;
}

export const ResourceTreeEventListener: React.FC<ResourceTreeEventListenerProps> = ({
    appName,
    deleting = false,
    deployedEnvironmentMode = false,
    eventType,
    executing = false,
    externalTriggerBaseUrl,
    inactive = false,
    needsSetup = false,
    readOnlyMode = false,
    scriptName,
    selectedNode,
    uid,
    urlId,
    disabled = false,
    onDelete,
    onOpen,
    onTrigger,
    onUrlCopy,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const selected = selectedNode === uid;
    const isGeneric = appName === APP.GENERIC.NAME;
    const showUrlId = !needsSetup && !readOnlyMode && !!urlId && isGeneric;
    const showScriptName = !needsSetup && !!scriptName;
    const showSetupWarning = !readOnlyMode && needsSetup;
    const showInactiveWarning = inactive && !needsSetup && !readOnlyMode;
    const showDisabled = disabled && !inactive && !needsSetup && !readOnlyMode;
    const canCopyPath = isGeneric && !readOnlyMode && !!urlId;
    const canTrigger = !deleting && !needsSetup && !readOnlyMode;
    const canDelete = !deployedEnvironmentMode && !readOnlyMode;

    return (
        <StyledResourceListItemButton key={uid} onClick={() => onOpen(uid)} selected={selected}>
            <StyledResourceInfo>
                <StyledResourceTitle>
                    <Tooltip title="Event Listener" placement="top">
                        <StyledResourceIcon role="img">
                            <ProductIcon name={appName} />
                        </StyledResourceIcon>
                    </Tooltip>
                    <Typography>{eventType ?? 'New Event Listener'}</Typography>
                </StyledResourceTitle>
                {showUrlId && (
                    <Tooltip title={`${externalTriggerBaseUrl}/${urlId}`} placement="top-start">
                        <StyledResourceSubtitle selected={selected} className="main" role="note">
                            <LinkIcon />
                            <Typography pr={1}>URL ID:</Typography>
                            <Typography>{urlId}</Typography>
                        </StyledResourceSubtitle>
                    </Tooltip>
                )}
                {showScriptName && (
                    <StyledResourceSubtitle selected={selected} className="main">
                        <CodeIcon />
                        <Typography pr={1}>Script:</Typography>
                        <Typography>{scriptName}</Typography>
                    </StyledResourceSubtitle>
                )}
                {showSetupWarning && (
                    <StyledResourceSubtitle className="warning">
                        <WarningOutlinedIcon />
                        <Typography>Complete event listener setup</Typography>
                    </StyledResourceSubtitle>
                )}
                {showInactiveWarning && (
                    <StyledResourceSubtitle className="warning">
                        <WarningOutlinedIcon />
                        <Typography>Inactive</Typography>
                    </StyledResourceSubtitle>
                )}
                {showDisabled && (
                    <StyledResourceSubtitle className="warning">
                        <WarningOutlinedIcon />
                        <Typography>Disabled</Typography>
                    </StyledResourceSubtitle>
                )}
            </StyledResourceInfo>
            <StyledResourceActionButtons>
                {canCopyPath && (
                    <ResourceActionButton
                        icon={<ContentCopyIcon fontSize="small" color="primary" />}
                        tooltipTitle="Copy URL"
                        uid={uid}
                        onClick={onUrlCopy}
                    />
                )}
                {canTrigger && (
                    <ResourceActionButton
                        busy={executing}
                        icon={<PlayCircleOutlineRoundedIcon fontSize="small" color="primary" />}
                        tooltipTitle="Emulate event with test payload"
                        uid={uid}
                        onClick={onTrigger}
                    />
                )}
                {canDelete && (
                    <ResourceActionButton
                        busy={deleting}
                        icon={<DeleteOutlineIcon fontSize="small" color="primary" />}
                        tooltipTitle="Delete"
                        uid={uid}
                        onClick={onDelete}
                    />
                )}
            </StyledResourceActionButtons>
        </StyledResourceListItemButton>
    );
};
