import { useObservableState } from 'observable-hooks';
import { OnboardingAppSelector } from '../../../components/onboarding/wizard/app-selector/OnboardingAppSelector';
import {
    navigateToOnboardingStepAction$,
    onboardingWizardError$,
    onboardingWizardSaving$,
    saveUserAppPreferenceAction$,
} from '../../../store/onboarding';
import { apps$ } from '../../../store/apps';
import { loggedInUserDetails$ } from '../../../store/user';

export const OnboardingAppSelectorContainer: React.FC = () => {
    const apps = useObservableState(apps$);
    const errors = useObservableState(onboardingWizardError$);
    const saving = useObservableState(onboardingWizardSaving$);
    const loggedInUserDetails = useObservableState(loggedInUserDetails$);

    return (
        <OnboardingAppSelector
            apps={apps.map((a) => ({ name: a.name, uid: a.uid }))}
            errors={errors}
            saving={saving}
            selectedApps={loggedInUserDetails?.userAppPreference ?? []}
            onGoBack={() => navigateToOnboardingStepAction$.next({ route: 'scripting' })}
            onSave={(apps) => saveUserAppPreferenceAction$.next(apps)}
        />
    );
};
