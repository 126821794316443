import { useObservableState, useSubscription } from 'observable-hooks';
import {
    selectedEnvironmentUid$,
    selectedWorkspace$,
    selectedWorkspaceEnvironment$,
    selectedWorkspaceResources$,
    selectedWorkspaceSelectedResource$,
    selectedWorkspaceUid$,
} from '../../../../store/workspace';
import { loggedInUserConnections$ } from '../../../../store/connections';
import { getUserDisplayName } from '../../../../utils/account';
import { useNavigate } from 'react-location';
import {
    openEventListenerSetupDialog,
    saveEventListenerAction$,
    savingSelectedEventListener$,
    selectedEventListenerDetails$,
    selectedEventListenerErrors$,
} from '../../../../store/workspace/event-listener';
import { apps$ } from '../../../../store/apps';
import {
    cancelCreateEventListenerPayloadAction$,
    changeEventListenerPayloadContentAction$,
    createEventListenerPayloadDialogOpen$,
    loadEventListenerPayloadContentAction$,
    loadingEventListenerPayloadContent$,
    newBlankEventListenerPayloadErrors$,
    openCreateEventListenerPayloadAction$,
    saveEventListenerPayloadAction$,
    saveNewBlankEventListenerPayloadAction$,
    savedEventListenerPayloadContent$,
    savingNewBlankEventListenerPayload$,
    savingSelectedEventListenerSelectedPayload$,
    selectedEventListenerPayloads$,
    selectedEventListenerSelectedPayloadErrors$,
    selectedEventListenerSelectedPayloadUid$,
    storedEventListenerPayloadContent$,
} from '../../../../store/workspace/event-listener-payload';
import { connectionCreatedAction$, openConnectionDetailsDialogAction$ } from '../../../../store/connection';
import { useState } from 'react';
import { newDeploymentCreatedAction$ } from '../../../../store/workspace/deployment';
import { getBasePath } from '../../../../utils/path';
import { NewEventListenerPayloadDialog } from '../../../../components/workspace-resources/event-listeners/event-listener-payloads/NewEventListenerPayloadDialog';
import { EventListenerTabbedDetails } from '../../../../components/workspace-resources/advanced-view/event-listeners/EventListenerTabbedDetails';

export const EventListenerDetailsContainer: React.FC = () => {
    const navigate = useNavigate();

    const [createdConnection, setCreatedConnection] = useState<string | undefined>(undefined);

    const { scripts } = useObservableState(selectedWorkspaceResources$);
    const connections = useObservableState(loggedInUserConnections$);
    const details = useObservableState(selectedEventListenerDetails$);
    const errors = useObservableState(selectedEventListenerErrors$);
    const saving = useObservableState(savingSelectedEventListener$);
    const selectedWorkspace = useObservableState(selectedWorkspace$);
    const apps = useObservableState(apps$);
    const workspaceResources = useObservableState(selectedWorkspaceResources$);
    const selectedWorkspaceEnvironment = useObservableState(selectedWorkspaceEnvironment$);

    const payloadErrors = useObservableState(selectedEventListenerSelectedPayloadErrors$);
    const payloads = useObservableState(selectedEventListenerPayloads$);
    const savingPayload = useObservableState(savingSelectedEventListenerSelectedPayload$);
    const loadingPayloadContent = useObservableState(loadingEventListenerPayloadContent$);
    const storedPayloadContent = useObservableState(storedEventListenerPayloadContent$);
    const savedPayloadContent = useObservableState(savedEventListenerPayloadContent$);
    const selectedPayloadUid = useObservableState(selectedEventListenerSelectedPayloadUid$);

    const createEventListenerPayloadDialogOpen = useObservableState(createEventListenerPayloadDialogOpen$);
    const newBlankEventListenerPayloadErrors = useObservableState(newBlankEventListenerPayloadErrors$);
    const savingNewBlankEventListenerPayload = useObservableState(savingNewBlankEventListenerPayload$);

    useSubscription(connectionCreatedAction$, (connectionUid) => {
        setCreatedConnection(connectionUid);
    });

    useSubscription(newDeploymentCreatedAction$, () => {
        // Ugly hack to refresh the page: https://github.com/TanStack/react-location/discussions/269
        // TODO: find a fix
        navigate({ to: '../../' });
        setTimeout(
            () =>
                navigate({
                    to: `${getBasePath()}/workspace/${selectedWorkspaceUid$.value}/environment/${
                        selectedEnvironmentUid$.value
                    }/event/${details?.uid}`,
                }),
            100
        );
    });

    const appName = apps.find((a) => a.uid === details?.appUid)?.name;

    const currentApp = apps.find((a) => a.uid === details?.appUid);
    const filteredEventTypes =
        currentApp?.connectionType.eventListenerTypes.find((elt) => elt.uid === details?.eventListenerTypeUid)
            ?.eventTypes ?? [];

    const filteredConnections = connections
        .filter((c) => c.connectionType.uid === details?.connectionTypeUid)
        .map((c) => ({
            authorized: c.authorized,
            name: c.name,
            uid: c.uid,
        }));

    if (details?.selectedSharedConnection) {
        filteredConnections.unshift({
            authorized: details.selectedSharedConnection.authorized,
            name: `${details.selectedSharedConnection.name} (owned by ${getUserDisplayName(
                details.selectedSharedConnection.ownedBy
            )})`,
            uid: details.selectedSharedConnection.uid,
        });
    }

    const handleCancel = (): void => {
        navigate({ to: '../../' });
        selectedWorkspaceSelectedResource$.next(undefined);
    };

    const handleNewConnection = (): void => {
        const connectionType = (apps ?? [])
            .flatMap((app) => app.connectionType)
            .find((ct) => ct.uid === details?.connectionTypeUid);

        if (connectionType) {
            openConnectionDetailsDialogAction$.next({
                connectionType,
            });
        } else {
            console.error('Connection type not found');
            alert('Connection type not found');
        }
    };

    const existingEventListeners = workspaceResources?.eventListeners.map((el) => el.eventType?.name);
    const existingInteractiveComponent = filteredEventTypes.some(
        (et) => existingEventListeners.includes(et.name) && et.category === 'Interactive Component'
    );

    //eslint-disable-next-line sonarjs/cognitive-complexity
    const handleViewSetupInstructions = (): void => {
        if (details) {
            openEventListenerSetupDialog(
                {
                    connectionUid: details.connectionUid,
                    eventListenerTypeUid: details.eventListenerTypeUid,
                    eventTypeCategory: details.eventTypeCategory,
                    uid: details.uid,
                    urlId: details.urlId,
                    eventTypeUid: details.eventTypeUid,
                },
                details.appUid
            );
        }
    };

    const storedContent = selectedPayloadUid ? storedPayloadContent[selectedPayloadUid] ?? '' : '';
    const savedContent = selectedPayloadUid ? savedPayloadContent[selectedPayloadUid] ?? '' : '';

    const hasUnsavedChanges = storedContent !== savedContent;

    return (
        <>
            <EventListenerTabbedDetails
                appName={appName ?? ''}
                details={{
                    connections: filteredConnections,
                    createdConnectionUid: createdConnection,
                    disabled: details?.disabled,
                    errors: errors,
                    eventTypes: filteredEventTypes,
                    saving: saving,
                    scripts: scripts,
                    selectedConnectionUid: details?.connectionUid,
                    selectedEventTypeUid: details?.eventTypeUid,
                    selectedScriptUid: details?.scriptUid,
                    uid: details?.uid ?? '',
                    urlId: details?.urlId ?? '',
                    webhookSetup: !!details?.urlId,
                    onCancel: handleCancel,
                    onOpenSetupInstructions: handleViewSetupInstructions,
                    onSave: (event) => saveEventListenerAction$.next(event),
                    onNewConnection: handleNewConnection,
                    existingEvent: existingInteractiveComponent,
                    workspaceLocked: !!selectedWorkspace?.locked,
                    connectionRequired: details?.connectionRequired ?? true,
                    environmentDeployed: selectedWorkspaceEnvironment?.deployment !== undefined,
                    hasImplicitlySharedConnectionAttached: !!details?.selectedSharedConnection,
                    warnings: details?.warnings,
                }}
                testPayload={{
                    activePayloadUid: payloads?.selectedTestPayloadUid,
                    content: storedContent,
                    errors: payloadErrors,
                    eventListenerPayloads: payloads?.testPayloads,
                    hasUnsavedChanges: hasUnsavedChanges,
                    loadingContent: loadingPayloadContent,
                    requiresSetup: !details?.urlId,
                    saving: savingPayload,
                    selectedPayloadUid: selectedPayloadUid ?? '',
                    onCancel: handleCancel,
                    onContentChange: (payload) => changeEventListenerPayloadContentAction$.next(payload),
                    onCreateNewPayload: () => openCreateEventListenerPayloadAction$.next(),
                    onSave: (payload) => saveEventListenerPayloadAction$.next(payload),
                    onSelectTestPayload: (uid) => loadEventListenerPayloadContentAction$.next(uid),
                }}
            />
            <NewEventListenerPayloadDialog
                open={createEventListenerPayloadDialogOpen}
                saving={savingNewBlankEventListenerPayload}
                errors={newBlankEventListenerPayloadErrors}
                onCancel={() => cancelCreateEventListenerPayloadAction$.next()}
                onSave={(name) => {
                    saveNewBlankEventListenerPayloadAction$.next({
                        name,
                        environmentUid: selectedWorkspaceEnvironment?.uid ?? '',
                        eventListenerUid: details?.uid ?? '',
                    });
                }}
            />
        </>
    );
};
