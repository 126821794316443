import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Alert } from '../../common/alerts/Alert';
import { PageContainer, PageTitle } from '../../app-main/PageComponentsForDeprecation';
import { LoadingSpinner } from '../../common/LoadingSpinner';
import { UserAuditLogsResponse, UserAuditLogsRequest } from '../../../data/reporting';
import { AuditLogTableRow } from './AuditLogTableRow';
import { Button } from '../../common/buttons/Button';
import { useInfiniteScroll } from '../../../utils/miscellaneous';

interface AuditLogsPageProps {
    auditLogs: UserAuditLogsResponse['auditLogs'];
    selectedTeamUid?: string;
    cursor?: string;
    isLoading: boolean;
    errors?: string;
    basePath: string;
    isGenerating?: boolean;
    onQueryAuditLogs: (request: UserAuditLogsRequest) => void;
    onExportCsv?: () => void;
}

export const AuditLogsPage: React.FC<AuditLogsPageProps> = ({
    auditLogs,
    selectedTeamUid,
    cursor,
    isLoading,
    errors,
    basePath,
    isGenerating = false,
    onQueryAuditLogs,
    onExportCsv,
}) => {
    const lastPostRef = useInfiniteScroll({
        cursor,
        loading: isLoading,
        callback: () => {
            if (selectedTeamUid) {
                onQueryAuditLogs({
                    organizationUid: selectedTeamUid,
                    cursor,
                });
            }
        },
    });

    return (
        <PageContainer sx={{ bgcolor: 'background.paper' }}>
            <PageTitle title="Audit Logs" />
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    {errors && <Alert severity="error" title={errors} />}
                    {auditLogs.length > 0 && (
                        <Button variant="contained" busy={isGenerating} onClick={onExportCsv}>
                            Export to CSV
                        </Button>
                    )}
                    {auditLogs.length > 0 ? (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Actor</TableCell>
                                    <TableCell>Action</TableCell>
                                    <TableCell>IP Address</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {auditLogs.map((auditLog, i) => (
                                    <AuditLogTableRow
                                        key={auditLog.uid}
                                        auditLog={auditLog}
                                        basePath={basePath}
                                        lastPostRef={auditLogs.length === i + 1 ? lastPostRef : undefined}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    ) : (
                        <div>No Audit logs found</div>
                    )}
                </>
            )}
        </PageContainer>
    );
};
