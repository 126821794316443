import { useObservableState } from 'observable-hooks';
import { OnboardingIndustryRoleSelector } from '../../../components/onboarding/wizard/OnboardingIndustryRoleSelector';
import {
    onboardingWizardError$,
    onboardingWizardSaving$,
    saveUserIndustryRoleAction$,
} from '../../../store/onboarding';
import { industryRoles$ } from '../../../store/roles';
import { loggedInUserDetails$ } from '../../../store/user';

export const OnboardingIndustryRoleSelectorContainer: React.FC = () => {
    const errors = useObservableState(onboardingWizardError$);
    const saving = useObservableState(onboardingWizardSaving$);
    const roles = useObservableState(industryRoles$);
    const loggedInUserDetails = useObservableState(loggedInUserDetails$);

    return (
        <OnboardingIndustryRoleSelector
            customRole={loggedInUserDetails?.customRole ?? undefined}
            errors={errors}
            roles={roles}
            roleUid={loggedInUserDetails?.roleUid ?? undefined}
            saving={saving}
            onSave={(details) => saveUserIndustryRoleAction$.next(details)}
        />
    );
};
