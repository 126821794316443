import { BehaviorSubject, map, Subject } from 'rxjs';
import {
    connectionCreatedAction$,
    ConnectionDetails,
    connectionCreatedFirstTimeAction$,
    connectionCreatedFirstTime$,
} from '..';
import { createConnection, saveConnection } from '../../../data/connection';
import { InformativeError } from '../../../utils/error';
import { configTopic$ } from '../../config';
import { publishLocalFeedbackEventAction$ } from '../../feedback';
import { monitor } from '../../monitor';
import {
    initiate,
    loadLoggedInUserConnectionsWhenEventIsEmitted,
    saveConnectionAllowed,
    openConsentWindow,
} from '../utils';
import { promptQuestion } from '../../confirm';
import { AYSYWTSAETCWHAI } from '../../../i18n';

export const gitlabManageConnectionOpenDialogAction$ = monitor(
    'gitlabManageConnectionOpenDialogAction$',
    new Subject<ConnectionDetails>()
);
export const gitlabManageConnectionCloseDialogAction$ = monitor(
    'gitlabManageConnectionCloseDialogAction$',
    new Subject<void>()
);

export const gitlabManageConnectionInitiateAuthorizeAction$ = monitor(
    'gitlabManageConnectionInitiateAuthorizeAction$',
    new Subject<string>()
);

export const gitlabSaveConnectionAction$ = monitor('gitlabSaveConnectionAction$', new Subject<string>());
export const gitlabConnectionCreatedAction$ = monitor('gitlabConnectionCreatedAction$', new Subject<string>());
export const gitlabConnectionSavedAction$ = monitor(
    'gitlabConnectionSavedAction$',
    new Subject<{ uid: string; connectionTypeUid: string }>()
);
export const gitlabManageConnectionInitiateSetupAction$ = monitor(
    'gitlabManageConnectionInitiateSetupAction$',
    new Subject<string>()
);

export const gitlabManageConnectionDialogOpen$ = monitor(
    'gitlabManageConnectionDialogOpen$',
    new BehaviorSubject(false)
);
export const gitlabConnectionSaving$ = monitor('gitlabConnectionSaving$', new BehaviorSubject(false));
export const gitlabManageConnectionAuthorizeLoading$ = monitor(
    'gitlabManageConnectionAuthorizeLoading$',
    new BehaviorSubject(false)
);
export const gitlabManageConnectionDetails$ = monitor(
    'googleManageConnectionDetails$',
    new BehaviorSubject<ConnectionDetails | undefined>(undefined)
);
export const gitlabManageConnectionDialogErrors$ = monitor(
    'gitlabManageConnectionDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

gitlabManageConnectionOpenDialogAction$.subscribe((details) => {
    gitlabManageConnectionDialogErrors$.next(undefined);
    gitlabManageConnectionDialogOpen$.next(true);
    gitlabManageConnectionDetails$.next(details);
});

gitlabManageConnectionCloseDialogAction$.subscribe(() => {
    gitlabManageConnectionAuthorizeLoading$.next(false);
    gitlabManageConnectionDialogOpen$.next(false);
    gitlabManageConnectionDetails$.next(undefined);
});

gitlabConnectionCreatedAction$.subscribe((uid) => connectionCreatedAction$.next(uid));

gitlabSaveConnectionAction$
    .pipe(
        map(async (name) => {
            gitlabConnectionSaving$.next(true);
            gitlabManageConnectionDialogErrors$.next(undefined);

            const { uid, connectionType } = gitlabManageConnectionDetails$.value ?? {};

            try {
                if (!connectionType) {
                    throw Error('GitLab connector type not defined');
                }
                const connectionTypeUid = connectionType.uid;

                if (uid) {
                    if (saveConnectionAllowed(uid)) {
                        await saveConnection(uid, name);

                        gitlabConnectionSavedAction$.next({ uid, connectionTypeUid });
                        gitlabManageConnectionCloseDialogAction$.next();
                        publishLocalFeedbackEventAction$.next({
                            level: 'SUCCESS',
                            message: 'Connector saved.',
                        });
                    } else {
                        promptQuestion({
                            title: AYSYWTSAETCWHAI,
                            onProceed: async () => {
                                await saveConnection(uid, name);

                                gitlabConnectionSavedAction$.next({ uid, connectionTypeUid });
                                gitlabManageConnectionCloseDialogAction$.next();
                                publishLocalFeedbackEventAction$.next({
                                    level: 'SUCCESS',
                                    message: 'Connector saved.',
                                });
                            },
                        });
                    }
                    if (connectionCreatedFirstTime$.value) {
                        connectionCreatedFirstTimeAction$.next();
                        connectionCreatedFirstTime$.next(false);
                    }
                } else {
                    promptQuestion({
                        title: AYSYWTSAETCWHAI,
                        onProceed: async () => {
                            const { uid } = await createConnection(
                                connectionType.uid,
                                name,
                                connectionType.apiHandlerTypes[0]?.uid ?? '',
                                connectionType.eventListenerTypes[0]?.uid ?? ''
                            );

                            gitlabConnectionCreatedAction$.next(uid);
                            gitlabConnectionSavedAction$.next({ uid, connectionTypeUid });
                            gitlabManageConnectionCloseDialogAction$.next();
                            publishLocalFeedbackEventAction$.next({
                                level: 'SUCCESS',
                                message: 'Connector created.',
                            });
                            connectionCreatedFirstTimeAction$.next();
                        },
                    });
                }
            } catch (e) {
                if (e instanceof InformativeError) {
                    gitlabManageConnectionDialogErrors$.next(e.message);
                } else {
                    gitlabManageConnectionDialogErrors$.next(
                        'Failed to save connector, please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while saving GitLab connector', e);
                }
            }

            gitlabConnectionSaving$.next(false);
        })
    )
    .subscribe();

gitlabManageConnectionInitiateAuthorizeAction$
    .pipe(
        map(async (name) => {
            gitlabConnectionSaving$.next(true);
            gitlabManageConnectionAuthorizeLoading$.next(true);
            gitlabManageConnectionDialogErrors$.next(undefined);

            const baseUrl = configTopic$.value.connection?.gitlab?.baseUrl;
            if (!baseUrl) {
                throw new Error('No Gitlab Connector URL configured in meta');
            }
            const initiateUrl = `${baseUrl}/initiate`;

            const { uid, connectionType } = gitlabManageConnectionDetails$.value ?? {};

            if (!connectionType) {
                throw Error('GitLab Connector type not defined');
            }
            const connectionTypeUid = connectionType.uid;
            const connectionTypeName = connectionType.name.toLowerCase().replace(' ', '-');

            if (uid) {
                await saveConnection(uid, name);

                gitlabConnectionSavedAction$.next({ uid, connectionTypeUid });
                const response = await initiate({ connectionId: uid, connectionType: connectionTypeName }, initiateUrl);
                openConsentWindow(response.location, gitlabManageConnectionAuthorizeLoading$);
            } else {
                try {
                    const { uid } = await createConnection(
                        connectionType.uid,
                        name,
                        connectionType.apiHandlerTypes[0]?.uid ?? '',
                        connectionType.eventListenerTypes[0]?.uid ?? ''
                    );

                    gitlabConnectionCreatedAction$.next(uid);
                    gitlabConnectionSavedAction$.next({ uid, connectionTypeUid });
                    gitlabManageConnectionDetails$.next({
                        connectionType,
                        name,
                        uid,
                    });

                    gitlabConnectionSaving$.next(false);
                    const response = await initiate(
                        { connectionId: uid, connectionType: connectionTypeName },
                        initiateUrl
                    );
                    openConsentWindow(response.location, gitlabManageConnectionAuthorizeLoading$);
                    connectionCreatedFirstTime$.next(true);
                } catch (e) {
                    if (e instanceof InformativeError) {
                        gitlabManageConnectionDialogErrors$.next(e.message);
                    } else {
                        gitlabManageConnectionDialogErrors$.next(
                            'Failed to save Connector, please try again, if the issue persists please contact support.'
                        );
                        console.error('Error while saving GitLab Connector', e);
                    }
                    gitlabManageConnectionAuthorizeLoading$.next(false);
                }
            }

            gitlabConnectionSaving$.next(false);
        })
    )
    .subscribe();

loadLoggedInUserConnectionsWhenEventIsEmitted(gitlabConnectionSavedAction$);
