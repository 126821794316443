import { useObservableState } from 'observable-hooks';
import { OnboardingScriptingFamiliaritySelector } from '../../../components/onboarding/wizard/OnboardingScriptingFamiliaritySelector';
import {
    navigateToOnboardingStepAction$,
    onboardingWizardError$,
    onboardingWizardSaving$,
    saveUserScriptingFamiliarityAction$,
    scriptingFamiliarityOptions$,
} from '../../../store/onboarding';
import { loggedInUserDetails$ } from '../../../store/user';

export const OnboardingScriptingFamiliaritySelectorContainer: React.FC = () => {
    const errors = useObservableState(onboardingWizardError$);
    const saving = useObservableState(onboardingWizardSaving$);
    const scriptingFamiliarityOptions = useObservableState(scriptingFamiliarityOptions$);
    const loggedInUserDetails = useObservableState(loggedInUserDetails$);

    return (
        <OnboardingScriptingFamiliaritySelector
            errors={errors}
            scriptingFamiliarityOptions={scriptingFamiliarityOptions}
            selectedScriptingFamiliarityUid={loggedInUserDetails?.scriptingFamiliarityUid ?? undefined}
            saving={saving}
            onGoBack={() => navigateToOnboardingStepAction$.next({ route: 'role' })}
            onSave={(uid) => saveUserScriptingFamiliarityAction$.next(uid)}
        />
    );
};
