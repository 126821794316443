import { styled } from '@mui/material';
import List, { ListProps } from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

interface BulletListProps extends ListProps {
    content?: (string | JSX.Element)[];
    id: string;
}

const StyledBulletListItem = styled(ListItem)(({ theme }) => ({
    padding: theme.spacing(0, 2),
    '&::before': {
        content: '"•"',
        color: theme.palette.text.primary,
        fontWeight: 'bold',
        display: 'inline-block',
        width: '1em',
    },
}));

export const BulletList: React.FC<BulletListProps> = ({ content = [], id }) => {
    const mappedContent = content.map((c, i) =>
        typeof c === 'string' ? <StyledBulletListItem key={`${id}-${i}`}>{c}</StyledBulletListItem> : c
    );

    return <List>{mappedContent}</List>;
};
