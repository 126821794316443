import { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Link } from 'react-location';
import ReactHtmlParser from 'react-html-parser';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import { Button } from '../../common/buttons/Button';
import { IconButton } from '../../common/buttons/IconButton';
import { StyledMainActions } from '../SetupGuideComponents';
import { ToggleButtonGroup } from '../../common/buttons/button-groups/ToggleButtonGroup';
import { Editor } from '@monaco-editor/react';
import { getSanitizedParsedMarkdown } from '../../../utils/readme';

export interface SetupGuideReadmeProps {
    content: string;
    fullScreenReadmeLink: string;
    hasUnsavedChanges?: boolean;
    templateMode?: boolean;
    useRouter?: boolean;
    templatePreviewMode?: boolean;
    saving?: boolean;
    workspaceLocked?: boolean;
    onCancel: () => void;
    onChangeContent: (content: string) => void;
    onClose?: () => void;
    onSave: () => void;
}

const StyledReadmeBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'templateMode',
})<{ templateMode?: boolean }>(({ theme, templateMode }) => ({
    backgroundColor: theme.palette.background.paper,
    borderLeft: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: !templateMode ? undefined : 600,
    minWidth: 512,
    overflow: 'hidden',
    width: !templateMode ? '100%' : undefined,
    height: '100%',
}));

const StyledReadmeHeader = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    height: 66,
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    width: '100%',
}));

const StyledReadmeHeaderActions = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(1),
    height: 66,
    justifyContent: 'center',
}));

const StyledReadmeMain = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: theme.spacing(2),
    overflow: 'hidden',
    padding: theme.spacing(2),
    width: '100%',
}));

const StyledReadmeContent = styled(Box)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    flexGrow: 1,
    flexShrink: 1,
    padding: theme.spacing(2),
    overflow: 'auto',
}));

const StyledReadmePreviewContent = styled(StyledReadmeContent)(({ theme }) => ({
    padding: theme.spacing(0, 2),
}));

const StyledReadmeEditModeContent = styled(StyledReadmeContent)(({ theme }) => ({
    padding: theme.spacing(2, 2, 2, 0),
}));

export const SetupGuideReadme: React.FC<SetupGuideReadmeProps> = ({
    content,
    fullScreenReadmeLink,
    hasUnsavedChanges = false,
    templateMode = false,
    useRouter = true,
    templatePreviewMode = false,
    saving = false,
    workspaceLocked = false,
    onChangeContent,
    onCancel,
    onClose,
    onSave,
}) => {
    const [readmeMode, setReadmeMode] = useState<'edit' | 'preview'>('preview');

    const theme = useTheme();
    const editorTheme = theme.palette.mode === 'dark' ? 'vs-dark' : 'vs';

    const toggleButtons = [
        { icon: <PreviewOutlinedIcon />, label: 'Preview', value: 'preview' },
        { icon: <EditOutlinedIcon />, label: 'Edit', value: 'edit' },
    ];

    const handleCancel = (): void => {
        setReadmeMode('preview');
        onCancel();
    };

    return (
        <StyledReadmeBox templateMode={templateMode}>
            <StyledReadmeHeader>
                <Typography variant="h5">README</Typography>
                {!templatePreviewMode && (
                    <StyledReadmeHeaderActions>
                        {useRouter ? (
                            <Link disabled={workspaceLocked} to={fullScreenReadmeLink} target="_blank">
                                <IconButton
                                    aria-label="Open in new tab"
                                    border
                                    disabled={workspaceLocked}
                                    icon={<OpenInNewOutlinedIcon />}
                                    tooltip="Open in new tab"
                                />
                            </Link>
                        ) : (
                            <IconButton
                                aria-label="Open in new tab"
                                border
                                disabled={workspaceLocked}
                                icon={<OpenInNewOutlinedIcon />}
                                tooltip="Open in new tab"
                            />
                        )}
                        {!!onClose && (
                            <IconButton
                                aria-label="Close README"
                                border
                                icon={<CloseOutlinedIcon />}
                                tooltip="Close README"
                                onClick={onClose}
                            />
                        )}
                    </StyledReadmeHeaderActions>
                )}
            </StyledReadmeHeader>
            <Divider />
            <StyledReadmeMain>
                {!templateMode && !templatePreviewMode && (
                    <ToggleButtonGroup
                        buttons={toggleButtons}
                        disabled={workspaceLocked}
                        exclusive
                        fullWidth
                        value={readmeMode}
                        onChange={(_, value) => {
                            if (value) {
                                setReadmeMode(value);
                            }
                        }}
                    />
                )}
                {readmeMode === 'edit' ? (
                    <StyledReadmeEditModeContent>
                        <Editor
                            defaultValue={content}
                            language="markdown"
                            options={{
                                minimap: { enabled: false },
                                readOnly: templateMode || workspaceLocked || templatePreviewMode,
                            }}
                            theme={editorTheme}
                            onChange={(value) => onChangeContent(value ?? '')}
                        />
                    </StyledReadmeEditModeContent>
                ) : (
                    <StyledReadmePreviewContent>
                        {ReactHtmlParser(getSanitizedParsedMarkdown(content))}
                    </StyledReadmePreviewContent>
                )}
            </StyledReadmeMain>
            {readmeMode === 'edit' && (
                <>
                    <Divider />
                    <StyledMainActions>
                        <Button onClick={handleCancel} variant="outlined">
                            Cancel
                        </Button>
                        <Button busy={saving} disabled={!hasUnsavedChanges} onClick={onSave}>
                            Save changes
                        </Button>
                    </StyledMainActions>
                </>
            )}
        </StyledReadmeBox>
    );
};
