import { useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { Button } from '../../common/buttons/Button';
import { StyledBorderBoxHeader } from '../../common/LayoutComponents';
import { StyledMainActions } from '../../setup-guide/SetupGuideComponents';
import { IconButton, styled, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CardMedia from '@mui/material/CardMedia';
import { IconCircle } from '../../common/IconCircle';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import DangerousOutlinedIcon from '@mui/icons-material/DangerousOutlined';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import Checkbox from '@mui/material/Checkbox';

import {
    LearnAboutCreditsContent,
    CreditsChip,
    StyledLink,
    StyledPageOneBlockImageContainer,
    StyledTermsContainer,
    StyledWelcomeScreenCaseContainer,
} from './AiAssistanceComponents';
import { dpaUrl, eulaUrl, privacyPolicyUrl, termsAndConditionsUrl } from '../../../utils/documentation';

interface WelcomeScreenProps {
    onCompleteOnboarding(): void;
    availableCredits: number;
    onClose(): void;
}

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    backgroundColor: 'transparent',
    marginLeft: theme.spacing(2),
    '&:hover': {
        '& .MuiSvgIcon-root': {
            color: theme.palette.primary.dark,
        },
    },
}));

const StyledIconBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flex: '0 0 32px',
    justifyContent: 'center',
    '& .MuiCheckbox-root': {
        padding: theme.spacing(3),
        margin: 0,
    },
    marginRight: theme.spacing(1),
}));

const StyledCheckbox = styled(Checkbox)(() => ({
    padding: 0,
    height: 24,
    width: 24,
}));

export const WelcomeScreen: React.FC<WelcomeScreenProps> = ({ onCompleteOnboarding, availableCredits, onClose }) => {
    const [page, setPage] = useState('page1');
    const [isChecked, setChecked] = useState(false);
    let pageContent: JSX.Element;
    let actions: JSX.Element;

    switch (page) {
        case 'page1':
            pageContent = (
                <Box display="flex" flexDirection="column">
                    <StyledPageOneBlockImageContainer>
                        <CardMedia
                            component="img"
                            image={process.env.PUBLIC_URL + '/ai-assistant-welcome.png'}
                            alt="AI assistant welcome screen image"
                        />
                    </StyledPageOneBlockImageContainer>
                    <Typography variant="subtitle1" pb={0.5}>
                        A smarter way to understand ScriptRunner Connect
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        Meet your AI assistant for ScriptRunner Connect. Quickly access docs, get coding help, and start
                        projects with templates—all with AI support to solve your problems.
                    </Typography>
                    <Box display="flex" flexDirection="column" pt={2} gap={1.5}>
                        <StyledWelcomeScreenCaseContainer>
                            <IconCircle icon={<MenuBookOutlinedIcon />} size="small" severity="grey" />
                            <Box flexDirection="column">
                                <Typography variant="subtitle2">Find documentation fast</Typography>
                                <Typography variant="body1" color="text.secondary">
                                    Discover the details you need quickly with simple questions.
                                </Typography>
                            </Box>
                        </StyledWelcomeScreenCaseContainer>
                        <StyledWelcomeScreenCaseContainer>
                            <IconCircle icon={<CodeOutlinedIcon />} size="small" severity="grey" />
                            <Box flexDirection="column">
                                <Typography variant="subtitle2">Simplify your coding</Typography>
                                <Typography variant="body1" color="text.secondary">
                                    Get quick coding help to make your tasks easier.
                                </Typography>
                            </Box>
                        </StyledWelcomeScreenCaseContainer>
                        <StyledWelcomeScreenCaseContainer>
                            <IconCircle icon={<LocalLibraryOutlinedIcon />} size="small" severity="grey" />
                            <Box flexDirection="column">
                                <Typography variant="subtitle2">Quick start with templates</Typography>
                                <Typography variant="body1" color="text.secondary">
                                    Explore and use ready-made templates to launch your projects.
                                </Typography>
                            </Box>
                        </StyledWelcomeScreenCaseContainer>
                    </Box>
                </Box>
            );
            actions = (
                <StyledMainActions>
                    <Button onClick={() => setPage('page2')}>Start exploring</Button>
                </StyledMainActions>
            );
            break;
        case 'page2':
            pageContent = (
                <Box display="flex" flexDirection="column" justifyContent="space-between">
                    <Box>
                        <Typography variant="subtitle1">As you use our AI assistant, please remember:</Typography>
                        <Box display="flex" flexDirection="column" pt={2} gap={1.5}>
                            <StyledWelcomeScreenCaseContainer>
                                <IconCircle icon={<SecurityOutlinedIcon />} size="small" severity="grey" />
                                <Box flexDirection="column">
                                    <Typography variant="subtitle2">Your data is protected</Typography>
                                    <Typography variant="body1" color="text.secondary">
                                        We care about your privacy. Our AI assistant only uses the data it needs to help
                                        you. Your information is safe and won't be used to train our models.
                                    </Typography>
                                </Box>
                            </StyledWelcomeScreenCaseContainer>
                            <StyledWelcomeScreenCaseContainer>
                                <IconCircle icon={<DangerousOutlinedIcon />} size="small" severity="grey" />
                                <Box flexDirection="column">
                                    <Typography variant="subtitle2">It won't always get it right</Typography>
                                    <Typography variant="body1" color="text.secondary">
                                        Our AI assistant is here to help, but it might make mistakes. Always check the
                                        answers and review and test the generated code.
                                    </Typography>
                                </Box>
                            </StyledWelcomeScreenCaseContainer>
                            <StyledWelcomeScreenCaseContainer>
                                <IconCircle icon={<RateReviewOutlinedIcon />} size="small" severity="grey" />
                                <Box flexDirection="column">
                                    <Typography variant="subtitle2">
                                        The assistant will improve with feedback
                                    </Typography>
                                    <Typography variant="body1" color="text.secondary">
                                        Your feedback is key to making our AI assistant better. Use ratings and flags to
                                        help us improve the answers.
                                    </Typography>
                                </Box>
                            </StyledWelcomeScreenCaseContainer>
                            <StyledWelcomeScreenCaseContainer>
                                <IconCircle icon={<BookOutlinedIcon />} size="small" severity="grey" />
                                <Box flexDirection="column">
                                    <Typography variant="subtitle2">For informational purposes only</Typography>
                                    <Typography variant="body1" color="text.secondary">
                                        Answers to your questions, code and templates provided by our AI assistant are
                                        only suggestions, and should not be taken as professional advice.
                                    </Typography>
                                </Box>
                            </StyledWelcomeScreenCaseContainer>
                        </Box>
                    </Box>
                    <StyledTermsContainer>
                        <StyledIconBox>
                            <StyledCheckbox checked={isChecked} onChange={() => setChecked(!isChecked)} />
                        </StyledIconBox>
                        <Box>
                            By checking this box, I agree to the terms of the{' '}
                            <StyledLink href={eulaUrl} target="_blank">
                                EULA
                            </StyledLink>
                            {', '}
                            <StyledLink href={termsAndConditionsUrl} target="_blank">
                                Terms & Conditions
                            </StyledLink>
                            {', '}
                            <StyledLink href={privacyPolicyUrl} target="_blank">
                                Privacy Policy
                            </StyledLink>
                            {' and '}
                            <StyledLink href={dpaUrl} target="_blank">
                                DPA
                            </StyledLink>
                            {'.'}
                        </Box>
                    </StyledTermsContainer>
                </Box>
            );
            actions = (
                <StyledMainActions>
                    <Button onClick={() => setPage('page1')} variant="outlined">
                        Cancel
                    </Button>
                    <Button disabled={!isChecked} onClick={() => setPage('page3')}>
                        Confirm & continue
                    </Button>
                </StyledMainActions>
            );
            break;
        case 'page3':
            pageContent = <LearnAboutCreditsContent />;
            actions = (
                <StyledMainActions>
                    <Button onClick={onCompleteOnboarding}>Continue</Button>
                </StyledMainActions>
            );
            break;

        default:
            pageContent = <></>;
            actions = <></>;
            break;
    }
    return (
        <>
            <StyledBorderBoxHeader>
                <Typography variant="h6">Welcome to our AI assistant</Typography>
                <Box display="flex" alignItems="center">
                    {page === 'page3' && <CreditsChip availableCredits={availableCredits} />}
                    <Tooltip title="Close AI assistant" placement="bottom-start" describeChild>
                        <StyledIconButton onClick={onClose}>
                            <CloseIcon sx={{ height: 20, width: 20 }} />
                        </StyledIconButton>
                    </Tooltip>
                </Box>
            </StyledBorderBoxHeader>
            <Divider />
            <Box display="flex" height="100%" p={2}>
                {pageContent}
            </Box>
            <Divider />
            {actions}
        </>
    );
};
