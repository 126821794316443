import { BehaviorSubject, map, Subject } from 'rxjs';
import {
    rateAiAssistanceResponse,
    sendMessageToAIAssistance,
    updateAiAssistanceOnboardingCompletion,
} from '../../data/ai-assistance';
import { publishLocalFeedbackEventAction$ } from '../feedback';
import { monitor } from '../monitor';
import { InformativeError } from '../../utils/error';
import { ulid } from 'ulid';
import { pendoAnalyticsTrack } from '../../data/pendo-analytics';
import { loggedInUserDetails$ } from '../user';
import { getOrganizationUsage } from '../../data/organization';
import { AiChatResponseRating } from '@avst-stitch/repository-lib/lib/models';

export const aiAssistanceOpen$ = monitor('aiAssistanceOpen$', new BehaviorSubject(false));
export const loadingAiAssistanceDialog$ = monitor('loadingAiAssistanceDialog$', new BehaviorSubject(false));
export const aiAssistanceChat$ = monitor(
    'aiAssistanceChat$',
    new BehaviorSubject<{ role: 'user' | 'assistant'; message: string; uid?: string; rating?: AiChatResponseRating }[]>(
        []
    )
);
export const aiAssistanceGeneratedMessage$ = monitor(
    'aiAssistanceGeneratedMessage$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const aiAssistanceGeneratedMessagePieces$ = monitor(
    'aiAssistanceGeneratedMessagePieces$',
    new BehaviorSubject<{ message: string; index: number; isLast?: boolean }[]>([])
);
export const aiAssistanceGeneratedMessageLength$ = monitor(
    'aiAssistanceGeneratedMessageLength$',
    new BehaviorSubject<number | undefined>(undefined)
);
export const aiAssistanceAnswering$ = monitor('aiAssistanceAnswering$', new BehaviorSubject(false));
export const aiAssistanceStreamAbortedAction$ = monitor('aiAssistanceStreamAbortedAction$', new Subject<void>());
export const aiAssistanceOnboardingCompletedAction$ = monitor(
    'aiAssistanceOnboardingCompletedAction$',
    new Subject<void>()
);
export const aiAssistanceGetUsageDetailsAction$ = monitor('aiAssistanceGetUsageDetailsAction$', new Subject<string>());
export const aiAssistanceRateMessageAction$ = monitor(
    'aiAssistanceRateMessageAction$',
    new Subject<{ uid: string; rating: AiChatResponseRating }>()
);
export const aiAssistanceStreamAborted$ = monitor('aiAssistanceStreamAborted$', new BehaviorSubject<boolean>(false));
export const aiAssistanceConversationUid$ = monitor(
    'aiAssistanceConversationUid$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const aiAssistanceIsLongResponse$ = monitor('aiAssistanceIsLongResponse$', new BehaviorSubject<boolean>(false));
export const aiAssistanceAvailableCredits$ = monitor('aiAssistanceAvailableCredits$', new BehaviorSubject<number>(0));
export const aiAssistanceResponseMessageUid$ = monitor(
    'aiAssistanceResponseMessageUid$',
    new BehaviorSubject<string>('')
);

export const openAiAssistanceAction$ = monitor('openAiAssistanceAction$', new Subject<void>());
export const closeAiAssistanceAction$ = monitor('closeAiAssistanceAction$', new Subject<void>());

export const sendMessageToAiAssistanceAction$ = monitor(
    'sendMessageToAiAssistanceAction$',
    new Subject<{
        message: string;
        workspaceUid?: string;
        environmentUid?: string;
        organizationUid: string;
    }>()
);
export const createAiAssistanceGeneratedMessageAction$ = monitor(
    'createAiAssistanceGeneratedMessageAction$',
    new Subject<{
        message: string;
        index: number;
        isLast?: boolean;
        id: string;
        finish_reason?: string;
    }>()
);
export const resetAiAssistanceChatAction$ = monitor('resetAiAssistanceChatAction$', new Subject<void>());
export const copyAiAssistanceCodeSnippetAction$ = monitor('copyAiAssistanceCodeSnippetAction$', new Subject<void>());

openAiAssistanceAction$.subscribe(() => {
    aiAssistanceOpen$.next(true);
});

closeAiAssistanceAction$.subscribe(() => {
    aiAssistanceOpen$.next(false);
});

resetAiAssistanceChatAction$.subscribe(() => {
    loadingAiAssistanceDialog$.next(false);
    aiAssistanceAnswering$.next(false);
    aiAssistanceGeneratedMessage$.next(undefined);
    aiAssistanceGeneratedMessagePieces$.next([]);
    aiAssistanceGeneratedMessageLength$.next(undefined);
    aiAssistanceChat$.next([]);
    aiAssistanceIsLongResponse$.next(false);
    pendoAnalyticsTrack('New conversation started', {
        userId: loggedInUserDetails$.value?.uid,
        userOrigin: loggedInUserDetails$.value?.userOrigin,
    });
});

copyAiAssistanceCodeSnippetAction$.subscribe(() => {
    pendoAnalyticsTrack('Code copied', {
        userId: loggedInUserDetails$.value?.uid,
        userOrigin: loggedInUserDetails$.value?.userOrigin,
    });
});

sendMessageToAiAssistanceAction$
    .pipe(
        map(async (event) => {
            loadingAiAssistanceDialog$.next(true);
            aiAssistanceAnswering$.next(true);
            aiAssistanceGeneratedMessage$.next(undefined);
            aiAssistanceGeneratedMessagePieces$.next([]);
            aiAssistanceGeneratedMessageLength$.next(undefined);
            aiAssistanceIsLongResponse$.next(false);
            const chat = aiAssistanceChat$.value;
            try {
                const isFirst = chat.length === 0;
                if (isFirst) {
                    aiAssistanceConversationUid$.next(ulid());
                }
                await sendMessageToAIAssistance({
                    message: event.message,
                    workspaceUid: event.workspaceUid,
                    first: isFirst,
                    conversationUid: aiAssistanceConversationUid$.value ?? '',
                    environmentUid: event.environmentUid,
                    organizationUid: event.organizationUid,
                });
                aiAssistanceChat$.next([...chat, { role: 'user', message: event.message }]);
                pendoAnalyticsTrack('New question asked', {
                    userId: loggedInUserDetails$.value?.uid,
                    userOrigin: loggedInUserDetails$.value?.userOrigin,
                });
            } catch (e) {
                aiAssistanceAnswering$.next(false);
                if (e instanceof InformativeError) {
                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message: e.message,
                    });
                } else {
                    console.error('Error while getting AI generated response.', e);

                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message: `Failed to get a response from AI assistant.`,
                        toastOptions: {
                            autoClose: false,
                        },
                    });
                }
            }

            loadingAiAssistanceDialog$.next(false);
        })
    )
    .subscribe();

createAiAssistanceGeneratedMessageAction$
    .pipe(
        map(async (event) => {
            if (event.isLast) {
                aiAssistanceGeneratedMessageLength$.next(event.index);
                aiAssistanceAnswering$.next(false);
            }

            if (event.finish_reason === 'length') {
                aiAssistanceIsLongResponse$.next(true);
            }

            const currentPieces = aiAssistanceGeneratedMessagePieces$.value;
            const length = aiAssistanceGeneratedMessageLength$.value;
            const sortedPieces = [...currentPieces, event].sort((pieceA, pieceB) => pieceA.index - pieceB.index);
            const message = sortedPieces.map((piece) => piece.message).join('');
            aiAssistanceGeneratedMessagePieces$.next(sortedPieces);
            aiAssistanceGeneratedMessage$.next(message);

            if (currentPieces.length === length) {
                const chat = aiAssistanceChat$.value;
                aiAssistanceChat$.next([
                    ...chat,
                    { role: 'assistant', message, uid: aiAssistanceResponseMessageUid$.value },
                ]);
                aiAssistanceGeneratedMessagePieces$.next([]);
                aiAssistanceGeneratedMessage$.next(undefined);
            }
        })
    )
    .subscribe();

aiAssistanceOnboardingCompletedAction$
    .pipe(
        map(async () => {
            try {
                await updateAiAssistanceOnboardingCompletion({ completed: true });
            } catch (e) {
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: 'Error saving AI assistant onboarding progress',
                });
                console.error('Error saving AI assistant onboarding progress', e);
            }
        })
    )
    .subscribe();

aiAssistanceGetUsageDetailsAction$
    .pipe(
        map(async (uid) => {
            try {
                const usage = await getOrganizationUsage(uid);
                aiAssistanceAvailableCredits$.next(usage.aiAssistanceAvailableCredits);
            } catch (e) {
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: 'Error retrieving organization usage for AI assistant',
                });
                console.error('Error retrieving organization usage for AI assistant', e);
            }
        })
    )
    .subscribe();

aiAssistanceRateMessageAction$
    .pipe(
        map(async (request) => {
            try {
                await rateAiAssistanceResponse({
                    uid: request.uid,
                    rating: request.rating,
                    conversationUid: aiAssistanceConversationUid$.value ?? '',
                });
                aiAssistanceChat$.next([
                    ...aiAssistanceChat$.value.map((message) => {
                        if (message.uid === request.uid) {
                            return {
                                rating: request.rating,
                                ...message,
                            };
                        }
                        return message;
                    }),
                ]);
            } catch (e) {
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: 'Error rating response from AI assistant',
                });
                console.error('Error rating response from AI assistant', e);
            }
        })
    )
    .subscribe();

// TODO: redo
aiAssistanceStreamAbortedAction$.subscribe(() => {
    aiAssistanceStreamAborted$.next(true);
});
