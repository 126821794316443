/**
 * This file should contain all the setup logic that should happen prior to rendering React app
 */

// Import all files that have to be loaded
import './store/workspace/utils';
import './store/editor/fetchpackage';
import './store/editor/editor';
import './store/apps';
import './store/user';
import './store/feedback';
import './store/workspaces';
import './store/templates';
import './store/connections';
import './store/connection';
import './store/connection/atlassian-cloud/manage-connection';
import './store/connection/atlassian-cloud/setup-event-listener';
import './store/connection/slack/setup-event-listener';
import './store/connection/slack/setup-connection';
import './store/connection/generic/setup-event-listener';
import './store/connection/generic/setup-connection';
import './store/connection/bitbucket-cloud/setup-event-listener';
import './store/connection/bitbucket-cloud/setup-connection';
import './store/connection/servicenow/setup-event-listener';
import './store/connection/servicenow/setup-connection';
import './store/connection/bitbucket-on-premise/setup-connection';
import './store/connection/atlassian-on-premise/setup-event-listener';
import './store/connection/atlassian-on-premise/setup-connection';
import './store/connection/google/authorize-connection';
import './store/connection/github/authorize-connection';
import './store/connection/gitlab/authorize-connection';
import './store/connection/monday/setup-connection';
import './store/connection/monday/setup-event-listener';
import './store/connection/tempo-cloud/setup-connection';
import './store/connection/microsoft/setup-connection';
import './store/connection/microsoft/setup-event-listener';
import './store/connection/azure-devops/setup-connection';
import './store/connection/azure-devops/setup-event-listener';
import './store/connection/zoom/setup-connection';
import './store/connection/zoom/setup-event-listener';
import './store/connection/salesforce/setup-connection';
import './store/connection/salesforce/setup-event-listener';
import './store/connection/statuspage/setup-connection';
import './store/connection/statuspage/setup-event-listener';
import './store/connection/opsgenie/setup-connection';
import './store/connection/opsgenie/setup-event-listener';
import './store/connection/trello/setup-connection';
import './store/connection/aws/setup-connection';
import './store/connection/zendesk/setup-event-listener';
import './store/connection/zendesk/setup-connection';
import './store/workspace';
import './store/workspace/script';
import './store/workspace/template';
import './store/workspace/deployment';
import './store/workspace/feedback';
import './store/workspace/environment';
import './store/workspace/readme';

import { fetchConfig } from './data/config';
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga4';
import { loadFeatureFlags } from './data/feature-flags';
import { initSegmentAnalytics } from './data/segment-analytics';
import { sprig } from '@sprig-technologies/sprig-browser';
import { initPendo } from './data/pendo-analytics';
import { startWorkspacePinger } from './store/workspace/utils';

// Add all initialization logic into this function
export const setup = async (): Promise<void> => {
    const config = await fetchConfig();

    if (config.launchDarkly?.clientId) {
        console.debug('Loading feature flags');
        await loadFeatureFlags(config.launchDarkly?.clientId);
    }

    const gaMeasurementId = config.analytics?.ga?.measurementId;

    if (gaMeasurementId) {
        console.debug('Init Google Analytics');
        ReactGA.initialize(gaMeasurementId);
        ReactGA.send({
            hitType: 'pageview',
            page: window.location.pathname + window.location.search,
        });
    }

    const hotjarSiteId = config.analytics?.hotjar?.siteId;
    const hotjarSnippetVersion = config.analytics?.hotjar?.snippetVersion;

    if (hotjarSiteId && hotjarSnippetVersion) {
        console.debug('Init Hotjar');
        hotjar.initialize(parseInt(hotjarSiteId), parseInt(hotjarSnippetVersion));
    }

    const segmentWriteKey = config.analytics?.segment?.writeKey;
    if (segmentWriteKey) {
        console.debug('Init Segment');
        await initSegmentAnalytics(segmentWriteKey);
    }

    const sprigEnvironmentId = config.analytics?.sprig?.environmentId;
    if (sprigEnvironmentId) {
        console.debug('Init Sprig');
        sprig.configure({
            environmentId: sprigEnvironmentId,
        });
    }

    const pendoApiKey = config.analytics?.pendo?.apiKey;
    if (pendoApiKey) {
        console.debug('Init Pendo');
        await initPendo(pendoApiKey);
    }

    startWorkspacePinger();
};
