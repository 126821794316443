import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import VpnKeyOutlined from '@mui/icons-material/VpnKeyOutlined';
import { Button } from '../../common/buttons/Button';
import { CopyTextField } from '../../common/inputs/custom/CopyTextField';
import { Dialog } from '../../common/Dialog';
import { DialogAlert } from '../../for-deprecation/dialog/DialogComponents';
import { IconCircle } from '../../common/IconCircle';
import { OrderedList, makeNonListItem } from '../../common/lists/OrderedList';
import { StyledBorderBox, StyledBorderBoxContent, StyledBorderBoxSubHeader } from '../../common/LayoutComponents';
import { handleKeyDown } from '../../../utils/input';

interface NewApiKeyGeneratedDialog {
    email: string;
    apiKey: string;
    open?: boolean;
    onClose(): void;
}

export const NewApiKeyGeneratedDialog: React.FC<NewApiKeyGeneratedDialog> = ({
    email,
    apiKey,
    open = false,
    onClose,
}) => {
    const docsUrl = 'https://docs.adaptavist.com/src/latest'; // TODO: fix URL
    const swaggerUrl = 'https://docs.adaptavist.com/src/latest'; // TODO: fix URL

    const emailAndApiKeyInstructions = [
        'Copy email and API key.',
        makeNonListItem(<CopyTextField fullWidth label="Email (username)" value={email} />, 'email'),
        makeNonListItem(<CopyTextField fullWidth label="API Key (password)" value={apiKey} />, 'api-key'),
        <Typography>
            And use it with the basic authentication where you need to, or try out our APIs in the{' '}
            <Link target="_blank" href={swaggerUrl}>
                Swagger UI
            </Link>
            .
        </Typography>,
    ];

    const authorizationHeaderInstructions = [
        'Alternatively you can copy the Authorization header value and use it directly.',
        makeNonListItem(
            <CopyTextField
                fullWidth
                label="Authorization"
                // eslint-disable-next-line sonarjs/no-nested-template-literals
                value={`Basic ${btoa(`${email}:${apiKey}`)}`}
            />,
            'authorization'
        ),
    ];

    return (
        <Dialog
            buttons={[
                <Button onClick={onClose} variant="outlined">
                    Close
                </Button>,
            ]}
            leftButton={
                <Button onClick={() => window.open(docsUrl)} variant="text">
                    Learn more
                </Button>
            }
            icon={<IconCircle icon={<VpnKeyOutlined />} size="medium" />}
            open={open}
            title={'New API key'}
            onClose={onClose}
            onKeyDown={(event) => handleKeyDown({ event, enterFn: onClose, escFn: onClose })}
        >
            <DialogAlert
                severity="warning"
                title="Warning"
                text="Once you close this dialog, you won't be able to retrieve the API key value again. Make sure to make a copy and store it securely!"
            />
            <StyledBorderBox>
                <StyledBorderBoxSubHeader>
                    <Typography variant="subtitle1" component="h6">
                        Using email and API key with basic authentication
                    </Typography>
                </StyledBorderBoxSubHeader>
                <Divider />
                <StyledBorderBoxContent gap={1.5}>
                    <OrderedList content={emailAndApiKeyInstructions} id="email-and-api-key" />
                </StyledBorderBoxContent>
            </StyledBorderBox>
            <StyledBorderBox>
                <StyledBorderBoxSubHeader>
                    <Typography variant="subtitle1" component="h6">
                        Using authorization header directly
                    </Typography>
                </StyledBorderBoxSubHeader>
                <Divider />
                <StyledBorderBoxContent gap={1.5}>
                    <OrderedList content={authorizationHeaderInstructions} id="authorization-header" />
                </StyledBorderBoxContent>
            </StyledBorderBox>
        </Dialog>
    );
};
