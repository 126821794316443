import { useObservableState, useSubscription } from 'observable-hooks';
import { OnboardingPage } from '../../../components/onboarding/wizard/OnboardingPage';
import {
    createWorkspaceFromOnboardingTemplate$,
    navigateToOnboardingStepAction$,
    navigateToReadOnlyTemplateFromOnboarding$,
} from '../../../store/onboarding';
import { useNavigate } from 'react-location';
import { getBasePath } from '../../../utils/path';

export type OnboardingStep = 'role' | 'scripting' | 'apps' | 'flow' | 'templates' | 'template' | 'naming';

export const OnboardingContainer: React.FC = () => {
    const navigate = useNavigate();

    const createWorkspaceFromOnboardingTemplate = useObservableState(createWorkspaceFromOnboardingTemplate$);

    useSubscription(navigateToOnboardingStepAction$, ({ route, templateUid }) => {
        const url =
            route === 'naming'
                ? templateUid
                    ? `${getBasePath()}onboarding/template/${templateUid}/naming`
                    : `${getBasePath()}onboarding/workspace/naming`
                : route === 'template'
                ? `${getBasePath()}onboarding/template/${templateUid}`
                : `${getBasePath()}onboarding/${route}`;

        navigate({ to: url });
    });

    useSubscription(navigateToReadOnlyTemplateFromOnboarding$, (templateUid) => {
        navigate({
            to: `${getBasePath()}template/${templateUid}${createWorkspaceFromOnboardingTemplate ? '?create=true' : ''}`,
        });
    });

    return <OnboardingPage />;
};
