import { useObservableState } from 'observable-hooks';
import { AtlassianCloudEventListenerSetupDialog } from '../../../../components/connections/apps/atlassian-cloud/EventListenerSetupDialog';
import { configTopic$ } from '../../../../store/config';
import {
    atlassianCloudEventListenerSetupDialogCloseAction$,
    atlassianCloudEventListenerSetupDialogDetails$,
    atlassianCloudEventListenerSetupDialogErrors$,
    atlassianCloudEventListenerSetupDialogLoading$,
    atlassianCloudEventListenerSetupDialogOpen$,
    atlassianCloudEventListenerSetupDialogSaveTokenAction$,
    atlassianCloudEventListenerSetupDialogSaving$,
} from '../../../../store/connection/atlassian-cloud/setup-event-listener';
import { selectedWorkspace$ } from '../../../../store/workspace';

export const AtlassianCloudEventListenerSetupDialogContainer: React.FC = () => {
    const loading = useObservableState(atlassianCloudEventListenerSetupDialogLoading$);
    const saving = useObservableState(atlassianCloudEventListenerSetupDialogSaving$);
    const open = useObservableState(atlassianCloudEventListenerSetupDialogOpen$);
    const details = useObservableState(atlassianCloudEventListenerSetupDialogDetails$);
    const errors = useObservableState(atlassianCloudEventListenerSetupDialogErrors$);
    const config = useObservableState(configTopic$);
    const selectedWorkspace = useObservableState(selectedWorkspace$);

    return (
        <AtlassianCloudEventListenerSetupDialog
            loading={loading}
            open={open}
            eventTypeName={details?.eventTypeName ?? ''}
            webhookBaseUrl={config.trigger?.srcExternalUrl ?? ''}
            webhookUrlId={details?.webhookUrl ?? ''}
            workspaceLocked={!!selectedWorkspace?.locked}
            setupBaseUrl={details?.setupBaseUrl}
            hasToken={details?.hasToken}
            errors={errors}
            onSave={(token) =>
                atlassianCloudEventListenerSetupDialogSaveTokenAction$.next({
                    token,
                    uid: details?.uid ?? '',
                    environmentUid: details?.environmentUid ?? '',
                })
            }
            saving={saving}
            onClose={() => atlassianCloudEventListenerSetupDialogCloseAction$.next()}
            connectionType={details?.connectionType}
        />
    );
};
