import { createApiHandler as createApiHandlerRpc } from '@avst-stitch/repository-lib/lib';
import { saveApiHandlerV2 as saveApiHandlerV2Rpc } from '@avst-stitch/repository-lib/lib';
import { getApiHandler as getApiHandlerRpc } from '@avst-stitch/repository-lib/lib';
import { deleteApiHandler as deleteApiHandlerRpc } from '@avst-stitch/repository-lib/lib';
import { Response as CreatedApiHandler } from '@avst-stitch/repository-lib/lib/rpcs/createApiHandler';
import { Response as ApiHandler } from '@avst-stitch/repository-lib/lib/rpcs/getApiHandler';
import { Response as ApiHandlerDeletion } from '@avst-stitch/repository-lib/lib/rpcs/deleteApiHandler';
import { Request as SaveApiHandlerRequest } from '@avst-stitch/repository-lib/lib/rpcs/saveApiHandlerV2';
import { repositoryInvoker } from '../utils/repository';
export type { CreatedApiHandler, ApiHandler };

export const createApiHandler = async (
    workspaceUid: string,
    appUid: string,
    apiHandlerTypeUid: string
): Promise<CreatedApiHandler> => {
    return await createApiHandlerRpc(repositoryInvoker, {
        workspaceUid,
        appUid,
        apiHandlerTypeUid,
    });
};

export const saveApiHandlerV2 = async (request: SaveApiHandlerRequest): Promise<void> => {
    return await saveApiHandlerV2Rpc(repositoryInvoker, request);
};

export const getApiHandler = async (uid: string, environmentUid?: string): Promise<ApiHandler> => {
    return await getApiHandlerRpc(repositoryInvoker, {
        uid,
        environmentUid,
    });
};

export const deleteApiHandler = async (uid: string, ignoreWarnings?: boolean): Promise<ApiHandlerDeletion> => {
    return await deleteApiHandlerRpc(repositoryInvoker, {
        uid,
        ignoreWarnings,
    });
};
