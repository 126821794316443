import { useObservableState } from 'observable-hooks';
import { loadingWorkspaceResources$, selectedWorkspaceResources$ } from '../../store/workspace';
import { navigateToReadmeFileAction$ } from '../../store/workspace/readme';
import { EmptyWorkspaceView } from '../../components/workspace-layouts/EmptyWorkspaceView';
import { openHelpAndSupportModalAction$ } from '../../store/helpAndSupport';
import { youtubeChannelUrl } from '../../utils/documentation';

export const DefaultWorkspaceViewContainer: React.FC = () => {
    const { readmeFile } = useObservableState(selectedWorkspaceResources$);
    const loadingWorkspaceResources = useObservableState(loadingWorkspaceResources$);

    if (loadingWorkspaceResources) {
        return null;
    }

    if (!readmeFile?.isDefault) {
        navigateToReadmeFileAction$.next(readmeFile?.uid ?? '');
        return null;
    }

    return (
        <EmptyWorkspaceView
            onOpenHelpAndSupportDialog={() => openHelpAndSupportModalAction$.next()}
            onOpenYoutubeVideo={() => window.open(youtubeChannelUrl, '_blank')}
        />
    );
};
