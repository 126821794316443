import { useObservableState } from 'observable-hooks';
import { loadingWorkspaceResources$, selectedWorkspaceResources$ } from '../../store/workspace';
import {
    navigateToEnvironmentVariablesAction$,
    savedEnvironmentVariables$,
} from '../../store/workspace/environment-variable';
import { navigateToReadmeFileAction$ } from '../../store/workspace/readme';
import { EmptyWorkspaceView } from '../../components/workspace-layouts/EmptyWorkspaceView';
import { openHelpAndSupportModalAction$ } from '../../store/helpAndSupport';
import { youtubeChannelUrl } from '../../utils/documentation';

export const DefaultReadOnlyTemplateViewContainer: React.FC = () => {
    const { readmeFile } = useObservableState(selectedWorkspaceResources$);
    const loadingWorkspaceResources = useObservableState(loadingWorkspaceResources$);
    const environmentVariables = useObservableState(savedEnvironmentVariables$);

    if (!loadingWorkspaceResources) {
        if (environmentVariables?.length) {
            navigateToEnvironmentVariablesAction$.next(true);
        } else if (!readmeFile?.isDefault) {
            navigateToReadmeFileAction$.next(readmeFile?.uid ?? '');
        }
    }

    return (
        <EmptyWorkspaceView
            onOpenHelpAndSupportDialog={() => openHelpAndSupportModalAction$.next()}
            onOpenYoutubeVideo={() => window.open(youtubeChannelUrl, '_blank')}
        />
    );
};
