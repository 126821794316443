import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import { ProductIcon } from '../icons/ProductIcon';
import {
    StyledListItem,
    StyledListItemButton,
    StyledSectionContainer,
    StyledSectionHeader,
    StyledSectionMain,
} from './DashboardComponents';
import { slackCommunityUrl, documentationUrl, supportPortalUrl, youtubeChannelUrl } from '../../utils/documentation';

export const DashboardResources: React.FC = () => {
    return (
        <StyledSectionContainer>
            <StyledSectionHeader>
                <Typography variant="h6">Resources</Typography>
            </StyledSectionHeader>
            <Divider />
            <StyledSectionMain component="ul" m={0}>
                <StyledListItem>
                    <StyledListItemButton component="a" data-pendo="videos" href={youtubeChannelUrl} target="_blank">
                        <Tooltip title="Video tutorials">
                            <OndemandVideoOutlinedIcon />
                        </Tooltip>
                        <Typography>Video tutorials</Typography>
                        <ArrowForwardIosOutlinedIcon />
                    </StyledListItemButton>
                </StyledListItem>
                <StyledListItem>
                    <StyledListItemButton component="a" data-pendo="docs" href={documentationUrl} target="_blank">
                        <Tooltip title="Documentation">
                            <MenuBookOutlinedIcon />
                        </Tooltip>
                        <Typography>Documentation</Typography>
                        <ArrowForwardIosOutlinedIcon />
                    </StyledListItemButton>
                </StyledListItem>
                <StyledListItem>
                    <StyledListItemButton component="a" data-pendo="support" href={supportPortalUrl} target="_blank">
                        <Tooltip title="Contact support">
                            <QuestionAnswerOutlinedIcon />
                        </Tooltip>
                        <Typography>Contact support</Typography>
                        <ArrowForwardIosOutlinedIcon />
                    </StyledListItemButton>
                </StyledListItem>
                <StyledListItem>
                    <StyledListItemButton
                        component="a"
                        data-pendo="slackCommunity"
                        href={slackCommunityUrl}
                        target="_blank"
                    >
                        <Tooltip title="Slack community" describeChild>
                            <Box height={24}>
                                <ProductIcon name="Slack" />
                            </Box>
                        </Tooltip>
                        <Typography>Slack community</Typography>
                        <ArrowForwardIosOutlinedIcon />
                    </StyledListItemButton>
                </StyledListItem>
            </StyledSectionMain>
        </StyledSectionContainer>
    );
};
