import { useObservableState } from 'observable-hooks';
import {
    navigateToWorkspaceSetupGuideStepAction$,
    saveWorkspaceSetupGuideProgressAction$,
    selectedWorkspaceSetupGuideStep$,
    workspaceSetupGuideStepError$,
    workspaceSetupGuideSteps$,
    workspaceSetupGuideStepSaving$,
} from '../../../store/setup-guide';
import { selectedWorkspace$, selectedWorkspaceResources$, selectedWorkspaceUid$ } from '../../../store/workspace';
import { loggedInUserConnections$ } from '../../../store/connections';
import { openAppSelectorAction$ } from '../../../store/apps';
import { SetupGuideApiHandlerScreen } from '../../../components/setup-guide/api-handlers/SetupGuideApiHandlerScreen';
import { ApiHandler } from '@avst-stitch/repository-lib/lib/rpcs/getWorkspaceResources';
import {
    apiHandlersBeingDeleted$,
    createApiHandlerAction$,
    deleteApiHandlerAction$,
} from '../../../store/workspace/api-handler';
import { getPreviousEnabledStep } from '../../../utils/setupGuide';
import { useNavigate } from 'react-location';

export const SetupGuideApiHandlerScreenContainer: React.FC = () => {
    const navigate = useNavigate();

    const selectedWorkspaceUid = useObservableState(selectedWorkspaceUid$);
    const selectedWorkspace = useObservableState(selectedWorkspace$);
    const setupGuideSteps = useObservableState(workspaceSetupGuideSteps$);
    const selectedSetupGuideStep = useObservableState(selectedWorkspaceSetupGuideStep$);
    const saving = useObservableState(workspaceSetupGuideStepSaving$);
    const errors = useObservableState(workspaceSetupGuideStepError$);
    const { apiHandlers } = useObservableState(selectedWorkspaceResources$);
    const apiHandlersBeingDeleted = useObservableState(apiHandlersBeingDeleted$);
    const connections = useObservableState(loggedInUserConnections$);

    const previousStep = getPreviousEnabledStep(setupGuideSteps, selectedSetupGuideStep);

    const nextStep =
        selectedSetupGuideStep && setupGuideSteps.find((s) => s.number === selectedSetupGuideStep.number + 1);

    const doesApiHandlerNeedSetup = (apiHandler: ApiHandler): boolean => {
        if (apiHandler.warning) {
            return true;
        }
        const foundConnection = connections.find((c) => c.uid === apiHandler.connectionUid);

        return !foundConnection || !foundConnection.authorized;
    };

    return (
        <SetupGuideApiHandlerScreen
            apiHandlers={apiHandlers.map((ah) => ({
                appName: ah.appName,
                connectionName: ah.connectionName,
                deleting: apiHandlersBeingDeleted[ah.uid],
                needsSetup: doesApiHandlerNeedSetup(ah),
                path: ah.path,
                uid: ah.uid,
            }))}
            errors={errors}
            nextStepName={nextStep?.title}
            saving={saving}
            stepRequired={selectedSetupGuideStep?.required}
            templateMode={selectedWorkspace?.setupGuide === 'TEMPLATE'}
            workspaceLocked={!!selectedWorkspace?.locked}
            onContinue={() =>
                saveWorkspaceSetupGuideProgressAction$.next({
                    stepUid: selectedSetupGuideStep?.uid ?? '',
                    workspaceUid: selectedWorkspaceUid ?? '',
                })
            }
            onCreateNewApiHandler={() =>
                openAppSelectorAction$.next({
                    nextAction: (app) =>
                        createApiHandlerAction$.next({
                            appUid: app.uid,
                            apiHandlerTypeUid: app.connectionType.apiHandlerTypes[0]?.uid ?? '',
                        }),
                    mode: 'API_HANDLER',
                })
            }
            onDeleteApiHandler={(uid) => deleteApiHandlerAction$.next({ uid })}
            onEditApiHandler={(uid) => navigate({ to: `./${uid}` })}
            onGoBack={() => navigateToWorkspaceSetupGuideStepAction$.next(previousStep?.name)}
        />
    );
};
