import { useObservableState } from 'observable-hooks';
import { selectedWorkspaceResources$ } from '../../../../store/workspace';
import { selectedScheduledTriggerDetails$ } from '../../../../store/workspace/scheduled-trigger';
import { ScheduledTriggerDetails } from '../../../../components/workspace-resources/advanced-view/scheduled-triggers/ScheduledTriggerDetails';

export const ReadOnlyScheduledTriggerDetailsContainer: React.FC = () => {
    const details = useObservableState(selectedScheduledTriggerDetails$);
    const workspaceResources = useObservableState(selectedWorkspaceResources$);

    return (
        <ScheduledTriggerDetails
            cron={details?.cronExpression}
            uid={details?.uid ?? ''}
            templatePreviewMode={true}
            scripts={workspaceResources.scripts}
            selectedScriptUid={details?.scriptUid}
        />
    );
};
