import { configTopic$, stitchSession$ } from '../store/config';
import { getFetchOptions } from './fetch';
import { useAuth0 } from '@auth0/auth0-react';
import { useObservableState } from 'observable-hooks';
import { Auth0Error } from './error';
import { Response } from '@avst-stitch/repository-lib/lib/rpcs/getMyDetails';

export type FullUserDetails = Response & Auth0UserDetails;

interface Auth0UserDetails {
    readonly sub?: string;
    readonly mfaEnabled?: boolean;
}

interface AuthContext {
    readonly userDetails: { sub?: string };
    readonly error?: Error;
    readonly isAuthenticated: boolean;
    readonly isLoading: boolean;
    readonly operations: AuthOperations;
}

interface AuthTokens {
    readonly id_token: string;
    readonly access_token: string;
    readonly refresh_token?: string;
    readonly expires_in: number;
}

interface WithAuthorization {
    readonly authorization: string;
}

/**
 * Operations that can be performed with the AuthContext
 */
interface AuthOperations {
    readonly login: (loginOpts?: { showSignup: boolean }) => Promise<void>;
    readonly logout: (logoutOpts: { redirectUrl: string }) => Promise<void>;
    readonly getTokens: GetTokens;
    readonly withAuth: <T>(req: T) => Promise<T & WithAuthorization>;
}

type GetTokens = () => Promise<AuthTokens>;

export const useAuth = (): AuthContext => {
    const { isLoading, isAuthenticated, error, user, loginWithRedirect, logout, getAccessTokenSilently } = useAuth0();

    const { auth0 } = useObservableState(configTopic$);

    const getTokens: GetTokens = async () => getAccessTokenSilently({ detailedResponse: true, audience: auth0.apiUrl });

    return {
        userDetails: {
            sub: user?.sub,
        },
        error,
        isAuthenticated,
        isLoading,
        operations: {
            login: async ({ showSignup } = { showSignup: false }) => {
                await loginWithRedirect({
                    appState: {
                        target: window.location.href,
                    },
                    detailedResponse: true,
                    prompt: 'select_account',
                    ...(showSignup ? { screen_hint: 'signup' } : {}),
                });
            },
            logout: async ({ redirectUrl } = { redirectUrl: window.location.origin }) =>
                logout({ returnTo: redirectUrl }),
            getTokens: getTokens,
            withAuth: async (req) => {
                return { ...req, authorization: (await getTokens()).access_token };
            },
        },
    };
};

export const getAuth0UserDetails = async (): Promise<Auth0UserDetails> => {
    const getUserUrl = configTopic$.value.trigger?.getUserFromAuth0Url;
    if (!getUserUrl) {
        throw new Error('No get user url configured in meta');
    }
    const fetchOptions = getFetchOptions({ Authorization: stitchSession$.value?.jwt ?? '' });
    const response = await fetch(getUserUrl, fetchOptions);
    if (!response.ok) {
        throw new Auth0Error('Could not get user from Auth0');
    }
    return (await response.json()) as Auth0UserDetails;
};

export const updateUserInAuth0 = async (enableMfa: boolean): Promise<void> => {
    const updateUserUrl = configTopic$.value.trigger?.updateUserInAuth0Url;
    if (!updateUserUrl) {
        throw new Error('No update user url configured in meta');
    }
    const fetchOptions = getFetchOptions({ Authorization: stitchSession$.value?.jwt ?? '' }, { mfaEnabled: enableMfa });
    const response = await fetch(updateUserUrl, fetchOptions);
    if (!response.ok) {
        throw new Error('Could not update MFA preference');
    }
};
