import { APP } from '@avst-stitch/repository-lib/constants';
import { BehaviorSubject, map, Subject } from 'rxjs';
import {
    connectionCreatedAction$,
    ConnectionDetails,
    connectionCreatedFirstTimeAction$,
    connectionCreatedFirstTime$,
} from '..';
import { createConnection, saveConnection } from '../../../data/connection';
import { InformativeError } from '../../../utils/error';
import { configTopic$ } from '../../config';
import { publishLocalFeedbackEventAction$ } from '../../feedback';
import { monitor } from '../../monitor';
import {
    loadLoggedInUserConnectionsWhenEventIsEmitted,
    saveConnectionAllowed,
    handleManageConnectionError,
    generateConnectorCertificate,
    authorizeNetSuiteConnectionCertificate,
} from '../utils';
import { promptQuestion } from '../../confirm';
import { AYSYWTSAETCWHAI } from '../../../i18n';

interface NetSuiteConnectionDetails extends ConnectionDetails {
    authorized?: boolean;
    netSuiteAccountId?: string;
    clientId?: string;
    publicCertificate?: string;
    certificateId?: string;
}

interface NetSuiteAppSetupSaveProps {
    netSuiteAccountId: string;
    clientId: string;
    certificateId: string;
}

export const netSuiteManageConnectionOpenDialogAction$ = monitor(
    'netSuiteManageConnectionOpenDialogAction$',
    new Subject<NetSuiteConnectionDetails>()
);
export const netSuiteManageConnectionCloseDialogAction$ = monitor(
    'netSuiteManageConnectionCloseDialogAction$',
    new Subject<void>()
);

export const netSuiteAppSetupOpenDialogAction$ = monitor(
    'netSuiteAppSetupOpenDialogAction$',
    new Subject<NetSuiteConnectionDetails>()
);
export const netSuiteAppSetupCloseDialogAction$ = monitor('netSuiteAppSetupCloseDialogAction$', new Subject<void>());
export const netSuiteAppSetupDialogOpen$ = monitor('netSuiteAppSetupDialogOpen$', new BehaviorSubject(false));
export const netSuiteAppSetupAuthorizeAction$ = monitor(
    'netSuiteAppSetupAuthorizeAction$',
    new Subject<NetSuiteAppSetupSaveProps>()
);
export const netSuiteAppSetupDialogErrors$ = monitor(
    'netSuiteAppSetupDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const netSuiteSaveConnectionAction$ = monitor('netSuiteSaveConnectionAction$', new Subject<string>());
export const netSuiteConnectionCreatedAction$ = monitor('netSuiteConnectionCreatedAction$', new Subject<string>());
export const netSuiteConnectionSavedAction$ = monitor(
    'netSuiteConnectionSavedAction$',
    new Subject<{ uid: string; connectionTypeUid: string }>()
);
export const netSuiteManageConnectionInitiateSetupAction$ = monitor(
    'netSuiteManageConnectionInitiateSetupAction$',
    new Subject<string>()
);

export const netSuiteManageConnectionDialogOpen$ = monitor(
    'netSuiteManageConnectionDialogOpen$',
    new BehaviorSubject(false)
);
export const netSuiteConnectionSaving$ = monitor('netSuiteConnectionSaving$', new BehaviorSubject(false));
export const netSuiteManageConnectionAuthorizeLoading$ = monitor(
    'netSuiteManageConnectionAuthorizeLoading$',
    new BehaviorSubject(false)
);
export const netSuiteManageConnectionDetails$ = monitor(
    'netSuiteManageConnectionDetails$',
    new BehaviorSubject<NetSuiteConnectionDetails | undefined>(undefined)
);
export const netSuiteManageConnectionDialogErrors$ = monitor(
    'netSuiteManageConnectionDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const netSuiteAppSetupDialogStage$ = monitor('netSuiteAppSetupDialogStage$', new BehaviorSubject(0));

netSuiteAppSetupOpenDialogAction$.subscribe((details) => {
    netSuiteAppSetupDialogErrors$.next(undefined);
    netSuiteManageConnectionDialogErrors$.next(undefined);
    netSuiteAppSetupDialogOpen$.next(true);
    netSuiteAppSetupDialogStage$.next(0);
    netSuiteManageConnectionDetails$.next(details);
});

netSuiteAppSetupCloseDialogAction$.subscribe(() => {
    netSuiteAppSetupDialogOpen$.next(false);
    netSuiteManageConnectionAuthorizeLoading$.next(false);
});

netSuiteManageConnectionOpenDialogAction$.subscribe((details) => {
    netSuiteAppSetupDialogErrors$.next(undefined);
    netSuiteManageConnectionDialogErrors$.next(undefined);
    netSuiteManageConnectionDialogOpen$.next(true);
    netSuiteManageConnectionDetails$.next(details);
});

netSuiteManageConnectionCloseDialogAction$.subscribe(() => {
    netSuiteManageConnectionDialogOpen$.next(false);
    netSuiteAppSetupDialogOpen$.next(false);
    netSuiteManageConnectionDetails$.next(undefined);
});

netSuiteConnectionCreatedAction$.subscribe((uid) => connectionCreatedAction$.next(uid));

netSuiteAppSetupAuthorizeAction$
    .pipe(
        map(async (appInfoProps) => {
            netSuiteConnectionSaving$.next(true);
            netSuiteAppSetupDialogErrors$.next(undefined);

            const { uid, connectionType } = netSuiteManageConnectionDetails$.value ?? {};
            try {
                if (!connectionType) {
                    // eslint-disable-next-line sonarjs/no-duplicate-string
                    throw Error('NetSuite Connector type not defined');
                }
                if (!uid) {
                    throw Error('NetSuite Connector has not been created so cannot configure it');
                }

                const baseUrl = configTopic$.value.connection?.netSuite?.baseUrl;
                if (!baseUrl) {
                    throw new Error('No NetSuite Connector url configured in meta');
                }

                await authorizeNetSuiteConnectionCertificate(
                    {
                        ...appInfoProps,
                        accountId: appInfoProps.netSuiteAccountId,
                        connectionId: uid,
                        connectionType: 'NetSuite',
                    },
                    `${baseUrl}/authorize`
                );

                netSuiteConnectionCreatedAction$.next(uid);
                netSuiteConnectionSavedAction$.next({ uid, connectionTypeUid: connectionType.uid });
                netSuiteAppSetupDialogOpen$.next(false);
                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: 'NetSuite Connector has been authorized.',
                });
            } catch (e) {
                if (e instanceof InformativeError) {
                    netSuiteAppSetupDialogErrors$.next(e.message);
                } else {
                    netSuiteAppSetupDialogErrors$.next(
                        'Failed to save NetSuite application details, please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while saving NetSuite application info', e);
                }
            }

            netSuiteConnectionSaving$.next(false);
            netSuiteManageConnectionAuthorizeLoading$.next(false);
        })
    )
    .subscribe();

netSuiteSaveConnectionAction$
    .pipe(
        map(async (name) => {
            netSuiteConnectionSaving$.next(true);
            netSuiteManageConnectionDialogErrors$.next(undefined);

            const { uid, connectionType } = netSuiteManageConnectionDetails$.value ?? {};

            try {
                if (!connectionType) {
                    throw Error('NetSuite Connector type not defined');
                }
                const connectionTypeUid = connectionType.uid;

                if (uid) {
                    if (saveConnectionAllowed(uid)) {
                        await saveNetSuiteConnection(uid, name, connectionTypeUid);
                    } else {
                        promptQuestion({
                            title: AYSYWTSAETCWHAI,
                            onProceed: async () => {
                                await saveNetSuiteConnection(uid, name, connectionTypeUid);
                            },
                        });
                    }
                } else {
                    promptQuestion({
                        title: AYSYWTSAETCWHAI,
                        onProceed: async () => {
                            try {
                                const { uid } = await createConnection(
                                    connectionType.uid,
                                    name,
                                    connectionType.apiHandlerTypes[0]?.uid ?? '',
                                    connectionType.eventListenerTypes[0]?.uid ?? ''
                                );

                                netSuiteConnectionCreatedAction$.next(uid);
                                netSuiteConnectionSavedAction$.next({ uid, connectionTypeUid });
                                netSuiteManageConnectionCloseDialogAction$.next();
                                publishLocalFeedbackEventAction$.next({
                                    level: 'SUCCESS',
                                    message: 'Connector created.',
                                });
                                connectionCreatedFirstTimeAction$.next();
                            } catch (e) {
                                handleManageConnectionError(
                                    e,
                                    netSuiteManageConnectionDialogErrors$,
                                    APP.NETSUITE.NAME
                                );
                            }
                        },
                    });
                }
            } catch (e) {
                handleManageConnectionError(e, netSuiteManageConnectionDialogErrors$, APP.NETSUITE.NAME);
            }

            netSuiteConnectionSaving$.next(false);
        })
    )
    .subscribe();

netSuiteManageConnectionInitiateSetupAction$
    .pipe(
        map(async (name) => {
            netSuiteManageConnectionAuthorizeLoading$.next(true);
            netSuiteManageConnectionDialogErrors$.next(undefined);

            const {
                uid,
                connectionType,
                name: currentName,
                authorized,
                ...restOfDetails
            } = netSuiteManageConnectionDetails$.value ?? {};
            try {
                if (!connectionType) {
                    throw Error('NetSuite Connector type not defined');
                }
                const baseUrl = configTopic$.value.connection?.netSuite?.baseUrl;
                if (!baseUrl) {
                    throw new Error('No NetSuite Connector url configured in meta');
                }
                const connectionTypeUid = connectionType.uid;

                if (uid) {
                    if (currentName !== name) {
                        await saveConnection(uid, name);
                    }
                    if (authorized) {
                        promptQuestion({
                            title: 'By re-authorizing, your certificate will be automatically regenerated, which will invalidate your existing connector setup. Are you sure you want to proceed?',
                            onProceed: async () => {
                                const { publicCertificate } = await generateConnectorCertificate(
                                    {
                                        connectionId: uid,
                                    },
                                    `${baseUrl}/generate`
                                );
                                netSuiteAppSetupOpenDialogAction$.next({
                                    ...restOfDetails,
                                    uid,
                                    connectionType,
                                    name,
                                    publicCertificate,
                                });
                            },
                            onCancel: async () => {
                                netSuiteManageConnectionAuthorizeLoading$.next(false);
                            },
                        });
                    } else {
                        const { publicCertificate } = await generateConnectorCertificate(
                            {
                                connectionId: uid,
                            },
                            `${baseUrl}/generate`
                        );
                        netSuiteAppSetupOpenDialogAction$.next({
                            ...restOfDetails,
                            uid,
                            connectionType,
                            name,
                            publicCertificate,
                        });
                    }
                    netSuiteConnectionSavedAction$.next({ uid, connectionTypeUid });
                } else {
                    const { uid } = await createConnection(
                        connectionType.uid,
                        name,
                        connectionType.apiHandlerTypes[0]?.uid ?? '',
                        connectionType.eventListenerTypes[0]?.uid ?? ''
                    );

                    netSuiteConnectionCreatedAction$.next(uid);
                    const { publicCertificate } = await generateConnectorCertificate(
                        {
                            connectionId: uid,
                        },
                        `${baseUrl}/generate`
                    );
                    netSuiteConnectionSavedAction$.next({ uid, connectionTypeUid });
                    netSuiteAppSetupOpenDialogAction$.next({
                        connectionType,
                        name,
                        uid,
                        publicCertificate,
                    });
                }
            } catch (e) {
                handleManageConnectionError(e, netSuiteManageConnectionDialogErrors$, APP.NETSUITE.NAME);
                netSuiteManageConnectionAuthorizeLoading$.next(false);
            }

            netSuiteConnectionSaving$.next(false);
        })
    )
    .subscribe();

loadLoggedInUserConnectionsWhenEventIsEmitted(netSuiteConnectionSavedAction$);

const saveNetSuiteConnection = async (uid: string, name: string, connectionTypeUid: string): Promise<void> => {
    try {
        await saveConnection(uid, name);

        netSuiteConnectionSavedAction$.next({ uid, connectionTypeUid });
        netSuiteManageConnectionCloseDialogAction$.next();
        publishLocalFeedbackEventAction$.next({
            level: 'SUCCESS',
            message: 'Connector saved.',
        });
        if (connectionCreatedFirstTime$.value) {
            connectionCreatedFirstTimeAction$.next();
            connectionCreatedFirstTime$.next(false);
        }
    } catch (e) {
        handleManageConnectionError(e, netSuiteManageConnectionDialogErrors$, APP.NETSUITE.NAME);
    }
};
