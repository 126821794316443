import { styled } from '@mui/material/styles';
import { Box, FormControl, InputAdornment, MenuItem, TextField, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ChangeEvent, useEffect, useState } from 'react';
import {
    ChangeOrganisationPlanRequest,
    OrganisationDetails,
    OrganisationPlanLimits,
} from '../../data/report/organisationDetails';
import { LoadingSpinner } from '../common/LoadingSpinner';
import { PageContainer } from '../app-main/PageComponentsForDeprecation';
import { Alert } from '../common/alerts/Alert';
import {
    changeOrganisationPartnerCompanyAction$,
    changeOrganisationPlanLimitsAction$,
    changeOrganisationPlanLimitsActionV2$,
    getOrganisationPlanLimitsAction$,
} from '../../store/report/organizations';
import { formatBytesToString } from '../../utils/miscellaneous';
import { Button } from '../common/buttons/Button';

export interface OrganisationsReportPageProps {
    organisation?: OrganisationDetails;
    limits?: OrganisationPlanLimits;
    limitsLoading?: boolean;
    limitsError?: string;
}

const StyledAlert = styled(Alert)(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
}));

const ORGANISATION_PLANS = ['Silver', 'Gold', 'Platinum', 'Suspended', 'Custom'];

export const OrganisationDetailsReportPage: React.FC<OrganisationsReportPageProps> = ({
    organisation,
    limits,
    limitsLoading = false,
    limitsError,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const [organisationPlan, setOrganisationPlan] = useState(organisation?.plan);
    const [organisationPlanPeriod, setOrganisationPlanPeriod] = useState(organisation?.planPeriod);
    const [organisationPartnerCompany, setOrganisationPartnerCompany] = useState(organisation?.partnerCompany);
    const [organisationPlanMonthlyCost, setOrganisationPlanMonthlyCost] = useState(organisation?.monthlyCost);
    const [salablePlanUid, setSalablePlanUid] = useState('');
    const [organisationPlanLimits, setOrganisationPlanLimits] = useState(limits);

    useEffect(() => {
        if (limits) {
            setOrganisationPlanLimits(limits);
            const period = organisationPlanPeriod?.toLowerCase() ?? 'monthly';
            const monthlyCost = period === 'monthly' ? limits.cost.monthly : limits.cost.annually;

            setOrganisationPlanMonthlyCost(monthlyCost);
        }
    }, [limits]);

    useEffect(() => {
        if (organisation) {
            setOrganisationPlan(organisation.plan);
            setOrganisationPlanPeriod(organisation.planPeriod);
            setOrganisationPartnerCompany(organisation.partnerCompany);
        }
    }, [organisation]);

    const handleOrganisationPlanChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const plan = e.target.value;
        setOrganisationPlan(plan);

        if (organisation) {
            getOrganisationPlanLimitsAction$.next({ plan, organisationUid: organisation.uid });
        }
    };

    const handleOrganisationPlanPeriodChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setOrganisationPlanPeriod(e.target.value);
        if (organisationPlan && organisationPlan !== 'custom') {
            const period = e.target.value.toLowerCase();
            const monthlyCost =
                period === 'monthly' ? organisationPlanLimits?.cost.monthly : organisationPlanLimits?.cost.annually;
            if (monthlyCost) {
                setOrganisationPlanMonthlyCost(monthlyCost);
            }
        }
    };

    const handleLimitChange = (value: number, field: string): void => {
        setOrganisationPlanLimits((current) => {
            if (current) {
                return {
                    ...current,
                    [field]: value,
                };
            }
            return current;
        });
    };

    const handleOnPlanChange = (): void => {
        if (organisation && organisation.salablePlan && !!salablePlanUid) {
            changeOrganisationPlanLimitsActionV2$.next({
                organizationUid: organisation.uid,
                salablePlanUid,
            });
        } else if (organisation && organisationPlanLimits) {
            // eslint-disable-next-line no-unused-vars
            const { cost, ...limits } = organisationPlanLimits;
            changeOrganisationPlanLimitsAction$.next({
                ...limits,
                plan: (organisationPlan ?? organisation.plan) as ChangeOrganisationPlanRequest['plan'],
                period: (
                    organisationPlanPeriod ?? organisation.planPeriod
                ).toUpperCase() as ChangeOrganisationPlanRequest['period'],
                monthlyCost: organisationPlanMonthlyCost ?? organisation.monthlyCost,
                organizationUid: organisation.uid,
            });
        }
    };

    const handlePartnerCompanyChange = (): void => {
        if (organisation && organisationPartnerCompany && organisationPartnerCompany.length > 0) {
            changeOrganisationPartnerCompanyAction$.next({
                organizationUid: organisation.uid,
                partnerCompany: organisationPartnerCompany,
            });
        }
    };

    const isNotCustomPlan =
        organisationPlan !== 'custom' || (organisationPlan === 'custom' && !!organisation?.salablePlan);
    const isSamePlan =
        organisation?.planPeriod === organisationPlanPeriod &&
        organisation?.monthlyCost === organisationPlanMonthlyCost &&
        organisation?.connectorsLimit === organisationPlanLimits?.connectorsLimit &&
        organisation?.invocationsLimit === organisationPlanLimits?.invocationsLimit &&
        organisation?.recordStorageCapacityLimit === organisationPlanLimits?.recordStorageCapacityLimit &&
        organisation?.scriptInvocationsBurstLimit === organisationPlanLimits?.scriptInvocationsBurstLimit &&
        organisation?.scriptInvocationsRefillRate === organisationPlanLimits?.scriptInvocationsRefillRate &&
        organisation?.egressCallsBurstLimit === organisationPlanLimits?.egressCallsBurstLimit &&
        organisation?.egressCallsRefillRate === organisationPlanLimits?.egressCallsRefillRate &&
        organisation?.recordStorageApiCallsBurstLimit === organisationPlanLimits?.recordStorageApiCallsBurstLimit &&
        organisation?.recordStorageApiCallsRefillRate === organisationPlanLimits?.recordStorageApiCallsRefillRate &&
        organisation?.triggerScriptApiCallsBurstLimit === organisationPlanLimits?.triggerScriptApiCallsBurstLimit &&
        organisation?.triggerScriptApiCallsBurstLimit === organisationPlanLimits?.triggerScriptApiCallsBurstLimit &&
        organisation?.privateApiCallsBurstLimit === organisationPlanLimits?.privateApiCallsBurstLimit &&
        organisation?.privateApiCallsRefillRate === organisationPlanLimits?.privateApiCallsRefillRate;

    return (
        <PageContainer sx={{ bgcolor: 'background.paper' }}>
            <Box pb={3}>
                <Typography variant="h3">Team Details</Typography>
            </Box>
            {organisation && (
                <Box sx={{ paddingLeft: 2 }}>
                    <Typography>
                        <strong>ID:</strong> {organisation.uid}
                    </Typography>
                    <Typography>
                        <strong>Name:</strong> {organisation.name}
                    </Typography>
                    {organisation.description && (
                        <Typography>
                            <strong>Description:</strong> {organisation.description}
                        </Typography>
                    )}
                    <Typography>
                        <strong>Salable Plan:</strong> {organisation?.salablePlan ? 'Yes' : 'No'}
                    </Typography>
                    <Typography>
                        <strong>Created By:</strong> {organisation.createdBy}
                    </Typography>
                    <Typography>
                        <strong>Plan Name:</strong> {organisation.plan}
                    </Typography>
                    <Typography>
                        <strong>Plan Period:</strong> {organisation.planPeriod}
                    </Typography>
                    <Typography>
                        <strong>Plan Version:</strong> {organisation.planVersion}
                    </Typography>
                    <Typography>
                        <strong>Plan Start Date:</strong> {new Date(organisation.planStartDate).toLocaleDateString()}
                    </Typography>
                    <Typography>
                        <strong>Plan End Date:</strong>{' '}
                        {organisation.planEndDate ? new Date(organisation.planEndDate).toLocaleDateString() : 'Endless'}
                    </Typography>
                    {organisation.planHistory.length > 0 && (
                        <>
                            <Typography>
                                <strong>Plan History:</strong>
                            </Typography>
                            <Table sx={{ marginBottom: 2 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell>Plan name</TableCell>
                                        <TableCell>Plan period</TableCell>
                                        <TableCell>Plan start date</TableCell>
                                        <TableCell>Plan end date</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {organisation.planHistory.map((plan, idx) => (
                                        <TableRow key={idx}>
                                            <TableCell>{idx + 1}</TableCell>
                                            <TableCell>{plan.name}</TableCell>
                                            <TableCell>{plan.period}</TableCell>
                                            <TableCell>{new Date(plan.startDate).toLocaleDateString()}</TableCell>
                                            {plan.endDate && idx + 1 === organisation.planHistory.length ? (
                                                <TableCell>{new Date(plan.endDate).toLocaleDateString()}</TableCell>
                                            ) : organisation.planHistory[idx + 1]?.startDate ? (
                                                <TableCell>
                                                    {new Date(
                                                        organisation.planHistory[idx + 1]?.startDate ?? new Date()
                                                    ).toLocaleDateString()}
                                                </TableCell>
                                            ) : (
                                                <TableCell />
                                            )}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </>
                    )}
                    <Typography>
                        <strong>Subscription Status:</strong> {organisation.subscriptionStatus}
                    </Typography>
                    <Typography>
                        <strong>Billing Details:</strong> {organisation.billingDetails}
                    </Typography>
                    <Typography>
                        <strong>Discount Code:</strong> {organisation.discountCode ?? 'No discount code used'}
                    </Typography>
                    <Typography>
                        <strong>Workspaces:</strong>{' '}
                        {organisation.workspaces.length > 0 ? organisation.workspaces.join(', ') : 'No workspaces'}
                    </Typography>
                    <Typography>
                        <strong>Members:</strong>{' '}
                        {organisation.members.length > 0 ? organisation.members.join(', ') : 'No members'}
                    </Typography>
                    <Typography>
                        <strong>Member Invites:</strong>{' '}
                        {organisation.memberInvites.length > 0
                            ? organisation.memberInvites.join(', ')
                            : 'No member invites'}
                    </Typography>
                </Box>
            )}
            {organisationPlan && (
                <Box sx={{ paddingLeft: 2, paddingTop: 3 }}>
                    <Typography variant="h4">Change team plan (0 = disabled, -1 = unlimited)</Typography>
                    <FormControl sx={{ paddingTop: 2, paddingBottom: 2 }}>
                        <TextField
                            select
                            label={'Team Plan'}
                            value={organisationPlan}
                            disabled={!!organisation?.salablePlan}
                            size="small"
                            onChange={handleOrganisationPlanChange}
                        >
                            {ORGANISATION_PLANS.map((plan, idx) => (
                                <MenuItem key={idx} value={plan.toLowerCase()}>
                                    {plan}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                    <FormControl sx={{ paddingTop: 2, paddingBottom: 2, paddingLeft: 2 }}>
                        <TextField
                            select
                            label={'Team Plan Period'}
                            value={organisationPlanPeriod}
                            disabled={!!organisation?.salablePlan}
                            size="small"
                            onChange={handleOrganisationPlanPeriodChange}
                        >
                            <MenuItem value={'MONTHLY'}>MONTHLY</MenuItem>
                            <MenuItem value={'ANNUALLY'}>ANNUALLY</MenuItem>
                        </TextField>
                    </FormControl>
                    {limitsLoading ? (
                        <LoadingSpinner />
                    ) : (
                        <>
                            {limitsError && <StyledAlert severity="error" title={limitsError} />}
                            <Box sx={{ display: 'flex' }}>
                                <StyledTextField
                                    label="Partner Company"
                                    size="small"
                                    disabled={!organisation?.salablePlan}
                                    value={organisationPartnerCompany}
                                    onChange={(e) => setOrganisationPartnerCompany(e.target.value)}
                                />
                                <Button
                                    disabled={
                                        !organisation?.salablePlan ||
                                        !organisationPartnerCompany ||
                                        organisationPartnerCompany === organisation.partnerCompany
                                    }
                                    onClick={handlePartnerCompanyChange}
                                    sx={{ marginTop: 1, marginBottom: 3 }}
                                >
                                    Change Partner Company
                                </Button>
                            </Box>
                            {organisationPlanLimits && (
                                <Box sx={{ paddingTop: 2 }}>
                                    <Box>
                                        <StyledTextField
                                            label="Monthly Cost"
                                            type="number"
                                            size="small"
                                            disabled={!!organisation?.salablePlan}
                                            value={organisationPlanMonthlyCost}
                                            onChange={(e) => setOrganisationPlanMonthlyCost(+e.target.value)}
                                        />
                                        <StyledTextField
                                            label="Connectors"
                                            type="number"
                                            size="small"
                                            disabled={isNotCustomPlan}
                                            value={organisationPlanLimits.connectorsLimit}
                                            onChange={(e) => handleLimitChange(+e.target.value, 'connectorsLimit')}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        {organisation?.usage.connectorsConsumed ?? 0} of
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <StyledTextField
                                            label="Invocations"
                                            type="number"
                                            size="small"
                                            disabled={isNotCustomPlan}
                                            value={organisationPlanLimits.invocationsLimit}
                                            onChange={(e) => handleLimitChange(+e.target.value, 'invocationsLimit')}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        {organisation?.usage.invocationsConsumed ?? 0} of
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <StyledTextField
                                            label="Record Storage Capacity"
                                            type="number"
                                            size="small"
                                            disabled={isNotCustomPlan}
                                            value={organisationPlanLimits.recordStorageCapacityLimit}
                                            onChange={(e) =>
                                                handleLimitChange(+e.target.value, 'recordStorageCapacityLimit')
                                            }
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        {formatBytesToString(
                                                            organisation?.usage.recordStorageConsumed ?? 0,
                                                            'B'
                                                        )}{' '}
                                                        of
                                                    </InputAdornment>
                                                ),
                                                endAdornment: <InputAdornment position="end">MB</InputAdornment>,
                                            }}
                                        />
                                    </Box>

                                    <OrganisationPlanLimitTextfieldPair
                                        title="Script Invocations"
                                        field="scriptInvocations"
                                        burstLimitValue={organisationPlanLimits.scriptInvocationsBurstLimit}
                                        refillRateValue={organisationPlanLimits.scriptInvocationsRefillRate}
                                        disabled={isNotCustomPlan}
                                        onChange={handleLimitChange}
                                    />
                                    <OrganisationPlanLimitTextfieldPair
                                        title="Egress Calls"
                                        field="egressCalls"
                                        burstLimitValue={organisationPlanLimits.egressCallsBurstLimit}
                                        refillRateValue={organisationPlanLimits.egressCallsRefillRate}
                                        disabled={isNotCustomPlan}
                                        onChange={handleLimitChange}
                                    />
                                    <OrganisationPlanLimitTextfieldPair
                                        title="Record Storage Api Calls"
                                        field="recordStorageApiCalls"
                                        burstLimitValue={organisationPlanLimits.recordStorageApiCallsBurstLimit}
                                        refillRateValue={organisationPlanLimits.recordStorageApiCallsRefillRate}
                                        disabled={isNotCustomPlan}
                                        onChange={handleLimitChange}
                                    />
                                    <OrganisationPlanLimitTextfieldPair
                                        title="Trigger Script Api Calls"
                                        field="triggerScriptApiCalls"
                                        burstLimitValue={organisationPlanLimits.triggerScriptApiCallsBurstLimit}
                                        refillRateValue={organisationPlanLimits.triggerScriptApiCallsRefillRate}
                                        disabled={isNotCustomPlan}
                                        onChange={handleLimitChange}
                                    />
                                    <OrganisationPlanLimitTextfieldPair
                                        title="Private Api Calls"
                                        field="privateApiCalls"
                                        burstLimitValue={organisationPlanLimits.privateApiCallsBurstLimit}
                                        refillRateValue={organisationPlanLimits.privateApiCallsRefillRate}
                                        disabled={isNotCustomPlan}
                                        onChange={handleLimitChange}
                                    />
                                    {organisation?.salablePlan && (
                                        <Box>
                                            {organisation?.salablePlan && (
                                                <FormControl>
                                                    <StyledTextField
                                                        label="Salable Plan to Update"
                                                        type="text"
                                                        size="small"
                                                        value={salablePlanUid}
                                                        onChange={(e) => setSalablePlanUid(e.target.value)}
                                                    />
                                                </FormControl>
                                            )}
                                        </Box>
                                    )}
                                    <Box>
                                        <Button
                                            disabled={organisation?.salablePlan ? !salablePlanUid : isSamePlan}
                                            onClick={handleOnPlanChange}
                                        >
                                            Change Plan
                                        </Button>
                                    </Box>
                                </Box>
                            )}
                        </>
                    )}
                </Box>
            )}
        </PageContainer>
    );
};

interface OrganisationPlanLimitTextfieldPairProps {
    title: string;
    field: string;
    burstLimitValue?: number;
    refillRateValue?: number;
    disabled?: boolean;
    onChange?: (value: number, field: string) => void;
}

export const OrganisationPlanLimitTextfieldPair: React.FC<OrganisationPlanLimitTextfieldPairProps> = ({
    title,
    field,
    burstLimitValue,
    refillRateValue,
    disabled = false,
    onChange,
}) => {
    return (
        <Box>
            <Typography variant="h4" sx={{ paddingBottom: 2 }}>
                {title}
            </Typography>
            <StyledTextField
                label="Burst Limit"
                type="number"
                size="small"
                value={burstLimitValue}
                disabled={disabled}
                onChange={(e) => onChange?.(+e.target.value, `${field}BurstLimit`)}
            />
            <StyledTextField
                label="Refill Rate"
                type="number"
                size="small"
                value={refillRateValue}
                disabled={disabled}
                onChange={(e) => onChange?.(+e.target.value, `${field}RefillRate`)}
            />
        </Box>
    );
};
