import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Button } from '../../../common/buttons/Button';
import { CopyTextField } from '../../../common/inputs/custom/CopyTextField';
import { Dialog } from '../../../common/Dialog';
import { EventListenerInstructionsStep } from '../../EventListenerInstructionsStep';
import { IconCircle } from '../../../common/IconCircle';
import { makeNonListItem } from '../../../common/lists/OrderedList';
import { ProductIcon } from '../../../icons/ProductIcon';
import { eventListenerDocumentationUrl } from '../../../../utils/documentation';
import { handleKeyDown } from '../../../../utils/input';

interface ServiceNowEventListenerSetupDialogProps {
    loading?: boolean;
    open?: boolean;
    setupBaseUrl?: string;
    webhookBaseUrl: string;
    webhookUrlId: string;
    onClose: () => void;
}

export const ServiceNowEventListenerSetupDialog: React.FC<ServiceNowEventListenerSetupDialogProps> = ({
    loading = false,
    open = false,
    setupBaseUrl,
    webhookBaseUrl,
    webhookUrlId,
    onClose,
}) => {
    const appName = 'ServiceNow';

    const createRecordInstructions = [
        setupBaseUrl ? (
            <Typography>
                Visit your{' '}
                <Link target="_blank" href={`${setupBaseUrl}/sys_rest_message_list.do`}>
                    REST messages
                </Link>{' '}
                page.
            </Typography>
        ) : (
            <Typography>Open https://[YOUR_SERVICENOW_INSTANCE]/sys_rest_message_list.do in your browser</Typography>
        ),
        <Typography>
            Click <strong>New</strong>.
        </Typography>,
        <Typography>
            Enter a meaningful name into the <strong>Name</strong> field.
        </Typography>,
        <Typography>
            Paste the copied URL into the <strong>Endpoint</strong> field.
        </Typography>,
        makeNonListItem(
            <CopyTextField fullWidth label="Webhook URL" value={`${webhookBaseUrl}/${webhookUrlId}`} />,
            'servicenow-create-record-webhook-url'
        ),
        <Typography>
            Click <strong>Submit</strong>.
        </Typography>,
    ];

    const createHttpMethodInstructions = [
        'Select the newly created REST message from the list to continue the configuration.',
        <Typography>
            Scroll down to <strong>HTTP Methods</strong> and click <strong>New</strong> to create a new HTTP method.
        </Typography>,
        <Typography>
            Enter a meaningful name into the <strong>Name</strong> field.
        </Typography>,
        <Typography>
            Select <strong>POST</strong> from the <strong>HTTP method</strong> dropdown.
        </Typography>,
        <Typography>
            Paste the copied URL into the <strong>Endpoint</strong> field.
        </Typography>,
        makeNonListItem(
            <CopyTextField fullWidth label="Webhook URL" value={`${webhookBaseUrl}/${webhookUrlId}`} />,
            'servicenow-create-http-method-webhook-url'
        ),
    ];

    const configureHttpMethodInstructions = [
        <Typography>
            Click on the <strong>HTTP Request</strong> tab.
        </Typography>,
        <Typography>
            In the <strong>HTTP Headers</strong> section, click on <strong>Insert a new row...</strong> Copy and paste
            values to the <strong>Name</strong> and <strong>Value</strong> inputs respectively
        </Typography>,
        makeNonListItem(
            <CopyTextField fullWidth label="HTTP Header Name" value="Content-type" />,
            'servicenow-http-header-name'
        ),
        makeNonListItem(
            <CopyTextField fullWidth label="HTTP Header Value" value="application/json; charset=UTF-8" />,
            'servicenow-http-header-value'
        ),
        <Typography>
            Copy the JSON and paste it to the <strong>Content</strong> field under the{' '}
            <strong>HTTP Query Parameters</strong> section. This will be the event payload that is sent to ScriptRunner
            Connect.
        </Typography>,
        makeNonListItem(
            <CopyTextField
                fullWidth
                label="Content"
                value={
                    '{"title": "${title}", "eventType": "generic_triggered", "user": "${user}", "trigger": "${trigger}"}'
                }
            />,
            'servicenow-payload-content'
        ),
        <Typography>
            Optionally you can add more variables to your event payload, by editing the provided JSON. Note that the
            values for these variables will need to be specified later under <strong>Business rules</strong>.
        </Typography>,
        <Typography>
            Click on <strong>Submit</strong>.
        </Typography>,
    ];

    const generateVariablesInstructions = [
        'Click on the newly created HTTP method.',
        <Typography>
            Scroll down to <strong>Related Links</strong>.
        </Typography>,
        <Typography>
            Click on the <strong>Auto-generate variables</strong> link.
        </Typography>,
        <Typography>
            Click on the <strong>Preview Script Usage</strong> to generate the code required to send a request to the
            webhook.
        </Typography>,
        'Copy the generated code.',
        <Typography>
            Close the modal and click on <strong>Update</strong>.
        </Typography>,
    ];

    const createBusinessRuleInstructions = [
        setupBaseUrl ? (
            <Typography>
                Navigate to{' '}
                <Link target="_blank" href={`${setupBaseUrl}/sys_script_list.do`}>
                    Business Rules
                </Link>
            </Typography>
        ) : (
            <Typography>Open https://[YOUR_SERVICENOW_INSTANCE]/sys_script_list.do in your browser</Typography>
        ),
        <Typography>
            Click on <strong>New</strong>.
        </Typography>,
        <Typography>
            Enter a meaningful name into the <strong>Name</strong> field.
        </Typography>,
        <Typography>
            Select the table for which you wish to set up the event listener, for example{' '}
            <strong>{'Incident[incident]'}</strong>.
        </Typography>,
        <Typography>
            Check the <strong>Advanced</strong> checkbox.
        </Typography>,
        'Check one or more appropriate checkboxes on the right depending on which events you wish to listen to.',
        <Typography>
            Optionally, you can change the timing of when exactly the rule will be run by selecting a preferred option
            from the <strong>When</strong> dropdown.
        </Typography>,
        <Typography>
            If you wish to add advanced conditions to when your event will fire, you can do so under{' '}
            <strong>Filter Conditions</strong> and <strong>Role Conditions</strong>.
        </Typography>,
    ];

    const configureScriptInstructions = [
        <Typography>
            Click on the <strong>Advanced</strong> tab and paste the code you had copied in the preview step below the{' '}
            <strong>{'//Add your code here'}</strong> line.
        </Typography>,
        <Typography>
            In the code, update the <strong>r.execute()</strong> function call to <strong>r.executeAsync()</strong>.
        </Typography>,
        <Typography>
            Replace <strong>r.setStringParameterNoEscape('user', '');</strong> with:
        </Typography>,
        makeNonListItem(
            <CopyTextField
                fullWidth
                label="User"
                value={`r.setStringParameterNoEscape('user', gs.getUser().getID());`}
            />,
            'servicenow-user-line'
        ),
        <Typography>
            Replace <strong>r.setStringParameterNoEscape('trigger', '');</strong> with:
        </Typography>,
        makeNonListItem(
            <CopyTextField
                fullWidth
                label="Trigger"
                value={`r.setStringParameterNoEscape('trigger', current.getDisplayValue());`}
            />,
            'servicenow-trigger-line'
        ),
        <Typography>
            Optionally replace the empty string in <strong>r.setStringParameterNoEscape('title', '');</strong> with an
            appropriate title in order to give the event object a name.
        </Typography>,
        <Typography>
            Optionally if you defined more variables in step 14, they will show up here as well, but you will need to
            provide the data yourself. Referencing the <strong>current</strong> object might be helpful.
        </Typography>,
        <Typography>
            For advanced use cases you can optionally specify your filter conditions using JavaScript. In order to do
            that, write your condition into the <strong>Condition</strong> field, by referencing the{' '}
            <strong>current</strong> object.
        </Typography>,
        <Typography>
            Click <strong>Submit</strong>.
        </Typography>,
    ];

    return (
        <Dialog
            buttons={[
                <Button disabled={loading} onClick={onClose} variant="outlined">
                    Close
                </Button>,
                <Button disabled={loading} onClick={onClose} color="success">
                    Mark as complete
                </Button>,
            ]}
            leftButton={
                <Button onClick={() => window.open(eventListenerDocumentationUrl)} variant="text">
                    Learn more
                </Button>
            }
            icon={<IconCircle icon={<ProductIcon name={appName} />} size="medium" />}
            open={open}
            title="Event listener setup"
            subtitle={appName}
            onClose={onClose}
            loading={loading}
            onKeyDown={(event) => handleKeyDown({ event, enterCondition: !loading, enterFn: onClose, escFn: onClose })}
        >
            <>
                <Box>
                    <Typography variant="subtitle1" component="h6" mb={0.5}>
                        How to set up an event listener
                    </Typography>
                    <Typography color="text.secondary">
                        Create a webhook in {appName} instance to listen to events.
                    </Typography>
                </Box>
                <EventListenerInstructionsStep
                    content={createRecordInstructions}
                    expanded={true}
                    id="servicenow-create-record"
                    title="Step 1: Create REST message record"
                />
                <EventListenerInstructionsStep
                    content={createHttpMethodInstructions}
                    id="servicenow-create-http-method"
                    startCounter={6}
                    title="Step 2: Create HTTP Method"
                />
                <EventListenerInstructionsStep
                    content={configureHttpMethodInstructions}
                    id="servicenow-configure-http-method"
                    startCounter={11}
                    title="Step 3: Configure HTTP method"
                />
                <EventListenerInstructionsStep
                    content={generateVariablesInstructions}
                    id="servicenow-generate-variables"
                    startCounter={16}
                    title="Step 4: Generate variables for event payload"
                />
                <EventListenerInstructionsStep
                    content={createBusinessRuleInstructions}
                    id="servicenow-create-business-rule"
                    startCounter={22}
                    title="Step 5: Create business rule"
                />
                <EventListenerInstructionsStep
                    content={configureScriptInstructions}
                    id="servicenow-configure-script"
                    startCounter={30}
                    title="Step 6: Configure business rule script"
                />
            </>
        </Dialog>
    );
};
