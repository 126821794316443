import { LoaderFn } from 'react-location';
import { LocationGenerics } from '../../router';
import { loadApps } from '../../store/apps';
import { navigateAction$ } from '../../store/navigation';
import { getBasePath } from '../../utils/path';
import { onboardingWizardError$ } from '../../store/onboarding';

export const onboardingAppSelectorLoader: LoaderFn<LocationGenerics> = async () => {
    onboardingWizardError$.next(undefined);

    try {
        await loadApps();
    } catch (e) {
        navigateAction$.next(getBasePath());
    }
    return {};
};
