import React, { useEffect, useState } from 'react';
import { Buffer } from 'buffer';
import { styled } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import { GenericIcon } from '../../../icons/product-icons/GenericIcon';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Alert } from '../../../common/alerts/Alert';
import { Button } from '../../../common/buttons/Button';
import { DialogTitleMain } from '../../../for-deprecation/dialog/DialogComponents';
import { testUrl } from '../../../../utils/input';

interface Header {
    name: string;
    value: string;
}

interface TrackableHeader extends Header {
    fromDb?: boolean;
}

interface SaveHeaderProps {
    url: string;
    newHeaders: Header[];
    deletedHeaders: string[];
}

interface GenericApiConnectionConfigureDialogProps {
    baseUrl?: string;
    errors?: string;
    headers?: string[];
    onSave(saveHeaderProps: SaveHeaderProps): void;
    onCancel(): void;
    open: boolean;
    saving?: boolean;
    setError: (error: string) => void;
    clearErrors: () => void;
}

const StyledSectionContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(1),
    width: 300,
}));

const StyledInstructions = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(1),
}));

const StyledActionButtons = styled(DialogActions)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    marginLeft: 'auto',
    marginTop: theme.spacing(1),
}));

const StyledHeader = styled('div')(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    marginLeft: theme.spacing(2),
}));

const StyledHeaderName = styled(Typography)(() => ({
    marginBottom: 0,
    width: 150,
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    marginLeft: theme.spacing(2),
    '& .MuiSvgIcon-root': {
        height: 18,
        width: 18,
    },
}));

export const GenericApiConnectionConfigureDialog: React.FC<GenericApiConnectionConfigureDialogProps> = ({
    baseUrl = '',
    errors,
    headers = [],
    onCancel,
    onSave,
    open = false,
    saving,
    setError,
    clearErrors,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const headersFromDb = headers.map((h) => {
        return { name: h, value: '', fromDb: true };
    });
    const [currentBaseUrl, setCurrentBaseUrl] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [headerName, setHeaderName] = useState('');
    const [headerValue, setHeaderValue] = useState('');
    const [currentHeaders, setCurrentHeaders] = useState<TrackableHeader[]>([]);
    const [basicAuthFormOpen, setBasicAuthFormOpen] = useState(false);
    const [customHeaderFormOpen, setCustomHeaderFormOpen] = useState(false);
    const [isAuthAdded, setIsAuthAdded] = useState(false);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [badUrlAttempted, setBadUrlAttempted] = useState<boolean>(false);
    const [testingUrl, setTestingUrl] = useState<boolean>(false);

    useEffect(() => {
        // strange but necessary workaround - b/c the component is already mounted
        // we need to set the state of these 2 variables when it's opened *shrugs*
        if (open) {
            setCurrentBaseUrl(baseUrl);
            setCurrentHeaders(headersFromDb);
        }
    }, [open]);

    useEffect(() => {
        const isAuthPresent = currentHeaders.some((ch) => ch.name === 'Authorization');
        setIsAuthAdded(isAuthPresent);
    }, [currentHeaders]);

    useEffect(() => {
        const hasChanges =
            currentHeaders.length !== headersFromDb.length ||
            !currentHeaders.every((ch) => ch.fromDb) ||
            currentBaseUrl !== baseUrl;
        setHasUnsavedChanges(hasChanges);
    }, [currentHeaders, currentBaseUrl]);

    const handleAddBasicAuth = (credentials: { username: string; password: string }): void => {
        let newHeaders: TrackableHeader[] = [];
        const basicAuthToken = `Basic ${Buffer.from(credentials.username + ':' + credentials.password).toString(
            'base64'
        )}`;
        if (isAuthAdded) {
            newHeaders = currentHeaders.map((ch) => {
                return ch.name === 'Authorization' ? { name: 'Authorization', value: basicAuthToken } : ch;
            });
        } else {
            newHeaders = [...currentHeaders, { name: 'Authorization', value: basicAuthToken }];
        }
        setCurrentHeaders(newHeaders);
        setBasicAuthFormOpen(false);
        setPassword('');
        setUsername('');
    };

    const handleCancelBasicAuth = (): void => {
        setBasicAuthFormOpen(false);
        setPassword('');
        setUsername('');
    };

    const handleAddCustomHeader = (header: Header): void => {
        const existingHeader = currentHeaders.find((ch) => ch.name === header.name);
        let newHeaders: TrackableHeader[] = [];

        if (existingHeader) {
            newHeaders = currentHeaders.map((ch) => {
                return ch.name === existingHeader.name ? header : ch;
            });
        } else {
            newHeaders = [...currentHeaders, header];
        }
        setCurrentHeaders(newHeaders);
        setCustomHeaderFormOpen(false);
        setHeaderName('');
        setHeaderValue('');
    };

    const handleCancelCustomHeader = (): void => {
        setCustomHeaderFormOpen(false);
        setHeaderName('');
        setHeaderValue('');
    };

    const handleRemoveHeader = (header: TrackableHeader): void => {
        const newHeaders = currentHeaders.filter((h) => h.name !== header.name);
        setCurrentHeaders(newHeaders);
        setCustomHeaderFormOpen(false);
    };

    const handleSave = (): void => {
        const newHeaders: Header[] = currentHeaders.filter((ch) => !ch.fromDb);
        const deletedHeaders = headersFromDb
            .filter((hfd) => {
                return (
                    !currentHeaders.find((ch) => ch.name === hfd.name) ||
                    currentHeaders.some((ch) => ch.name === hfd.name && !ch.fromDb)
                );
            })
            .map((h) => h.name);

        onSave({ url: currentBaseUrl, newHeaders, deletedHeaders });
    };

    const displayedHeaders = currentHeaders.map((h) => {
        return (
            <StyledHeader key={h.name}>
                <StyledHeaderName noWrap variant="body1">
                    {h.name}
                </StyledHeaderName>
                <StyledIconButton onClick={() => handleRemoveHeader(h)}>
                    <DeleteOutlineIcon className="lightIcon" />
                </StyledIconButton>
            </StyledHeader>
        );
    });

    return (
        <Dialog open={open}>
            <DialogTitleMain
                title="Configure Generic API Connection Headers"
                variant="h6"
                icon={<GenericIcon className="productIcon" fontSize="medium" />}
            />
            {errors && <Alert severity="error" title={errors} />}
            <DialogContent>
                <StyledSectionContainer>
                    <TextField
                        variant="outlined"
                        label="Base URL *"
                        placeholder="Enter URL"
                        onChange={(e) => {
                            if (badUrlAttempted) {
                                clearErrors();
                                setBadUrlAttempted(false);
                            }
                            setCurrentBaseUrl(e.target.value);
                        }}
                        value={currentBaseUrl}
                    />
                </StyledSectionContainer>
                <StyledSectionContainer
                    onKeyDown={(event) => {
                        if (basicAuthFormOpen && event.key === 'Enter' && username && password) {
                            handleAddBasicAuth({ username, password });
                        }
                    }}
                >
                    {basicAuthFormOpen ? (
                        <>
                            <StyledInstructions variant="subtitle2">Add Credentials</StyledInstructions>
                            <TextField
                                variant="outlined"
                                label="Username"
                                placeholder="Enter username"
                                required
                                onChange={(event) => setUsername(event.target.value)}
                                value={username}
                            />
                            <TextField
                                variant="outlined"
                                label="Password"
                                placeholder="Enter password"
                                required
                                onChange={(event) => setPassword(event.target.value)}
                                value={password}
                                type="password"
                            />
                            <StyledActionButtons>
                                <Button size="small" variant="outlined" onClick={handleCancelBasicAuth}>
                                    Cancel
                                </Button>
                                <Button
                                    size="small"
                                    disabled={!username || !password}
                                    onClick={() => handleAddBasicAuth({ username, password })}
                                >
                                    {isAuthAdded ? 'Update' : 'Add'}
                                </Button>
                            </StyledActionButtons>
                        </>
                    ) : (
                        <Button
                            variant="text"
                            startIcon={
                                isAuthAdded ? <EditOutlinedIcon sx={{ transform: 'translateX(2px)' }} /> : <AddIcon />
                            }
                            onClick={() => setBasicAuthFormOpen(true)}
                        >
                            {isAuthAdded ? 'Change Auth Header' : 'Add Basic Auth'}
                        </Button>
                    )}
                </StyledSectionContainer>
                <StyledSectionContainer
                    onKeyDown={(event) => {
                        if (customHeaderFormOpen && event.key === 'Enter' && headerName && headerValue) {
                            handleAddCustomHeader({ name: headerName, value: headerValue });
                        }
                    }}
                >
                    {customHeaderFormOpen ? (
                        <>
                            <StyledInstructions variant="subtitle2">Add Custom Header Details</StyledInstructions>
                            <TextField
                                variant="outlined"
                                label="Header name"
                                placeholder="Enter header name"
                                required
                                onChange={(event) => setHeaderName(event.target.value)}
                                value={headerName}
                            />
                            <TextField
                                variant="outlined"
                                label="Header value"
                                placeholder="Enter header value"
                                required
                                onChange={(event) => setHeaderValue(event.target.value)}
                                value={headerValue}
                            />
                            <StyledActionButtons>
                                <Button size="small" variant="outlined" onClick={handleCancelCustomHeader}>
                                    Cancel
                                </Button>
                                <Button
                                    size="small"
                                    disabled={!headerName || !headerValue}
                                    onClick={() => {
                                        handleAddCustomHeader({ name: headerName, value: headerValue });
                                    }}
                                >
                                    {currentHeaders.some((h) => h.name === headerName) ? 'Update' : 'Add'}
                                </Button>
                            </StyledActionButtons>
                        </>
                    ) : (
                        <Button variant="text" startIcon={<AddIcon />} onClick={() => setCustomHeaderFormOpen(true)}>
                            Add Custom Header
                        </Button>
                    )}
                </StyledSectionContainer>
                {!!currentHeaders.length && (
                    <StyledSectionContainer>
                        <StyledInstructions variant="subtitle2">Added Headers:</StyledInstructions>
                        {displayedHeaders}
                    </StyledSectionContainer>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        setCurrentBaseUrl('');
                        setUsername('');
                        setPassword('');
                        setHeaderName('');
                        setHeaderValue('');
                        setCurrentHeaders([]);
                        setBasicAuthFormOpen(false);
                        setCustomHeaderFormOpen(false);
                        setIsAuthAdded(false);
                        setHasUnsavedChanges(false);
                        clearErrors();
                        onCancel();
                    }}
                    variant="outlined"
                >
                    Cancel
                </Button>
                <Button
                    data-pendo={'connectorAuthorised'}
                    busy={saving || testingUrl}
                    //eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={async () => {
                        setTestingUrl(true);
                        if (await testUrl(currentBaseUrl)) {
                            handleSave();
                            setBadUrlAttempted(false);
                        } else {
                            setError(
                                'A URL protocol was not specified. Please append the url below with the desired protocol such as https:// or http://.'
                            );
                            setBadUrlAttempted(true);
                        }
                        setTestingUrl(false);
                    }}
                    disabled={!currentBaseUrl || !hasUnsavedChanges}
                    variant="contained"
                    color="primary"
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
