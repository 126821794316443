import { useObservableState } from 'observable-hooks';
import { OrganizationsReportPage } from '../../components/report/OrganizationsReportPage';
import {
    getOrganizationsReportAction$,
    organizationReportLoading$,
    organizationsReport$,
} from '../../store/report/organizations';
import { OrganizationsReportRequest } from '../../data/report/organizations';
import { useMatch, useNavigate } from 'react-location';

export const OrganizationsReportPageContainer: React.FC = () => {
    const organizationsReport = useObservableState(organizationsReport$);
    const loading = useObservableState(organizationReportLoading$);
    const navigate = useNavigate();
    const match = useMatch();

    const handleSearch = (request: OrganizationsReportRequest): void => {
        if (request.cursor) {
            getOrganizationsReportAction$.next(request);
        } else {
            navigate({
                to: '../',
            });
            setTimeout(
                () =>
                    navigate({
                        to: './teams',
                        search: {
                            search: true,
                            userType: request.userType,
                            status: request.status,
                            plan: request.plan,
                            planPeriod: request.planPeriod,
                        },
                    }),
                100
            );
        }
    };
    return (
        <OrganizationsReportPage
            organizations={organizationsReport?.organizations}
            cursor={organizationsReport?.cursor}
            loading={loading}
            userType={match.search.userType as OrganizationsReportRequest['userType']}
            status={match.search.status as OrganizationsReportRequest['status']}
            plan={match.search.plan as OrganizationsReportRequest['plan']}
            planPeriod={match.search.planPeriod as OrganizationsReportRequest['planPeriod']}
            onSearch={handleSearch}
        />
    );
};
