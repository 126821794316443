import { useObservableState } from 'observable-hooks';
import { AWSManageConnectionDialog } from '../../../../components/connections/apps/aws/ManageConnectionDialog';
import {
    awsConnectionSaving$,
    awsManageConnectionAuthorizeLoading$,
    awsManageConnectionCloseDialogAction$,
    awsManageConnectionDetails$,
    awsManageConnectionDialogErrors$,
    awsManageConnectionDialogOpen$,
    awsManageConnectionInitiateSetupAction$,
    awsSaveConnectionAction$,
} from '../../../../store/connection/aws/setup-connection';
import { loggedInUserConnections$ } from '../../../../store/connections';

export const AWSManageConnectionDialogContainer: React.FC = () => {
    const open = useObservableState(awsManageConnectionDialogOpen$);
    const saving = useObservableState(awsConnectionSaving$);
    const authorizeLoading = useObservableState(awsManageConnectionAuthorizeLoading$);
    const details = useObservableState(awsManageConnectionDetails$);
    const errors = useObservableState(awsManageConnectionDialogErrors$);
    const connection = useObservableState(loggedInUserConnections$).find(
        (connection) => connection.uid === details?.uid
    );

    return (
        <AWSManageConnectionDialog
            open={open}
            saving={saving}
            errors={errors}
            authorizeLoading={authorizeLoading}
            name={connection?.name ?? ''}
            authorized={!!connection?.authorized}
            onCancel={() => awsManageConnectionCloseDialogAction$.next()}
            onSave={(name) => awsSaveConnectionAction$.next(name)}
            onAuthorize={(name) => awsManageConnectionInitiateSetupAction$.next(name)}
            connectionType={details?.connectionType.name}
        />
    );
};
