/* eslint-disable sonarjs/no-duplicate-string */
import { MakeGenerics, Outlet, ReactLocation, Route } from 'react-location';
import { ApiHandlerDetailsContainer } from './containers/workspace-resources/advanced-view/api-connections/ApiConnectionDetailsContainer';
import { AppMainContainer } from './containers/app-main/AppMainContainer';
import { AppSelectorContainer } from './containers/app-main/AppSelectorContainer';
import { EventListenerDetailsContainer } from './containers/workspace-resources/advanced-view/event-listener/EventListenerTabsContainer';
import { ScriptDetailsContainer } from './containers/workspace-resources/advanced-view/scripts/ScriptDetailsContainer';
import { WorkspaceContainer } from './containers/workspace-layouts/WorkspaceContainer';
import { WorkspaceSelectorContainer } from './containers/workspace-layouts/WorkspaceSelectorContainer';
import { apiHandlerDetailsLoader, readOnlyApiHandlerDetailsLoader } from './loaders/workspace/api-handler';
import { appLoader } from './loaders/app';
import { eventListenerLoader, readOnlyEventListenerLoader } from './loaders/workspace/event-listener';
import { scriptDetailsLoader } from './loaders/workspace/script';
import { workspaceLoader, workspaceUnloader } from './loaders/workspace/workspace';
import { workspacesLoader } from './loaders/workspaces';
import { CreateNewWorkspaceEnvironmentDialogContainer } from './containers/workspace-dialogs/CreateNewEnvironmentDialogContainer';
import { ManageEnvironmentDeploymentTargetsDialogContainer } from './containers/workspace-dialogs/ManageEnvironmentDeploymentTargetsDialogContainer';
import { SlackManageConnectionDialogContainer } from './containers/connections/apps/slack/ManageConnectionDialogContainer';
import { SlackAppConfigureDialog } from './containers/connections/apps/slack/SlackAppConfigureDialogContainer';
import { SlackEventListenerSetupDialogContainer } from './containers/connections/apps/slack/EventListenerSetupDialogContainer';
import { TemplatesContainer } from './containers/templates/TemplatesContainer';
import { templatesLoader } from './loaders/templates';
import { ConfirmDialogContainer } from './containers/app-main/ConfirmDialogContainer';
import { UsersReportPageContainer } from './containers/report/UsersReportPageContainer';
import { usersReportLoader } from './loaders/report/users';
import { OrganisationsReportPageContainer } from './containers/report/OrganisationsReportPageContainer';
import { organisationsReportLoader } from './loaders/report/organisations';
import { OrganisationDetailsReportPageContainer } from './containers/report/OrganisationDetailsReportPageContainer';
import { organisationDetailsReportLoader } from './loaders/report/organisationDetails';
import { InvocationsReportPageContainer } from './containers/report/InvocationsReportPageContainer';
import { invocationsReportLoader } from './loaders/report/invocations';
import { Reports } from './components/report/Reports';
import { BitbucketCloudManageConnectionDialogContainer } from './containers/connections/apps/bitbucket-cloud/ManageConnectionDialogContainer';
import { BitbucketCloudConfigureDialog } from './containers/connections/apps/bitbucket-cloud/BitbucketCloudConfigureDialogContainer';
import { GenericEventListenerSetupDialogContainer } from './containers/connections/apps/generic/EventListenerSetupDialogContainer';
import { ServiceNowManageConnectionDialogContainer } from './containers/connections/apps/servicenow/ManageConnectionDialogContainer';
import { ServiceNowConfigureDialog } from './containers/connections/apps/servicenow/ServiceNowConfigureDialogContainer';
import { ZoomManageConnectionDialogContainer } from './containers/connections/apps/zoom/ManageConnectionDialogContainer';
import { ZoomConfigureDialog } from './containers/connections/apps/zoom/ZoomConfigureDialogContainer';
import { BitbucketOnPremiseManageConnectionDialogContainer } from './containers/connections/apps/bitbucket-on-premise/ManageConnectionDialogContainer';
import { BitbucketOnPremiseConfigureDialog } from './containers/connections/apps/bitbucket-on-premise/BitbucketOnPremiseConfigureDialogContainer';
import { SalesforceManageConnectionDialogContainer } from './containers/connections/apps/salesforce/ManageConnectionDialogContainer';
import { SalesforceConfigureDialog } from './containers/connections/apps/salesforce/SalesforceConfigureDialogContainer';
import { AtlassianCloudEventListenerSetupDialogContainer } from './containers/connections/apps/atlassian-cloud/EventListenerSetupDialogContainer';
import { AtlassianCloudManageConnectionDialogContainer } from './containers/connections/apps/atlassian-cloud/ManageConnectionDialogContainer';
import { AtlassianCloudInstanceSelectionContainer } from './containers/connections/apps/atlassian-cloud/AtlassianCloudInstanceSelectionContainer';
import { ApiConnectionImportDialogContainer } from './containers/workspace-dialogs/ApiConnectionImportDialogContainer';
import { EditScriptNameDialogContainer } from './containers/workspace-dialogs/EditScriptNameDialogContainer';
import { DashboardContainer } from './containers/dashboard/DashboardContainer';
import { dashboardLoader } from './loaders/dashboard';
import { CreateNewWorkspaceDialogContainer } from './containers/workspace-dialogs/NewWorkspaceDialogContainer';
import { EditWorkspaceDialogContainer } from './containers/workspace-dialogs/EditWorkspaceDialogContainer';
import { AtlassianOnPremiseManageConnectionDialogContainer } from './containers/connections/apps/atlassian-on-premise/ManageConnectionDialogContainer';
import { AtlassianOnPremiseConfigureDialog } from './containers/connections/apps/atlassian-on-premise/AtlassianOnPremiseConfigureDialogContainer';
import { AtlassianOnPremiseEventListenerSetupDialogContainer } from './containers/connections/apps/atlassian-on-premise/EventListenerSetupDialogContainer';
import { UsersWithDeployedWorkspacesReportPageContainer } from './containers/report/UsersWithDeployedWorkspacesReportPageContainer';
import { usersWithDeployedWorkspacesReportLoader } from './loaders/report/usersWithDeployedWorkspaces';
import { UserFeedbackReportPageContainer } from './containers/report/UserFeedbackReportPageContainer';
import { userFeedbackReportLoader } from './loaders/report/userFeedback';
import { DefaultWorkspaceViewContainer } from './containers/workspace-layouts/DefaultWorkspaceViewContainer';
import { GoogleManageConnectionDialogContainer } from './containers/connections/apps/google/ManageConnectionDialogContainer';
import { GithubManageConnectionDialogContainer } from './containers/connections/apps/github/ManageConnectionDialogContainer';
import { BitbucketCloudEventListenerSetupDialogContainer } from './containers/connections/apps/bitbucket-cloud/EventListenerSetupDialogContainer';
import { GitlabManageConnectionDialogContainer } from './containers/connections/apps/gitlab/ManageConnectionDialogContainer';
import { GitlabConfigureDialog } from './containers/connections/apps/gitlab/GitlabConfigureDialogContainer';
import { GitlabEventListenerSetupDialogContainer } from './containers/connections/apps/gitlab/EventListenerSetupDialogContainer';
import { GithubEventListenerSetupDialogContainer } from './containers/connections/apps/github/EventListenerSetupDialogContainer';
import { ConnectionTypesReportPageContainer } from './containers/report/ConnectionTypesReportPageContainer';
import { connectionTypesReportLoader } from './loaders/report/connectionTypes';
import { ServiceNowEventListenerSetupDialogContainer } from './containers/connections/apps/servicenow/EventListenerSetupDialogContainer';
import { NetSuiteEventListenerSetupDialogContainer } from './containers/connections/apps/netsuite/EventListenerSetupDialogContainer';
import { ZoomEventListenerSetupDialogContainer } from './containers/connections/apps/zoom/EventListenerSetupDialogContainer';
import { BitbucketOnPremiseEventListenerSetupDialogContainer } from './containers/connections/apps/bitbucket-on-premise/EventListenerSetupDialogContainer';
import { ConnectorsContainer } from './containers/connections/ConnectionsContainer';
import { connectorsLoader } from './loaders/connectors';
import { LanguageSelectorContainer } from './containers/workspace-dialogs/LanguageSelectorContainer';
import { GenericManageConnectionDialogContainer } from './containers/connections/apps/generic/ManageConnectionDialogContainer';
import { GenericAppConfigureDialog } from './containers/connections/apps/generic/GenericAppConfigureDialogContainer';
import { MondayManageConnectionDialogContainer } from './containers/connections/apps/monday/ManageConnectionDialogContainer';
import { MondayConfigureDialog } from './containers/connections/apps/monday/MondayConfigureDialogContainer';
import { MondayEventListenerSetupDialogContainer } from './containers/connections/apps/monday/EventListenerSetupDialogContainer';
import { readmeFileDetailsLoader } from './loaders/workspace/readme';
import { ReadmeFileDetailsContainer } from './containers/workspace-resources/advanced-view/readme/ReadmeFileDetailsContainer';
import { PackageManagerContainer } from './containers/workspace-dialogs/PackageManagerContainer';
import { TempoCloudManageConnectionDialogContainer } from './containers/connections/apps/tempo-cloud/ManageConnectionDialogContainer';
import { TempoCloudConfigureDialog } from './containers/connections/apps/tempo-cloud/TempoCloudConfigureDialogContainer';
import { NetSuiteManageConnectionDialogContainer } from './containers/connections/apps/netsuite/ManageConnectionDialogContainer';
import { NetSuiteConfigureDialog } from './containers/connections/apps/netsuite/NetSuiteConfigureDialogContainer';
import { ScheduledTriggerDetailsContainer } from './containers/workspace-resources/advanced-view/scheduled-triggers/ScheduledTriggerDetailsContainer';
import { scheduledTriggerLoader } from './loaders/workspace/scheduled-trigger';
import { editTemplatePendingLoader, readOnlyTemplateLoader, readOnlyTemplateUnloader } from './loaders/template';
import { ReadOnlyTemplateContainer } from './containers/templates/ReadOnlyTemplateContainer';
import { DefaultReadOnlyTemplateViewContainer } from './containers/workspace-layouts/DefaultReadOnlyTemplateViewContainer';
import { ReadOnlyApiHandlerDetailsContainer } from './containers/workspace-resources/advanced-view/api-connections/ReadOnlyApiConnectionDetailsContainer';
import { ReadOnlyEventListenerDetailsContainer } from './containers/workspace-resources/advanced-view/event-listener/ReadOnlyEventListenerTabsContainer';
import { ReadOnlyScheduledTriggerDetailsContainer } from './containers/workspace-resources/advanced-view/scheduled-triggers/ReadOnlyScheduledTriggerDetailsContainer';
import { JsmCloudAssetsManageConnectionDialogContainer } from './containers/connections/apps/jsmCloudAssets/ManageConnectionDialogContainer';
import { JsmCloudAssetsConfigureDialog } from './containers/connections/apps/jsmCloudAssets/JsmCloudAssetsConfigureDialogContainer';
import { RegisteredVsActiveUsersReportPageContainer } from './containers/report/RegisteredVsActiveUsersReportPageContainer';
import { UserActivationReportPageContainer } from './containers/report/UserActivationReportPageContainer';
import { TemplatesUsedReportPageContainer } from './containers/report/TemplatesUsedReportPageContainer';
import { registeredVsActiveUsersReportLoader } from './loaders/report/registeredVsActiveUsers';
import { EnvironmentManagerContainer } from './containers/workspace-dialogs/EnvironmentManagerContainer';
import { UsersUtilizationPageContainer } from './containers/report/UsersUtilizationPageContainer';
import { usersUtilizationReportLoader } from './loaders/report/usersUtilization';
import { OrganisationsUtilizationPageContainer } from './containers/report/OrganisationsUtilizationReportPageContainer';
import { organisationsUtilizationReportLoader } from './loaders/report/organisationsUtilization';
import { MicrosoftManageConnectionDialogContainer } from './containers/connections/apps/microsoft/ManageConnectionDialogContainer';
import { MicrosoftEventListenerSetupDialogContainer } from './containers/connections/apps/microsoft/EventListenerSetupDialogContainer';
import { MicrosoftConfigureDialog } from './containers/connections/apps/microsoft/MicrosoftConfigureDialogContainer';
import { AzureDevopsManageConnectionDialogContainer } from './containers/connections/apps/azure-devops/ManageConnectionDialogContainer';
import { AzureDevopsConfigureDialog } from './containers/connections/apps/azure-devops/AzureDevopsConfigureDialogContainer';
import { ZenDeskManageConnectionDialogContainer } from './containers/connections/apps/zendesk/ManageConnectionDialogContainer';
import { ZenDeskConfigureDialogContainer } from './containers/connections/apps/zendesk/ZenDeskConfigureDialogContainer';
import { reportingLoader } from './loaders/reporting';
import { ReportingContainer } from './containers/reporting/InvocationsReportContainer';
import { ProfileContainer } from './containers/profile/ProfileContainer';
import { profileLoader } from './loaders/profile';
import { invocationLogsLoader } from './loaders/workspace/invocation-logs';
import { InvocationLogsContainer } from './containers/reporting/printed-logs/InvocationLogsContainer';
import { largeInvocationLogPayloadLoader } from './loaders/workspace/large-invocation-log-payload';
import { LargeInvocationLogPayloadContainer } from './containers/reporting/printed-logs/LargeInvocationLogPayloadContainer';
import { httpLogsLoader } from './loaders/workspace/http-logs';
import { HttpLogsContainer } from './containers/reporting/printed-logs/HttpLogsContainer';
import { BillingDetailsContainer } from './containers/organizations/billing/BillingDetailsContainer';
import { billingDetailsLoader } from './loaders/billing-details';
import { OrganizationsContainer } from './containers/organizations/OrganizationsContainer';
import { organizationsLoader } from './loaders/organizations';
import { CreateOrganizationWizardContainer } from './containers/organizations/CreateOrganizationWizardContainer';
import { OrganizationContainer } from './containers/organizations/OrganizationContainer';
import { templateUsedReportLoader } from './loaders/report/templatesUsed';
import { organizationBillingDetailsLoader } from './loaders/organization/billing-details';
import { ShareWorkspaceDialogContainer } from './containers/workspace-dialogs/ShareWorkspaceDialogContainer';
import { organizationLoader } from './loaders/organization/organization';
import { OrganizationUsageContainer } from './containers/organizations/usage/OrganizationUsageContainer';
import { organizationUsageLoader } from './loaders/organization/usage';
import { organizationDetailsLoader } from './loaders/organization/details';
import { OrganizationDetailsContainer } from './containers/organizations/details/OrganizationDetailsContainer';
import { OrganizationPlanContainer } from './containers/organizations/plan/OrganizationPlanContainer';
import { organizationPlanLoader } from './loaders/organization/plan';
import { LoadingSpinner } from './components/common/LoadingSpinner';
import { OrganizationBillingDetailsContainer } from './containers/organizations/billing/OrganizationBillingDetailsContainer';
import { organizationMembersLoader } from './loaders/organization/members';
import { OrganizationMembersContainer } from './containers/organizations/members/OrganizationMembersContainer';
import { HelpAndSupportContainer } from './containers/app-main/HelpAndSupportContainer';
import { BillingEntityContainer } from './containers/organizations/billing/BillingEntityContainer';
import { SalesforceEventListenerSetupDialogContainer } from './containers/connections/apps/salesforce/EventListenerSetupDialogContainer';
import { billingEntityLoader } from './loaders/billing-entity';
import { StatuspageConfigureDialog } from './containers/connections/apps/statuspage/StatuspageConfigureDialogContainer';
import { StatuspageManageConnectionDialogContainer } from './containers/connections/apps/statuspage/ManageConnectionDialogContainer';
import { TrelloConfigureDialog } from './containers/connections/apps/trello/TrelloConfigureDialogContainer';
import { TrelloManageConnectionDialogContainer } from './containers/connections/apps/trello/ManageConnectionDialogContainer';
import { AWSConfigureDialog } from './containers/connections/apps/aws/AWSConfigureDialogContainer';
import { AWSManageConnectionDialogContainer } from './containers/connections/apps/aws/ManageConnectionDialogContainer';
import { OpsgenieConfigureDialog } from './containers/connections/apps/opsgenie/OpsgenieConfigureDialogContainer';
import { OpsgenieManageConnectionDialogContainer } from './containers/connections/apps/opsgenie/ManageConnectionDialogContainer';
import { OpsgenieEventListenerSetupDialogContainer } from './containers/connections/apps/opsgenie/EventListenerSetupDialogContainer';
import { anonymousUsersReportLoader } from './loaders/report/anonymousUsers';
import { AnonymousUsersReportPageContainer } from './containers/report/AnonymousUsersReportPageContainer';
import { userActivationReportLoader } from './loaders/report/userActivation';
import { StatuspageEventListenerSetupDialogContainer } from './containers/connections/apps/statuspage/EventListenerSetupDialogContainer';
import { SelectBillingDetailsDialogContainer } from './containers/organizations/billing/SelectBillingDetailsDialogContainer';
import { OrganizationPlanSuccessStateContainer } from './containers/organizations/plan/OrganizationPlanSuccessStateContainer';
import { ErrorPageContainer } from './containers/app-main/ErrorPageContainer';
import { ImpersonationPasswordDialogContainer } from './containers/report/ImpersonationPasswordDialogContainer';
import { UserOnboardingDataReportPageContainer } from './containers/report/UserOnboardingDataReportPageContainer';
import { userOnboardingDataReportLoader } from './loaders/report/userOnboardingData';
import { ChangeOrganizationPlanWizardContainer } from './containers/organizations/plan/ChangeOrganizationPlanWizardContainer';
import { CancelOrganizationPlanContainer } from './containers/organizations/plan/CancelOrganizationPlanContainer';
import { CancelOrganizationPlanSuccessStateContainer } from './containers/organizations/plan/CancelOrganizationPlanSuccessStateContainer';
import { CancelOrganizationPlanFeedbackContainer } from './containers/organizations/plan/CancelOrganizationPlanFeedbackContainer';
import { ChangeOrganizationPlanConfirmationContainer } from './containers/organizations/plan/ChangeOrganizationPlanConfirmationContainer';
import { OrganizationInvoicePlanInformationDialogContainer } from './containers/organizations/plan/OrganizationInvoicePlanInformationDialogContainer';
import { ChangeOrganizationPlanConfirmPurchaseContainer } from './containers/organizations/plan/ChangeOrganizationPlanConfirmPurchaseContainer';
import { PackageUpgradesDialogContainer } from './containers/workspace-dialogs/PackageUpgradesDialogContainer';
import { EnvironmentVariableDetailsContainer } from './containers/workspace-resources/EnvironmentVariableDetailsContainer';
import { AtlassianCloudConfigureDialog } from './containers/connections/apps/atlassian-cloud/AtlassianCloudConfigureDialogContainer';
import { environmentVariablesLoader } from './loaders/workspace/environment-variable';
import { DiscountCodesReportPageContainer } from './containers/report/DiscountCodesReportPageContainer';
import { discountCodesLoader } from './loaders/report/discountCodes';
import { DiscountCodeDetailsReportPageContainer } from './containers/report/DiscountCodeDetailsReportPageContainer';
import { discountCodeDetailsLoader } from './loaders/report/discountCodeDetails';
import { AzureDevopsEventListenerSetupDialogContainer } from './containers/connections/apps/azure-devops/EventListenerSetupDialogContainer';
import { ZenDeskEventListenerSetupDialogContainer } from './containers/connections/apps/zendesk/EventListenerSetupDialogContainer';
import { AuditLogsPageContainer } from './containers/reporting/AuditLogsPageContainer';
import { auditLogsLoader } from './loaders/audit-logs';
import { SetupGuideContainer } from './containers/setup-guide/SetupGuideContainer';
import { setupGuideEditScreenUnloader, setupGuideLoader, setupGuideUnloader } from './loaders/setup-guide/setup-guide';
import { SetupGuideWelcomeScreenContainer } from './containers/setup-guide/SetupGuideWelcomeScreenContainer';
import { SetupGuideConnectionScreenContainer } from './containers/setup-guide/connections/SetupGuideConnectionScreenContainer';
import { SetupGuideApiHandlerScreenContainer } from './containers/setup-guide/api-handlers/SetupGuideApiHandlerScreenContainer';
import { SetupGuideTriggerScreenContainer } from './containers/setup-guide/triggers/SetupGuideTriggerScreenContainer';
import { SetupGuideConnectionSelectorContainer } from './containers/setup-guide/connections/SetupGuideConnectionSelectorContainer';
import { SetupGuideEnvironmentVariableScreenContainer } from './containers/setup-guide/environment-variables/SetupGuideEnvironmentVariablesScreenContainer';
import { SetupGuideScriptScreenContainer } from './containers/setup-guide/scripts/SetupGuideScriptScreenContainer';
import { setupGuideConnectionStepLoader } from './loaders/setup-guide/steps/connections';
import {
    setupGuideEditEventListenerScreenLoader,
    setupGuideEditScheduledTriggerScreenLoader,
    setupGuideTriggerStepLoader,
} from './loaders/setup-guide/steps/triggers';
import {
    setupGuideApiHandlerStepLoader,
    setupGuideEditApiHandlerScreenLoader,
} from './loaders/setup-guide/steps/api-handlers';
import { setupGuideEnvironmentVariableStepLoader } from './loaders/setup-guide/steps/environment-variables';
import { setupGuideEditScriptScreenLoader, setupGuideScriptStepLoader } from './loaders/setup-guide/steps/scripts';
import { setupGuideRunStepLoader } from './loaders/setup-guide/steps/run';
import { SetupGuideReviewScreenContainer } from './containers/setup-guide/review/SetupGuideReviewScreenContainer';
import { SetupGuideEditApiHandlerScreenContainer } from './containers/setup-guide/api-handlers/SetupGuideEditApiHandlerScreenContainer';
import { SetupGuideEditEventListenerScreenContainer } from './containers/setup-guide/triggers/SetupGuideEditEventListenerScreenContainer';
import { setupGuideWelcomeScreenLoader } from './loaders/setup-guide/welcome-screen';
import { SetupGuideEditScriptScreenContainer } from './containers/setup-guide/scripts/SetupGuideEditScriptScreenContainer';
import { setupGuideReviewStepLoader } from './loaders/setup-guide/steps/review';
import { SetupGuideRunScreenContainer } from './containers/setup-guide/run/SetupGuideRunScreenContainer';
import { SetupGuideCongratulationsScreenContainer } from './containers/setup-guide/SetupGuideCongratulationsScreenContainer';
import { SetupGuideEditScheduledTriggerScreenContainer } from './containers/setup-guide/triggers/SetupGuideEditScheduledTriggerScreenContainer';
import { CronBuilderDialogContainer } from './containers/setup-guide/triggers/CronBuilderDialogContainer';
import { FullScreenReadmeContainer } from './containers/workspace-resources/readme/FullScreenReadmeContainer';
import { fullScreenReadmeLoader } from './loaders/full-screen-readme';
import { ApiKeysContainer } from './containers/profile/api-keys/ApiKeysContainer';
import { apiKeysLoader } from './loaders/api-keys';
import { setupGuideCongratulationsScreenLoader } from './loaders/setup-guide/congratulation-screen';
import { OnboardingContainer } from './containers/onboarding/wizard/OnboardingContainer';
import { OnboardingIndustryRoleSelectorContainer } from './containers/onboarding/wizard/OnboardingIndustryRoleSelectorContainer';
import { OnboardingScriptingFamiliaritySelectorContainer } from './containers/onboarding/wizard/OnboardingScriptingFamiliaritySelectorContainer';
import { OnboardingAppSelectorContainer } from './containers/onboarding/wizard/OnboardingAppSelectorContainer';
import { OnboardingFlowSelectorContainer } from './containers/onboarding/wizard/OnboardingFlowSelectorContainer';
import { OnboardingTemplateListingContainer } from './containers/onboarding/wizard/OnboardingTemplateListingContainer';
import { OnboardingTemplateViewContainer } from './containers/onboarding/wizard/OnboardingTemplateViewContainer';
import { OnboardingWorkspaceNamingScreenContainer } from './containers/onboarding/wizard/OnboardingWorkspaceNamingScreen';
import { onboardingIndustryRoleSelectorLoader } from './loaders/onboarding/role';
import { onboardingScriptingFamiliaritySelectorLoader } from './loaders/onboarding/scripting-familiarity';
import { onboardingAppSelectorLoader } from './loaders/onboarding/apps';
import { onboardingFlowSelectorLoader } from './loaders/onboarding/flow-selector';
import { workspaceNamingScreenUnLoader } from './loaders/onboarding/workspace-naming';
import { AskNewApiKeyNameDialogContainer } from './containers/profile/api-keys/AskNewApiKeyDialogContainer';
import { NewApiKeyGeneratedDialogContainer } from './containers/profile/api-keys/NewApiKeyGeneratedDialogContainer';
import { EditTemplateContainer } from './containers/templates/EditTemplateContainer';
import { onboardingUnloader } from './loaders/onboarding/onboarding';

export const location = new ReactLocation<LocationGenerics>();

// eslint-disable-next-line @typescript-eslint/ban-types
export type LocationGenerics = MakeGenerics<{}>;

export const getRoutes = (): Route<LocationGenerics>[] => {
    /**
     * PUBLIC_URL could be . or /<git commit hash> so need to adjust the root path accordingly
     */
    const rootPath = process.env.PUBLIC_URL.substring(1).length ? process.env.PUBLIC_URL : '/';
    const errorElement = <ErrorPageContainer />;
    const pendingElement = async (): Promise<JSX.Element> => <LoadingSpinner />;

    return [
        {
            path: rootPath,
            element: (
                <>
                    <AppMainContainer rootPath={rootPath} />
                    <AppSelectorContainer />
                    <AtlassianCloudManageConnectionDialogContainer />
                    <AtlassianCloudInstanceSelectionContainer />
                    <AtlassianCloudConfigureDialog />
                    <BitbucketCloudManageConnectionDialogContainer />
                    <BitbucketCloudConfigureDialog />
                    <AtlassianOnPremiseManageConnectionDialogContainer />
                    <AtlassianOnPremiseConfigureDialog />
                    <ServiceNowManageConnectionDialogContainer />
                    <BitbucketOnPremiseManageConnectionDialogContainer />
                    <ServiceNowConfigureDialog />
                    <BitbucketOnPremiseConfigureDialog />
                    <SlackManageConnectionDialogContainer />
                    <SlackAppConfigureDialog />
                    <GoogleManageConnectionDialogContainer />
                    <GenericManageConnectionDialogContainer />
                    <GenericAppConfigureDialog />
                    <GithubManageConnectionDialogContainer />
                    <GitlabManageConnectionDialogContainer />
                    <GitlabConfigureDialog />
                    <MondayManageConnectionDialogContainer />
                    <MondayConfigureDialog />
                    <ConfirmDialogContainer />
                    <CreateNewWorkspaceDialogContainer />
                    <EditWorkspaceDialogContainer />
                    <ShareWorkspaceDialogContainer />
                    <SelectBillingDetailsDialogContainer />
                    <ImpersonationPasswordDialogContainer />
                    <AskNewApiKeyNameDialogContainer />
                    <NewApiKeyGeneratedDialogContainer />
                    <TempoCloudManageConnectionDialogContainer />
                    <TempoCloudConfigureDialog />
                    <NetSuiteManageConnectionDialogContainer />
                    <NetSuiteConfigureDialog />
                    <JsmCloudAssetsManageConnectionDialogContainer />
                    <JsmCloudAssetsConfigureDialog />
                    <MicrosoftManageConnectionDialogContainer />
                    <MicrosoftEventListenerSetupDialogContainer />
                    <MicrosoftConfigureDialog />
                    <AzureDevopsManageConnectionDialogContainer />
                    <AzureDevopsEventListenerSetupDialogContainer />
                    <AzureDevopsConfigureDialog />
                    <ZoomManageConnectionDialogContainer />
                    <ZoomEventListenerSetupDialogContainer />
                    <ZoomConfigureDialog />
                    <ZenDeskManageConnectionDialogContainer />
                    <ZenDeskEventListenerSetupDialogContainer />
                    <ZenDeskConfigureDialogContainer />
                    <CreateOrganizationWizardContainer />
                    <SalesforceManageConnectionDialogContainer />
                    <SalesforceConfigureDialog />
                    <StatuspageManageConnectionDialogContainer />
                    <StatuspageConfigureDialog />
                    <StatuspageEventListenerSetupDialogContainer />
                    <HelpAndSupportContainer />
                    <TrelloManageConnectionDialogContainer />
                    <TrelloConfigureDialog />
                    <AWSManageConnectionDialogContainer />
                    <AWSConfigureDialog />
                    <OpsgenieManageConnectionDialogContainer />
                    <OpsgenieConfigureDialog />
                    <OrganizationPlanSuccessStateContainer />
                    <GenericEventListenerSetupDialogContainer />
                    <AtlassianCloudEventListenerSetupDialogContainer />
                    <SlackEventListenerSetupDialogContainer />
                    <AtlassianOnPremiseEventListenerSetupDialogContainer />
                    <BitbucketCloudEventListenerSetupDialogContainer />
                    <ServiceNowEventListenerSetupDialogContainer />
                    <NetSuiteEventListenerSetupDialogContainer />
                    <SalesforceEventListenerSetupDialogContainer />
                    <BitbucketOnPremiseEventListenerSetupDialogContainer />
                    <MondayEventListenerSetupDialogContainer />
                    <GitlabEventListenerSetupDialogContainer />
                    <GithubEventListenerSetupDialogContainer />
                    <ZoomEventListenerSetupDialogContainer />
                </>
            ),
            loader: appLoader,
            pendingElement,
            errorElement,
            pendingMs: 0,
            children: [
                {
                    path: '/onboarding',
                    element: <OnboardingContainer />,
                    unloader: onboardingUnloader,
                    pendingElement,
                    errorElement,
                    pendingMs: 0,
                    children: [
                        {
                            path: '/role',
                            element: <OnboardingIndustryRoleSelectorContainer />,
                            loader: onboardingIndustryRoleSelectorLoader,
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/scripting',
                            element: <OnboardingScriptingFamiliaritySelectorContainer />,
                            loader: onboardingScriptingFamiliaritySelectorLoader,
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/apps',
                            element: <OnboardingAppSelectorContainer />,
                            loader: onboardingAppSelectorLoader,
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/flow',
                            element: <OnboardingFlowSelectorContainer />,
                            loader: onboardingFlowSelectorLoader,
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/templates',
                            element: <OnboardingTemplateListingContainer />,
                            loader: templatesLoader,
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/workspace/naming',
                            element: <OnboardingWorkspaceNamingScreenContainer />,
                            unloader: workspaceNamingScreenUnLoader,
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/template/:templateUid',
                            loader: readOnlyTemplateLoader,
                            unloader: readOnlyTemplateUnloader,
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                            children: [
                                {
                                    path: '/',
                                    element: <OnboardingTemplateViewContainer />,
                                    pendingElement,
                                    errorElement,
                                    pendingMs: 0,
                                },
                                {
                                    path: '/naming',
                                    element: <OnboardingWorkspaceNamingScreenContainer />,
                                    unloader: workspaceNamingScreenUnLoader,
                                    pendingElement,
                                    errorElement,
                                    pendingMs: 0,
                                },
                            ],
                        },
                    ],
                },
                {
                    path: '/workspaces',
                    element: <WorkspaceSelectorContainer />,
                    loader: workspacesLoader,
                    pendingElement,
                    errorElement,
                    pendingMs: 0,
                },
                {
                    path: '/workspace/:workspaceUid/environment/:environmentUid',
                    element: (
                        <>
                            <WorkspaceContainer />
                            <CreateNewWorkspaceEnvironmentDialogContainer />
                            <ManageEnvironmentDeploymentTargetsDialogContainer />
                            <LanguageSelectorContainer />
                            <PackageManagerContainer />
                            <EnvironmentManagerContainer />
                            <OpsgenieEventListenerSetupDialogContainer />
                            <PackageUpgradesDialogContainer />
                        </>
                    ),
                    loader: workspaceLoader,
                    unloader: workspaceUnloader,
                    pendingElement,
                    errorElement,
                    pendingMs: 0,
                    children: [
                        {
                            path: '/',
                            element: (
                                <>
                                    <DefaultWorkspaceViewContainer />
                                </>
                            ),
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/script/:scriptUid',
                            element: (
                                <>
                                    <ScriptDetailsContainer />
                                    <ApiConnectionImportDialogContainer />
                                    <EditScriptNameDialogContainer />
                                </>
                            ),
                            loader: scriptDetailsLoader,
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/readme/:readmeFileUid',
                            element: <ReadmeFileDetailsContainer />,
                            loader: readmeFileDetailsLoader,
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/parameters',
                            element: <EnvironmentVariableDetailsContainer />,
                            loader: environmentVariablesLoader,
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/api/:apiHandlerUid',
                            element: <ApiHandlerDetailsContainer />,
                            loader: apiHandlerDetailsLoader,
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/event/:eventListenerUid',
                            element: <EventListenerDetailsContainer />,
                            loader: eventListenerLoader,
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/scheduled/:scheduledTriggerUid',
                            element: (
                                <>
                                    <ScheduledTriggerDetailsContainer />
                                    <CronBuilderDialogContainer />
                                </>
                            ),
                            loader: scheduledTriggerLoader,
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                        },
                    ],
                },
                {
                    path: '/template/:templateUid',
                    element: (
                        <>
                            <ReadOnlyTemplateContainer />
                        </>
                    ),
                    loader: readOnlyTemplateLoader,
                    unloader: readOnlyTemplateUnloader,
                    pendingElement,
                    errorElement,
                    pendingMs: 0,
                    children: [
                        {
                            path: '/',
                            element: (
                                <>
                                    <DefaultReadOnlyTemplateViewContainer />
                                </>
                            ),
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/script/:scriptUid',
                            element: (
                                <>
                                    <ScriptDetailsContainer />
                                </>
                            ),
                            loader: scriptDetailsLoader,
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/readme/:readmeFileUid',
                            element: (
                                <>
                                    <ReadmeFileDetailsContainer />
                                </>
                            ),
                            loader: readmeFileDetailsLoader,
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/parameters',
                            element: <EnvironmentVariableDetailsContainer />,
                            loader: environmentVariablesLoader,
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/api/:apiHandlerUid',
                            element: <ReadOnlyApiHandlerDetailsContainer />,
                            loader: readOnlyApiHandlerDetailsLoader,
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/event/:eventListenerUid',
                            element: <ReadOnlyEventListenerDetailsContainer />,
                            loader: readOnlyEventListenerLoader,
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/scheduled/:scheduledTriggerUid',
                            element: <ReadOnlyScheduledTriggerDetailsContainer />,
                            loader: scheduledTriggerLoader,
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                        },
                    ],
                },
                {
                    path: '/editTemplate/:templateUid',
                    loader: editTemplatePendingLoader,
                    pendingElement: <EditTemplateContainer />,
                    errorElement,
                    pendingMs: 0,
                },
                {
                    path: '/fullscreen-readme/:readmeFileUid/workspace/:workspaceUid/environment/:environmentUid',
                    element: <FullScreenReadmeContainer />,
                    loader: fullScreenReadmeLoader,
                    pendingElement,
                    errorElement,
                    pendingMs: 0,
                },
                {
                    path: '/team/:teamUid',
                    element: (
                        <>
                            <OrganizationContainer />
                        </>
                    ),
                    loader: organizationLoader,
                    pendingElement,
                    errorElement,
                    pendingMs: 0,
                    children: [
                        {
                            path: '/',
                            element: <OrganizationContainer />,
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                        },

                        {
                            path: '/usage',
                            element: <OrganizationUsageContainer />,
                            loader: organizationUsageLoader,
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/details',
                            element: <OrganizationDetailsContainer />,
                            loader: organizationDetailsLoader,
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/members',
                            element: <OrganizationMembersContainer />,
                            loader: organizationMembersLoader,
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/plan',
                            element: (
                                <>
                                    <OrganizationPlanContainer />
                                    <ChangeOrganizationPlanWizardContainer />
                                    <ChangeOrganizationPlanConfirmationContainer />
                                    <ChangeOrganizationPlanConfirmPurchaseContainer />
                                    <CancelOrganizationPlanContainer />
                                    <CancelOrganizationPlanSuccessStateContainer />
                                    <CancelOrganizationPlanFeedbackContainer />
                                    <OrganizationInvoicePlanInformationDialogContainer />
                                </>
                            ),
                            loader: organizationPlanLoader,
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/billing',
                            element: <OrganizationBillingDetailsContainer />,
                            loader: organizationBillingDetailsLoader,
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                        },
                    ],
                },
                {
                    path: '/connectors',
                    element: <ConnectorsContainer />,
                    loader: connectorsLoader,
                    pendingElement,
                    errorElement,
                    pendingMs: 0,
                },
                {
                    path: '/teams',
                    element: <OrganizationsContainer />,
                    loader: organizationsLoader,
                    pendingElement,
                    errorElement,
                    pendingMs: 0,
                },
                {
                    path: '/templates',
                    element: <TemplatesContainer />,
                    loader: templatesLoader,
                    pendingElement,
                    errorElement,
                    pendingMs: 0,
                },
                {
                    path: '/dashboard',
                    element: <DashboardContainer />,
                    loader: dashboardLoader,
                    pendingElement,
                    errorElement,
                    pendingMs: 0,
                },
                {
                    path: '/reporting',
                    element: <ReportingContainer />,
                    loader: reportingLoader,
                    pendingElement,
                    errorElement,
                    pendingMs: 0,
                },
                {
                    path: '/invocationlogs/workspace/:workspaceUid/invocation/:invocationId/log/:logId',
                    element: <LargeInvocationLogPayloadContainer />,
                    loader: largeInvocationLogPayloadLoader,
                    pendingElement,
                    errorElement,
                    pendingMs: 0,
                },
                {
                    path: '/invocationlogs/workspace/:workspaceUid/invocation/:invocationId',
                    element: <InvocationLogsContainer />,
                    loader: invocationLogsLoader,
                    pendingElement,
                    errorElement,
                    pendingMs: 0,
                },
                {
                    path: '/httplogs/workspace/:workspaceUid/invocation/:invocationId',
                    element: <HttpLogsContainer />,
                    loader: httpLogsLoader,
                    pendingElement,
                    errorElement,
                    pendingMs: 0,
                },
                {
                    path: '/auditLogs',
                    element: <AuditLogsPageContainer />,
                    loader: auditLogsLoader,
                    pendingElement,
                    errorElement,
                    pendingMs: 0,
                },
                {
                    path: '/reports',
                    element: <Outlet />,
                    children: [
                        {
                            path: '/',
                            element: <Reports />,
                        },
                        {
                            path: '/users',
                            element: <UsersReportPageContainer />,
                            loader: usersReportLoader,
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/anonymous',
                            element: <AnonymousUsersReportPageContainer />,
                            loader: anonymousUsersReportLoader,
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/team/:teamUid',
                            element: <OrganisationDetailsReportPageContainer />,
                            loader: organisationDetailsReportLoader,
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/teams',
                            element: <OrganisationsReportPageContainer />,
                            loader: organisationsReportLoader,
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/invocations',
                            element: <InvocationsReportPageContainer />,
                            loader: invocationsReportLoader,
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/usersWithDeployedWorkspaces',
                            element: <UsersWithDeployedWorkspacesReportPageContainer />,
                            loader: usersWithDeployedWorkspacesReportLoader,
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/userFeedback',
                            element: <UserFeedbackReportPageContainer />,
                            loader: userFeedbackReportLoader,
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/connectionTypes',
                            element: <ConnectionTypesReportPageContainer />,
                            loader: connectionTypesReportLoader,
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/registeredVsActiveUsers',
                            element: <RegisteredVsActiveUsersReportPageContainer />,
                            loader: registeredVsActiveUsersReportLoader,
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/usersUtilization',
                            element: <UsersUtilizationPageContainer />,
                            loader: usersUtilizationReportLoader,
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/teamsUtilization',
                            element: <OrganisationsUtilizationPageContainer />,
                            loader: organisationsUtilizationReportLoader,
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/templatesUsed',
                            element: <TemplatesUsedReportPageContainer />,
                            loader: templateUsedReportLoader,
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/userActivation',
                            element: <UserActivationReportPageContainer />,
                            loader: userActivationReportLoader,
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/userOnboardingData',
                            element: <UserOnboardingDataReportPageContainer />,
                            loader: userOnboardingDataReportLoader,
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/discountCodes',
                            element: <DiscountCodesReportPageContainer />,
                            loader: discountCodesLoader,
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                        },

                        {
                            path: '/discountCode/:uid',
                            element: <DiscountCodeDetailsReportPageContainer />,
                            loader: discountCodeDetailsLoader,
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                        },
                    ],
                },
                {
                    path: '/guide',
                    children: [
                        {
                            path: '/complete/workspace/:workspaceUid',
                            element: <SetupGuideCongratulationsScreenContainer />,
                            pendingElement,
                            loader: setupGuideCongratulationsScreenLoader,
                            unloader: setupGuideUnloader,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/workspace/:workspaceUid',
                            element: <SetupGuideContainer />,
                            loader: setupGuideLoader,
                            unloader: setupGuideUnloader,
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                            children: [
                                {
                                    path: '/',
                                    element: <SetupGuideWelcomeScreenContainer />,
                                    pendingElement,
                                    loader: setupGuideWelcomeScreenLoader,
                                    errorElement,
                                    pendingMs: 0,
                                },
                                {
                                    path: '/step/connectors',
                                    element: (
                                        <>
                                            <SetupGuideConnectionScreenContainer />
                                            <SetupGuideConnectionSelectorContainer />
                                        </>
                                    ),
                                    loader: setupGuideConnectionStepLoader,
                                    pendingElement,
                                    errorElement,
                                    pendingMs: 0,
                                },
                                {
                                    path: '/step/api',
                                    children: [
                                        {
                                            path: '/',
                                            element: <SetupGuideApiHandlerScreenContainer />,
                                            loader: setupGuideApiHandlerStepLoader,
                                            pendingElement,
                                            errorElement,
                                            pendingMs: 0,
                                        },
                                        {
                                            path: '/:apiHandlerUid',
                                            element: <SetupGuideEditApiHandlerScreenContainer />,
                                            loader: setupGuideEditApiHandlerScreenLoader,
                                            unloader: setupGuideEditScreenUnloader,
                                            pendingElement,
                                            errorElement,
                                            pendingMs: 0,
                                        },
                                    ],
                                },
                                {
                                    path: '/step/triggers',
                                    children: [
                                        {
                                            path: '/',
                                            element: <SetupGuideTriggerScreenContainer />,
                                            loader: setupGuideTriggerStepLoader,
                                            pendingElement,
                                            errorElement,
                                            pendingMs: 0,
                                        },
                                        {
                                            path: '/event/:eventListenerUid',
                                            element: <SetupGuideEditEventListenerScreenContainer />,
                                            loader: setupGuideEditEventListenerScreenLoader,
                                            pendingElement,
                                            errorElement,
                                            pendingMs: 0,
                                        },
                                        {
                                            path: '/scheduled/:scheduledTriggerUid',
                                            element: (
                                                <>
                                                    <SetupGuideEditScheduledTriggerScreenContainer />
                                                    <CronBuilderDialogContainer />
                                                </>
                                            ),
                                            loader: setupGuideEditScheduledTriggerScreenLoader,
                                            unloader: setupGuideEditScreenUnloader,
                                            pendingElement,
                                            errorElement,
                                            pendingMs: 0,
                                        },
                                    ],
                                },
                                {
                                    path: '/step/parameters',
                                    element: <SetupGuideEnvironmentVariableScreenContainer />,
                                    loader: setupGuideEnvironmentVariableStepLoader,
                                    pendingElement,
                                    errorElement,
                                    pendingMs: 0,
                                },
                                {
                                    path: '/step/scripts',
                                    children: [
                                        {
                                            path: '/',
                                            element: <SetupGuideScriptScreenContainer />,
                                            loader: setupGuideScriptStepLoader,
                                            pendingElement,
                                            errorElement,
                                            pendingMs: 0,
                                        },
                                        {
                                            path: '/:scriptUid',
                                            element: (
                                                <>
                                                    <SetupGuideEditScriptScreenContainer />
                                                    <ApiConnectionImportDialogContainer />
                                                </>
                                            ),
                                            loader: setupGuideEditScriptScreenLoader,
                                            unloader: setupGuideEditScreenUnloader,
                                            pendingElement,
                                            errorElement,
                                            pendingMs: 0,
                                        },
                                    ],
                                },
                                {
                                    path: '/step/review',
                                    element: <SetupGuideReviewScreenContainer />,
                                    loader: setupGuideReviewStepLoader,
                                    pendingElement,
                                    errorElement,
                                    pendingMs: 0,
                                },
                                {
                                    path: '/step/run',
                                    element: <SetupGuideRunScreenContainer />,
                                    loader: setupGuideRunStepLoader,
                                    pendingElement,
                                    errorElement,
                                    pendingMs: 0,
                                },
                            ],
                        },
                    ],
                },
                {
                    path: '/profile',
                    element: <ProfileContainer />,
                    loader: profileLoader,
                    pendingElement,
                    errorElement,
                    pendingMs: 0,
                },
                {
                    path: '/apiKeys',
                    element: <ApiKeysContainer />,
                    loader: apiKeysLoader,
                    pendingElement: async () => <LoadingSpinner />,
                    errorElement,
                    pendingMs: 0,
                },
                {
                    path: '/billing',
                    element: <Outlet />,
                    children: [
                        {
                            path: '/',
                            element: <BillingDetailsContainer />,
                            loader: billingDetailsLoader,
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/:billingEntityUid',
                            element: <BillingEntityContainer />,
                            loader: billingEntityLoader,
                            pendingElement,
                            errorElement,
                            pendingMs: 0,
                        },
                    ],
                },
            ],
        },
    ];
};
