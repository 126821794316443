import { APP } from '@avst-stitch/repository-lib/constants';
import { BehaviorSubject, map, Subject } from 'rxjs';
import {
    connectionCreatedAction$,
    ConnectionDetails,
    connectionCreatedFirstTimeAction$,
    connectionCreatedFirstTime$,
} from '..';
import { createConnection, saveConnection } from '../../../data/connection';
import { publishLocalFeedbackEventAction$ } from '../../feedback';
import { monitor } from '../../monitor';
import {
    loadLoggedInUserConnectionsWhenEventIsEmitted,
    saveConnectionAllowed,
    handleManageConnectionError,
} from '../utils';
import { AWSSetupSaveProps, saveCredentials } from './save';
import { promptQuestion } from '../../confirm';
import { AYSYWTSAETCWHAI } from '../../../i18n';
import { InformativeError } from '../../../utils/error';

interface AWSConnectionDetails extends ConnectionDetails {
    accessKey?: string;
    secretKey?: string;
}

export const awsManageConnectionOpenDialogAction$ = monitor(
    'awsManageConnectionOpenDialogAction$',
    new Subject<AWSConnectionDetails>()
);
export const awsManageConnectionCloseDialogAction$ = monitor(
    'awsManageConnectionCloseDialogAction$',
    new Subject<void>()
);

export const awsSetupOpenDialogAction$ = monitor('awsSetupOpenDialogAction$', new Subject<AWSConnectionDetails>());
export const awsSetupCloseDialogAction$ = monitor('awsSetupCloseDialogAction$', new Subject<void>());

export const awsSetupDialogOpen$ = monitor('awsSetupDialogOpen$', new BehaviorSubject(false));
export const awsSetupAuthorizeAction$ = monitor('awsSetupAuthorizeAction$', new Subject<AWSSetupSaveProps>());
export const awsSetupDialogErrors$ = monitor(
    'awsSetupDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const awsSaveConnectionAction$ = monitor('awsSaveConnectionAction$', new Subject<string>());
export const awsConnectionCreatedAction$ = monitor('awsConnectionCreatedAction$', new Subject<string>());
export const awsConnectionSavedAction$ = monitor(
    'awsConnectionSavedAction$',
    new Subject<{ uid: string; connectionTypeUid: string }>()
);
export const awsManageConnectionInitiateSetupAction$ = monitor(
    'awsManageConnectionInitiateSetupAction$',
    new Subject<string>()
);

export const awsManageConnectionDialogOpen$ = monitor('awsManageConnectionDialogOpen$', new BehaviorSubject(false));
export const awsConnectionSaving$ = monitor('awsConnectionSaving$', new BehaviorSubject(false));
export const awsManageConnectionAuthorizeLoading$ = monitor(
    'awsManageConnectionAuthorizeLoading$',
    new BehaviorSubject(false)
);
export const awsManageConnectionDetails$ = monitor(
    'awsManageConnectionDetails$',
    new BehaviorSubject<AWSConnectionDetails | undefined>(undefined)
);
export const awsManageConnectionDialogErrors$ = monitor(
    'awsManageConnectionDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const awsSetupDialogStage$ = monitor('awsSetupDialogStage$', new BehaviorSubject(0));

awsSetupOpenDialogAction$.subscribe((details) => {
    awsSetupDialogErrors$.next(undefined);
    awsManageConnectionDialogErrors$.next(undefined);
    awsSetupDialogOpen$.next(true);
    awsSetupDialogStage$.next(0);
    awsManageConnectionDetails$.next(details);
});

awsSetupCloseDialogAction$.subscribe(() => {
    awsSetupDialogOpen$.next(false);
    awsManageConnectionAuthorizeLoading$.next(false);
});

awsManageConnectionOpenDialogAction$.subscribe((details) => {
    awsSetupDialogErrors$.next(undefined);
    awsManageConnectionDialogErrors$.next(undefined);
    awsManageConnectionDialogOpen$.next(true);
    awsManageConnectionDetails$.next(details);
});

awsManageConnectionCloseDialogAction$.subscribe(() => {
    awsManageConnectionDialogOpen$.next(false);
    awsSetupDialogOpen$.next(false);
    awsManageConnectionDetails$.next(undefined);
});

awsConnectionCreatedAction$.subscribe((uid) => connectionCreatedAction$.next(uid));

const CONNECTOR_TYPE_NOT_DEFINED = 'AWS connector type not defined';

awsSetupAuthorizeAction$
    .pipe(
        map(async (credentials) => {
            awsConnectionSaving$.next(true);
            awsSetupDialogErrors$.next(undefined);

            const { uid, connectionType } = awsManageConnectionDetails$.value ?? {};
            try {
                if (!connectionType) {
                    throw Error(CONNECTOR_TYPE_NOT_DEFINED);
                }

                if (!uid) {
                    throw Error('AWS connector has not been created so cannot configure it');
                }

                await saveCredentials({ ...credentials, connectionId: uid });

                awsConnectionCreatedAction$.next(uid);
                awsConnectionSavedAction$.next({ uid, connectionTypeUid: connectionType.uid });
                awsSetupDialogOpen$.next(false);
                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: 'AWS connector has been configured.',
                });
            } catch (e) {
                if (e instanceof InformativeError) {
                    awsSetupDialogErrors$.next(e.message);
                } else {
                    awsSetupDialogErrors$.next(
                        'Failed to save AWS connector details, please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while saving AWS connector info', e);
                }
            }

            awsConnectionSaving$.next(false);
            awsManageConnectionAuthorizeLoading$.next(false);
        })
    )
    .subscribe();

awsSaveConnectionAction$
    .pipe(
        map(async (name) => {
            awsConnectionSaving$.next(true);
            awsManageConnectionDialogErrors$.next(undefined);

            const { uid, connectionType } = awsManageConnectionDetails$.value ?? {};

            try {
                if (!connectionType) {
                    throw Error(CONNECTOR_TYPE_NOT_DEFINED);
                }
                const connectionTypeUid = connectionType.uid;

                if (uid) {
                    if (saveConnectionAllowed(uid)) {
                        await saveAWSConnection(uid, name, connectionTypeUid);
                    } else {
                        promptQuestion({
                            title: AYSYWTSAETCWHAI,
                            onProceed: async () => {
                                await saveAWSConnection(uid, name, connectionTypeUid);
                            },
                        });
                    }
                } else {
                    promptQuestion({
                        title: AYSYWTSAETCWHAI,
                        onProceed: async () => {
                            try {
                                const { uid } = await createConnection(
                                    connectionType.uid,
                                    name,
                                    connectionType.apiHandlerTypes[0]?.uid ?? '',
                                    connectionType.eventListenerTypes[0]?.uid ?? ''
                                );

                                awsConnectionCreatedAction$.next(uid);
                                awsConnectionSavedAction$.next({ uid, connectionTypeUid });
                                awsManageConnectionCloseDialogAction$.next();
                                publishLocalFeedbackEventAction$.next({
                                    level: 'SUCCESS',
                                    message: 'Connector created.',
                                });
                                connectionCreatedFirstTimeAction$.next();
                            } catch (e) {
                                handleManageConnectionError(e, awsManageConnectionDialogErrors$, APP.AWS.NAME);
                            }
                        },
                    });
                }
            } catch (e) {
                handleManageConnectionError(e, awsManageConnectionDialogErrors$, APP.AWS.NAME);
            }

            awsConnectionSaving$.next(false);
        })
    )
    .subscribe();

awsManageConnectionInitiateSetupAction$
    .pipe(
        map(async (name) => {
            awsConnectionSaving$.next(true);
            awsManageConnectionAuthorizeLoading$.next(true);
            awsManageConnectionDialogErrors$.next(undefined);

            const {
                uid,
                connectionType,
                name: currentName,
                ...restOfDetails
            } = awsManageConnectionDetails$.value ?? {};
            try {
                if (!connectionType) {
                    throw Error(CONNECTOR_TYPE_NOT_DEFINED);
                }
                const connectionTypeUid = connectionType.uid;

                if (uid) {
                    if (currentName !== name) {
                        await saveConnection(uid, name);
                    }

                    awsConnectionSavedAction$.next({ uid, connectionTypeUid });
                    awsSetupOpenDialogAction$.next({
                        ...restOfDetails,
                        uid,
                        connectionType,
                        name,
                    });
                } else {
                    const { uid } = await createConnection(
                        connectionType.uid,
                        name,
                        connectionType.apiHandlerTypes[0]?.uid ?? '',
                        connectionType.eventListenerTypes[0]?.uid ?? ''
                    );

                    awsConnectionCreatedAction$.next(uid);
                    awsConnectionSavedAction$.next({ uid, connectionTypeUid });

                    awsManageConnectionDetails$.next({
                        connectionType,
                        name,
                        uid,
                    });

                    awsConnectionSaving$.next(false);

                    awsSetupOpenDialogAction$.next({
                        connectionType,
                        name,
                        uid,
                    });
                    connectionCreatedFirstTime$.next(true);
                }
            } catch (e) {
                handleManageConnectionError(e, awsManageConnectionDialogErrors$, APP.AWS.NAME);
                awsManageConnectionAuthorizeLoading$.next(false);
            }

            awsConnectionSaving$.next(false);
        })
    )
    .subscribe();

loadLoggedInUserConnectionsWhenEventIsEmitted(awsConnectionSavedAction$);

const saveAWSConnection = async (uid: string, name: string, connectionTypeUid: string): Promise<void> => {
    try {
        await saveConnection(uid, name);

        awsConnectionSavedAction$.next({ uid, connectionTypeUid });
        awsManageConnectionCloseDialogAction$.next();
        publishLocalFeedbackEventAction$.next({
            level: 'SUCCESS',
            message: 'Connector saved.',
        });
        if (connectionCreatedFirstTime$.value) {
            connectionCreatedFirstTimeAction$.next();
            connectionCreatedFirstTime$.next(false);
        }
    } catch (e) {
        handleManageConnectionError(e, awsManageConnectionDialogErrors$, APP.AWS.NAME);
    }
};
