import { useState } from 'react';
import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ApiOutlinedIcon from '@mui/icons-material/ApiOutlined';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import HearingOutlinedIcon from '@mui/icons-material/HearingOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import {
    ApiHandlerResource,
    EventListenerResource,
    WorkspaceLanguage,
    ResourceName,
    ScheduledTriggerResource,
    TreeResource,
} from './types';
import { InfoIcon } from '../../icons/InfoIcon';
import { ResourceTreeScript } from './ResourceTreeScript';
import { ResourceTreeFolder } from './ResourceTreeFolder';
import { ResourceTreeApiHandler } from './ResourceTreeApiHandler';
import { ResourceTreeEventListener } from './ResourceTreeEventListener';
import { ResourceTreeScheduledTrigger } from './ResourceTreeScheduledTrigger';
import {
    StyledAccordion,
    StyledAccordionDetails,
    StyledAccordionSummary,
    StyledResourceInfo,
    StyledResourceSubtitle,
} from './ResourceTreeComponents';
import { readLocalStorage, saveLocalStorage } from '../../../utils/localStorage';
import { resourceTreeActionButtonIconWrapperSize, resourceTreeInfoIconSize } from '.';
import { IconButton } from '../../common/buttons/IconButton';

interface ResourceTreeSectionProps {
    apiHandlers?: ApiHandlerResource[];
    deployedEnvironmentMode?: boolean;
    eventListeners?: EventListenerResource[];
    externalTriggerBaseUrl?: string;
    innerRef?: React.RefObject<HTMLDivElement>;
    language?: WorkspaceLanguage;
    noResourcesCreated?: boolean;
    readOnlyMode?: boolean;
    resourceIcon: JSX.Element;
    resourceName: ResourceName;
    scheduledTriggers?: ScheduledTriggerResource[];
    selectedNode?: string;
    tooltipText: string;
    treeNodes?: TreeResource[];
    workspaceUid: string;
    onCreateNew(): void;
    onDelete(uid: string): void;
    onOpen(uid: string): void;
    onPathCopy?(uid: string): void;
    onToggleExpand?(): void;
    onTrigger?(uid: string): void;
    onUrlCopy?(uid: string): void;
}

const StyledAddIconButton = styled(IconButton)(({ theme }) => ({
    ...theme.typography.radiusCircle,
    '& .MuiSvgIcon-root': {
        color: theme.palette.primary.main,
    },
    height: resourceTreeActionButtonIconWrapperSize,
    width: resourceTreeActionButtonIconWrapperSize,
}));

const StyledInfoIconButton = styled(IconButton)(({ theme }) => ({
    height: resourceTreeActionButtonIconWrapperSize,
    width: resourceTreeActionButtonIconWrapperSize,
    '& .MuiSvgIcon-root': {
        color: theme.palette.primary.main,
        height: resourceTreeInfoIconSize,
        width: resourceTreeInfoIconSize,
    },
    '&:hover': {
        backgroundColor: 'unset',
    },
}));

const StyledResourceName = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    '& .MuiTypography-root': {
        fontWeight: theme.typography.fontWeightBold,
    },
    '& .MuiSvgIcon-root': {
        height: 16,
    },
    marginLeft: theme.spacing(1),
}));

export const ResourceTreeSection: React.FC<ResourceTreeSectionProps> = ({
    apiHandlers = [],
    deployedEnvironmentMode = false,
    eventListeners = [],
    externalTriggerBaseUrl,
    innerRef,
    language,
    noResourcesCreated = false,
    readOnlyMode = false,
    resourceName,
    scheduledTriggers = [],
    selectedNode,
    tooltipText,
    treeNodes = [],
    workspaceUid,
    onCreateNew,
    onDelete,
    onOpen,
    onPathCopy,
    onTrigger,
    onUrlCopy,
}) => {
    const modifiedResourceName = resourceName.replace(/\s/g, '').toLowerCase();
    const ariaId = modifiedResourceName + '-aria';
    const expandedSectionKey = `isExpanded-${workspaceUid}-${modifiedResourceName}`;
    const [expanded, setExpanded] = useState(readLocalStorage(expandedSectionKey, true));
    const showAddButton = !deployedEnvironmentMode && !readOnlyMode;
    const showCreateWarning = !readOnlyMode && noResourcesCreated;

    const handleCreateNew = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.stopPropagation();
        onCreateNew();
    };

    const handleExpand = (): void => {
        saveLocalStorage(expandedSectionKey, !expanded);
        setExpanded(!expanded);
    };

    const getNodeList = (): JSX.Element => {
        switch (resourceName) {
            case 'Scripts':
                return (
                    <StyledAccordionDetails>
                        {showCreateWarning && (
                            <StyledResourceInfo>
                                <StyledResourceSubtitle className="empty">
                                    <CodeOutlinedIcon />
                                    <Typography>No scripts found.</Typography>
                                </StyledResourceSubtitle>
                            </StyledResourceInfo>
                        )}
                        {treeNodes.map((tn, i) => {
                            if (tn.type === 'FOLDER') {
                                return (
                                    <ResourceTreeFolder
                                        deployedEnvironmentMode={deployedEnvironmentMode}
                                        fullPath={tn.fullPath}
                                        key={i}
                                        language={language}
                                        name={tn.name}
                                        readOnlyMode={readOnlyMode}
                                        selectedNode={selectedNode}
                                        subItems={tn.subItems}
                                        workspaceUid={workspaceUid}
                                        onDelete={onDelete}
                                        onOpen={onOpen}
                                        onTrigger={onTrigger}
                                    />
                                );
                            } else {
                                return (
                                    <ResourceTreeScript
                                        deleting={tn.deleting}
                                        deployedEnvironmentMode={deployedEnvironmentMode}
                                        executing={tn.executing}
                                        key={i}
                                        language={language}
                                        name={tn.name}
                                        readOnlyMode={readOnlyMode}
                                        selectedNode={selectedNode}
                                        uid={tn.uid}
                                        unsaved={tn.unsaved}
                                        onDelete={onDelete}
                                        onOpen={onOpen}
                                        onTrigger={onTrigger}
                                    />
                                );
                            }
                        })}
                    </StyledAccordionDetails>
                );
            case 'API connections':
                return (
                    <StyledAccordionDetails>
                        {showCreateWarning && (
                            <StyledResourceInfo>
                                <StyledResourceSubtitle className="empty">
                                    <ApiOutlinedIcon />
                                    <Typography>No API connections found.</Typography>
                                </StyledResourceSubtitle>
                            </StyledResourceInfo>
                        )}
                        {apiHandlers.map((ah) => {
                            const existingRecommendedApiVersion = ah.libraries?.some((l) => l.recommended);
                            return (
                                <ResourceTreeApiHandler
                                    appName={ah.appName}
                                    connectionName={ah.connectionName}
                                    deleting={ah.deleting}
                                    deployedEnvironmentMode={deployedEnvironmentMode}
                                    key={ah.uid}
                                    needsSetup={ah.warning}
                                    path={ah.path}
                                    readOnlyMode={readOnlyMode}
                                    existingRecommendedApiVersion={existingRecommendedApiVersion}
                                    selectedApiHandlerLibrary={ah.selectedApiHandlerLibrary}
                                    selectedNode={selectedNode}
                                    uid={ah.uid}
                                    onDelete={onDelete}
                                    onOpen={onOpen}
                                    onPathCopy={onPathCopy}
                                />
                            );
                        })}
                    </StyledAccordionDetails>
                );
            case 'Event listeners':
                return (
                    <StyledAccordionDetails>
                        {showCreateWarning && (
                            <StyledResourceInfo>
                                <StyledResourceSubtitle className="empty">
                                    <HearingOutlinedIcon />
                                    <Typography>No event listeners found.</Typography>
                                </StyledResourceSubtitle>
                            </StyledResourceInfo>
                        )}
                        {eventListeners.map((el) => {
                            return (
                                <ResourceTreeEventListener
                                    appName={el.appName}
                                    deleting={el.deleting}
                                    deployedEnvironmentMode={deployedEnvironmentMode}
                                    executing={el.executing}
                                    eventType={el.eventTypeName}
                                    externalTriggerBaseUrl={externalTriggerBaseUrl}
                                    inactive={el.inactive}
                                    key={el.uid}
                                    needsSetup={el.warning}
                                    readOnlyMode={readOnlyMode}
                                    scriptName={el.scriptName}
                                    selectedNode={selectedNode}
                                    uid={el.uid}
                                    urlId={el.urlId}
                                    onDelete={onDelete}
                                    onOpen={onOpen}
                                    onTrigger={onTrigger}
                                    onUrlCopy={onUrlCopy}
                                    disabled={el.disabled}
                                />
                            );
                        })}
                    </StyledAccordionDetails>
                );
            case 'Scheduled triggers':
                return (
                    <StyledAccordionDetails>
                        {showCreateWarning && (
                            <StyledResourceInfo>
                                <StyledResourceSubtitle className="empty">
                                    <TimerOutlinedIcon />
                                    <Typography>No scheduled triggers found.</Typography>
                                </StyledResourceSubtitle>
                            </StyledResourceInfo>
                        )}
                        {scheduledTriggers.map((st) => {
                            return (
                                <ResourceTreeScheduledTrigger
                                    cronDescription={st.cronDescription}
                                    cronExpression={st.cronExpression}
                                    deleting={st.deleting}
                                    deployedEnvironmentMode={deployedEnvironmentMode}
                                    inactive={st.inactive}
                                    key={st.uid}
                                    needsSetup={st.warning}
                                    readOnlyMode={readOnlyMode}
                                    scriptName={st.scriptName}
                                    selectedNode={selectedNode}
                                    uid={st.uid}
                                    onDelete={onDelete}
                                    onOpen={onOpen}
                                    disabled={st.disabled}
                                />
                            );
                        })}
                    </StyledAccordionDetails>
                );
        }
    };

    const nodeList = getNodeList();
    const createNewTooltip = `Create new ${resourceName.slice(0, -1)}`;

    return (
        <>
            <StyledAccordion expanded={expanded} data-test-id={`resource-tree-${ariaId}`}>
                <StyledAccordionSummary
                    ref={innerRef}
                    onClick={handleExpand}
                    expandIcon={
                        <IconButton
                            aria-label={`${expanded ? 'Collapse' : 'Expand'} ${resourceName} section`}
                            icon={<ArrowDropDownOutlinedIcon />}
                            tooltip={`${expanded ? 'Collapse' : 'Expand'} ${resourceName} section`}
                        />
                    }
                >
                    <StyledResourceName>
                        <Typography noWrap>{resourceName}</Typography>
                        <StyledInfoIconButton aria-label={tooltipText} icon={<InfoIcon />} tooltip={tooltipText} />
                    </StyledResourceName>
                    {showAddButton && (
                        <StyledAddIconButton
                            aria-label={createNewTooltip}
                            icon={<AddOutlinedIcon />}
                            tooltip={createNewTooltip}
                            onClick={handleCreateNew}
                        />
                    )}
                </StyledAccordionSummary>
                {nodeList}
            </StyledAccordion>
        </>
    );
};
