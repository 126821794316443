import { LoaderFn } from 'react-location';
import { LocationGenerics } from '../../router';
import { navigateAction$ } from '../../store/navigation';
import { getBasePath } from '../../utils/path';
import { loadTemplates } from '../../store/templates';

export const onboardingFlowSelectorLoader: LoaderFn<LocationGenerics> = async () => {
    try {
        await loadTemplates();
    } catch (e) {
        navigateAction$.next(getBasePath());
    }
    return {};
};
