import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import React, { useState } from 'react';
import { Button } from '../../../common/buttons/Button';
import {
    ConnectionModalSecretTextField,
    ConnectionModalTextField,
} from '../../../for-deprecation/textfield/ConnectionModalTextField';
import { AWSWizardStage, AWSWizardSteps, StageType } from './AWSWizardSteps';
import { DialogAlert, DialogTitleMain } from '../../../for-deprecation/dialog/DialogComponents';
import { AWSIcon } from '../../../icons/product-icons/AWSIcon';
import { OpenInNewLink } from '../../../common/OpenInNewLink';

export interface AWSClientInfo {
    accessKey: string;
    secretKey: string;
}

export interface AWSConnection {
    clientInfo: AWSClientInfo;
    open: boolean;
    onClose: () => void;
    saving: boolean;
    currentStage: AWSWizardStage;
    setStage: (stage: AWSWizardStage) => void;
    onSave: (props: AWSClientInfo) => void;
    errors?: string;
    setError: (error: string) => void;
    clearErrors: () => void;
}

const StageContent: StageType = (props) => {
    const [accessKey, setAccessKey] = useState(props.clientInfo.accessKey);
    const [secretKey, setSecretKey] = useState(props.clientInfo.secretKey);

    switch (props.stage) {
        case AWSWizardStage.CREATE_USER:
            return (
                <>
                    <DialogAlert
                        severity="info"
                        text={`If you already have a user or an access key and secret access key then skip this step by clicking on next`}
                    />
                    <DialogContent>
                        <DialogContentText component="ol">
                            <li>
                                Visit the{' '}
                                <OpenInNewLink url={'https://us-east-1.console.aws.amazon.com/iam/home'}>
                                    Identity and Access Management (IAM)
                                </OpenInNewLink>{' '}
                                page in AWS.
                            </li>
                            <li>
                                On the left hand side under <strong>Access management</strong>, click on{' '}
                                <strong>Users</strong>.
                            </li>
                            <li>
                                Click on <strong>Create user</strong> on the top right corner.
                            </li>
                            <li>
                                Under <strong>User name</strong>, enter a username for the user and click{' '}
                                <strong>Next</strong>.
                            </li>
                            <li>
                                Grant the user the necessary permissions by adding the new user to an existing group,
                                copying the permissions of a group or attaching the necessary policies.
                            </li>
                            <li>
                                Click <strong>Next</strong> to review the user details then click{' '}
                                <strong>Create user</strong>.
                            </li>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => props.onClose?.()}>
                            Close
                        </Button>
                        <Button onClick={() => props.setStage(AWSWizardStage.GENERATE_CREDENTIALS)}>Next</Button>
                    </DialogActions>
                </>
            );
        case AWSWizardStage.GENERATE_CREDENTIALS:
            return (
                <>
                    <DialogAlert
                        severity="info"
                        text={`If you already have an access key and secret access key then skip this step by clicking on next`}
                    />
                    <DialogContent>
                        <DialogContentText component="ol">
                            <li>Click on the newly created user you want to use to make API requests to AWS.</li>
                            <li>
                                Click on the <strong>Security credentials</strong> tab and scroll down to{' '}
                                <strong>Access keys</strong> and click on <strong>Create access key</strong>.
                            </li>
                            <li>
                                Click on <strong>Other</strong> user case option. Then click <strong>Next</strong>.
                            </li>
                            <li>
                                Provide a short description for your new credentials then click on{' '}
                                <strong>Create access key</strong>.
                            </li>
                            <li>
                                The access key will be displayed along with the obfuscated secret access key. Do not
                                navigate from the page. Instead, proceed to the next step below.
                            </li>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => props.setStage(AWSWizardStage.CREATE_USER)}>
                            Back
                        </Button>
                        <Button onClick={() => props.setStage(AWSWizardStage.INPUT_CREDENTIALS)}>Next</Button>
                    </DialogActions>
                </>
            );
        case AWSWizardStage.INPUT_CREDENTIALS:
            return (
                <>
                    <DialogContent>
                        <DialogContentText component="ol">
                            <li>
                                Copy the <strong>Access key</strong> and the <strong>Secret access key</strong> into the
                                form below. <br />
                            </li>
                            <strong>NOTE:</strong> These credentials will be stored securely in our platform.
                        </DialogContentText>
                        <ConnectionModalTextField
                            label="Access Key"
                            value={accessKey}
                            onUpdate={(e) => {
                                if (props.errors) props.clearErrors();
                                setAccessKey(e.target.value.trim());
                            }}
                        />
                        <ConnectionModalSecretTextField
                            label="Secret Access Key"
                            value={secretKey}
                            onUpdate={(e) => {
                                if (props.errors) props.clearErrors();
                                setSecretKey(e.target.value.trim());
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(AWSWizardStage.GENERATE_CREDENTIALS);
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            data-pendo={'connectorAuthorised'}
                            busy={props.saving}
                            disabled={!accessKey || !secretKey}
                            onClick={() =>
                                props.onSave({
                                    accessKey,
                                    secretKey,
                                })
                            }
                        >
                            Done
                        </Button>
                    </DialogActions>
                </>
            );
    }
};

export const AWSConnectionAppConfigureDialog: React.FC<AWSConnection> = (props) => {
    const error = <DialogAlert severity="error" title={props.errors} />;
    return (
        <Dialog open={props.open} onClose={() => props.onClose()}>
            <DialogTitleMain title="Configure Connector" variant="h6" icon={<AWSIcon />} />
            {props.errors && error}
            <AWSWizardSteps stage={props.currentStage} />
            <StageContent {...props} stage={props.currentStage} setStage={props.setStage} />
        </Dialog>
    );
};
