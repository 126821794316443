import { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { LoadingSpinner } from '../common/LoadingSpinner';
import { Button } from '../common/buttons/Button';
import { DialogAlert, DialogTitleMain } from '../for-deprecation/dialog/DialogComponents';
import { InfoIcon } from '../icons/InfoIcon';
import { IconCircle } from '../for-deprecation/IconCircle';
import { autoFocus, handleKeyDown } from '../../utils/input';
import { deploymentsAndEnvironmentsDocumentationUrl } from '../../utils/documentation';

interface NewDeploymentDialogProps {
    currentVersion?: string;
    environments?: {
        name: string;
        uid: string;
        deployed?: boolean;
    }[]; // If the array is empty, show no environments section.
    errors?: string;
    loading?: boolean;
    newVersion?: string;
    open?: boolean;
    saving?: boolean;
    onCancel: () => void;
    onDeploy: (event: OnDeployEvent) => void;
}

export interface OnDeployEvent {
    label?: string;
    newVersion: string;
    selectedEnvironmentUids: string[]; // UIDs of selected environments
}

const StyledCheckboxGroup = styled(FormControl)(({ theme }) => ({
    marginBottom: theme.spacing(1),
}));

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
    display: 'block',
    marginBottom: theme.spacing(2),
}));

export const NewDeploymentDialog: React.FC<NewDeploymentDialogProps> = ({
    currentVersion,
    environments = [],
    errors,
    loading = false,
    newVersion = '',
    open = false,
    saving = false,
    onCancel,
    onDeploy,
}) => {
    const defaultSelectedEnvironments: string[] = [];

    const inputRef = useRef<HTMLInputElement>(null);

    const [label, setLabel] = useState('');
    const [selectedEnvironments, setSelectedEnvironments] = useState<string[]>(defaultSelectedEnvironments);
    const [version, setVersion] = useState('');

    useEffect(() => {
        setLabel('');
        setSelectedEnvironments(defaultSelectedEnvironments);
        setVersion(newVersion);
        autoFocus(inputRef);
    }, [open, newVersion]);

    const onSubmit = (): void => {
        onDeploy({ label, newVersion: version, selectedEnvironmentUids: selectedEnvironments });
    };

    const onSelectEnvironment = (environmentUid: string): void => {
        if (!selectedEnvironments.includes(environmentUid)) {
            setSelectedEnvironments([...selectedEnvironments, environmentUid]);
        } else {
            setSelectedEnvironments(selectedEnvironments.filter((uid) => uid !== environmentUid));
        }
    };

    const checkboxes = environments.map((environment) => {
        return (
            <FormControlLabel
                checked={selectedEnvironments.includes(environment.uid)}
                control={<Checkbox value={environment.uid} />}
                key={environment.uid}
                label={environment.name}
                onChange={() => onSelectEnvironment(environment.uid)}
            />
        );
    });

    const hasVersionChanged = version !== currentVersion;
    const canSubmit = !!version && !saving && !loading && hasVersionChanged;

    return (
        <Dialog
            open={open}
            onKeyDown={(event) =>
                handleKeyDown({ event, enterCondition: canSubmit, enterFn: onSubmit, escFn: onCancel })
            }
        >
            <DialogTitleMain
                icon={
                    <IconCircle
                        icon={
                            <FileDownloadOutlinedIcon
                                sx={{
                                    transform: 'rotate(180deg)',
                                }}
                            />
                        }
                    />
                }
                title="Release and Deploy"
                tooltipElement={
                    <InfoIcon
                        href={deploymentsAndEnvironmentsDocumentationUrl}
                        tooltip="Learn more about Deployments and Environments"
                        onClick={() => window.open(deploymentsAndEnvironmentsDocumentationUrl, '_blank')}
                    />
                }
            />
            {loading ? (
                <LoadingSpinner />
            ) : (
                <>
                    {errors && <DialogAlert severity="error" title={errors} />}
                    <DialogContent>
                        <StyledFormLabel>Create new release</StyledFormLabel>
                        <TextField
                            label="Release version"
                            onChange={(event) => {
                                setVersion(event.target.value);
                            }}
                            placeholder="Enter a release version"
                            value={version}
                            variant="outlined"
                            required
                        ></TextField>
                        <TextField
                            label="Release label"
                            inputRef={inputRef}
                            onChange={(event) => {
                                setLabel(event.target.value);
                            }}
                            placeholder="Enter a release label"
                            value={label}
                            variant="outlined"
                        ></TextField>
                        {environments.length > 0 ? (
                            <StyledCheckboxGroup>
                                <StyledFormLabel>Deploy new release into following environments:</StyledFormLabel>
                                {environments.length === 1 &&
                                    selectedEnvironments.length === 1 &&
                                    environments[0]?.deployed === false && (
                                        <DialogAlert
                                            severity="warning"
                                            title="Warning"
                                            text={
                                                'It is advised to always keep one environment targeting the HEAD version, so you can always try out the latest changes without having to create a new release and deploy it.'
                                            }
                                        />
                                    )}
                                <FormGroup>{checkboxes}</FormGroup>
                            </StyledCheckboxGroup>
                        ) : null}
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" data-hotspot="cancel" onClick={onCancel}>
                            Cancel
                        </Button>
                        <Button busy={saving} disabled={!canSubmit} onClick={onSubmit}>
                            {selectedEnvironments.length > 0 ? 'Release and Deploy' : 'Create Release'}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};
