import { SESSION_ID } from '..';
import { configTopic$, stitchSession$ } from '../store/config';
import { publishLocalFeedbackEventAction$ } from '../store/feedback';
import { InformativeError } from './error';
import { getFetchOptions } from './fetch';

export const executeEventListener = async (
    environmentUid: string,
    eventListenerUid: string,
    request = {}
): Promise<void> => {
    const fetchOptions = getFetchOptions(
        { Authorization: stitchSession$.value?.jwt ?? '', 'x-stitch-session-id': SESSION_ID },
        request
    );
    const triggerUrl = configTopic$.value.trigger?.listener?.executeUrl;

    if (!triggerUrl) {
        throw new Error('No trigger service url configured in meta');
    }

    const response = await fetch(`${triggerUrl}/${environmentUid}/${eventListenerUid}`, fetchOptions);

    if (!response.ok) {
        const message = await response.text();
        if (message) {
            throw new Error(message);
        } else {
            throw Error(`Unexpected error while communicating with trigger service: ${response.status}`);
        }
    }

    const respBody = await response.json();
    if (respBody?.statusCode && respBody.statusCode >= 400) {
        if (respBody.value) {
            throw new InformativeError(respBody.value);
        } else {
            throw new Error(`Unexpected error while communicating with trigger service: ${respBody.statusCode}`);
        }
    }
};

export const executeInvocationReplay = async (invocationUid: string, payload = {}): Promise<void> => {
    const fetchOptions = getFetchOptions(
        {
            Authorization: stitchSession$.value?.jwt ?? '',
            'x-stitch-session-id': SESSION_ID,
        },
        payload
    );
    const replayUrl = configTopic$.value.trigger?.retriggerUrl;

    if (!replayUrl) {
        throw new Error('No replay service url configured in meta');
    }

    const response = await fetch(`${replayUrl}/${invocationUid}`, fetchOptions);

    if (!response.ok) {
        const message = await response.text();
        if (message) {
            throw new Error(message);
        } else {
            throw Error(`Unexpected error while communicating with trigger service: ${response.status}`);
        }
    }

    const respBody = await response.json();
    if (respBody?.statusCode && respBody.statusCode >= 400) {
        if (respBody.value) {
            throw new InformativeError(respBody.value);
        } else {
            throw new Error(`Unexpected error while communicating with trigger service: ${respBody.statusCode}`);
        }
    }
};

export const createNetSuiteEventListenerScriptContent = (url: string): string => {
    return `/**
    * @NApiVersion 2.x
    * @NScriptType UserEventScript
    */
   define(['N/https', 'N/record'], function (https, record) {
       function afterSubmit(context) {
           var webhookUrl = '${url}';
   
           https.post({
               url: webhookUrl,
               body: JSON.stringify(context),
               headers: {
                   'Content-Type': 'application/json'
               }
           });
       }
   
       return {
           afterSubmit: afterSubmit
       };
   });`;
};

type ExecuteScriptResponse = Record<string, string>;

export const executeScript = async (
    environmentUid: string,
    scriptUid: string,
    request = {}
): Promise<ExecuteScriptResponse> => {
    const fetchOptions = getFetchOptions(
        { Authorization: stitchSession$.value?.jwt ?? '', 'x-stitch-session-id': SESSION_ID },
        request
    );
    const triggerUrl = configTopic$.value.trigger?.executeUrl;

    if (!triggerUrl) {
        throw new Error('No trigger service url configured in meta');
    }

    const response = await fetch(`${triggerUrl}/${environmentUid}/${scriptUid}`, fetchOptions);

    if (!response.ok) {
        console.error('Error while processing error');

        const message = await response.text();
        if (message) {
            throw new Error(message);
        } else {
            throw Error(`Unexpected error while communicating with trigger service: ${response.status}`);
        }
    }

    const respBody = await response.text();

    if (respBody) {
        return JSON.parse(respBody);
    } else {
        return {};
    }
};

export const abortInvocation = async (abortInvocationUrlPath: string): Promise<void> => {
    const fetchOptions = getFetchOptions({ Authorization: stitchSession$.value?.jwt ?? '' });
    const abortInvocationBaseUrl = configTopic$.value.trigger?.abortInvocationUrl;

    if (!abortInvocationBaseUrl) {
        throw new Error('No abort invocation service url configured in meta');
    }

    const invocationId = abortInvocationUrlPath.split('/')[2];

    const response = await fetch(`${abortInvocationBaseUrl}${abortInvocationUrlPath}`, fetchOptions);
    publishLocalFeedbackEventAction$.next({
        level: 'INFO',
        message: 'Invocation abort request has been sent for invocation ID: ' + invocationId,
        noToast: true,
    });

    if (!response.ok) {
        const message = await response.json();
        if (message.code && message.error) {
            console.error('Error from abort request: ' + message);
            publishLocalFeedbackEventAction$.next({
                level: 'ERROR',
                message: `${message.error} - Status ${message.code}`,
                noToast: true,
            });
        } else {
            console.error(`Unexpected error while aborting invocation service: ${response.status}`);
            publishLocalFeedbackEventAction$.next({
                level: 'ERROR',
                message:
                    `Failed to abort invocation: ${invocationId}. If the script is still being executed, ` +
                    'try again. If the issue persists please contact support.',
                noToast: true,
            });
        }
    }
};
