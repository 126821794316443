import { useEffect, useState } from 'react';
import { Button } from '../common/buttons/Button';
import { Dialog } from '../common/Dialog';
import { handleKeyDown } from '../../utils/input';
import { TextField } from '../common/inputs/TextField';

export interface EditScriptNameDialogProps {
    scriptName: string;
    errors?: string;
    loading?: boolean;
    open?: boolean;
    onCancel(): void;
    onSave(name: string): void;
}

export const EditScriptNameDialog: React.FC<EditScriptNameDialogProps> = ({
    scriptName,
    errors,
    loading = false,
    open = false,
    onCancel,
    onSave,
}) => {
    const [currentScriptName, setCurrentScriptName] = useState(scriptName);

    useEffect(() => {
        setCurrentScriptName(scriptName);
    }, [open]);

    const canSave = scriptName !== currentScriptName;

    const onSaveScriptName = (): void => {
        onSave(currentScriptName);
    };

    return (
        <Dialog
            buttons={[
                <Button busy={loading} variant="outlined" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button busy={loading} disabled={!canSave} onClick={onSaveScriptName}>
                    Save changes
                </Button>,
            ]}
            open={open}
            title="Edit script"
            onClose={onCancel}
            onKeyDown={(event) =>
                handleKeyDown({
                    event,
                    enterCondition: !loading && canSave,
                    enterFn: onSaveScriptName,
                    escFn: onCancel,
                })
            }
            loading={loading}
            errors={errors}
        >
            <TextField
                label="Script name"
                placeholder="Enter script name"
                fullWidth
                onChange={(e) => setCurrentScriptName(e.target.value)}
                value={currentScriptName}
                required
                variant="outlined"
            />
        </Dialog>
    );
};
