import { LoaderFn, UnloaderFn } from 'react-location';
import { LocationGenerics } from '../../router';
import { loadErrorPage } from '../../store/error';
import {
    loadWorkspaceSetupGuideSteps,
    modifiedWorkspaceSetupGuideAppInstanceDetails$,
    selectedWorkspaceSetupGuideConnections$,
    selectedWorkspaceSetupGuideStep$,
    selectedWorkspaceSetupGuideTriggerScreenType$,
    workspaceSetupGuideConnectionsHaveUnsavedChanges$,
    workspaceSetupGuideStepError$,
    workspaceSetupGuideSteps$,
    workspaceSetupGuideStepSaving$,
} from '../../store/setup-guide';
import { loadReadmeFile, loadWorkspaceEnvironments, loadWorkspaceResources } from '../../store/workspace/utils';
import {
    cleanUpWorkspace,
    selectedEnvironmentUid$,
    selectedWorkspace$,
    selectedWorkspaceEnvironment$,
    selectedWorkspaceManualScriptTriggerBundle$,
    selectedWorkspaceManualBundledScripts$,
    selectedWorkspaceResources$,
    selectedWorkspaceUid$,
} from '../../store/workspace';
import { getWorkspace } from '../../data/workspace';
import { PermissionError } from '../../utils/error';
import { loadLoggedInUserConnections } from '../../store/connection/utils';
import { selectedApiHandlerDetails$ } from '../../store/workspace/api-handler';
import { selectedEventListenerDetails$ } from '../../store/workspace/event-listener';
import { unsavedCronExpression$, selectedScheduledTriggerDetails$ } from '../../store/workspace/scheduled-trigger';
import { selectReadmeFileAction$ } from '../../store/workspace/readme';
import { getAppInstanceSelectedConnections } from '../../utils/setupGuide';

export const setupGuideLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const workspaceUid = routeMatch.params.workspaceUid!;

    try {
        const [workspace] = await Promise.all([
            getWorkspace(workspaceUid),
            loadWorkspaceSetupGuideSteps(workspaceUid),
            loadWorkspaceEnvironments(workspaceUid),
            loadLoggedInUserConnections(),
        ]);

        if (!workspace.setupGuide) {
            throw new PermissionError('Setup guide not available for this workspace.');
        }

        selectedWorkspace$.next(workspace);
        selectedWorkspaceUid$.next(workspace.uid);
        selectedWorkspaceManualScriptTriggerBundle$.next(true);
        selectedWorkspaceManualBundledScripts$.next([]);

        await loadWorkspaceResources(workspaceUid, selectedWorkspaceEnvironment$.value?.uid ?? '');

        const resources = selectedWorkspaceResources$.value;

        const selectedConnections = getAppInstanceSelectedConnections(resources.apiHandlers, resources.eventListeners);
        selectedWorkspaceSetupGuideConnections$.next(selectedConnections);

        await loadReadmeFile(resources.readmeFile?.uid ?? '', workspaceUid, selectedEnvironmentUid$.value ?? '');
        selectReadmeFileAction$.next(resources.readmeFile?.uid ?? '');

        workspaceSetupGuideConnectionsHaveUnsavedChanges$.next(false);
    } catch (e) {
        loadErrorPage({
            error: e,
            genericMessage: 'Failed to load setup guide.',
        });

        throw e;
    }

    return {};
};

export const setupGuideUnloader: UnloaderFn<LocationGenerics> = (routeMatch) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const workspaceUid = routeMatch.params.workspaceUid!;

    cleanUpWorkspace(workspaceUid);
    selectedWorkspaceSetupGuideConnections$.next({});
    selectedWorkspaceSetupGuideTriggerScreenType$.next(undefined);
    modifiedWorkspaceSetupGuideAppInstanceDetails$.next(undefined);
    workspaceSetupGuideStepError$.next(undefined);
    workspaceSetupGuideStepSaving$.next(false);
    workspaceSetupGuideSteps$.next([]);
    selectedWorkspaceSetupGuideStep$.next(undefined);
};

export const setupGuideEditScreenUnloader: UnloaderFn<LocationGenerics> = () => {
    selectedApiHandlerDetails$.next(undefined);
    selectedEventListenerDetails$.next(undefined);
    selectedScheduledTriggerDetails$.next(undefined);
    unsavedCronExpression$.next(undefined);
};
