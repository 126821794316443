import { useObservableState } from 'observable-hooks';
import { OnboardingFlowSelector } from '../../../components/onboarding/wizard/OnboardingFlowSelector';
import { navigateToOnboardingStepAction$ } from '../../../store/onboarding';
import { templates$ } from '../../../store/templates';

const QUICKSTART_TEMPLATE_NAME = 'Create a Confluence Cloud page when a Jira Cloud issue is created';

export const OnboardingFlowSelectorContainer: React.FC = () => {
    const templates = useObservableState(templates$);

    const quickStartTemplate = templates.find((t) => t.name === QUICKSTART_TEMPLATE_NAME);

    return (
        <OnboardingFlowSelector
            onExploreTemplates={() => navigateToOnboardingStepAction$.next({ route: 'templates' })}
            onQuickstart={() =>
                navigateToOnboardingStepAction$.next({ route: 'template', templateUid: quickStartTemplate?.uid ?? '' })
            }
            onStartFromScratch={() => navigateToOnboardingStepAction$.next({ route: 'naming' })}
        />
    );
};
