import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Button } from '../../../common/buttons/Button';
import { CopyTextField } from '../../../common/inputs/custom/CopyTextField';
import { Dialog } from '../../../common/Dialog';
import { IconCircle } from '../../../common/IconCircle';
import { OrderedList, makeNonListItem } from '../../../common/lists/OrderedList';
import { ProductIcon } from '../../../icons/ProductIcon';
import { StyledBorderBox, StyledBorderBoxContent, StyledBorderBoxSubHeader } from '../../../common/LayoutComponents';
import { eventListenerDocumentationUrl } from '../../../../utils/documentation';
import { handleKeyDown } from '../../../../utils/input';

interface BitbucketOnPremiseEventListenerSetupDialogProps {
    eventTypeName: string;
    loading?: boolean;
    open?: boolean;
    setupBaseUrl?: string;
    webhookBaseUrl: string;
    webhookUrlId: string;
    onClose: () => void;
}

export const BitbucketOnPremiseEventListenerSetupDialog: React.FC<BitbucketOnPremiseEventListenerSetupDialogProps> = ({
    eventTypeName,
    loading = false,
    open = false,
    setupBaseUrl,
    webhookBaseUrl,
    webhookUrlId,
    onClose,
}) => {
    const connectionTypeName = 'Bitbucket On-Premise';
    const normalizedUrl = setupBaseUrl ? (setupBaseUrl.endsWith('/') ? setupBaseUrl.slice(0, -1) : setupBaseUrl) : '';

    const listContent = [
        setupBaseUrl ? (
            <Typography>
                Visit your instance's{' '}
                <Link target="_blank" href={normalizedUrl + '/projects'}>
                    Projects
                </Link>{' '}
                page and click on your project's name.
            </Typography>
        ) : (
            <Typography>Open https://[YOUR_INSTANCE]/projects/[YOUR_PROJECT] URL in your browser.</Typography>
        ),

        <Typography>Click on the repository where you want to add the webhook.</Typography>,
        <Typography>
            Click <strong>Repository settings</strong> on the left side.
        </Typography>,
        <Typography>
            Click <strong>Webhooks</strong> on the left side under <strong>Workflow</strong>.
        </Typography>,
        <Typography>
            Click <strong>Create webhook</strong>.
        </Typography>,
        <Typography>
            Enter a meaningful name into the <strong>Name</strong> field.
        </Typography>,
        <Typography>
            Paste the copied URL into the <strong>URL</strong> field.
        </Typography>,
        makeNonListItem(
            <CopyTextField fullWidth label="Webhook URL" value={`${webhookBaseUrl}/${webhookUrlId}`} />,
            'bitbucket-on-premise-webhook-url'
        ),

        <Typography>
            Select ONLY the following event to listen: <strong>{eventTypeName}</strong>.
        </Typography>,
        <Typography>
            Then click <strong>Create</strong>.
        </Typography>,
    ];

    return (
        <>
            <Dialog
                leftButton={
                    <Button onClick={() => window.open(eventListenerDocumentationUrl)} variant="text">
                        Learn more
                    </Button>
                }
                buttons={[
                    <Button disabled={loading} onClick={onClose} variant="outlined">
                        Close
                    </Button>,
                    <Button disabled={loading} onClick={onClose} color="success">
                        Mark as complete
                    </Button>,
                ]}
                icon={<IconCircle icon={<ProductIcon name={connectionTypeName} />} size="medium" />}
                open={open}
                title="Event listener setup"
                subtitle={connectionTypeName}
                onClose={onClose}
                onKeyDown={(event) =>
                    handleKeyDown({ event, enterCondition: !loading, enterFn: onClose, escFn: onClose })
                }
                loading={loading}
            >
                <StyledBorderBox>
                    <StyledBorderBoxSubHeader>
                        <Typography variant="subtitle1" component="h6">
                            How to set up an event listener
                        </Typography>
                        <Typography color="text.secondary">
                            Create a webhook in {connectionTypeName} instance to listen to events.
                        </Typography>
                    </StyledBorderBoxSubHeader>
                    <Divider />
                    <StyledBorderBoxContent gap={1.5}>
                        <OrderedList content={listContent} id="bitbucket-on-premise" />
                    </StyledBorderBoxContent>
                </StyledBorderBox>
            </Dialog>
        </>
    );
};
