import { LoaderFn } from 'react-location';
import { LocationGenerics } from '../../router';
import { onboardingWizardError$, scriptingFamiliarityOptions$ } from '../../store/onboarding';
import { getScriptingFamiliarityOptions } from '../../data/onboarding';
import { navigateAction$ } from '../../store/navigation';
import { getBasePath } from '../../utils/path';

export const onboardingScriptingFamiliaritySelectorLoader: LoaderFn<LocationGenerics> = async () => {
    onboardingWizardError$.next(undefined);
    try {
        const scriptingFamiliarityOptions = await getScriptingFamiliarityOptions();
        scriptingFamiliarityOptions$.next(scriptingFamiliarityOptions);
    } catch (e) {
        navigateAction$.next(getBasePath());
    }
    return {};
};
