import { BehaviorSubject, combineLatest, debounceTime, filter, map, Subject } from 'rxjs';
import { WorkspaceDeployments } from '../../data/deployment';
import { exitWorkspace, Workspace, WorkspaceEnvironments, WorkspaceResources } from '../../data/workspace';
import { getBasePath } from '../../utils/path';
import { promptQuestion } from '../confirm';
import { monitor } from '../monitor';
import { navigateAction$ } from '../navigation';
import { implicitlyIncludedPackages$, packageManagerCorePackages$, packageManagerManagedAPIs$ } from './packages';
import {
    parsedReadmeFileContent$,
    readmeFileHasUnsavedChanges$,
    savedReadmeFileDetails$,
    unsavedReadmeFileDetails$,
} from './readme';
import {
    newScriptCreatedAction$,
    savedScriptDetails$,
    scriptHasUnsavedChanges$,
    scriptNameChangedAction$,
    unsavedScriptDetails$,
} from './script';
import {
    loadWorkspaceDeploymentsWhenEventIsEmitted,
    loadWorkspaceEnvironmentsWhenEventIsEmitted,
    loadWorkspaceResourcesWhenEventIsEmitted,
    workspaceHasUnsavedChanges,
} from './utils';
import { workspaceDetailsUpdatedAction$ } from '../workspaces';
import {
    environmentVariablesHaveMissingInformation$,
    environmentVariablesHaveUnsavedChanges$,
    savedEnvironmentVariables$,
    unsavedEnvironmentVariables$,
} from './environment-variable';
import { selectOrganizationAndInitPendoAction$ } from '../organization';
import { consoleFullScreen$, searchLogQuery$ } from './console';
import { PACKAGE_MANAGER_CORE_PACKAGES } from '../../components/workspace-dialogs/package-manager';
import { savedEventListenerPayloadContent$, storedEventListenerPayloadContent$ } from './event-listener-payload';

export const selectWorkspaceAction$ = monitor('selectWorkspaceAction$', new Subject<string | undefined>());
export const selectEnvironmentAction$ = monitor('selectEnvironmentAction$', new Subject<string | undefined>());
export const exitWorkspaceAction$ = monitor('exitWorkspaceAction$', new Subject<string>());
export const switchWorkspaceAction$ = monitor('switchWorkspaceAction$', new Subject<SelectedWorkspace>());
export const newReadmeFileCreatedAction$ = monitor('newReadmeFileCreatedAction$', new Subject<string>());

export const selectedWorkspaceUid$ = monitor(
    'selectedWorkspaceUid$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const selectedWorkspaceReadOnlyMode$ = monitor(
    'selectedWorkspaceReadOnlyMode$',
    new BehaviorSubject<boolean | undefined>(undefined)
);
export const selectedWorkspace$ = monitor('selectedWorkspace$', new BehaviorSubject<Workspace | undefined>(undefined));
export const selectedEnvironmentUid$ = monitor(
    'selectedEnvironmentUid$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const loadingWorkspaceResources$ = monitor('loadingWorkspaceResources$', new BehaviorSubject(false));
export const selectedWorkspaceResources$ = monitor(
    'selectedWorkspaceResources$',
    new BehaviorSubject<WorkspaceResources & { default?: boolean }>({
        scripts: [],
        apiHandlers: [],
        eventListeners: [],
        scheduledTriggers: [],
        default: true,
        invocationsUsage: {
            limit: 0,
            consumed: 0,
        },
    })
);
export const selectedWorkspaceSelectedResource$ = monitor(
    'selectedWorkspaceSelectedResource$',
    new BehaviorSubject<SelectedResource | undefined>(undefined)
);
export const selectedWorkspaceEnvironments$ = monitor(
    'selectedWorkspaceEnvironments$',
    new BehaviorSubject<WorkspaceEnvironments>([])
);
export const selectedWorkspaceEnvironment$ = monitor(
    'selectedWorkspaceEnvironments$',
    new BehaviorSubject<WorkspaceEnvironments[number] | undefined>(undefined)
);
export const selectedWorkspaceDeployments$ = monitor(
    'selectedWorkspaceDeployments$',
    new BehaviorSubject<WorkspaceDeployments>([])
);
export const loadingWorkspaceEnvironments$ = monitor('loadingWorkspaceEnvironments$', new BehaviorSubject(false));
export const ignoreNextWorkspaceExit$ = monitor('ignoreNextWorkspaceExit$', new BehaviorSubject<boolean>(false));
export const ignoreWorkspaceExitUnsavedChangesCheck$ = monitor(
    'ignoreWorkspaceExitUnsavedChangesCheck$',
    new BehaviorSubject(false)
);

export const selectedWorkspaceManualScriptTriggerBundle$ = monitor(
    'selectedWorkspaceManualScriptTriggerBundle$',
    new BehaviorSubject(true)
);
export const selectedWorkspaceManualBundledScripts$ = monitor(
    'selectedWorkspaceManualBundledScripts$',
    new BehaviorSubject<string[]>([])
);

selectWorkspaceAction$.subscribe((workspaceUid) => {
    selectedWorkspaceUid$.next(workspaceUid);
    selectedWorkspaceManualScriptTriggerBundle$.next(true);
    selectedWorkspaceManualBundledScripts$.next([]);
});

selectEnvironmentAction$.subscribe((environmentUid) => {
    selectedEnvironmentUid$.next(environmentUid);
});

exitWorkspaceAction$.subscribe((workspaceUid) => {
    if (workspaceHasUnsavedChanges() && !ignoreWorkspaceExitUnsavedChangesCheck$.value) {
        promptQuestion({
            title: 'The workspace you left has unsaved changes',
            messages: ['Progress will be lost if you continue without saving.'],
            proceedLabel: 'Go back',
            cancelLabel: 'Discard & continue',
            onProceed: () => {
                selectedWorkspaceSelectedResource$.next(undefined);
                selectOrganizationAndInitPendoAction$.next({ orgUid: selectedWorkspace$.value?.organization?.uid });

                navigateAction$.next(
                    `${getBasePath()}workspace/${selectedWorkspaceUid$.value ?? ''}/environment/${
                        selectedEnvironmentUid$.value ?? ''
                    }`
                );
            },
            onCancel: () => cleanUpWorkspace(workspaceUid),
        });
    } else {
        ignoreWorkspaceExitUnsavedChangesCheck$.next(false);
        cleanUpWorkspace(workspaceUid);
    }
});

combineLatest([packageManagerManagedAPIs$, packageManagerCorePackages$, selectedWorkspaceResources$])
    .pipe(
        filter(
            ([managedAPIs, corePackages, resources]) =>
                managedAPIs.length > 0 && corePackages.length > 0 && !resources.default
        ),
        map(([managedAPIs, corePackages, resources]) => {
            const selectedApiHandlerLibraries = resources.apiHandlers.flatMap((apiHandler) => {
                const recommendedLibraries = apiHandler.libraries
                    .filter((library) => library.recommended && !library.deprecated)
                    .map((library) => library.name);

                const nonDeprecatedLibraries = apiHandler.libraries
                    .filter((library) => !library.deprecated)
                    .map((library) => library.name);

                return apiHandler.selectedApiHandlerLibrary
                    ? apiHandler.selectedApiHandlerLibrary.name
                    : recommendedLibraries.length > 0
                    ? recommendedLibraries
                    : nonDeprecatedLibraries;
            });

            const selectedEventListenerLibraries = resources.eventListeners
                .flatMap((eventListener) => eventListener.libraries)
                .map((library) => library.name);
            const implicitlyIncludedPackages = corePackages
                .filter(
                    (pkg) =>
                        PACKAGE_MANAGER_CORE_PACKAGES.includes(pkg.name) ||
                        selectedEventListenerLibraries.includes(pkg.name)
                )
                .map((pkg) => ({
                    name: pkg.name,
                    version: pkg.selectedVersion,
                    type: 'CORE',
                }));
            implicitlyIncludedPackages.push(
                ...managedAPIs
                    .filter((pkg) => selectedApiHandlerLibraries.includes(pkg.name))
                    .map((pkg) => ({
                        name: pkg.name,
                        version: pkg.selectedVersion,
                        type: 'MANAGED_API',
                    }))
            );
            return implicitlyIncludedPackages;
        })
    )
    .subscribe((implicitlyIncludedPackages) => implicitlyIncludedPackages$.next(implicitlyIncludedPackages));

loadWorkspaceResourcesWhenEventIsEmitted(selectWorkspaceAction$);
loadWorkspaceEnvironmentsWhenEventIsEmitted(selectWorkspaceAction$);
loadWorkspaceDeploymentsWhenEventIsEmitted(selectWorkspaceAction$);
loadWorkspaceResourcesWhenEventIsEmitted(newReadmeFileCreatedAction$);

loadWorkspaceResourcesWhenEventIsEmitted(newScriptCreatedAction$);
loadWorkspaceResourcesWhenEventIsEmitted(scriptNameChangedAction$.pipe(debounceTime(1000)));
loadWorkspaceResourcesWhenEventIsEmitted(workspaceDetailsUpdatedAction$);

export const cleanUpWorkspace = (workspaceUid: string, ignoreExitWorkspace?: boolean): void => {
    if (!ignoreExitWorkspace) {
        // Non-await on purpose (fire and forget)
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        callExitWorkspace(workspaceUid);
    }

    savedScriptDetails$.next({});
    unsavedScriptDetails$.next({});
    scriptHasUnsavedChanges$.next({});
    savedReadmeFileDetails$.next({});
    unsavedReadmeFileDetails$.next({});
    parsedReadmeFileContent$.next({});
    readmeFileHasUnsavedChanges$.next({});
    storedEventListenerPayloadContent$.next({});
    savedEventListenerPayloadContent$.next({});
    unsavedEnvironmentVariables$.next(undefined);
    savedEnvironmentVariables$.next(undefined);
    consoleFullScreen$.next(false);
    searchLogQuery$.next(undefined);
    environmentVariablesHaveUnsavedChanges$.next(false);
    environmentVariablesHaveMissingInformation$.next(false);
    selectedWorkspaceSelectedResource$.next(undefined);
    selectedEnvironmentUid$.next(undefined);
    selectedWorkspaceUid$.next(undefined);
    selectedWorkspaceReadOnlyMode$.next(undefined);
    selectedWorkspaceEnvironments$.next([]);
    selectedWorkspaceEnvironment$.next(undefined);
    selectedWorkspace$.next(undefined);
    selectedWorkspaceManualScriptTriggerBundle$.next(true);
    selectedWorkspaceManualBundledScripts$.next([]);
    selectedWorkspaceResources$.next({
        scripts: [],
        apiHandlers: [],
        eventListeners: [],
        scheduledTriggers: [],
        default: true,
        invocationsUsage: {
            limit: 0,
            consumed: 0,
        },
    });
};

const callExitWorkspace = async (workspaceUid: string): Promise<void> => {
    try {
        if (workspaceUid) {
            await exitWorkspace(workspaceUid);
        }
    } catch (e) {
        console.error('Failed to call exit workspace', e, {
            workspaceUid,
        });
    }
};

export type SelectedWorkspace = {
    workspaceUid: string;
    environmentUid: string;
};

export type SelectedResource = {
    resourceType:
        | 'SCRIPT'
        | 'API_HANDLER'
        | 'EVENT_LISTENER'
        | 'SCHEDULED_TRIGGER'
        | 'README'
        | 'ENVIRONMENT_VARIABLES';
    uid?: string;
};

export const showInvocationsLimitAlert$ = monitor('showInvocationsLimitAlert$', new BehaviorSubject<boolean>(false));
export const invocationsLimitProcessAction$ = monitor(
    'invocationsLimitProcessAction$',
    new Subject<WorkspaceResources['invocationsUsage']>()
);
export const navigateToPlanManagementAction$ = monitor('navigateToPlanManagementAction$', new Subject<void>());

invocationsLimitProcessAction$.subscribe((invocationsUsage) => {
    const storedSession = sessionStorage.getItem('SRC_INVOCATION_LIMIT_SESSION');
    const currentSession = sessionStorage.getItem('STITCH_SESSION_ID');

    if (invocationsUsage && storedSession !== currentSession) {
        if ((invocationsUsage.consumed / invocationsUsage.limit) * 100 >= 75) {
            showInvocationsLimitAlert$.next(true);
        } else {
            showInvocationsLimitAlert$.next(false);
        }
    }
});
