/**
 * Wrapper for wrapping async functions for reach actions to satisfy linter
 */
export function wrapAsync<ARGS extends unknown[]>(fn: (...args: ARGS) => Promise<unknown>): (...args: ARGS) => void {
    return (...args) => {
        void fn(...args);
    };
}

export const sleep = (duration: number): Promise<void> => new Promise<void>((resolve) => setTimeout(resolve, duration));

interface FormattedBytes {
    value: number;
    unit: 'B' | 'KB' | 'MB' | 'GB';
}

export const formatBytes = (value: number, unit: 'B' | 'MB' = 'MB'): FormattedBytes => {
    const fullBytes = unit === 'B' ? value : value * 1024 * 1024;

    if (fullBytes < 1024) {
        return { value: fullBytes, unit: 'B' };
    } else if (fullBytes < 1024 * 1024) {
        const roundedValue = Math.round((fullBytes / 1024) * 100) / 100;
        return { value: roundedValue, unit: 'KB' };
    } else if (fullBytes < 1024 * 1024 * 1024) {
        const roundedValue = Math.round((fullBytes / (1024 * 1024)) * 100) / 100;
        return { value: roundedValue, unit: 'MB' };
    } else {
        const roundedValue = Math.round((fullBytes / (1024 * 1024 * 1024)) * 100) / 100;
        return { value: roundedValue, unit: 'GB' };
    }
};

export const formatBytesToString = (value: number, unit: 'B' | 'MB' = 'MB'): string => {
    const formattedBytes = formatBytes(value, unit);
    return `${formattedBytes.value} ${formattedBytes.unit}`;
};

export const legacyImportsRegex = /import\s*\{?\s*.*\s*\}?\s*from\s*('|").*stitch-it.*('|")/gm;

export const getFilterKey = (workspaceUid: string, environmentUid: string): string =>
    `${workspaceUid}-${environmentUid}`;
