import { createEventListener as createEventListenerRpc } from '@avst-stitch/repository-lib/lib';
import { saveEventListener as saveEventListenerRpc } from '@avst-stitch/repository-lib/lib';
import { getEventListener as getEventListenerRpc } from '@avst-stitch/repository-lib/lib';
import { getEventListenerSetupDetails as getEventListenerSetupDetailsRpc } from '@avst-stitch/repository-lib/lib/rpcs/getEventListenerSetupDetails';
import { deleteEventListener as deleteEventListenerRpc } from '@avst-stitch/repository-lib/lib';
import { getEventListenerTestPayloads as getEventListenerTestPayloadsRpc } from '@avst-stitch/repository-lib/lib';
import { getEventListenerTestPayload as getEventListenerTestPayloadRpc } from '@avst-stitch/repository-lib/lib';
import { createEventListenerTestPayload as createEventListenerTestPayloadRpc } from '@avst-stitch/repository-lib/lib';
import { saveEventListenerTestPayload as saveEventListenerTestPayloadRpc } from '@avst-stitch/repository-lib/lib';
import { Response as CreatedEventListener } from '@avst-stitch/repository-lib/lib/rpcs/createEventListener';
import {
    Request as SaveEventListenerRequest,
    Response as SavedEventListener,
} from '@avst-stitch/repository-lib/lib/rpcs/saveEventListener';
import { Response as EventListener } from '@avst-stitch/repository-lib/lib/rpcs/getEventListener';
import { Response as EventListenerSetupDetails } from '@avst-stitch/repository-lib/lib/rpcs/getEventListenerSetupDetails';
import { Response as EventListenerDeletion } from '@avst-stitch/repository-lib/lib/rpcs/deleteEventListener';
import { Response as EventListenerTestPayloads } from '@avst-stitch/repository-lib/lib/rpcs/getEventListenerTestPayloads';
import { Response as CreatedEventListenerTestPayload } from '@avst-stitch/repository-lib/lib/rpcs/createEventListenerTestPayload';
import { Response as EventListenerTestPayload } from '@avst-stitch/repository-lib/lib/rpcs/getEventListenerTestPayload';
import { repositoryInvoker } from '../utils/repository';
export type { CreatedEventListener, SavedEventListener, EventListener, EventListenerTestPayloads };

export const createEventListener = async (
    workspaceUid: string,
    appUid: string,
    eventListenerTypeUid: string
): Promise<CreatedEventListener> => {
    return createEventListenerRpc(repositoryInvoker, {
        workspaceUid,
        appUid,
        eventListenerTypeUid,
    });
};

export const saveEventListener = async (request: SaveEventListenerRequest): Promise<SavedEventListener> => {
    return saveEventListenerRpc(repositoryInvoker, request);
};

export const getEventListener = async (uid: string, environmentUid?: string): Promise<EventListener> => {
    return getEventListenerRpc(repositoryInvoker, {
        uid,
        environmentUid,
    });
};

export const getEventListenerSetupDetails = async (
    uid: string,
    environmentUid: string
): Promise<EventListenerSetupDetails> => {
    return getEventListenerSetupDetailsRpc(repositoryInvoker, {
        uid,
        environmentUid,
    });
};

export const deleteEventListener = async (uid: string, ignoreWarnings?: boolean): Promise<EventListenerDeletion> => {
    return deleteEventListenerRpc(repositoryInvoker, {
        uid,
        ignoreWarnings,
    });
};

export const getEventListenerTestPayloads = async (
    eventListenerUid: string,
    environmentUid: string
): Promise<EventListenerTestPayloads> => {
    return getEventListenerTestPayloadsRpc(repositoryInvoker, {
        eventListenerUid,
        environmentUid,
    });
};

export const getEventListenerTestPayload = async (uid: string): Promise<EventListenerTestPayload> => {
    return getEventListenerTestPayloadRpc(repositoryInvoker, {
        uid,
    });
};

export const createEventListenerTestPayload = async (
    eventListenerUid: string,
    environmentUid: string,
    name: string
): Promise<CreatedEventListenerTestPayload> => {
    return createEventListenerTestPayloadRpc(repositoryInvoker, {
        eventListenerUid,
        environmentUid,
        name,
    });
};

export const saveEventListenerTestPayload = async (
    eventListenerTestPayloadUid: string,
    environmentUid: string,
    content: string
): Promise<void> => {
    return saveEventListenerTestPayloadRpc(repositoryInvoker, {
        eventListenerTestPayloadUid,
        environmentUid,
        content,
    });
};
