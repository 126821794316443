import { useEffect, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import ReceiptLongRoundedIcon from '@mui/icons-material/ReceiptLongRounded';
import { Button } from '../../common/buttons/Button';
import { DialogAlert, DialogTitleMain } from '../../for-deprecation/dialog/DialogComponents';
import { IconCircle } from '../../for-deprecation/IconCircle';
import { autoFocus, handleKeyDown } from '../../../utils/input';
import isEmail from 'validator/lib/isEmail';

interface CreateBillingEntityModalProps {
    open?: boolean;
    errors?: string;
    saving?: boolean;
    onSave(event: CreateBillingDetailsEvent): void;
    onCancel(): void;
}

export interface CreateBillingDetailsEvent {
    contactName: string;
    contactEmail: string;
}

export const CreateBillingEntityModal: React.FC<CreateBillingEntityModalProps> = ({
    errors,
    open = false,
    saving = false,
    onSave,
    onCancel,
}) => {
    const [contactName, setContactName] = useState('');
    const [contactEmail, setContactEmail] = useState('');

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setContactName('');
        setContactEmail('');
        autoFocus(inputRef);
    }, [open]);

    const emailValidationError = !!contactEmail && !isEmail(contactEmail);

    const canSave = !!contactEmail && !!contactName && !saving && !emailValidationError;

    return (
        <Dialog
            open={open}
            onKeyDown={(event) =>
                handleKeyDown({
                    event,
                    enterCondition: canSave,
                    enterFn: () => onSave({ contactEmail, contactName }),
                    escFn: onCancel,
                })
            }
        >
            <DialogTitleMain icon={<IconCircle icon={<ReceiptLongRoundedIcon />} />} title="Billing details" />
            {errors && <DialogAlert severity="error" title={errors} />}
            <DialogContent>
                <TextField
                    inputRef={inputRef}
                    id="name-text-field"
                    onChange={(e) => setContactName(e.target.value)}
                    placeholder="Enter a name"
                    label="Billing contact name"
                    value={contactName}
                    required
                />
                <TextField
                    error={emailValidationError}
                    onChange={(e) => setContactEmail(e.target.value)}
                    label="Email"
                    placeholder="Enter an email"
                    value={contactEmail}
                    required
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} variant="outlined">
                    Cancel
                </Button>

                <Button
                    busy={saving}
                    disabled={!canSave}
                    onClick={() =>
                        onSave({
                            contactName,
                            contactEmail,
                        })
                    }
                    variant="contained"
                    color="primary"
                >
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    );
};
