import { LoaderFn } from 'react-location';
import { LocationGenerics } from '../router';
import { selectReadmeFileAction$ } from '../store/workspace/readme';
import { loadReadmeFile } from '../store/workspace/utils';
import { loadErrorPage } from '../store/error';
import { selectWorkspaceAction$, selectedEnvironmentUid$, selectedWorkspace$ } from '../store/workspace';
import { getWorkspace } from '../data/workspace';

export const fullScreenReadmeLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    try {
        //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const readmeUid = routeMatch.params.readmeFileUid!;

        //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const workspaceUid = routeMatch.params.workspaceUid!;

        //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const environmentUid = routeMatch.params.environmentUid!;

        selectedEnvironmentUid$.next(environmentUid);

        const workspace = await getWorkspace(workspaceUid);
        selectedWorkspace$.next(workspace);
        selectWorkspaceAction$.next(workspaceUid);

        await loadReadmeFile(readmeUid, workspaceUid, environmentUid);
        selectReadmeFileAction$.next(readmeUid);
    } catch (e) {
        loadErrorPage({
            error: e,
            background: 'paper',
            genericMessage: 'Failed to load README file.',
        });

        throw e;
    }

    return {};
};
