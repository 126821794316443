import { styled } from '@mui/material';
import MuiTabContext, { TabContextProps } from '@mui/lab/TabContext';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

interface CustomTabContextProps extends TabContextProps {
    tabs: { content: JSX.Element; label: string; value: string }[];
    setTabValue: (value: string) => void;
}

const StyledTabList = styled(TabList)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
    minHeight: 'unset',
    padding: 0,
    '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.primary.main,
    },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
    color: theme.palette.text.secondary,
    minHeight: 'unset',
    padding: theme.spacing(0, 2, 2),
    '&.Mui-selected': {
        color: theme.palette.primary.main,
    },
}));

const StyledTabPanel = styled(TabPanel)(() => ({
    padding: 0,
}));

export const TabContext: React.FC<CustomTabContextProps> = ({ tabs = [], setTabValue, ...props }) => {
    return (
        <MuiTabContext {...props}>
            <StyledTabList onChange={(_, v) => setTabValue(v)}>
                {tabs.map((t) => (
                    <StyledTab key={t.value} label={t.label} value={t.value} />
                ))}
            </StyledTabList>
            {tabs.map((t) => (
                <StyledTabPanel key={t.value} value={t.value}>
                    {t.content}
                </StyledTabPanel>
            ))}
        </MuiTabContext>
    );
};
