import { getFetchOptions } from '../../../utils/fetch';
import { configTopic$, stitchSession$ } from '../../config';

export interface AWSSetupSaveProps {
    accessKey: string;
    secretKey: string;
}

export interface SaveACredentialsProps extends AWSSetupSaveProps {
    connectionId: string;
}

export const saveCredentials = async (request: SaveACredentialsProps): Promise<void> => {
    const connectorUrl = configTopic$.value.connection?.aws?.baseUrl;
    if (!connectorUrl) {
        throw new Error('No AWS connector url configured in meta');
    }

    const fetchOptions = getFetchOptions({ Authorization: stitchSession$.value?.jwt ?? '' }, request);

    const response = await fetch(`${connectorUrl}/save`, fetchOptions);
    if (!response.ok) {
        console.error('Error while saving token');

        const message = await response.text();
        if (message) {
            throw new Error(message);
        } else {
            throw Error(`Unexpected error while saving token to AWS Connector Service: ${response.status}`);
        }
    }
};
