import { useObservableState, useSubscription } from 'observable-hooks';
import { Dashboard } from '../../components/dashboard';
import { loggedInUserDetails$ } from '../../store/user';
import { getBasePath } from '../../utils/path';
import { useNavigate } from 'react-location';
import {
    selectedOrganizationDashboardUsageAction$,
    organizationSwitchedAction$,
    selectedOrganizationPlanLastUpdatedDate$,
    selectedOrganizationUid$,
    selectedOrganizationUsage$,
} from '../../store/organization';
import { loggedInUserWorkspaces$, openNewBlankWorkspaceAction$ } from '../../store/workspaces';
import { appSelectorOpen$, openAppSelectorAction$ } from '../../store/apps';
import { openConnectionDetailsDialogAction$ } from '../../store/connection';

export const DashboardContainer: React.FC = () => {
    const navigate = useNavigate();

    const loggedInUserDetails = useObservableState(loggedInUserDetails$);
    const loggedInUserWorkspaces = useObservableState(loggedInUserWorkspaces$);
    const selectedOrganizationPlanLastUpdatedDate = useObservableState(selectedOrganizationPlanLastUpdatedDate$);
    const selectedOrganizationUid = useObservableState(selectedOrganizationUid$);
    const selectedOrganization = loggedInUserDetails?.organizations?.find((org) => org.uid === selectedOrganizationUid);
    const { connectors, invocations } = useObservableState(selectedOrganizationUsage$) || {};

    useSubscription(organizationSwitchedAction$, (uid) => selectedOrganizationDashboardUsageAction$.next(uid));

    return (
        <Dashboard
            displayName={loggedInUserDetails?.firstName ?? loggedInUserDetails?.email ?? 'User'}
            organizationName={selectedOrganization?.name ?? ''}
            plan={selectedOrganization?.planName ?? ''}
            usage={{
                lastUpdated: selectedOrganizationPlanLastUpdatedDate.toLocaleString(),
                connectors: {
                    limit: connectors?.limit && connectors?.limit === -1 ? 'Unlimited' : connectors?.limit ?? 0,
                    consumed: connectors?.consumed ?? 0,
                },
                invocations: {
                    limit: invocations?.limit ?? 0,
                    consumed: invocations?.consumed ?? 0,
                },
                workspaces: {
                    limit: 'Unlimited',
                    consumed: loggedInUserWorkspaces.length,
                },
            }}
            onCreateConnector={() => {
                navigate({ to: `${getBasePath()}connectors` });
                setTimeout(() => {
                    openAppSelectorAction$.next({
                        nextAction: (app) => {
                            openConnectionDetailsDialogAction$.next({
                                connectionType: app.connectionType,
                            });
                            appSelectorOpen$.next(false);
                        },
                        mode: 'CONNECTOR',
                    });
                }, 300);
            }}
            onExploreTemplates={() => navigate({ to: `${getBasePath()}templates` })}
            onStartFromScratch={() => {
                navigate({ to: `${getBasePath()}workspaces` });
                setTimeout(() => {
                    openNewBlankWorkspaceAction$.next('blank');
                }, 300);
            }}
            onViewPlans={() => navigate({ to: `${getBasePath()}team/${selectedOrganizationUid}/plan` })}
        />
    );
};
