import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { useObservableState } from 'observable-hooks';
import React, { PropsWithChildren } from 'react';
import { configTopic$ } from '../store/config';
import { readLocalStorage, saveLocalStorage } from '../utils/localStorage';

const AuthProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
    const { auth0 } = useObservableState(configTopic$);
    const originUid = new URLSearchParams(window.location.search).get('originUid');

    const utmMedium = new URLSearchParams(window.location.search).get('utm_medium');
    const utmSource = new URLSearchParams(window.location.search).get('utm_source');
    const utmCampaign = new URLSearchParams(window.location.search).get('utm_campaign');

    const referrer = document.referrer;

    const stitchInitialUid: string = readLocalStorage('stitchInitialUid', '');

    const onRedirectCallback = (appState?: AppState): void => {
        if (appState?.target) {
            const redirectUrl = appState?.target;
            window.location.replace(redirectUrl);
        }
        saveLocalStorage('isLoginEvent', true);
    };

    return (
        <Auth0Provider
            domain={auth0.domain}
            clientId={auth0.clientId}
            redirectUri={window.location.origin}
            useRefreshTokens={true}
            onRedirectCallback={onRedirectCallback}
            audience={auth0.apiUrl}
            originUid={originUid}
            utmMedium={utmMedium}
            utmSource={utmSource}
            utmCampaign={utmCampaign}
            referrerSite={referrer}
            stitchInitialUid={stitchInitialUid !== 'N/A' ? stitchInitialUid : undefined}
            cacheLocation="localstorage"
        >
            {children}
        </Auth0Provider>
    );
};

export default AuthProvider;
