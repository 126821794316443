import { Outlet } from 'react-location';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SRConnectIcon } from '../../icons/SRConnectIcon';
import { StyledPageContainer } from '../../common/LayoutComponents';

interface OnboardingPageProps {
    useRouter?: boolean;
}

const StyledTitleContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(1.5),
    marginBottom: theme.spacing(3),
}));

export const OnboardingPage: React.FC<OnboardingPageProps> = ({ useRouter = true }) => {
    return (
        <StyledPageContainer>
            <StyledTitleContainer>
                <SRConnectIcon />
                <Typography variant="h5">ScriptRunner Connect</Typography>
            </StyledTitleContainer>
            {useRouter && <Outlet />}
        </StyledPageContainer>
    );
};
