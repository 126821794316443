import { configTopic$, stitchSession$ } from '../../../store/config';
import { getFetchOptions } from '../../../utils/fetch';
import { InformativeError } from '../../../utils/error';

interface SaveTokenRequest {
    connectionId: string;
    appId: string;
    signingSecret: string;
    botUserOAuthAccessToken: string;
    instanceUrl: string;
}

export const saveToken = async (request: SaveTokenRequest): Promise<void> => {
    const fetchOptions = getFetchOptions({ Authorization: stitchSession$.value?.jwt ?? '' }, request);
    const baseUrl = configTopic$.value.connection?.slack?.baseUrl;
    if (!baseUrl) {
        throw new Error('No Slack Connector URL configured in meta');
    }

    const response = await fetch(`${baseUrl}/save`, fetchOptions);
    if (!response.ok) {
        const res = await response.text();
        console.error('Error while saving Slack token: ' + res);

        try {
            const message = JSON.parse(res);
            if (message && message.error) {
                throw new InformativeError(message.error);
            } else {
                throw Error('Failed to parse response.');
            }
        } catch (err) {
            throw Error(
                'Failed to save Slack application details, please try again, if the issue persists please contact support.'
            );
        }
    }
};
