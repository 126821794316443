import { useState } from 'react';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Button } from '../../../common/buttons/Button';
import { InformationChip } from '../../../common/chips/InformationChip';
import { ProductIcon } from '../../../icons/ProductIcon';
import { OnboardingTemplateCard } from './OnboardingTemplateCard';
import { OnboardingTemplateListingQuickFilterButton } from './OnboardingTemplateListingQuickFilterButton';
import {
    StyledTemplateBox,
    StyledTemplateBoxContentRight,
    StyledTemplateBoxHeaderRight,
    StyledTemplateBoxLeftSide,
    StyledTemplateBoxRightSide,
} from '../../OnboardingComponents';
import { TextField } from '../../../common/inputs/TextField';
import { StyledBorderBoxContent } from '../../../common/LayoutComponents';

export interface OnboardingTemplateListingProps {
    apps: string[];
    templates?: {
        apps: string[];
        name: string;
        uid: string;
    }[];
    onGoBack: () => void;
    onSelectTemplate: (uid: string) => void;
}

const StyledTemplateBoxHeaderLeft = styled(Box)(({ theme }) => ({
    alignItems: 'flex-start',
    display: 'flex',
    flex: '0 0 120px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
}));

const StyledTemplateBoxContentLeft = styled(StyledBorderBoxContent)(() => ({
    flexGrow: 1,
    overflowY: 'auto',
}));

const StyledQuickFilterButtonContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
}));

const StyledTemplateBoxHeaderRightUpper = styled(Box)(() => ({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
}));

const StyledTemplateBoxHeaderRightLower = styled(Box)(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    gap: theme.spacing(1.5),
    width: '100%',
}));

export const OnboardingTemplateListing: React.FC<OnboardingTemplateListingProps> = ({
    apps = [],
    templates = [],
    onGoBack,
    onSelectTemplate,
}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [quickFilters, setQuickFilters] = useState<string[]>([]);

    const getAppTemplateCount = (app: string): number => templates.filter((t) => t.apps.includes(app)).length;

    const handleApplyQuickFilter = (app: string): void => {
        let updatedFilters: string[] = [];
        if (quickFilters.includes(app)) {
            updatedFilters = quickFilters.filter((qf) => qf !== app);
        } else {
            updatedFilters = [...quickFilters, app];
        }
        setQuickFilters(updatedFilters);
    };

    const filteredTemplates = templates.filter((temp) => {
        let keep = true;
        if (quickFilters.length > 0) {
            keep = quickFilters.some((qf) => temp.apps.includes(qf));
        }
        if (keep && searchTerm && searchTerm.trim().length > 0) {
            keep = temp.name.toLocaleLowerCase().includes(searchTerm.trim().toLocaleLowerCase());
        }
        return keep;
    });

    return (
        <StyledTemplateBox>
            <StyledTemplateBoxLeftSide>
                <StyledTemplateBoxHeaderLeft>
                    <Button size="small" startIcon={<ArrowBackOutlinedIcon />} variant="text" onClick={onGoBack}>
                        Back
                    </Button>
                    <OnboardingTemplateListingQuickFilterButton
                        count={templates.length}
                        label="All templates"
                        selected={quickFilters.length === 0}
                        onClick={() => setQuickFilters([])}
                    />
                </StyledTemplateBoxHeaderLeft>
                <Divider />
                <StyledTemplateBoxContentLeft>
                    <StyledQuickFilterButtonContainer>
                        {apps.map((app) => {
                            return (
                                <OnboardingTemplateListingQuickFilterButton
                                    key={app}
                                    count={getAppTemplateCount(app)}
                                    icon={<ProductIcon name={app} />}
                                    label={app}
                                    selected={quickFilters.includes(app)}
                                    onClick={() => handleApplyQuickFilter(app)}
                                />
                            );
                        })}
                    </StyledQuickFilterButtonContainer>
                </StyledTemplateBoxContentLeft>
            </StyledTemplateBoxLeftSide>
            <StyledTemplateBoxRightSide>
                <StyledTemplateBoxHeaderRight>
                    <StyledTemplateBoxHeaderRightUpper>
                        <Typography variant="h6">All templates</Typography>
                        <InformationChip
                            label={`${filteredTemplates.length} Result${filteredTemplates.length === 1 ? '' : 's'}`}
                            severity="grey"
                        />
                    </StyledTemplateBoxHeaderRightUpper>
                    <StyledTemplateBoxHeaderRightLower>
                        <TextField
                            sx={{ flexGrow: 1, flexShrink: 2 }}
                            label={null}
                            placeholder="Search"
                            size="small"
                            startIcon={<SearchOutlinedIcon />}
                            value={searchTerm}
                            onChange={(event) => setSearchTerm(event.target.value)}
                        />
                        <Button
                            onClick={() => setQuickFilters([])}
                            variant="outlined"
                            startIcon={<RestartAltOutlinedIcon />}
                        >
                            Reset all
                        </Button>
                    </StyledTemplateBoxHeaderRightLower>
                </StyledTemplateBoxHeaderRight>
                <Divider />
                <StyledTemplateBoxContentRight>
                    <Grid container spacing={2}>
                        {filteredTemplates.map((t) => (
                            <Grid xs={4}>
                                <OnboardingTemplateCard
                                    key={t.uid}
                                    apps={t.apps}
                                    name={t.name}
                                    uid={t.uid}
                                    onSelect={onSelectTemplate}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </StyledTemplateBoxContentRight>
            </StyledTemplateBoxRightSide>
        </StyledTemplateBox>
    );
};
