import { useState } from 'react';
import {
    styled,
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    FormControl,
    TextField,
    Button,
    MenuItem,
} from '@mui/material';
import { UsersWithOnboardingData, GetUserOnboardingDataRequest } from '../../data/report/userOnboardingData';
import { PageContainer } from '../app-main/PageComponentsForDeprecation';
import { ProductIcon } from '../icons/ProductIcon';

export interface UserOnboardingDataReportPageProps extends GetUserOnboardingDataRequest {
    users?: UsersWithOnboardingData;
    onSearch: (
        userType: GetUserOnboardingDataRequest['userType'],
        status: GetUserOnboardingDataRequest['status'],
        termsAccepted: GetUserOnboardingDataRequest['termsAccepted']
    ) => void;
}

const StyledIconContainer = styled('div')(() => ({
    display: 'flex',
    flexWrap: 'wrap',
    width: 300,
}));

export const UserOnboardingDataReportPage: React.FC<UserOnboardingDataReportPageProps> = ({
    status = 'all',
    users = [],
    userType = 'all',
    termsAccepted = 'all',
    onSearch,
}) => {
    const [userTypeFilterValue, setUserTypeFilterValue] = useState(userType);
    const [statusFilterValue, setStatusFilterValue] = useState(status);
    const [termsAcceptedFilterValue, setTermsAcceptedFilterValue] = useState(termsAccepted);
    return (
        <PageContainer sx={{ bgcolor: 'background.paper' }}>
            <Box pb={3}>
                <Typography variant="h3">User Onboarding Data</Typography>
            </Box>
            <Box
                sx={{
                    '& .MuiFormControl-root': { m: 1 },
                }}
            >
                <div>
                    <FormControl>
                        <TextField
                            select
                            variant="outlined"
                            label="Filter by User Type"
                            value={userTypeFilterValue}
                            onChange={(event) =>
                                setUserTypeFilterValue(event.target.value as GetUserOnboardingDataRequest['userType'])
                            }
                        >
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="non-adaptavist">Non-Adaptavist</MenuItem>
                            <MenuItem value="adaptavist">Adaptavist Only</MenuItem>
                            <MenuItem value="non-stitch">Non-Stitch</MenuItem>
                        </TextField>
                    </FormControl>
                    <FormControl>
                        <TextField
                            select
                            variant="outlined"
                            label="Filter by Status"
                            value={statusFilterValue}
                            onChange={(event) =>
                                setStatusFilterValue(event.target.value as GetUserOnboardingDataRequest['status'])
                            }
                        >
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="registered">Registered</MenuItem>
                            <MenuItem value="active">Active</MenuItem>
                        </TextField>
                    </FormControl>
                </div>
                <div>
                    <FormControl>
                        <TextField
                            select
                            variant="outlined"
                            label="Filter by T&C"
                            value={termsAcceptedFilterValue}
                            onChange={(event) =>
                                setTermsAcceptedFilterValue(
                                    event.target.value as GetUserOnboardingDataRequest['termsAccepted']
                                )
                            }
                        >
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="accepted">Accepted</MenuItem>
                            <MenuItem value="not accepted">Not Accepted</MenuItem>
                        </TextField>
                    </FormControl>
                </div>
                <Box sx={{ paddingLeft: 2 }}>
                    <Typography>Total Users: {users.length}</Typography>
                </Box>
                <div>
                    <Button
                        variant="contained"
                        onClick={() => onSearch(userTypeFilterValue, statusFilterValue, termsAcceptedFilterValue)}
                        sx={{ m: 2 }}
                    >
                        Search
                    </Button>
                </div>
            </Box>

            {users.length > 0 ? (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>User ID</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>First Name</TableCell>
                            <TableCell>Last Name</TableCell>
                            <TableCell>Company</TableCell>
                            <TableCell>Role</TableCell>
                            <TableCell>App Preferences</TableCell>
                            <TableCell>Scripting Level</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>T&C</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((u) => {
                            const icons = u.appPreference && (
                                <StyledIconContainer>
                                    {u.appPreference.map((ctp) => (
                                        <Tooltip title={ctp} describeChild>
                                            <Box padding={0.5}>
                                                <ProductIcon name={ctp} />
                                            </Box>
                                        </Tooltip>
                                    ))}
                                </StyledIconContainer>
                            );

                            return (
                                <TableRow id={u.uid} key={u.uid}>
                                    <TableCell>{u.uid}</TableCell>
                                    <TableCell>{u.email}</TableCell>
                                    <TableCell>{u.firstName}</TableCell>
                                    <TableCell>{u.lastName}</TableCell>
                                    <TableCell>{u.company}</TableCell>
                                    <TableCell>{u.role}</TableCell>
                                    <TableCell>{icons}</TableCell>
                                    <TableCell>{u.scriptingLevel}</TableCell>
                                    <TableCell>{u.status}</TableCell>
                                    <TableCell>{u.termsAccepted ? 'Accepted' : 'Not accepted'}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            ) : (
                <div>No users found</div>
            )}
        </PageContainer>
    );
};
