import { useObservableState } from 'observable-hooks';
import { EditScriptNameDialog } from '../../components/workspace-dialogs/EditScriptNameDialog';
import { selectedEnvironmentUid$ } from '../../store/workspace';
import {
    closeEditScriptNameDialogAction$,
    editScriptNameAction$,
    editScriptNameDialogErrors$,
    editScriptNameDialogLoading$,
    editScriptNameDialogOpen$,
    savedScriptDetails$,
    selectedScriptUid$,
} from '../../store/workspace/script';

export const EditScriptNameDialogContainer: React.FC = () => {
    const open = useObservableState(editScriptNameDialogOpen$);
    const errors = useObservableState(editScriptNameDialogErrors$);
    const loading = useObservableState(editScriptNameDialogLoading$);
    const selectedScriptUid = useObservableState(selectedScriptUid$);
    const selectedEnvironmentUid = useObservableState(selectedEnvironmentUid$);

    const scriptKey = `${selectedScriptUid ?? ''}_${selectedEnvironmentUid ?? ''}`;
    const selectedSavedScript = savedScriptDetails$.value[scriptKey];

    return (
        <EditScriptNameDialog
            scriptName={selectedSavedScript?.name ?? ''}
            open={open}
            errors={errors}
            loading={loading}
            onCancel={() => closeEditScriptNameDialogAction$.next()}
            onSave={(name) => editScriptNameAction$.next(name)}
        />
    );
};
