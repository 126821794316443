import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import { CustomTextFieldProps, TextField } from '../TextField';
import { IconCopyButton } from '../../IconCopyButton';

type CopyTextFieldProps = Omit<CustomTextFieldProps, 'placeholder' | 'onChange'>;

const StyledTextFieldContainer = styled(Box)<{ fullWidth?: boolean }>(({ fullWidth, theme }) => ({
    alignItems: 'center',
    display: 'flex',
    gap: theme.spacing(1),
    width: fullWidth ? '100%' : 'unset',
    '& .MuiButtonBase-root': {
        padding: theme.spacing(3),
    },
}));

export const CopyTextField: React.FC<CopyTextFieldProps> = (props) => {
    return (
        <StyledTextFieldContainer fullWidth={props.fullWidth}>
            <TextField placeholder="" {...props} />
            <IconCopyButton value={`${props.value}`} />
        </StyledTextFieldContainer>
    );
};
